
import { newConnectConfig } from "./contant";
import Axios from "axios";

export const matcher_service = {
  affect,
  validate,
  reject,
  deleteUser,
  matching
};

function deleteUser(userId) {
  const url =  `${newConnectConfig.API_PATH}/matchings/${userId}`;
  return Axios({
    method:'delete',
    url,
    headers: {"Authorization" : `Bearer ${localStorage.getItem('token')}`}
  });
}

function reject( mission_id ) {
  const url =  `${newConnectConfig.API_PATH}/matchings/${mission_id}/reset`;

  return Axios({
    method:'get',
    url,
    headers: {"Authorization" : `Bearer ${localStorage.getItem('token')}`}
  });
}

function validate( matching_id ) {
  const url =  `${newConnectConfig.API_PATH}/matchings/${matching_id}/validate`;

  return Axios({
    method:'get',
    url,
    headers: {"Authorization" : `Bearer ${localStorage.getItem('token')}`}
  });

}

function affect( content ) {
    const url =  `${newConnectConfig.API_PATH}/matchings/multiple`;
    return Axios({
      method:'post',
      url,
      headers: {"Authorization" : `Bearer ${localStorage.getItem('token')}`},
      data: content
    });
}

function matching( content ) {
    const url =  `${newConnectConfig.API_PATH}/matchings`;
    return Axios({
      method:'post',
      url,
      headers: {"Authorization" : `Bearer ${localStorage.getItem('token')}`},
      data: content
    });
}

