import React from 'react'
import cx from 'classnames/bind.js'
import {usePrevious} from '../../hooks/usePrevious';

const BooleanToggler = ({offLabel, onLabel, onToggle, defaultValue, background, disabled}) => {
  const [isOn, setIsOn] = React.useState(defaultValue);
  const previous = usePrevious(isOn)
  React.useEffect(() => {
    if(previous !== undefined && previous !== isOn) {
      onToggle(isOn)
    }
  }, [isOn]);


  return (
    <div
      style={{
        background: background !== undefined ? background : '#F2F2F4'
      }}
      onClick={() => {
        if(!disabled) {
          setIsOn(!isOn)
        }
      }}
      data-text={isOn ? onLabel : offLabel }
      className={cx({
        "boolean-toggler": true,
        "boolean-toggler--on": isOn,
        "boolean-toggler--disabled": disabled
      })}
    />
  )
}

export default BooleanToggler
