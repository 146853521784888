import React, { Component } from 'react'
import '../../styles/components/profile_container.css'
import Axios from "axios";
import { profile_creators } from '../../reducers/Profile/actions/_update_profile';
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux';
import { hasCap } from '../../helpers/app';
import { newConnectConfig } from '../../_services/contant';
import { withRouter } from 'react-router-dom';
import AffectExpertMission from './components/AffectExpertMission';
import EditField from "./editing/EditField";
import moment from 'moment'

class PictureProfileContainer extends Component {

  constructor (props) {
    super(props);
    this.handleClick = this.handleClick.bind(this);
    this.fileUploader = React.createRef()
    this.state = {
      procesing: false,
      affect_mission_popup: false
    }
  }

  renderPicture = () => {

    return(
      <React.Fragment>
        { ( this.props.match.path === "/my-profile" || hasCap('MANAGER', this.props.role ) ? <div onClick={this.handleClick} className="overlay"><i className="fas fa-camera" /></div> : null )  }
        { this.props.profile.profilePhoto ? <img src={this.props.profile.profilePhoto.contentUrl} alt=""/> : null }
        <input onChange={this.onChangeFile.bind(this)} type="file" id="file" ref="fileUploader" style={{display: "none"}}/>
      </React.Fragment>
    )
  }

  onChangeFile(e) {
      const self = this;
      const files = e.target.files || e.dataTransfer.files;
      const file = files[0]; //this will not work.
      const formData = new FormData();
      formData.append('file', file, file.filename);
      this.setState({procesing: true})
      const url = `${newConnectConfig.API_PATH}/media_objects`;
      const request =  Axios({
          method:'post',
          url,
          headers: {
            'Content-Type': 'multipart/form-data',
            'Authorization' : `Bearer ${localStorage.getItem('token')}`
          },
          data: formData
      })

      request.then(function(response){
        self.setState({procesing: false})
        self.props.notifiHeaderPp(response.data.contentUrl)
        self.props.updateProfile(
          self.props.editing,
          {
            "profilePhoto" : {
                "id" : response.data.id
            }
          }
        )
      });
  }
  handleClick(e) {
    this.refs.fileUploader.click();
  }

  render() {
    return (
      <div className="picture">
        <div className="profile-container">
          <div className="profile-container__img">
            {this.state.procesing && <span className="loading"><img src={require('../../assets/images/processing.gif')} alt="processing"/></span> }
            {this.renderPicture()}
          </div>
        </div>
        <div className="name">
          <span className="t-header-large">
            <span>
              {this.props.profile.firstName}
              <EditField defaultValue={this.props.profile.firstName} type='firstName' onChange={ (value,type) => this.props.onProfileChange(value, type)} />
            </span>
            <span className="ml-2">
              {this.props.profile.lastName}
              <EditField defaultValue={this.props.profile.lastName} type='lastName' onChange={ (value,type) => this.props.onProfileChange(value, type)} />
            </span>
          </span>
          <h3 className="subtitle">{this.props.profile.position && this.props.profile.position.name}</h3>
          <ul className="socials">
            <li className='relative'>
              <a target="_blank" rel="noreferrer noopener" href={this.props.profile.twitter}>
                <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path fillRule="evenodd" clipRule="evenodd" d="M29.1921 8.11609C28.4441 9.21049 27.5401 10.1425 26.4801 10.9121C26.4913 11.0681 26.4969 11.3025 26.4969 11.6153C26.4969 13.0665 26.2849 14.5145 25.8601 15.9593C25.4361 17.4049 24.7921 18.7913 23.9265 20.1193C23.0617 21.4481 22.0321 22.6225 20.8377 23.6433C19.6441 24.6649 18.2041 25.4793 16.5185 26.0881C14.8337 26.6961 13.0305 27.0001 11.1121 27.0001C8.08651 27.0001 5.31931 26.1913 2.80811 24.5729C3.19931 24.6169 3.63371 24.6401 4.11371 24.6401C6.62491 24.6401 8.86331 23.8689 10.8273 22.3289C9.65531 22.3073 8.60571 21.9473 7.68011 21.2489C6.75291 20.5521 6.11691 19.6625 5.77131 18.5793C6.14011 18.6353 6.48011 18.6633 6.79211 18.6633C7.27211 18.6633 7.74731 18.6025 8.21531 18.4793C6.96491 18.2225 5.92971 17.6001 5.10971 16.6121C4.28971 15.6241 3.87931 14.4785 3.87931 13.1721V13.1049C4.63771 13.5289 5.45291 13.7585 6.32411 13.7913C5.58731 13.3001 5.00091 12.6585 4.56571 11.8665C4.13131 11.0745 3.91291 10.2145 3.91291 9.28809C3.91291 8.30649 4.15771 7.39609 4.64971 6.55929C6.00011 8.22249 7.64331 9.55289 9.58011 10.5521C11.5161 11.5513 13.5889 12.1065 15.7993 12.2185C15.7097 11.7945 15.6649 11.3809 15.6649 10.9793C15.6649 9.48329 16.1929 8.20809 17.2465 7.15289C18.3017 6.09929 19.5769 5.57129 21.0729 5.57129C22.6353 5.57129 23.9521 6.14089 25.0233 7.27929C26.2401 7.04489 27.3841 6.60889 28.4553 5.97289C28.0417 7.25689 27.2497 8.25049 26.0777 8.95289C27.1161 8.84249 28.1537 8.56329 29.1921 8.11609Z" fill="#00B5D4"/>
                </svg>
              </a>
              <EditField defaultValue={this.props.profile.twitter} type='twitter' onChange={ (value,type) => this.props.onProfileChange(value, type)} />
            </li>
            <li className='relative'>
              <a target="_blank" rel="noreferrer noopener" href={this.props.profile.linkedin}>
                <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path fillRule="evenodd" clipRule="evenodd" d="M6.05172 2.6665C4.00614 2.6665 2.66666 4.00879 2.66666 5.77473C2.66666 7.50151 3.96699 8.88435 5.97342 8.88435H6.01257C8.1001 8.88435 9.39624 7.50151 9.39624 5.77473C9.35989 4.00879 8.1015 2.6665 6.05312 2.6665H6.05172ZM30.5736 19.0158V29.3332H24.592V19.7065C24.592 17.2876 23.7265 15.6377 21.5621 15.6377C19.9094 15.6377 18.9251 16.7507 18.493 17.8259C18.335 18.2104 18.2945 18.7459 18.2945 19.2842V29.3332H12.3101C12.3101 29.3332 12.3912 13.03 12.3101 11.3396H18.2945V13.8899L18.2553 13.9487H18.2945V13.8899C19.0887 12.6665 20.5079 10.9173 23.686 10.9173C27.6219 10.9173 30.5736 13.49 30.5736 19.0158ZM9.00334 29.3332H3.0218V11.3396H9.00334V29.3332Z" fill="#00B5D4"/>
                </svg>
              </a>
              <EditField defaultValue={this.props.profile.linkedin} type='linkedin' onChange={ (value,type) => this.props.onProfileChange(value, type)}/>
            </li>
          </ul>
          <ul className="consultant-details">
            {
              !this.props.profile.externe ?
                <li className='relative'>
                  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path fillRule="evenodd" clipRule="evenodd"
                          d="M12 7V3H2V21H22V7H12ZM6 19H4V17H6V19ZM6 15H4V13H6V15ZM6 11H4V9H6V11ZM6 7H4V5H6V7ZM10 19H8V17H10V19ZM10 15H8V13H10V15ZM10 11H8V9H10V11ZM10 7H8V5H10V7ZM20 19H12V17H14V15H12V13H14V11H12V9H20V19ZM18 11H16V13H18V11ZM18 15H16V17H18V15Z"
                          fill="#222222"/>
                  </svg>
                  <span>{this.props.profile.workplace && this.props.profile.workplace.name}</span>
                  <EditField
                    defaultValue={this.props.profile.workplace && this.props.profile.workplace.id}
                    type='workplace'
                    onChange={(value, type) => this.props.onProfileChange(value, type)}
                    fieldType='select'
                    options={this.props.selectDatas}
                  />
                </li> : <li className='relative'>
                  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path fillRule="evenodd" clipRule="evenodd"
                          d="M12 7V3H2V21H22V7H12ZM6 19H4V17H6V19ZM6 15H4V13H6V15ZM6 11H4V9H6V11ZM6 7H4V5H6V7ZM10 19H8V17H10V19ZM10 15H8V13H10V15ZM10 11H8V9H10V11ZM10 7H8V5H10V7ZM20 19H12V17H14V15H12V13H14V11H12V9H20V19ZM18 11H16V13H18V11ZM18 15H16V17H18V15Z"
                          fill="#222222"/>
                  </svg>
                  <span>{this.props.profile.city && this.props.profile.city}</span>
                  <EditField defaultValue={this.props.profile.city && this.props.profile.city}
                             type='city' onChange={(value, type) => this.props.onProfileChange(value, type)}/>
                </li>
            }
            <li className='relative'>
              <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path fillRule="evenodd" clipRule="evenodd" d="M9 2H15C16.6569 2 18 3.34315 18 5V19C18 20.6569 16.6569 22 15 22H9C7.34315 22 6 20.6569 6 19V5C6 3.34315 7.34315 2 9 2ZM9 4C8.44772 4 8 4.44772 8 5V19C8 19.5523 8.44772 20 9 20H15C15.5523 20 16 19.5523 16 19V5C16 4.44772 15.5523 4 15 4H9ZM11 18C11 18.5523 11.4477 19 12 19C12.5523 19 13 18.5523 13 18C13 17.4477 12.5523 17 12 17C11.4477 17 11 17.4477 11 18Z" fill="black"/>
              </svg>
              <span>{this.props.profile.tel}</span>
              <EditField defaultValue={this.props.profile.tel} type='tel' onChange={ (value,type) => this.props.onProfileChange(value, type)}/>
            </li>
            <li className='relative'>
              <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M17.5567 7.67725L18.4075 6.92399C18.1357 6.61908 17.8404 6.33594 17.5244 6.07715L16.8052 6.95632C17.0744 7.17629 17.3257 7.41739 17.5567 7.67725Z" fill="black"/>
                <path d="M18.6722 9.43211L19.7147 8.98458C19.5535 8.60894 19.3622 8.24703 19.1424 7.90234L18.1855 8.51039C18.3722 8.8041 18.5349 9.11234 18.6722 9.43211Z" fill="black"/>
                <path d="M19.7658 10.9198L16.5287 14.1568L17.3307 14.9589L19.0851 13.2068C18.4185 16.7432 15.0113 19.0697 11.4748 18.4031C7.93832 17.7366 5.61183 14.3293 6.27841 10.7928C6.94499 7.25634 10.3523 4.92986 13.8887 5.59643C14.6137 5.73306 15.3101 5.99181 15.9484 6.36167L16.5157 5.38097C12.859 3.25262 8.16942 4.49155 6.04111 8.14817C3.91279 11.8048 5.15169 16.4944 8.80831 18.6227C12.4649 20.751 17.1545 19.5121 19.2829 15.8555C19.791 14.9824 20.1215 14.0175 20.2553 13.0162L22.198 14.9589L23 14.1568L19.7658 10.9198Z" fill="black"/>
                <path d="M11.2578 6.89844V13.1377L13.7535 15.0095L14.4342 14.102L12.3922 12.5705V6.89844H11.2578Z" fill="black"/>
              </svg>
              <span>{ moment(this.props.profile.updatedAt).format('DD/MM/YYYY k:ss') }</span>
            </li>
            {
              this.props.profile.externe  &&
                <>
                  <li className='relative'>
                    <svg width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M20.4551 20H4.45508C3.92465 20 3.41594 19.7893 3.04086 19.4142C2.66579 19.0391 2.45508 18.5304 2.45508 18V5.913C2.47751 5.39779 2.69809 4.91116 3.07076 4.5547C3.44343 4.19824 3.93938 3.99951 4.45508 4H20.4551C20.9855 4 21.4942 4.21071 21.8693 4.58579C22.2444 4.96086 22.4551 5.46957 22.4551 6V18C22.4551 18.5304 22.2444 19.0391 21.8693 19.4142C21.4942 19.7893 20.9855 20 20.4551 20ZM4.45508 7.868V18H20.4551V7.868L12.4551 13.2L4.45508 7.868ZM5.25508 6L12.4551 10.8L19.6551 6H5.25508Z" fill="black"/>
                    </svg>
                    <span>{this.props.profile.email}</span>
                  </li>
                  <li className='relative'>
                    <i className="fas fa-hourglass-start" />
                    <span>{this.props.profile.sourceType}</span>
                    {(hasCap('ADMIN', this.props.role) || this.props.role === 'EXTERNE') &&
                    <EditField
                      defaultValue={this.props.profile.sourceType}
                      type='sourceType'
                      onChange={(value, type) =>  this.props.updateProfile(
                        this.props.profile.id,
                        {[type]: value.id}
                      )}
                      fieldType='select'
                      options={[
                        { label: 'Indépendant', value: 'Indépendant'},
                        { label: 'Société partenaire', value: 'Société partenaire'},
                        { label: 'Société portage', value: 'Société portage'}
                      ]}
                    />}
                  </li>
                  <li className='relative'>
                    <i className="fas fa-hourglass-start" />
                    <span>{this.props.profile.source}</span>
                    {(hasCap('ADMIN', this.props.role) || this.props.role === 'EXTERNE') &&
                    <EditField defaultValue={this.props.profile.source} type='source' onChange={(value, type) => this.props.onProfileChange(value, type)}/>
                    }
                  </li>
                </>
            }
            {this.props.profile.externe && this.props.profile.hasRealizedMission &&
              <li className="relative">
                <svg width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path fill-rule="evenodd" clip-rule="evenodd" d="M22.3493 0.00021492H2.31671C1.24719 -0.0154712 0.363453 0.829438 0.333008 1.89675V22.0893C0.355929 23.1621 1.24188 24.0154 2.31671 23.9998H22.3493C23.4241 24.0154 24.3101 23.1621 24.333 22.0893V1.89675C24.3026 0.829438 23.4188 -0.0154712 22.3493 0.00021492ZM13.0365 2.95713C13.0604 3.89827 12.8974 4.83476 12.557 5.71179L12.0638 7.21309L8.80347 10.3534L12.2419 1.88281C12.6737 2.07871 12.9742 2.48499 13.0365 2.95713ZM8.21932 4.56465C7.91104 4.09911 7.43712 3.80706 6.92114 3.76465L6.93412 3.77919C7.04663 4.50647 7.12885 5.09313 7.18078 5.53919C7.39924 7.60528 7.50762 9.68412 7.50532 11.7647L8.80349 8.27374V6.81919C8.76268 6.028 8.56299 5.25733 8.21932 4.56465ZM15.755 21.0874C14.5619 21.9484 13.0799 22.2897 11.638 22.0355C10.3013 21.8249 9.10697 21.0696 8.33078 19.9441C7.53228 18.8429 7.22375 17.4525 7.47995 16.1097C7.6977 14.7486 8.457 13.5385 9.57958 12.7634C10.7838 11.9289 12.2588 11.5992 13.6965 11.8432C14.4389 11.9897 15.144 12.2886 15.7687 12.7216C16.4987 13.2738 17.0755 14.0088 17.4429 14.8549C16.6358 15.2269 15.6929 15.1198 14.9865 14.576C13.8083 13.6162 12.1678 13.4995 10.8696 14.2832C10.1398 14.7755 9.64302 15.5528 9.49724 16.4304C9.33684 17.2904 9.5357 18.1794 10.0462 18.8844C10.5339 19.6291 11.3044 20.1342 12.1732 20.2787C13.0303 20.4524 13.9192 20.233 14.6022 19.6791C14.9527 19.4127 15.2507 19.0816 15.4805 18.7031C15.918 17.8813 16.8673 17.4896 17.7448 17.7689C17.5325 19.0967 16.8248 20.2902 15.7687 21.1013L15.755 21.0874ZM9.27421 11.2941C10.5233 10.6601 11.9356 10.4077 13.3308 10.5691L16.4968 7.4318C17.7265 6.10718 18.2919 5.14509 18.2071 4.48976C18.165 4.22414 18.0529 3.9741 17.882 3.7647L9.27421 11.2941Z" fill="black" />
                </svg>
                <span>A déjà travaillé pour ConvictionsRH</span>
              </li>
            }
          </ul>
          { hasCap('CONSULTANT_SENIOR', this.props.role) && this.props.profile.externe  && <AffectExpertMission user={this.props.profile}/>}
        </div>
      </div>
    )
  }

}

const mapStateToProps = (state) => ({
  editing : state.App.editing_profile,
  role: state.Role.role,
  selectDatas : state.SelectData.workplace
})

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({
    updateProfile: profile_creators.update_profile,
    notifiHeaderPp : (pic) => dispatch({
      type : 'SET_PROFILE_PIC',
      payload : pic
    })
  },dispatch)
}

export default connect(mapStateToProps,mapDispatchToProps)(withRouter(PictureProfileContainer ))
