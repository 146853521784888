import * as _ from "lodash";
import initialState from "./initialState";

function Consultants(state = initialState, action) {

  if (_.isUndefined(action)) {
    return state;
  }

  const {type, payload} = action;

  if (type === 'UPDATE_CAREER_COMITEE_REPORT') {
    {
      return {
        ...state,
        data: payload.data
      }
    }
  } else if (type === 'UPDATE_FILTERS_CC') {
    return {
      ...state,
      filters: payload.data
    }
  } else {
    return state;
  }
}

export default Consultants;
