import * as _ from "lodash";
import initialState from "./initialState";
import {
  EMPTY_LESS_SKILLED_LIST,
  GET_LESS_SKILLED_LIST_FAIL,
  GET_LESS_SKILLED_LIST_START,
  GET_LESS_SKILLED_LIST_SUCCESS,
  TOGGLE_DISPLAY_MATCH_BLOCKS,
  EMPTY_CONSULTANT_MISSIONS,
  GET_CONSULTANT_MISSIONS_FAIL,
  GET_CONSULTANT_MISSIONS_START,
  GET_CONSULTANT_MISSIONS_SUCCESS,
} from "./actions-types";
import {
  HYDRA_MEMBER,
  HYDRA_VIEW,
  HYDRA_TOTAL_ITEMS,
  HYDRA_NEXT,
  HYDRA_PREV
} from '../hydra';

function Consultants(state = initialState, action) {
  if (_.isUndefined(action)) {
    return state;
  }

  const { type, payload } = action;

  switch (type) {
    case "UPDATE_CONSULTANTS_LIST":
      if (payload === null) {
        return {
          ...state,
          list: [],
          prev_url: "",
          next_url: ""
        };
      } else if (payload.filter !== undefined || payload.toKeepPrevList !== undefined) {
        return {
          ...state,
          total: payload.data[HYDRA_TOTAL_ITEMS],
          list: payload.data[HYDRA_MEMBER],
          next_url: payload.data[HYDRA_VIEW] && payload.data[HYDRA_VIEW][HYDRA_NEXT],
          prev_url: payload.data[HYDRA_VIEW] && payload.data[HYDRA_VIEW][HYDRA_PREV],
        };
      } else {
        return {
          ...state,
          total: payload.data[HYDRA_TOTAL_ITEMS],
          list: [...state.list, ...payload.data[HYDRA_MEMBER]],
          next_url: payload.data[HYDRA_VIEW] && payload.data[HYDRA_VIEW][HYDRA_NEXT],
          prev_url: payload.data[HYDRA_VIEW] && payload.data[HYDRA_VIEW][HYDRA_PREV],
        };
      }

    case GET_LESS_SKILLED_LIST_START:
      return {
        ...state,
        lessSkilledLoading: true
      }
    case GET_LESS_SKILLED_LIST_SUCCESS:
      return {
        ...state,
        lessSkilledList: [...state.lessSkilledList, ...payload[HYDRA_MEMBER]],
        lessSkilledNextUrl: payload[HYDRA_VIEW] && payload[HYDRA_VIEW][HYDRA_NEXT],
        lessSkilledTotal: payload[HYDRA_TOTAL_ITEMS],
        lessSkilledLoading: false
      }
    case GET_LESS_SKILLED_LIST_FAIL:
      return {
        ...state,
        lessSkilledLoading: false
      }
    case EMPTY_LESS_SKILLED_LIST:
      return {
        ...state,
        lessSkilledList: [],
        lessSkilledNextUrl: null,
        lessSkilledTotal: 0
      }
    case TOGGLE_DISPLAY_MATCH_BLOCKS:
      return {
        ...state,
        toDisplayMatchBlocks: payload
      }
    case EMPTY_CONSULTANT_MISSIONS:
      return {
        ...state,
        consultantMissions: []
      }

    case GET_CONSULTANT_MISSIONS_START:
      return {
        ...state,
        loadingConsultantMissions: true
      }

    case GET_CONSULTANT_MISSIONS_SUCCESS:
      return {
        ...state,
        loadingConsultantMissions: false,
        consultantMissions: payload.data[HYDRA_MEMBER]
      }

    case GET_CONSULTANT_MISSIONS_FAIL:
      return {
        ...state,
        loadingConsultantMissions: false,
      }

    default:
      return state;
  }
}

export default Consultants;
