import { combineReducers } from 'redux'
import App from './App/index.js'
import Role from './Role/index.js'
import Profile from './Profile/index.js'
import SelectData from './SelectData.js'
import Consultants from './Consultants/index'
import Propale from './Propale/index'
import Livrable from './Livrable/index'
import Missions from './Missions/index'
import Towns from './Towns'
import Refere from './Refere'
import Matcher from './Matcher'
import UserMissions from './UserMissions'
import Notifications from './Notifications'
import Menu from './Menu'
import planningReducer from './Planning/planning.reducer.js';
import CrReducer from './Cr/cr.reducers'
import Ats from './Ats/listingCandidates/index.js'
import NextArrivals from './Ats/nextArrivals/index'
import MyInterviews from './Ats/myInterviews/index'
import CandidatesNeeds from './Ats/candidateNeeds/index'
import ApplicantCircle from './Ats/candidateCircles/index';
import ApplicationStatusHistory from './ApplicationStatusHistory/index';
import Circle from './Circle/index';
import UserCircle from './UserCircle/index';
import CoreSkill from './CoreSkill/index';
import Need from './Need';
import Experts from './Experts';
import CareerComiteReport from './CareerComitee/Reports';

const moduleReducers = combineReducers({
    App,
    Profile,
    Role,
    Consultants,
    Matcher,
    Refere,
    Missions,
    UserMissions,
    SelectData,
    Towns,
    Notifications,
    Menu,
    planning: planningReducer,
    cr : CrReducer,
    Propale,
    Livrable,
    ListingCandidates: Ats,
    NextArrivals,
    MyInterviews,
    CandidatesNeeds,
    ApplicantCircle,
    ApplicationStatusHistory,
    Circle,
    UserCircle,
    Need,
    CoreSkill,
    Experts,
    CareerComiteReport
})

export default moduleReducers
