import React from 'react';

const PrevNextNavigators = props => {
    const [currentPage, setCurrentPage] = React.useState(1);

    const handlePrev = () => {
        if (props.prevDisabled) {
            return;
        }
        setCurrentPage(currentPage - 1)
        props.loadPrev();
    }

    const handleNext = () => {
        if (props.nextDisabled) {
            return;
        }
        setCurrentPage(currentPage + 1)
        props.loadNext();
    }

    return (
        <div className="flex flex--vertical-space-c flex--horizontal-space-center mt-4 listing-pagination">
            <span className="prev" onClick={handlePrev}>
                <svg width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path fillRule="evenodd" clipRule="evenodd" d="M14.793 3.29285L16.2073 4.70706L8.91436 12L16.2073 19.2928L14.793 20.7071L6.08594 12L14.793 3.29285Z"
                        fill={currentPage <= 1 ? '#737375' : '#00B5D4'} />
                </svg>
            </span>
            <span className="pagination-numbers flex">
                <>
                    <span style={{ color: '#00B5D4' }}>
                        {currentPage}
                    </span>&nbsp;/&nbsp;
                    <span>
                        {props.pagesTotal}
                    </span>
                </>
            </span>
            <span className="next" onClick={handleNext}>
                <svg width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path fillRule="evenodd" clipRule="evenodd" d="M16.0859 12L8.79297 4.70706L10.2072 3.29285L18.9143 12L10.2072 20.7071L8.79297 19.2928L16.0859 12Z"
                        fill={currentPage >= props.pagesTotal ? '#737375' : '#00B5D4'} />
                </svg>
            </span>
        </div>
    )
}

export default PrevNextNavigators
