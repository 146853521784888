import React from 'react'

const ModalConfirmArchive = props => {
  return (
    <>
      <header className="blue">
        {props.icon !== undefined ? props.icon :
        <svg width="94" height="96" viewBox="0 0 94 96" fill="none" xmlns="http://www.w3.org/2000/svg">
          <circle cx="47.5" cy="52.5" r="39.5" fill="white"/>
          <path d="M26 49.3913L45.7353 69L87 28" stroke="#7ED321" strokeWidth="8" strokeLinecap="round"/>
        </svg>}
      </header>
      <div className="content">
        <p className="title">{props.label}</p>
        {props.desc && <p dangerouslySetInnerHTML={{__html: props.desc}} />}
        <div className="actions">
          <button className="white" onClick={ () => props.onCancel() }>Annuler</button>
          <button className="blue" onClick={ () => props.onConfirm() }>Valider</button>
        </div>
      </div>
    </>
  )
}

export default ModalConfirmArchive
