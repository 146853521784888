import {clearAllBodyScrollLocks, disableBodyScroll} from 'body-scroll-lock';
import React, { createContext, useState } from 'react';

export const AffectContext = createContext();

export function AffectProvider({ children }) {

  const [data, setData] = useState({
    display: false,
    affected: false,
    consultantsSelected: []
  });

  React.useEffect(() => {
    if( !data.display ) {
      setData({
        ...data,
        consultantsSelected: [],
        affected: false
      })
      clearAllBodyScrollLocks()
    } else {
      disableBodyScroll(document.querySelector('.sliding-modal__content'));

    }
  }, [data.display]);


  return (
    <AffectContext.Provider value={
      [data, setData]
    }>
      {children}
    </AffectContext.Provider>
  );
}
