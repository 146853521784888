import React from 'react'
import {withRouter} from "react-router";
import Axios from "axios";
import {newConnectConfig} from "../_services/contant";

const ValidateToken = props => {

    const [validated, setValidated] = React.useState(false);

    React.useLayoutEffect(() => {
        if (localStorage.getItem('token')) {
            Axios.post(
                `${newConnectConfig.API_PATH}/token/refresh`,
                {"refresh_token": localStorage.getItem('refresh_token')}
            )
                .then(res => {
                    localStorage.setItem('token', res.data.token);
                    localStorage.setItem('refresh_token', res.data.refresh_token);
                    setValidated(true)
                })
                .catch(() => {
                    localStorage.removeItem('token')
                    localStorage.removeItem('refresh_token')
                    window.location.replace(`/#/signin`)
                });
        } else {
            localStorage.removeItem('token')
            localStorage.removeItem('refresh_token')
            window.location.replace(`/#/signin`)
        }
        return () => {
            setValidated(false)
        };
    }, [props.location]);

    return (
        <>{(validated || !localStorage.getItem('token')) && props.children}</>
    )
}

export default withRouter(ValidateToken)
