
import { newConnectConfig } from "./contant";
import Axios from "axios";

export const referent_service = {
    get_lists
};

function get_lists(user, nextUrl = null, filter = false) {
  let url = '';
  if (!filter) {
    url = (nextUrl === null) ? `${newConnectConfig.API_PATH}${newConnectConfig.USER_ENDPOINT}?referent.id=${user}` :
      `${newConnectConfig.API_HOST}${nextUrl}`
  } else {
    url =  `${newConnectConfig.API_PATH}${newConnectConfig.USER_ENDPOINT}?referent.id=${user}${nextUrl}`;
  }

  return Axios({
    method: 'get',
    url,
    headers: { "Authorization": `Bearer ${localStorage.getItem('token')}` }
  });

}

