import * as _ from "lodash";
import initialState from "./initialState";

function Consultants(state = initialState, action) {

  if (_.isUndefined(action)) {
    return state;
  }

  const { type, payload } = action;

  switch (type) {
    case 'UPDATE_LIST_NEXT_ARRIVALS': {
      return {
        ...state,
        data: payload.data
      }
    }
    default:
      return state;
  }
}

export default Consultants;
