import React, { Component } from 'react'
import '../../styles/components/MyMission.css'
import {Link} from 'react-router-dom'
import { routePath } from '../../helpers/route_helper';
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux';
import MissionDetails from '../../components/missions/MissionDetails';
import { missions_action } from '../../reducers/Missions/actions/getMissionsList';
import { hasCap } from '../../helpers/app';

class AffectedMissionContainer extends Component {

    componentDidMount() {
        this.props.get_affected_missions()
    }

    componentWillUnmount() {
        this.props.destroyAfftectedMissions()
    }

    render() {
        const { missions } = this.props;
        return (
            <div className="container" style={{ marginTop: '30px' }}>
            <div className="row">
            <div className="col-md-4 col-lg-3">
                <div className="navbar-mission">
                    <ul className="navbar-nav">
                        {/* { this.props.role === 'CONSULTANT_CRH' ?
                        <li className="nav-item">
                            <Link to={routePath('my-missions')} className="nav-link">Mes besoins</Link>
                        </li> : null } */}
                        { hasCap('CONSULTANT_SENIOR', this.props.role) ? <li className="nav-item">
                            <Link to={routePath('my-missions/created')} className="nav-link">Mes besoins créés</Link>
                        </li> : null }

                        {/* { hasCap('MATCHEUR', this.props.role) ? <li className="nav-item active">
                            <Link to={routePath('my-missions/need_affectation')} className="nav-link">Besoins à affecter</Link>
                        </li> : null } */}
                    </ul>
                </div>
                { hasCap('CONSULTANT_SENIOR',this.props.role ) ?  <div className="content-buttons">
                    <Link to={routePath('missions/edit') } className="btn btn-primary btn-add-mission btn-lg btn-block">Créer un besoin <i className="fas fa-plus-circle"></i></Link>
                </div> : null}
            </div>
            <div className="col-md-8 col-lg-9">
            {
                missions
                ?
                missions.map( (item,i) => <MissionDetails mission={item} key={ i } /> )
                : null
            }
            </div>
        </div>
        </div>
        )
    }

}


const mapStateToProps = (state) => ({
    missions : state.Missions.list,
    role : state.Role.role
  })

  function mapDispatchToProps(dispatch){
    return bindActionCreators({
        get_affected_missions: missions_action.get_affected_missions,
        destroyAfftectedMissions : missions_action.destroyAfftectedMissions
    },dispatch);
  }



  export default connect(mapStateToProps, mapDispatchToProps)(AffectedMissionContainer)
