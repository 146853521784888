import React from 'react'

const TooltipLabelForm = ({tooltip}) => {
  return (
    <div className="ml-2 label-tooltip position-relative">
      <svg width="17" height="16" viewBox="0 0 17 16" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g clipPath="url(#clip0_11874_180463)">
          <path d="M8.5 16C6.37808 16 4.3432 15.1569 2.8432 13.6568C1.3432 12.1567 0.5 10.1218 0.5 8C0.5 5.87824 1.34312 3.8432 2.8432 2.3432C4.34328 0.8432 6.37824 0 8.5 0C10.6218 0 12.6568 0.84312 14.1568 2.3432C15.6568 3.84328 16.5 5.87824 16.5 8C16.4975 10.1213 15.6537 12.1544 14.1544 13.6544C12.6544 15.1538 10.6213 15.9976 8.5 16ZM8.5 1.1744C6.69008 1.1744 4.95376 1.89378 3.67376 3.17376C2.39376 4.45374 1.6744 6.19008 1.6744 8C1.6744 9.80992 2.39378 11.5462 3.67376 12.8262C4.95374 14.1062 6.69008 14.8256 8.5 14.8256C10.3099 14.8256 12.0462 14.1062 13.3262 12.8262C14.6062 11.5463 15.3256 9.80992 15.3256 8C15.3237 6.19056 14.6037 4.45568 13.3243 3.17568C12.0443 1.8963 10.3093 1.17632 8.5 1.1744Z" fill="#003D71"/>
          <path d="M8.49949 6.9707C8.86448 6.9707 9.16011 7.3357 9.16011 7.63133V11.8881C9.16011 12.2531 8.86448 12.5488 8.49949 12.5488C8.1345 12.5488 7.83887 12.1838 7.83887 11.8881V7.63133C7.83887 7.26634 8.1345 6.9707 8.49949 6.9707Z" fill="#003D71"/>
          <path d="M9.45463 4.76882C9.45463 5.2957 9.02713 5.72319 8.50027 5.72319C7.9734 5.72319 7.5459 5.29569 7.5459 4.76882C7.5459 4.24194 7.9734 3.81445 8.50027 3.81445C9.02713 3.81445 9.45463 4.24196 9.45463 4.76882Z" fill="#003D71"/>
        </g>
        <defs>
          <clipPath id="clip0_11874_180463">
            <rect width="16" height="16" fill="white" transform="translate(0.5)"/>
          </clipPath>
        </defs>
      </svg>
      <div className="label-tooltip__tooltip">
        <span className="position-relative">{tooltip}</span>
      </div>
    </div>
  )
}

export default TooltipLabelForm
