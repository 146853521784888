

function logoutAction( params ) {
  return (dispatch) => {
    dispatch({
      type : 'LOGOUT_ACTION',
      payload : null
    })
  }
}

export default logoutAction;