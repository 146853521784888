import cx from 'classnames/bind';
import React from 'react'
import {AffectContext} from "../../../context/AffectContext";

const RecapSelection = props => {

  const [affect, setAffect] = React.useContext(AffectContext);

  const deleteFromSelection = (consultant) => {
    setAffect({
      ...affect,
      consultantsSelected: affect.consultantsSelected.filter(item => consultant.id !== item.id)
    })
  }

  function getProfilePicture(cosultant) {
    let photo = 'https://back-mystaff.convictionsrh.com/media/5cc3ac194031a_man-user.png'

    if (cosultant.profilePhoto && cosultant.profilePhoto.contentUrl) {
      photo = cosultant.profilePhoto.contentUrl
    } else if (cosultant.profilePhoto && typeof cosultant.profilePhoto === 'string') {
      photo = cosultant.profilePhoto
    }

    return photo
  }

  return (
    <div className="recap-selection">
      <div className="flex">
        {props.selected.map(cosultant => {
          return (
            <div
              key={cosultant.id}
              className={cx({
                "recap-selection__consultant": true
              })}>
              <span className="delete" onClick={e => {
                e.stopPropagation()
                deleteFromSelection(cosultant)
              }}>
                <svg width="14" height="18" viewBox="0 0 14 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path
                    d="M12.6538 2.15385H9.69231V1.61538C9.69231 0.724668 8.96764 0 8.07692 0H5.92308C5.03236 0 4.30769 0.724668 4.30769 1.61538V2.15385H1.34615C0.603885 2.15385 0 2.75773 0 3.5V5.38462C0 5.68198 0.241096 5.92308 0.538462 5.92308H0.832731L1.29793 15.6922C1.33902 16.555 2.04777 17.2308 2.91146 17.2308H11.0885C11.9523 17.2308 12.661 16.555 12.7021 15.6922L13.1673 5.92308H13.4615C13.7589 5.92308 14 5.68198 14 5.38462V3.5C14 2.75773 13.3961 2.15385 12.6538 2.15385ZM5.38462 1.61538C5.38462 1.31849 5.62618 1.07692 5.92308 1.07692H8.07692C8.37382 1.07692 8.61539 1.31849 8.61539 1.61538V2.15385H5.38462V1.61538ZM1.07692 3.5C1.07692 3.35155 1.19771 3.23077 1.34615 3.23077H12.6538C12.8023 3.23077 12.9231 3.35155 12.9231 3.5V4.84615C12.7571 4.84615 1.76457 4.84615 1.07692 4.84615V3.5ZM11.6264 15.641C11.6127 15.9286 11.3764 16.1538 11.0885 16.1538H2.91146C2.62355 16.1538 2.3873 15.9286 2.37364 15.641L1.91087 5.92308H12.0891L11.6264 15.641Z"
                    fill="#003D71"/>
                  <path
                    d="M6.99989 15.0769C7.29725 15.0769 7.53835 14.8358 7.53835 14.5385V7.53846C7.53835 7.2411 7.29725 7 6.99989 7C6.70252 7 6.46143 7.2411 6.46143 7.53846V14.5385C6.46143 14.8358 6.70249 15.0769 6.99989 15.0769Z"
                    fill="#003D71"/>
                  <path
                    d="M9.69227 15.0769C9.98964 15.0769 10.2307 14.8358 10.2307 14.5385V7.53846C10.2307 7.2411 9.98964 7 9.69227 7C9.3949 7 9.15381 7.2411 9.15381 7.53846V14.5385C9.15381 14.8358 9.39487 15.0769 9.69227 15.0769Z"
                    fill="#003D71"/>
                  <path
                    d="M4.30775 15.0769C4.60511 15.0769 4.84621 14.8358 4.84621 14.5385V7.53846C4.84621 7.2411 4.60511 7 4.30775 7C4.01038 7 3.76929 7.2411 3.76929 7.53846V14.5385C3.76929 14.8358 4.01035 15.0769 4.30775 15.0769Z"
                    fill="#003D71"/>
                </svg>
              </span>
              <img src={getProfilePicture(cosultant)} alt="" />
              <span>
                {cosultant.fullname || `${cosultant.firstName} ${cosultant.lastName}`}
                <strong>
                  {cosultant.positionName || (cosultant.position && cosultant.position.name)}
                </strong>
              </span>
            </div>
          )
        })}
      </div>
    </div>
  )
}

export default RecapSelection
