import * as _ from 'lodash'

function Towns(state = [], action) {

    if (_.isUndefined(action)) {
        return state
    }

    const { type, payload } = action

    switch (type) {
      case 'GET_TOWNS':
       return Object.assign(payload,state)
      default:
        return state
    }

}

export default Towns
