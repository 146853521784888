import React, { useEffect, useState } from 'react'
import Axios from 'axios'
import { newConnectConfig } from '../../_services/contant'
import moment from 'moment'
import { Link } from 'react-router-dom'
import Calendar from 'react-calendar'
import '../../styles/AdminConstultants.css'


function Listing () {
  const [data, setData] = useState(null)
  const [displayCalendar, setDisplayCalendar] = useState(false)
  const [date, setDate] = useState(null)

  useEffect(
    () => {
      if (date) {
        Axios.get(
          `${newConnectConfig.API_PATH}/c_rs?draft=false&date=${moment(
            date
          ).format('YYYY-MM-DD')}`,
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem('token')}`
            }
          }
        ).then(response => {
          setData(response.data['hydra:member'])
        })
      } else {
        Axios.get(`${newConnectConfig.API_PATH}/c_rs?draft=false`, {
          headers: { Authorization: `Bearer ${localStorage.getItem('token')}` }
        }).then(response => {
          setData(response.data['hydra:member'])
        })
      }

      return () => {}
    },
    [date]
  )

  return data === null ? (
    <div className='app_loader'>Chargement...</div>
  ) : (
    <div className='container pt-5' style={{ position: 'relative' }}>
      <h1
        className='title-page'
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center'
        }}
      >
        Tous les comptes rendus de réunion
        <div>
          <span
            onClick={e => {
              e.preventDefault()
              setDisplayCalendar(!displayCalendar)
            }}
            style={{
              fontSize: 15,
              cursor: 'pointer',
              position: 'relative'
            }}
          >
            {displayCalendar ? 'Fermer' : 'Filtrer par date'}
          </span>
          {date && (
            <span
              onClick={() => {
                setDisplayCalendar(false)
                setDate(null)
              }}
              style={{
                fontSize: 15,
                cursor: 'pointer',
                position: 'relative',
                marginLeft: 10
              }}
            >
              Annuler les filtres
            </span>
          )}
        </div>
      </h1>

      <Link
        className='button-return button-return-dark mb-2 mb-md-0'
        to='/cr_matching'
        style={{
          position: 'relative',
          top: -10
        }}
      >
        <span className='button-return__icon'>
          <i className='fas fa-arrow-left' />
        </span>
        <span>retour</span>
      </Link>

      {displayCalendar && (
        <div
          style={{
            position: 'absolute',
            right: 0,
            marginTop: -80,
            zIndex: '999'
          }}
        >
          <Calendar
            style={{ width: '100%' }}
            onChange={value => {
              setDate(value)
              setDisplayCalendar(false)
            }}
            value={date || new Date()}
          />
        </div>
      )}

      <div className='content-users'>
        <div className='head-users'>
          <div className='row row-users'>
            <div className='col-lg-8'>
              <h2 className='users-title'>Date</h2>
            </div>
            <div className='col-lg-4'>
              <h2 className='users-title'>Actions</h2>
            </div>
          </div>
        </div>

        {data.map(line => (
          <div className='user'>
            <div className='row row-users align-items-lg-center'>
              <div className='col-lg-8'>
                CR du {moment(line.finishDate).format('DD MMMM YYYY')}
              </div>
              <div className='col-lg-4'>
                <Link to={`/cr_details/${line.id}`}>Voir le détail</Link>
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  )
}

export default Listing
