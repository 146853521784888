import React from 'react'
import Select from 'react-select';
import {levelDatas} from '../../helpers/app';

const ExpAdder = props => {

  const [data, setData] = React.useState({
    rating: props.edit.rating ? props.edit.rating : null,
    [props.type] : {
      id: props.edit.rating ? props.edit[props.type].id : null,
    }
  });

  const add = () => {
    props.onAdd(data)
    props.onCancel()
  }

  return (
    <>
      <div className="form-fields">
        <div className="form-group">
          <label htmlFor="">Compétence</label>
          <Select
            isSearchable={true}
            isMulti={props.isMulti !== undefined}
            onChange={ value => setData({...data, [props.type] : {id : value.value}} ) }
            options={props.selectData}
            isDisabled={props.edit.rating}
            defaultValue={
              props.edit.rating
              ? {
                  label: props.selectData.find(d => d.value === props.edit[props.type].id).label,
                  value: props.edit[props.type].id
              } : null
            }
          />
        </div>
        <div className="form-group">
          <label htmlFor="">Niveau minimum requis</label>
          <Select
            isSearchable={true}
            isMulti={props.isMulti !== undefined}
            onChange={ value => setData({...data, rating: value.value}) }
            options={props.labelTooltipContent ? props.labelTooltipContent.reduce((acc,item) =>{
              acc.push({
                label: item.label,
                value: item.rating
              })
              return acc
            }, []) : levelDatas}
            defaultValue={
              props.edit.rating
              ? {
                  label: levelDatas.find(data => data.value === props.edit.rating).label,
                  value: props.edit.rating
                } : null
            }
          />
        </div>
      </div>
      <div className="actions" style={{
        display: 'flex',
        margin: '12px auto',
        flex: 1,
        justifyContent: 'flex-end'
      }}>
        <button onClick={props.onCancel} type="button" className="button-ui button-ui--white">Annuler</button>
        <button onClick={add} disabled={ (data.rating === null || data[props.type].id === null) && props.edit.rating === undefined} type="button" className="button-ui button-ui--blue" style={{ marginLeft: 8 }}>Valider</button>
      </div>
    </>
  )
}

export default ExpAdder
