import LazyAdminConsultants from './features/admin/LazyAdminConsultants';
import LazyRepositories from './features/admin/LazyRepositories';
import LazyBesoinsCandidats from './features/ats/besoins-candidats/LazyBesoinsCandidats';
import LazyForms from './features/ats/forms/LazyForms';
import LazyJobPromise from './features/ats/job-promise/LazyJobPromise';
import LazyListCandidates from './features/ats/list-candidates/LazyListCandidates';
import LazyMyCoopted from './features/ats/my-coopted/LazyMyCoopted';
import LazyMyMeetings from './features/ats/my-meetings/LazyMyMeetings';
import LazyNextArrivals from './features/ats/next-arrivals/LazyNextArrivals';
import LazyProfileCandidate from './features/ats/profile/LazyProfileCandidate';
import LazyCcListing from './features/comite-carriere/listing-consultant/LazyCcListing';
import LazyMatrice from './features/comite-carriere/matrice/LazyMatrice';
import LazySynthese from './features/comite-carriere/synthese/LazySynthese';
import LazyExperts from './features/consultants/LazyExperts';
import LazyCreateFeedbackExt from './features/development/LazyCreateFeedbackExt';
import { routePath } from './helpers/route_helper'
import profileContainer from './containers/profileComponent'
import ConsulttantsContainer from './containers/consultants/ConsulttantsContainer'
import MissionsContainer from './containers/Missions/MissionsContainer'
import ViewMission from './containers/Missions/ViewMission'
import EditMission from './containers/Missions/EditMission'
import MyMissionsContainer from './containers/Account/MyMissionsContainer'
import CreatedMissionContainer from './containers/Account/CreatedMissionContainer'
import AffectedMissionContainer from './containers/Account/AffectedMissionContainer'
import MyRefereeContainer from './containers/Account/MyRefereeContainer'
import NotificationContainer from './containers/Notifications/NotificationContainer'
import PlanningContainer from './containers/Planning/PlanningContainer'
import PlanningEditContainer from './containers/Planning/PlanningEditContainer'
import CompteRenduReunion from './containers/Cr/CompteRenduReunion'
import signIn from './features/externe/signIn'
import signUp from './features/externe/signUp'
import onBoarding from './features/externe/onBoarding'
import Listing from './containers/Cr/Listing'
import CrDetail from './containers/Cr/CrDetail'
import LazyPropale from "./features/propale/Lazy";
import LazyEditPropale from "./features/propale/LazyEdit";
import LazyView from "./features/propale/LazyView";
import LazyViewLivrable from "./features/livrable/LazyView";
import LazyLivrable from "./features/livrable/Lazy";
import LazyEditLivrable from "./features/livrable/LazyEdit";
import LazyValidation from "./features/livrable/LazyValidation";
import LazyEvaluate from "./features/propale/LazyEvaluate";
import LazyLivrableEvaluate from "./features/livrable/LazyLivrableEvaluate";
import LazyDevelopment from "./features/development/LazyDevelopment";
import LazyCreateFeedback from "./features/development/LazyCreateFeedback";

export const routes = [
  {
    path: routePath('my-profile'),
    strict: true,
    role: 'CONSULTANT_CRH',
    type: 'private',
    handler: profileContainer,
    props: {}
  },
  {
    path: routePath('comite-carriere/matrice/:id'),
    strict: true,
    role: 'MANAGER',
    type: 'private',
    handler: LazyMatrice,
    props: {}
  },
  {
    path: routePath('comite-carriere/listing'),
    strict: true,
    role: 'MANAGER',
    type: 'private',
    handler: LazyCcListing,
    props: {}
  },
  {
    path: routePath('recruitment/candidates/forms'),
    strict: true,
    role: 'CONSULTANT_CRH',
    type: 'private',
    handler: LazyForms,
    props: {}
  },
  {
    path: routePath('recruitment/arrivals/list'),
    strict: true,
    role: 'SENIOR_MANAGER',
    type: 'private',
    handler: LazyNextArrivals,
    props: {}
  },
  {
    path: routePath('recruitment/my-interviews/list'),
    strict: true,
    role: 'CONSULTANT_CRH',
    type: 'private',
    handler: LazyMyMeetings,
    props: {}
  },
  {
    path: routePath('recruitment/my-coopted/list'),
    strict: true,
    role: 'CONSULTANT_CRH',
    type: 'private',
    handler: LazyMyCoopted,
    props: {}
  },
  {
    path: routePath('recruitment/candidates/list'),
    strict: true,
    role: 'CONSULTANT_CRH',
    type: 'private',
    handler: LazyListCandidates,
    props: {}
  },
  {
    path: routePath('recruitment/candidates/needs'),
    strict: true,
    role: 'CONSULTANT_CRH',
    type: 'private',
    handler: LazyBesoinsCandidats,
    props: {}
  },
  {
    path: routePath('recruitment/proposal/job/:applicantID'),
    strict: true,
    role: 'CONSULTANT_CRH',
    type: 'private',
    handler: LazyJobPromise,
    props: {}
  },
  {
    path: routePath('recruitment/candidate/:id'),
    strict: true,
    role: 'CONSULTANT_CRH',
    type: 'private',
    handler: LazyProfileCandidate,
    props: {}
  },
  {
    path: routePath('consultant/list/interne'),
    strict: true,
    role: 'CONSULTANT_CRH',
    type: 'private',
    handler: ConsulttantsContainer,
    props: {}
  },
  {
    path: routePath('consultant/list/externe'),
    strict: true,
    role: 'CONSULTANT_CRH',
    type: 'private',
    handler: LazyExperts,
    props: {}
  },
  {
    path: routePath('feedbacks/create'),
    strict: true,
    role: 'CONSULTANT_SENIOR',
    type: 'private',
    handler: LazyCreateFeedback,
    props: {}
  },
  {
    path: routePath('feedback/externe/edit'),
    strict: true,
    role: 'CONSULTANT_SENIOR',
    type: 'private',
    handler: LazyCreateFeedbackExt,
    props: {}
  },
  {
    path: routePath('development'),
    strict: true,
    role: 'CONSULTANT_CRH',
    type: 'private',
    handler: LazyDevelopment,
    props: {}
  },
  {
    path: routePath('development/:tab'),
    strict: true,
    role: 'CONSULTANT_CRH',
    type: 'private',
    handler: LazyDevelopment,
    props: {}
  },
  {
    path: routePath('propale'),
    strict: true,
    role: 'CONSULTANT_CRH',
    type: 'private',
    handler: LazyPropale,
    props: {}
  },
  {
    path: routePath('livrables'),
    strict: true,
    role: 'CONSULTANT_CRH',
    type: 'private',
    handler: LazyLivrable,
    props: {}
  },
  {
    path: routePath('livrables/validation'),
    strict: true,
    role: 'CONSULTANT_SENIOR',
    type: 'private',
    handler: LazyValidation,
    props: {}
  },
  {
    path: routePath('livrable/edit'),
    strict: true,
    role: 'CONSULTANT_CRH',
    type: 'private',
    handler: LazyEditLivrable,
    props: {}
  },
  {
    path: routePath('livrable/edit/:id'),
    strict: true,
    role: 'CONSULTANT_CRH',
    type: 'private',
    handler: LazyEditLivrable,
    props: {}
  },
  {
    path: routePath('livrable/view/:id'),
    strict: true,
    role: 'CONSULTANT_CRH',
    type: 'private',
    handler: LazyViewLivrable,
    props: {}
  },
  {
    path: routePath('livrable/evaluate/:id'),
    strict: true,
    role: 'CONSULTANT_SENIOR',
    type: 'private',
    handler: LazyLivrableEvaluate,
    props: {}
  },
  {
    path: routePath('livrable/evaluation/:id'),
    strict: true,
    role: 'CONSULTANT_SENIOR',
    type: 'private',
    handler: LazyLivrableEvaluate,
    props: {}
  },
  {
    path: routePath('propale/edit'),
    strict: true,
    role: 'CONSULTANT_CRH',
    type: 'private',
    handler: LazyEditPropale,
    props: {}
  },
  {
    path: routePath('propale/edit/:id'),
    strict: true,
    role: 'CONSULTANT_CRH',
    type: 'private',
    handler: LazyEditPropale,
    props: {}
  },
  {
    path: routePath('propale/view/:id'),
    strict: true,
    role: 'CONSULTANT_SENIOR',
    type: 'private',
    handler: LazyView,
    props: {}
  },
  {
    path: routePath('propale/evaluate/:id'),
    strict: true,
    role: 'CONSULTANT_SENIOR',
    type: 'private',
    handler: LazyEvaluate,
    props: {}
  },
  {
    path: routePath('propale/evaluation/:id'),
    strict: true,
    role: 'CONSULTANT_SENIOR',
    type: 'private',
    handler: LazyEvaluate,
    props: {}
  },
  {
    path: routePath('propale/reopen/:id'),
    strict: true,
    role: 'CONSULTANT_CRH',
    type: 'private',
    handler: LazyView,
    props: {}
  },
  {
    path: routePath('consultant/edit/:id'),
    strict: true,
    role: 'ADMIN',
    type: 'private',
    handler: profileContainer,
    props: {}
  },
  {
    path: routePath('consultant/view/:id'),
    strict: true,
    role: 'CONSULTANT_CRH',
    type: 'private',
    handler: profileContainer,
    props: {}
  },
  {
    path: routePath('missions'),
    strict: true,
    role: 'CONSULTANT_SENIOR',
    type: 'private',
    handler: MissionsContainer,
    props: {}
  },
  {
    path: routePath('missions/view/:id'),
    role: 'CONSULTANT_CRH',
    strict: true,
    type: 'private',
    handler: ViewMission,
    props: {}
  },
  {
    path: routePath('missions/edit'),
    strict: true,
    role: 'CONSULTANT_SENIOR',
    type: 'private',
    handler: EditMission,
    props: {}
  },
  {
    path: routePath('missions/edit/:id'),
    strict: true,
    role: 'CONSULTANT_SENIOR',
    type: 'private',
    handler: EditMission,
    props: {}
  },
  {
    path: routePath('missions/duplicate/:id'),
    strict: true,
    role: 'CONSULTANT_SENIOR',
    type: 'private',
    handler: EditMission,
    props: {}
  },
  {
    path: routePath('my-missions'),
    strict: true,
    role: 'CONSULTANT_CRH',
    type: 'private',
    handler: MyMissionsContainer,
    props: {}
  },
  {
    path: routePath('my-missions/created'),
    strict: true,
    role: 'CONSULTANT_SENIOR',
    type: 'private',
    handler: CreatedMissionContainer,
    props: {}
  },
  {
    path: routePath('my-missions/need_affectation'),
    strict: true,
    role: 'RH',
    type: 'private',
    handler: AffectedMissionContainer,
    props: {}
  },
  {
    path: routePath('admin/consultants'),
    strict: true,
    role: 'ADMIN',
    attributesUserProtection: ['isManagementController'],
    type: 'private',
    handler: LazyAdminConsultants,
    props: {}
  },
  {
    path: routePath('admin/repositories'),
    strict: true,
    role: 'ADMIN',
    type: 'private',
    handler: LazyRepositories,
    props: {}
  },
  {
    path: routePath('mes-referes'),
    strict: true,
    role: 'MANAGER',
    type: 'private',
    handler: MyRefereeContainer,
    props: {}
  },
  {
    path: routePath('notifications'),
    strict: true,
    role: 'CONSULTANT_CRH',
    type: 'private',
    handler: NotificationContainer,
    props: {}
  },
  {
    path: routePath('planning/'),
    strict: true,
    role: 'CONSULTANT_SENIOR',
    type: 'private',
    handler: PlanningContainer,
    props: {}
  },
  {
    path: routePath('planning/edit'),
    strict: true,
    role: 'CONSULTANT_CRH',
    type: 'private',
    handler: PlanningEditContainer,
    props: {}
  },
  {
    path: routePath('cr_matching'),
    strict: true,
    role: 'CONSULTANT_SENIOR',
    type: 'private',
    handler: CompteRenduReunion,
    props: {}
  },
  {
    path: routePath('cr_details/:id'),
    strict: true,
    role: 'CONSULTANT_SENIOR',
    type: 'private',
    handler: CrDetail,
    props: {}
  },
  {
    path: routePath('cr_matching/list'),
    strict: true,
    role: 'CONSULTANT_SENIOR',
    type: 'private',
    handler: Listing,
    props: {}
  },
  {
    path: routePath('signin'),
    strict: false,
    type: 'public',
    handler: signIn,
    props: {}
  },
  {
    path: routePath('signup'),
    strict: false,
    type: 'public',
    handler: signUp,
    props: {}
  },
  {
    path: routePath('onboarding'),
    strict: false,
    type: 'public',
    handler: onBoarding,
    props: {}
  },
  {
    path: routePath('/'),
    strict: true,
    role: 'CONSULTANT_CRH',
    type: 'private',
    handler: profileContainer,
    props: {}
  },
  {
    path: routePath('comite-carriere/synthese/:id'),
    strict: true,
    role: 'MANAGER',
    type: 'private',
    handler: LazySynthese,
    props: {}
  },
]
