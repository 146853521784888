import { profileService } from "../../../_services/profile_service";

export function _getUserProfile(user_id) {
    return function (dispatch) {
        if( user_id === null) {
            dispatch({
                type : 'UPDATE_PROFILE',
                payload : null
            })
            dispatch({
                type : 'EDITING_PROFILE',
                payload : null
            })
            return;
        }

        profileService.getProfile( user_id ).then(function(response){
            dispatch({
                type : 'UPDATE_PROFILE',
                payload : response.data
            })
            dispatch({
                type : 'EDITING_PROFILE',
                payload : response.data.id
            })
        })
    };
}
