import moment from 'moment';
import React from 'react'
import {Link} from 'react-router-dom';
import {missions_service} from '../../../../_services/missions';
import StarsRating from '../../../../components/StarsRating';
import DynamicDropDown from '../../../../components/ui/DynamicDropDown';
import {downloadFile} from '../../../../helpers/DownloadFile';
import MissionComments from '../../MissionComments';
import AffectArea from './AffectArea';
import MissionStatus from './MissionStatus';

const MissionResume = ({mission, onReload, postComment}) => {

  const onChange = status => {
    missions_service.update(mission.id, {status: status}).then(onReload)
  }

  return (
    <div className="container mt-5 propale-content">

      <div className="row left">
        <div className="col-sm-5">

          {mission.file &&
          <div className="row">
            <div className="info col-md-12">
              <div className="row flex flex-column">
                <span className="info__label">Brief client</span>
                <span className="info__text">
                  <button onClick={() => downloadFile(mission.file.contentUrl)} className="button-ui button-ui--blue-outline">
                    <svg width="17" height="19" viewBox="0 0 17 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path fillRule="evenodd" clipRule="evenodd" d="M7.49999 18.2072L7.49999 4.62137L2.20709 9.91426L0.79288 8.50005L8.49999 0.792939L16.2071 8.50005L14.7929 9.91426L9.49999 4.62137L9.49999 18.2072L7.49999 18.2072Z" fill="#00B5D4" />
                    </svg>
                    Télécharger le brief client
                  </button>
                </span>
              </div>
            </div>
          </div>
          }

          <div className="row">
            <div className="info col-md-12">
              <div className="row flex flex-column">
                <span className="info__label">Statut</span>
                <span className="info__text">
                  <MissionStatus onChange={onChange} mission={mission}/>
                </span>
              </div>
            </div>
          </div>

          <div className="row">
            <div className="info col-md-12">
              <div className="row flex flex-column">
                <span className="info__label">Description</span>
                <span className="info__text"  dangerouslySetInnerHTML={{ __html: mission.description }}/>
              </div>
            </div>
          </div>

          <div className="row">
            <div className="info col-md-12">
              <div className="row flex flex-column">
                <span className="info__label">Années d'expérience requises</span>
                <span className="info__text">{mission.requiredExperience}</span>
              </div>
            </div>
          </div>

          <div className="row">
            <div className="info col-md-6">
              <div className="row flex flex-column">
                <span className="info__label">Priorité</span>
                <span className="info__text">{mission.priority && mission.priority.name}</span>
              </div>
            </div>
            <div className="info col-md-6">
              <div className="row flex flex-column">
                <span className="info__label">Type</span>
                <span className="info__text">{mission.invoicingMode && mission.invoicingMode.name}</span>
              </div>
            </div>
          </div>

          <div className="row">
            <div className="info col-md-6">
              <div className="row flex flex-column">
                <span className="info__label">Date de la mission</span>
                <span className="info__text">
                  du {moment(mission.startDate).format('DD/MM/YY')}
                  <br/> au {moment(mission.finishDate).format('DD/MM/YY')}
                </span>
              </div>
            </div>
            <div className="info col-md-6">
              <div className="row flex flex-column">
                <span className="info__label">Localisation</span>
                <span className="info__text">
                  {mission.city}
                </span>
              </div>
            </div>
          </div>

          <div className="row">
            <div className="info col-md-6">
              <div className="row flex flex-column">
                <span className="info__label">Chef de projet</span>
                <span className="info__text">
                  {mission.leaderName}
                </span>
              </div>
            </div>
            <div className="info col-md-6">
              <div className="row flex flex-column">
                <span className="info__label">ETP</span>
                <span className="info__text">{mission.etp && mission.etp.name}</span>
              </div>
            </div>

            <div className="info col-md-6">
              <div className="row flex flex-column">
                <span className="info__label">Domaine d'expertise</span>
                <span className="info__text">{mission.approach && mission.approachData.text}</span>
              </div>
            </div>

          </div>

          <div className="row">
            <div className="info col-md-6">
              <div className="row flex flex-column">
                <span className="info__label">Connaissances métier</span>
                <span className="info__text">
                  {mission.missionSkills.map(data => {
                    return (
                      <div className="flex align-items-center mb-2 flex--horizontal-space-b">
                        <span className="mr-2">{data.experience && data.experience.name}</span>
                        <StarsRating key={data.id} stars={4} value={data.rating} editMode={false}/>
                      </div>
                    )
                  })}
                </span>
              </div>
            </div>
            <div className="info col-md-6">
              <div className="row flex flex-column">
                <span className="info__label">Contexte d'applications</span>
                <span className="info__text">
                  {mission.missionFields.map(data => {
                    return (
                      <div className="flex align-items-center mb-2  flex--horizontal-space-b">
                        <span className="mr-2">{data.experience && data.experience.name}</span>
                        <StarsRating key={data.id} stars={4} value={data.rating} editMode={false}/>
                      </div>
                    )
                  })}
                </span>
              </div>
            </div>
          </div>

          <div className="row">
            <div className="info col-md-6">
              <div className="row flex flex-column">
                <span className="info__label">Solutions</span>
                <span className="info__text">
                  {mission.missionSolutions.map(data => {
                    return (
                      <div className="flex align-items-center mb-1 flex--horizontal-space-b">
                        <span className="mr-2">{data.experience && data.experience.name}</span>
                        <StarsRating key={data.id} stars={4} value={data.rating} editMode={false}/>
                      </div>
                    )
                  })}
                </span>
              </div>
            </div>
            <div className="info col-md-6">
              <div className="row flex flex-column">
                <span className="info__label">Langues</span>
                <span className="info__text">
                   {mission.missionLanguages.map(data => {
                     return (
                       <div className="flex align-items-center mb-1 flex--horizontal-space-b">
                         <span className="mr-2">{data.experience && data.experience.name}</span>
                         <StarsRating key={data.id} stars={4} value={data.rating} editMode={false}/>
                       </div>
                     )
                   })}
                </span>
              </div>
            </div>
          </div>

          <div className="row">
            <div className="info col-md-12">
              <div className="row flex flex-column">
                <span className="info__label">Besoin</span>
                <span className="info__text">
                  <div className="flex flex-wrap">
                  {mission.requiredPositions.map(need => {
                    return (
                      <DynamicDropDown key={need.id} readOnly={true} defaultValue={{
                        text: need.name
                      }} center={false}/>
                    )
                  })}
                  </div>
                </span>
              </div>
            </div>
          </div>

          <div className="row">
            <Link to={`/missions/edit/${mission.id}`}
                  className="button-ui button-ui--outline" style={{width: '100%'}}>Editer le besoin</Link>
          </div>

        </div>


        <div className="col-sm-6 offset-1 right">
          {(!['LOST', 'CANCELED'].includes(mission.status)) ? <AffectArea mission={mission} onReload={onReload} /> : <AffectArea mission={mission} onReload={onReload} readOnly/>}
          <MissionComments onPostComment={coment => postComment(coment)} comments={mission.comments.reverse()}/>
        </div>
      </div>
    </div>
  )
}

export default MissionResume
