import React from 'react';

function ButtonWithControlledDisable(props) {
    const [clicked, setClicked] = React.useState(false);
    let disabled = clicked;
    const handleClick = () => {
        if (clicked) {
            return;
        }
        setClicked(true);
        if(props.onClick !== undefined) {
            props.onClick();
        }
    }

    if (props.isTargetDisabled) {
        disabled = !(!props.isTargetDisabled && !clicked);
    }

    React.useEffect(() => {
        setClicked(props.isTargetDisabled)
    }, [props.isTargetDisabled]);

    return (
        <button
            {...props.otherProps}
            disabled={disabled}
            className={props.classes || 'blue'}
            onClick={handleClick}
        >
            {props.text}
            {props.icon && <span className="ml-2" dangerouslySetInnerHTML={{__html: props.icon}}/>}
        </button>
    )
}

export default ButtonWithControlledDisable;

export function SubmitButtonWithControlledDisable(props) {
    return (
        <ButtonWithControlledDisable
            {...props}
            classes="button-ui button-ui--blue"
            otherProps={{
                ...props.otherProps,
                type: 'submit'
            }}
        />
    );
}
