import React from 'react';
import { connect } from 'react-redux';

const MissionItem = ({ id, title, client, status, employmentRef, isFirst, isLast }) => {
    return (
        <span className={`d-flex flex-column p-2 pb-${isLast ? '3' : '4'}${isFirst ? ' pt-3' : ''}`}>
            <a href={`/#/missions/view/${id}`} target="_blank" rel="noopener noreferrer">
                <u>
                    {title}
                </u>
            </a>
            <div className="mt-1 d-flex justify-content-between" style={{width: 'max-content'}}>
                <span className='client'>{client}</span>
                <span>&nbsp;-&nbsp;</span>
                <span className='status'>{status}</span>
                <span>&nbsp;-&nbsp;</span>
                <span>{employmentRef}</span>
            </div>
        </span>
    )
}

const ConsultantMissionsTooltip = props => {
    return (
        <div className="consultant-mission-tooltip-content d-flex flex-column justify-content-between text-left">
            {
                !props.loadingConsultantMissions && props.consultantMissions.length === 0 &&
                <span className="p-4">Aucune mission</span>
            }
            {
                props.loadingConsultantMissions && <span className="p-4">En cours...</span>
            }
            {
                props.consultantMissions.map((mission, index, { length, }) =>
                    <MissionItem key={index} id={mission.id} title={mission.title} client={mission.client}
                        status={mission.statusData.text} employmentRef={mission.etp.name}
                        isFirst={index === 0} isLast={index >= length - 1} />)
            }
        </div>
    )
}

const mapStateToProps = state => ({
    consultantMissions: state.Consultants.consultantMissions,
    loadingConsultantMissions: state.Consultants.loadingConsultantMissions,
});

export default connect(mapStateToProps, null)(ConsultantMissionsTooltip)
