import React, {useEffect,useState} from 'react'
import moment from 'moment'
import UserAvailibilityBubble from "./UserAvailibilityBubble";

const LineConsultant = ({user,view}) => {

  const [userAvailabities, setUserAvailabities] = useState(null);
  const [bubbleOpen, setBubbleOpen] = React.useState(null);

  useEffect(() => {
    const reduced = view.filter((item,index) =>  index < 5).reduce((acc,day) => {
      acc[moment(day).format('YYYY-MM-DD')] = {
        'am' : user[1].availabities.find( av =>  moment(av.dispo).unix() === moment(moment(day).format('YYYY-MM-DD')).unix() && av.plage === 'am' ),
        'pm' : user[1].availabities.find( av =>  moment(av.dispo).unix() === moment(moment(day).format('YYYY-MM-DD')).unix() && av.plage === 'pm' )
      }
      return acc;
    },[])
    setUserAvailabities(reduced)
  }, [user]);

  return (
    userAvailabities &&
    <div className='line-consultant'>
      <span>
        {user[0].split('-')[0]}
        <strong style={{
          display: 'block',
          color: '#06b5d3',
          fontWeight: 700
        }}>{user[0].split('-')[1]}</strong>
      </span>
      <div className="user_availabilities">
        {
          Object.values(userAvailabities).map(
            (availability, index) =>
              <UserAvailibilityBubble
                key={`availability-${index}`}
                bubbleOpen={bubbleOpen}
                setBubbleOpen={ key => setBubbleOpen(key)}
                availability={availability}
              />
          )
        }
      </div>
    </div>
  )
}

export default LineConsultant
