import React from 'react'
import '../../styles/components/viewMission.css';
import {connect} from 'react-redux'
import {bindActionCreators} from 'redux';
import {missions_action} from '../../reducers/Missions/actions/getMissionsList';
import Header from './components/Header';
import '../../features/livrable/styles/view.scss'
import MissionContent from './components/MissionContent';

function ViewMission(props) {

  React.useEffect(() => {
    props.updateMissionSingle(props.match.params.id)
  }, []);

  const onReload = () => props.updateMissionSingle(props.match.params.id)

  const postComment = comment => {
    props.postComment(
      {
        "body": comment,
        "mission": {
          "id": props.mission.id
        }
      },
      props.mission.id
    )
  }

  return (
    props.mission &&
    <div>
      <Header
        title={props.mission.title}
        subtitle={`${props.mission.client} - <span class="c-white ml-2">${props.mission.businessCode ? props.mission.businessCode : ''}</span>`}
        mission={props.mission}
        role={props.role}
        onReload={onReload}
      />
      <MissionContent
        mission={props.mission}
        onReload={onReload}
        postComment={postComment}
      />
    </div>
  )
}

const mapStateToProps = (state) => (
  {
    mission: state.Missions.mission_view,
    role: state.Role.role
  }
)

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({
    updateMissionSingle: missions_action.get_detail_mission,
    postComment: missions_action.postComment
  }, dispatch)
}


export default connect(mapStateToProps, mapDispatchToProps)(ViewMission)
