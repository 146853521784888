import { missions_service } from "../../../_services/missions"
import {
    EMPTY_CONSULTANT_MISSIONS,
    GET_CONSULTANT_MISSIONS_FAIL,
    GET_CONSULTANT_MISSIONS_START,
    GET_CONSULTANT_MISSIONS_SUCCESS
} from "../actions-types"

export const consultantMissionsCreators = {
    get_consultant_missions
}

function get_consultant_missions_start() {
    return {
        type: GET_CONSULTANT_MISSIONS_START,
    }
}

function get_consultant_missions_success(data) {
    return {
        type: GET_CONSULTANT_MISSIONS_SUCCESS,
        payload: data
    }
}

function get_consultant_missions_fail() {
    return {
        type: GET_CONSULTANT_MISSIONS_FAIL,
    }
}

function empty_consultant_missions() {
    return {
        type: EMPTY_CONSULTANT_MISSIONS,
    }
}

function get_consultant_missions(id, empty = false) {
    return dispatch => {
        if (empty) {
            dispatch(empty_consultant_missions());
            return;
        }
        dispatch(get_consultant_missions_start())
        missions_service.get_consultant_recent_missions(id)
            .then(response => dispatch(get_consultant_missions_success(response)))
            .catch(err => {
                dispatch(get_consultant_missions_fail())
                console.log('error while fetching consultnat missions: ', err)
            })
    }
}
