import Axios from "axios";
import {newConnectConfig} from "./contant";
import { getHeaders, REQUEST_METHOD_GET } from "./utils/headers";

export const AdminService = {
  addRepository,
  editRepository,
  deleteField,
  customGet,
  getCurrentFiscalYear,
};

function customGet(endpoint) {
  const url = `${newConnectConfig.API_PATH}/${endpoint}`;

  return Axios({
    method: 'get',
    url,
    headers: {"Authorization": `Bearer ${localStorage.getItem('token')}`}
  });
}

function addRepository(data, endpoint) {
  const url = `${newConnectConfig.API_PATH}/${endpoint}`;
  return Axios({
    method: 'POST',
    url,
    headers: {"Authorization": `Bearer ${localStorage.getItem('token')}`},
    data
  });
}

function editRepository(data, endpoint, id) {
  const url = `${newConnectConfig.API_PATH}/${endpoint}/${id}`;
  return Axios({
    method: 'PUT',
    url,
    headers: {"Authorization": `Bearer ${localStorage.getItem('token')}`},
    data
  });
}

function deleteField(endpoint, id) {
  const url = `${newConnectConfig.API_PATH}/${endpoint}/${id}`;
  return Axios({
    method: 'DELETE',
    url,
    headers: {"Authorization": `Bearer ${localStorage.getItem('token')}`}
  });
}

function getCurrentFiscalYear() {
  return Axios({
    url: `${newConnectConfig.API_PATH}/get_current_fiscal_year`,
    method: REQUEST_METHOD_GET,
    headers: getHeaders()
  })
}
