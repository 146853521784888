import React from 'react'
import cx from 'classnames/bind'
import FilterComponentSelect from "../../../../../components/FilterComponentSelect";

const Prio = props => {
  return (
    <div className={cx({
      "filter-box": true,
      "filter-box--right" : props.filterXPos === 'right'
    })}>
      <span style={{
        color: '#000',
        fontSize: 14,
        marginBottom: 8,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between'
      }}>
        Filtrer par...
        { props.select_data.prioritie.find( item => item.value === props.value ) !== undefined &&
        <span style={{ color: '#00B5D4' }} className="reset-filter" onClick={ () => props.onFilterChange('priority.id', null)  }>
          Ré-initialiser
        </span>}
      </span>
      <FilterComponentSelect
        filter="prio"
        multiple={false}
        select={ value => props.onFilterChange('priority.id', value.value) }
        data={ props.select_data.prioritie }
        value={ props.select_data.prioritie.find( item => item.value === props.value ) }
      />
    </div>
  )
}

export default Prio
