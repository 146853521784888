import { coreSkillService } from '../../_services/coreSkillService';
import { GET_CORE_SKILL } from './actions-types';

export const coreSkillCreators = {
    get_core_skill,
}

function get_core_skill(coreSkillId = null) {
    return (dispatch) => {
        coreSkillService.get_core_skill(coreSkillId)
            .then(response => {
                return dispatch({
                    type: GET_CORE_SKILL,
                    payLoad: response.data
                });
            })
    }
}
