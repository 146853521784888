import React from 'react'
import {Link} from "react-router-dom";

const CardUserMissionAffected = props => {
  return (
    <div className={"card"}>
      <span>
        <svg width="12" height="24" viewBox="0 0 12 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M12 0V24L0 12L12 0Z" fill="white"/>
        </svg>
      </span>
      <div className="content">
        {props.type === undefined ? <>
          <img src={props.user && props.user.photo} alt=""/>
          <h3>
            {props.user && props.user.fullname}
          </h3>
          <strong>{props.user && props.user.position}</strong></>
          : props.users.map( u => <div style={{ display: 'flex', alignItems: 'center', justifyContent : 'flex-start', width: '100%', marginBottom: 8 }}>
              <img style={{margin: '0 12px 0 0'}} src={u.user.profilePhoto && u.user.profilePhoto.contentUrl} alt=""/>
              <h3 style={{textAlign: 'left'}}>
                {u.user && u.user.fullName}
                <strong style={{ display: 'block', textAlign: 'left' }}>{u.user.position && u.user.position.name}</strong>
              </h3>
            </div>
          )
        }
        { props.owner && <div style={{ display: 'flex', alignItems: 'center', justifyContent : 'flex-start', width: '100%', marginBottom: 8 }}>
          <img style={{margin: '0 12px 0 0'}} src={props.owner.user.profilePhoto && props.owner.user.profilePhoto.contentUrl} alt=""/>
          <h3 style={{textAlign: 'left'}}>
            {props.owner.user && props.owner.user.fullName}
            <strong style={{ display: 'block', textAlign: 'left' }}>{props.owner.user.position && props.owner.user.position.name}</strong>
          </h3>
        </div> }
        { props.type === undefined &&
        <Link target="_blank" rel="noreferrer noopener" style={{ marginTop: 12 }} to={`/missions/view/${props.missionId}`} className="button-ui button-ui--outline">
          <svg width="14" height="16" viewBox="0 0 14 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fillRule="evenodd" clipRule="evenodd" d="M4.74388 0.744141L5.92239 1.92265L4.01164 3.8334H11.9998V5.50006H4.01164L5.92239 7.41081L4.74388 8.58932L0.821289 4.66673L4.74388 0.744141Z" fill="#00B5D4"/>
            <path fillRule="evenodd" clipRule="evenodd" d="M9.25221 7.41081L8.0737 8.58932L9.98445 10.5001H1.99629V12.1667H9.98445L8.0737 14.0775L9.25221 15.256L13.1748 11.3334L9.25221 7.41081Z" fill="#00B5D4"/>
          </svg>
          Revoir l'affectation
        </Link>}
      </div>
    </div>
  )
}

export default CardUserMissionAffected
