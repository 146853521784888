import * as _ from 'lodash'
import initialState from './initialState'

function Matcher(state = initialState, action) {

    if (_.isUndefined(action)) {
        return state
    }

    const { type, payload } = action

    switch (type) {
      case 'AFFECT_MISSION':
        return {'action' : payload}
      default:
        return state
    }

}

export default Matcher
