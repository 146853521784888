import React from 'react'
import cx from 'classnames/bind'
import FilterComponentSelect from "../../../../../components/FilterComponentSelect";
import {connect} from "react-redux";

const TypeEtp = props => {
  return (
    <div className={cx({
      "filter-box": true,
      "filter-box--right" : props.filterXPos === 'right'
    })}>
       <span style={{
         color: '#000',
         fontSize: 14,
         marginBottom: 8,
         display: 'flex',
         alignItems: 'center',
         justifyContent: 'space-between'
       }}>
        Filtrer par...
         { props.select_data.etp.find( item => item.value === props.value ) !== undefined &&
         <span style={{ color: '#00B5D4' }} className="reset-filter" onClick={ () => props.onFilterChange('etp.id', null)  }>
          Ré-initialiser
        </span>}
      </span>
      <FilterComponentSelect
        filter="statut"
        multiple={false}
        select={ value => props.onFilterChange('etp.id', value.value) }
        data={ props.select_data.etp }
        value={ props.select_data.etp.find( item => item.value === props.value ) }
      />
    </div>
  )
}

const mapStateToProps = (state) => ({
  select_data: state.SelectData
});

export default connect(mapStateToProps, null)(TypeEtp);

