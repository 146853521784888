import * as _ from 'lodash'
import { GET_CURRENT_FISCAL_YEAR } from './actions/action-types';
import initialState from './initialState'

function app(state = initialState, action) {

    if (_.isUndefined(action)) {
        return state
    }

    const { type, payload } = action

    switch (type) {
      case 'EDITING_PROFILE':
        return {
          ...state,
          editing_profile : payload,
        }
      case 'END_LOADING':
        return {
          ...state,
          isLoading: payload,
      }
      case 'NOTIFY_MATCHING_CONSULTANT_FETCHING':
        return {
          ...state,
          matcher_list_consultant_fetching: payload,
      }
      case 'DUPLICATE_MISSION':
        return {
          ...state,
          duplicateMissionData: payload,
      }
      case GET_CURRENT_FISCAL_YEAR:
        return {
          ...state,
          currentFiscalYear: payload
        }
      default:
        return state
    }
}

export default app
