import React from 'react'
import '../../styles/components/MyMission.css'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux';
import InputBoxDoneTyping from "react-input-box-done-typing";
import { refere_creator } from '../../reducers/Refere/actions';
import ConsultantItemFiche from '../../components/consultant/ConsultantItemFiche';

const MyRefereeContainer = props => {
    const [term, setTerm] = React.useState('');

    React.useEffect(() => {
        props.get_referent(
          localStorage.getItem('user_id'),
          buildFilterUrl(),
          true
        );
    }, [term]);

    const loadMore = () => {
        props.get_referent(
          localStorage.getItem('user_id'),
          props.next
        )
    }

    const buildFilterUrl = () => {
        // to refactor if there will be multiple filters
        return `&term=${term}`;
    }

    function handleFilterChange(val){
        setTerm(() => val)
    }

    return (
      props.referent &&
        <div className="container mb-3 my-lg-5 py-lg-4">
                <div className="row row-md-reverse">
                    <div className="col-md-6 col-lg-9">
                        <div className="row">
                            <div className="col-md-12">
                                <div className="row">
                                    {
                                     props.referent.map((item, i) =>
                                       <ConsultantItemFiche isLink={true} referePage role={props.role} consultant={item} key={i}/>)
                                    }
                                </div>
                            </div>
                        </div>
                        {props.next ? (
                          <div className="content-button text-center">
                              <button
                                className="button-lg"
                                onClick={loadMore}
                              >
                                  Voir plus
                              </button>
                          </div>
                        ) : null}
                    </div>
                    <div className="col-md-6 col-lg-3">
                        <div className="content-filter">
                            <h2 className="title-filter">
                                Mes référés
                                <span>{props.total || '0'} résultat(s)</span>
                            </h2>
                            <div className="box-filter">
                                <h3>Recherche par mot-clé</h3>
                                <InputBoxDoneTyping
                                  className="form-control"
                                  autoComplete="off"
                                  value={term}
                                  doneTyping={(value) => handleFilterChange(value)}
                                  doneTypingInterval={700}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
    )
}

const mapStateToProps = (state) => ({
    next: state.Refere.next_url,
    referent: state.Refere.referent,
    total: state.Refere.total,
    role: state.Role.role
})

function mapDispatchToProps(dispatch) {
    return bindActionCreators({
        get_referent: refere_creator.get_list
    }, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(MyRefereeContainer)
