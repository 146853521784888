import React, { Component } from 'react'
import Moment from 'react-moment';
import 'moment/locale/fr';

class CommentItem extends Component {
  render() {
    return (
        <div className="card-comment rounded-0">
        <div className="card-body">
            <div className="row no-gutters">
                <div className="col-md-1 mb-3 mb-md-0">
                    <span className="card-comment__thumbnail">
                        <img alt="profilepic" src={this.props.comment && this.props.comment.profilePic} className="img rounded-circle img-fluid" />
                    </span>
                </div>
                <div className="col-md-11 pl-3">
                    <div className="card-comment__top">
                        <span>{ this.props.comment.username }</span>
                        <span>
                            <Moment fromNow>{ this.props.comment.creationDate }</Moment>
                        </span>
                    </div>
                    <div className="card-comment__content">
                    { this.props.comment.body }
                    </div>
                    {/* <div className="card-comment__footer">
                        <span>Répondre</span>
                    </div> */}
                </div>
            </div>

        </div>
    </div>
    )
  }
}

export default CommentItem
