import React from 'react'
import {withRouter} from "react-router";
import axios from "axios";
import {Link} from "react-router-dom";

export function AccessDenied() {
    return (
        <div className="wrapper mx-auto" style={{marginTop: 103, display: 'flex', flexFlow: 'column', maxWidth: 500}}>
            <p className="t-base-medium mt-5 text-center">Cette page n’est pas accessible.</p>
            <Link to={"/"} className="button-ui button-ui--blue mt-2">Retour à votre profil</Link>
        </div>
    );
}

function InternalSeverError() {
    return (
        <div className="wrapper mx-auto" style={{marginTop: 103, display: 'flex', flexFlow: 'column', maxWidth: 500}}>
            <p className="t-base-medium mt-5 text-center">
                Une erreur est survenue, pardon pour la gêne occasionnée. <br/>
                Merci de remonter le problème au support et vous serez informé.e de la résolution.</p>
            <Link to={"/"} className="button-ui button-ui--blue mt-2">Retour à votre profil</Link>
        </div>
    );
}

const RequestInterceptor = props => {

    const [allowed, setAllowed] = React.useState(true);

    React.useEffect(() => {
        axios.interceptors.response.use(function (response) {
            return response;
        }, function (error) {
            if (error.response && error.response.status === 403) {
                setAllowed(() => <AccessDenied />)
            }
            else if(error.response && error.response.status === 500) {
                setAllowed(() => <InternalSeverError />)

            }
            return Promise.reject(error);
        });

        return () => {
            setAllowed(true)
        }
    }, [props.location]);



    return (
        allowed === true ? props.children : allowed
    )
}

export default withRouter(RequestInterceptor)
