import React, {Component} from 'react'
import '../../styles/components/header_menu.css'
import {NavLink} from 'react-router-dom'
import {routePath} from '../../helpers/route_helper'
import uniqid from 'uniqid'
import {connect} from 'react-redux'
import {hasCap} from '../../helpers/app'
import classNames from 'classnames/bind'
import {bindActionCreators} from 'redux'
import {menu_actions} from '../../reducers/Menu/actions'

const menuItems = [
  {
    label: 'Recrutement',
    to: '',
    can_view: 'CONSULTANT_CRH',
    hideTo: ['EXTERNE'],
    notif: false,
    submenu: [
      {
        label: 'Liste des candidats',
        to: 'recruitment/candidates/list',
        can_view: 'SENIOR_MANAGER',
        hideTo: ['EXTERNE'],
        notif: false
      },
      {
        label: 'Plan de recrutement',
        to: 'recruitment/candidates/needs',
        can_view: 'SENIOR_MANAGER',
        hideTo: ['EXTERNE'],
        notif: false
      },
      {
        label: 'Mes entretiens',
        to: 'recruitment/my-interviews/list',
        can_view: 'CONSULTANT_SENIOR',
        hideTo: ['EXTERNE'],
        notif: false
      },
      {
        label: 'Mes cooptés',
        to: 'recruitment/my-coopted/list',
        can_view: 'CONSULTANT_CRH',
        hideTo: ['EXTERNE'],
        notif: false
      },
      {
        label: 'Ajouter un candidat',
        to: 'recruitment/candidates/forms',
        can_view: 'CONSULTANT_CRH',
        hideTo: ['EXTERNE'],
        notif: false
      }
    ]
  },
  {
    label: 'People',
    to: 'consultant/list/interne',
    can_view: 'CONSULTANT_CRH',
    hideTo: [],
    notif: false,
    submenu: [
      {
        label: 'Consultants',
        to: 'consultant/list/interne',
        can_view: 'CONSULTANT_CRH',
        hideTo: ['EXTERNE'],
        notif: false
      },
      {
        label: 'Experts',
        to: 'consultant/list/externe',
        can_view: '',
        hideTo: ['CONSULTANT_CRH'],
        notif: false
      },
      {
        label: 'Mes référés',
        to: 'mes-referes',
        can_view: 'MANAGER',
        hideTo: [],
        notif: false
      },
      {
        label: 'Prochaines arrivées',
        to: 'recruitment/arrivals/list',
        can_view: 'SENIOR_MANAGER',
        hideTo: ['EXTERNE'],
        notif: false
      }
    ]
  },
  {
    label: 'Staffing',
    to: 'planning',
    can_view: 'CONSULTANT_SENIOR',
    hideTo: ['EXTERNE'],
    notif: false,
    submenu: [
      {
        label: 'Dispos courtes',
        to: 'planning',
        can_view: 'CONSULTANT_SENIOR',
        hideTo: [''],
        notif: false
      },
      {
        label: 'Tous les besoins',
        to: 'missions',
        can_view: 'CONSULTANT_SENIOR',
        hideTo: [''],
        notif: false
      },
      {
        label: 'Créer un besoin',
        to: 'missions/edit',
        can_view: 'CONSULTANT_SENIOR',
        hideTo: [''],
        notif: false
      },
      {
        label: 'Mes besoins créés',
        to: 'my-missions',
        can_view: 'CONSULTANT_SENIOR',
        hideTo: [''],
        notif: false
      },
      {
        label: 'Comptes-rendus / Réunions staffing',
        to: 'cr_matching',
        can_view: 'CONSULTANT_SENIOR',
        hideTo: [''],
        notif: false
      }
    ]
  },
  {
    label: 'Développement',
    to: '',
    can_view: 'CONSULTANT_CRH',
    hideTo: ['EXTERNE'],
    notif: false,
    submenu: [
      {
        label: 'Créer un feedback',
        to: 'feedbacks/create',
        can_view: 'CONSULTANT_SENIOR',
        hideTo: [''],
        notif: false
      },
      {
        label: 'Comité carrière',
        to: 'comite-carriere/listing',
        can_view: 'MANAGER',
        hideTo: [''],
        notif: false
      },
      {
        label: 'Mon plan de développement',
        to: 'development/feedbacks',
        qp: `?user=${localStorage.getItem('user_id')}`,
        can_view: 'CONSULTANT_CRH',
        hideTo: [''],
        notif: false
      },
      {
        label: 'Mes entretiens',
        to: 'development/meetings',
        qp: `?user=${localStorage.getItem('user_id')}`,
        can_view: 'CONSULTANT_CRH',
        hideTo: ['PARTNER', 'ADMIN'],
        notif: false
      },
      {
        label: 'My learning',
        to: 'https://convictionsrh.onelogin.com/client/apps/select/649858600',
        isExternal: true,
        can_view: 'CONSULTANT_CRH',
        hideTo: [''],
        notif: false
      }
    ]
  },
  {
    label: 'Objectifs',
    to: 'propale',
    can_view: 'CONSULTANT_CRH',
    hideTo: ['EXTERNE'],
    notif: false,
    submenu: [
      {
        label: 'Tous les livrables',
        to: 'livrables',
        can_view: 'CONSULTANT_CRH',
        hideTo: [''],
        notif: false
      },
      {
        label: 'Créer un livrable',
        to: 'livrable/edit',
        can_view: 'CONSULTANT_SENIOR',
        hideTo: [''],
        notif: false
      },
      {
        label: 'Livrables à valider',
        to: 'livrables/validation',
        can_view: 'PARTNER',
        hideTo: ['CONSULTANT_SENIOR'],
        notif: false
      },
      {
        label: 'Toutes les propales',
        to: 'propale',
        can_view: 'CONSULTANT_CRH',
        hideTo: [''],
        notif: false
      },
      {
        label: 'Créer une propale',
        to: 'propale/edit',
        can_view: 'CONSULTANT_SENIOR',
        hideTo: [''],
        notif: false
      }
    ]
  }
]

class MenuComponent extends Component {
  constructor(props) {
    super(props)
    this.state = {
      active: null,
      hasNotif: false
    }
  }

  getClasses(notif, data) {
    const classes = classNames({
      'nav-item': true,
      has_submenu: data.submenu,
      'item-notifs': this.props.hasNotif && notif
    })

    return classes
  }

  toggle_mobile_menu() {
    this.props.toggle_mobile_menu()
  }

  render() {
    return (
      <React.Fragment>
        <button
          onClick={this.toggle_mobile_menu.bind(this)}
          className="navbar-toggler"
          type="button"
          data-toggle="collapse"
          data-target="#navbarConnect"
          aria-controls="navbarConnect"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <i className="fas fa-bars"/>
        </button>

        <div className="collapse navbar-collapse " id="navbarConnect">
          <ul className="navbar-nav ml-auto">
            {menuItems.map(menuItem =>
              this.props.role &&
              hasCap(menuItem.can_view, this.props.role) &&
              !menuItem.hideTo.includes(this.props.role) ? (
                <div key={uniqid()}>
                  <li
                    className={this.getClasses(menuItem.notif, menuItem)}
                  >
                    {menuItem.to !== '' ?
                     <NavLink
                       activeClassName="active"
                       className="nav-link"
                       to={routePath(menuItem.to)}
                     >
                       {menuItem.label}
                     </NavLink> : <span className="nav-link">{menuItem.label}</span>
                    }

                    {menuItem.submenu && (
                      <ul className="submenu">
                        {menuItem.submenu &&
                          menuItem.submenu.map(sub => (
                            sub.hideTo.includes(this.props.role) ? null :
                            hasCap(sub.can_view, this.props.role) ?
                            sub.isExternal !== undefined ? <a
                              href={sub.to}
                              rel="noreferrer noopener" target="_blank" className="nav-link"> {sub.label}</a>
                                                         :
                            <NavLink
                              key={uniqid()}
                              activeClassName="active"
                              className="nav-link"
                              to={`${routePath(sub.to)}${sub.qp !== undefined ? sub.qp : ''}`}
                            >
                              {sub.label}
                            </NavLink> : null
                          ))}
                      </ul>
                    )}
                  </li>
                </div>
              ) : null
            )}
          </ul>
        </div>
      </React.Fragment>
    )
  }
}

const mapStateToProps = state => (
  {
    role: state.Role.role,
    hasNotif: state.Notifications.hasNotif,
    refreshNotif: state.App.hasNotif
  }
)

const mapDispatchToProps = dispatch => {
  return bindActionCreators(
    {
      toggle_mobile_menu: menu_actions.toggle_mobile_menu
    },
    dispatch
  )
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(MenuComponent)
