import React, { Component } from 'react'
import '../../styles/components/MissionDetails.css'
import Moment from 'react-moment'
import 'moment/locale/fr'
import { Link } from 'react-router-dom'
import { routePath } from '../../helpers/route_helper'
import { hasCap } from '../../helpers/app'
import { connect } from 'react-redux'
import classNames from 'classnames/bind'

class MissionDetails extends Component {
  render () {
    const { mission } = this.props

    const classes = classNames({
      'card-mission__top': true,
      pourvue: mission.statut === 'Pourvue',
      pending: mission.statut === 'En attente de validation',
      archived: mission.statut === 'Archivé'
    })

    return (
      <div className='relative w-100'>
        {hasCap('CONSULTANT_SENIOR', this.props.role) ? (
          <Link to={routePath(`missions/edit/${mission.id}`)}>
            <span
              style={{
                top: '-12px',
                left: '-12px',
                right: 'initial',
                zIndex: '14'
              }}
              className='card-preview__edit'
            >
              <i className='fa fa-edit' />
            </span>
          </Link>
        ) : null}

        <Link
          to={routePath(`missions/view/${mission.id}`)}
          className='card flex-md-row no-gutters card-mission card-list-mission'
        >
          <div className='card-body d-flex flex-column align-items-start col-12 col-md-8 col-lg-9'>
            <h2 className='title'>{mission.title}</h2>
            <h3 className='sub-title'>{mission.client}</h3>
            <h4 className='marker'>
              <i className='fas fa-map-marker-alt' />{' '}
              {mission.town != null
                ? `${mission.town.name}, ${mission.town.country.name}`
                : mission.city}
              {mission.leaderName !== '' && (
                <span
                  style={{
                    fontWeight: 'bold',
                    fontSize: '12px',
                    textTransform: 'uppercase',
                    marginLeft: '20px'
                  }}
                >
                  <i className='far fa-user' /> Chef de projet :{' '}
                  {mission.leaderName}
                </span>
              )}
            </h4>
            <div
              className='box-about__content'
              dangerouslySetInnerHTML={{ __html: mission.description }}
            />
            <div className='d-block clear w-100'>
              <span className='more'>Lire l'intégralité du besoin</span>
            </div>
            <ul className='tags'>
              {mission.missionSkills.length === 0
                ? null
                : mission.missionSkills.map((item, i) => (
                  <li key={i} className='tags__link'>
                    {item.skill.name}
                  </li>
                ))}
            </ul>
            <div className='matching' style={{ display: 'flex' }}>
              {mission.matching.length > 0 && mission.statut !== 'Pourvue'
                ? mission.matching.map((m, index) => (
                  <div
                    key={index}
                    className='avatar-container'
                    style={{
                      marginTop: '20px',
                      width: '100px',
                      textAlign: 'center'
                    }}
                  >
                    <span className='avatar-container__img'>
                      <img
                        src={m.photo && m.photo.contentUrl}
                        alt='profile_pic'
                      />
                    </span>
                    {m.externe && (
                      <span
                        style={{
                          position: 'absolute',
                          width: 30,
                          height: 30,
                          borderRadius: '50%',
                          left: 15,
                          top: -10,
                          background: '#00b5d4',
                          color: '#fff',
                          fontSize: 11,
                          lineHeight: '30px',
                          textAlign: 'center'
                        }}
                        className='externBadge'
                      >
                          Ext.
                      </span>
                    )}
                    <span
                      className='avatar-container__user'
                      style={{ wordBreak: 'break-word' }}
                    >
                      {m.fullname}
                    </span>
                  </div>
                ))
                : null}

              {mission.statut === 'Pourvue' ? (
                <div
                  className='avatar-container'
                  style={{
                    marginTop: '20px',
                    width: '100px',
                    textAlign: 'center'
                  }}
                >
                  <span className='avatar-container__img'>
                    <img
                      src={mission.matchedUser && mission.matchedUser.photo}
                      alt='profile_pic'
                    />
                  </span>
                  <span
                    className='avatar-container__user'
                    style={{ wordBreak: 'break-word' }}
                  >
                    {mission.matchedUser && mission.matchedUser.fullname}
                  </span>
                </div>
              ) : null}
            </div>
          </div>

          <div className='card-right flex-auto d-none d-md-block text-center col-md-4 col-lg-3'>
            <div className={classes}>
              <h3>
                <span>Statut</span>
                {mission.statut}
                { (mission.statut === 'En recherche' ||
                  mission.statut === 'En attente de validation') && (
                  <Link
                    style={{
                      margin: '20px 0 0',
                      display: 'table',
                      width: '100%',
                      background: 'transparent',
                      border: '1px solid #fff'
                    }}
                    className='nav-link nav-link--affect'
                    to={routePath(`matcher/mission/id/${mission.id}`)}
                  >
                    Affecter
                    <i className='fas fa-exchange-alt ml-2' />
                  </Link>
                )}
              </h3>
            </div>
            <ul className='card-mission__list'>
              <li>
                <h3>
                  <span>Priorité</span>
                  {mission.priority != null ? mission.priority.name : ''}
                </h3>
              </li>
              <li>
                <h3>
                  <span>Date de la mission</span>
                  <Moment format='DD MMM YYYY'>{mission.startDate}</Moment>
                  <br />
                  au
                  <br />
                  <Moment format='DD MMM YYYY'>{mission.finishDate}</Moment>
                </h3>
              </li>
              <li>
                <h3>
                  <span>ETP</span>
                  {mission.etp && mission.etp.name}
                </h3>
              </li>
              <li>
                <h3>
                  <span>Type</span>
                  {mission.invoicingMode != null
                    ? mission.invoicingMode.name
                    : ''}
                </h3>
              </li>
            </ul>
          </div>
        </Link>
      </div>
    )
  }
}

function mapStateToProps (state) {
  return { role: state.Role.role }
}

export default connect(mapStateToProps)(MissionDetails)
