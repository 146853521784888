import React from 'react';
import Select from 'react-select';

function CircleSelect(props) {
    const [options, setOptions] = React.useState({
        circles: [],
        subCircles: []
    });
    const [selectedValue, setSelectedValue] = React.useState({
        circle: props.defaultValue ? props.defaultValue.circles : null,
        subCircles: props.defaultValue ? props.defaultValue.subCircles : []
    });
    const [touched, setTouched] = React.useState(false);

    React.useEffect(() => {
        const circlesOptions = props.circles.map(circle => ({
            value: circle.id,
            label: circle.name
        }));
        const subCirclesOptions = props.isEditAction ?
            props.circles[0].subCircles.map(sub => ({
                value: sub.id,
                label: sub.name
            })) : [];
        setOptions({
            circles: circlesOptions,
            subCircles: subCirclesOptions
        })
    }, [props.circles]);

    const handleOnChangeCircle = (selectedCircle) => {
        setSelectedValue({
            circle: selectedCircle,
            subCircles: []
        })
        const circle = props.circles.find(circleItem => circleItem.id === selectedCircle.value);
        const subs = circle ? circle.subCircles.map(sub => ({
            value: sub.id,
            label: sub.name
        })) : [];

        setOptions({
            ...options,
            subCircles: subs
        });
    }

    const handleOnChangeSubCircles = (selectedSubs) => {
        setTouched(true);
        setSelectedValue({
            ...selectedValue,
            subCircles: selectedSubs
        })
    }

    const handleSubmit = () => {
        props.submitCircle(selectedValue);
    }

    return (
        <>
        <div className="flex mt-4 justify-content-around">
            <div className="pl-2 pr-2">
                <label className="label" htmlFor="">
                    {props.circleLabel || 'Cercle'}
                    {props.required && <span style={{ color: '#00b5d4', marginLeft: 8 }} className='required'>*</span>}
                </label>
                <div style={{ marginTop: '1rem' }}>
                    <Select
                        placeholder="Choisir"
                        isSearchable={true}
                        isDisabled={props.isEditAction}
                        isMulti={props.isMulti !== undefined}
                        onChange={value => handleOnChangeCircle(value)}
                        options={options.circles}
                        value={selectedValue.circle}
                    />
                </div>
            </div>
            <div  className="pl-2 pr-2">
                <label className="label" htmlFor="">
                    {props.subCircleLabel || 'Cercle'}
                    {props.required && <span style={{ color: '#00b5d4', marginLeft: 8 }} className='required'>*</span>}
                </label>
                <div style={{ marginTop: '1rem' }}>
                    <Select
                        placeholder={selectedValue.circle && options.subCircles.length === 0 ? '-Aucun-' : 'Choisir'}
                        isDisabled={props.isEditAction ? false : options.subCircles.length === 0}
                        isSearchable={true}
                        isMulti={true}
                        onChange={values => handleOnChangeSubCircles(values)}
                        options={options.subCircles}
                        value={selectedValue.subCircles}
                    />
                </div>
            </div>
        </div>
        <div>
                {
                    props.isEditAction ?
                        <div className="actions" style={{
                            display: 'flex',
                            margin: '12px auto',
                            flex: 1,
                            justifyContent: 'flex-end'
                        }}>
                            <button onClick={props.onCancel} type="button" className="button-ui button-ui--white">Annuler</button>
                            <button onClick={handleSubmit} disabled={!touched || selectedValue.subCircles.length === 0} type="button" className="button-ui button-ui--blue" style={{ marginLeft: 8 }}>Valider</button>
                        </div>
                        :
                        (selectedValue.circle !== null && (selectedValue.subCircles.length > 0 || options.subCircles.length === 0)) ?
                        <button onClick={handleSubmit} type="button" className="button button-ui mt-3" style={{
                            color: 'white',
                            width: '100%'
                        }}>Valider le cercle</button> : <button type="button" onClick={props.onCancel} style={{width: '100%'}} className="button button-ui--white mt-4">Annuler</button>
                }
            </div>
        </>
    )
}

export default (CircleSelect)
