import React from 'react'
import {newConnectConfig} from "../../../_services/contant";
import Axios from "axios";
import {bindActionCreators} from "redux";
import {profile_creators} from "../../../reducers/Profile/actions/_update_profile";
import {connect} from "react-redux";

const  CvUploader = props => {
  const ref = React.useRef();
  const [state, setState] = React.useState({
    resume: '',
    resume_id:null
  });

  React.useEffect(() => {
    const resume = props.resumes.find( res => res.language.id === parseInt(props.languageID))
    if( resume !== undefined ) {
      setState({
        resume: resume.path,
        resume_id: resume.id
      })
    }
  }, [props.resumes]);

  const upload = e => {
    e.preventDefault();
    ref.current.click()
  }

  const onChange = e => {
    const files = e.target.files || e.dataTransfer.files;
    const file = files[0];
    const formData = new FormData();
    const languageID = parseInt(props.languageID);
    formData.append('file', file, file.filename);

    const  url = `${newConnectConfig.API_PATH}/media_objects`;

    const request =  Axios({
      method:'post',
      url,
      headers: {
        'Content-Type': 'multipart/form-data',
        'Authorization' : `Bearer ${localStorage.getItem('token')}`
      },
      data: formData
    })

    request.then( response => {

      let url2 = '';
      let method = 'post';

      if(  state.resume === '' ) {
        url2 = `${newConnectConfig.API_PATH}/resumes`;
      } else {
        url2 = `${newConnectConfig.API_PATH}/resumes/${state.resume_id}`;
        method = 'put';
      }
      props.upload_cv(
        url2,
        method,
        {
          "path" : response.data.contentUrl,
          "language" : {"id" : languageID },
          "user" : {"id" : props.editing}
        },
        props.editing
      )
    });

  }

  return (
    <>
      <input onChange={onChange} type="file" id="file" ref={ref} style={{display: "none"}}/>
      <button onClick={upload}>
        <svg width="14" height="15" viewBox="0 0 14 15" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path fillRule="evenodd" clipRule="evenodd" d="M7 0L14 6.63864L12.7155 7.85679L7.90825 3.29767V15H6.09175V3.29767L1.28446 7.85679L0 6.63864L7 0Z" fill="#00B5D4"/>
        </svg>
      </button>
    </>
  )
}

const mapStateToProps = (state) => ({
  editing : state.App.editing_profile
})

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({
    upload_cv: profile_creators.upload_cv
  },dispatch)
}

export default connect(mapStateToProps,mapDispatchToProps)(CvUploader)
