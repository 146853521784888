import React from 'react'
import { connect } from 'react-redux';
import './style.scss'
import ErreurPlannif from "./components/ErreurPlannif";
import DispoCourteManager from "./components/DispoCourteManager";
import {ModalContext} from "../../context/ModalContext";
import DatePicker from "./components/modal/DatePicker";
import HalfDaysButton from "./components/modal/HalfDaysButton";
import moment from 'moment'
import {planningService} from "../../_services/planningService";
import ButtonWithControlledDisable from '../../ui/ButtonWithControlledDisable';

const ModalComponent = props => {

  const [date, setDate] = React.useState(null);
  const [duration, setDuration] = React.useState(null);
  const [halfDays, setHalfDays] = React.useState(null);
  const [summary, setSummary] = React.useState('');

  function displaySummary ({ startDate, startTime, duration }) {
    startDate = startDate || undefined;
    startTime = startTime || 'am';
    duration = Math.min(Math.max(0, duration || 1), 5);
    const start = moment(startDate).startOf('day').add(startTime === 'am' ? 6 : 18, 'hours');

    let output = [];

    for(let elapsed = 0 ; elapsed < duration ; elapsed += 0.5) {
      output.push( start.clone().add(elapsed * 24, 'hours'));
    }
    return output
  }

  React.useEffect(() => {
    if(date !== null && duration !== null) {
      setHalfDays( displaySummary({startDate:date.beginDate, startTime: date.plage, duration}))
    }
  }, [date,duration]);

  React.useEffect(() => {
    if(  halfDays ) {

      const halfDaysWithoutWeeks = halfDays.filter( item => item.day() !== 6 && item.day() !== 0 )

      const reduced = halfDaysWithoutWeeks.reduce( (acc, item) =>{
        let formated = item.format('ddd DD A')

        if( formated.includes('AM') ) {
          formated = formated.replace('AM','matinée')
        } else {
          formated = formated.replace('PM','après-midi')
        }

        acc += formated  + ', '
        return acc
      }, '')


      setSummary(reduced)
    }
    }, [halfDays]);

  const validate = () => {
    const arrayDateForApi = halfDays.filter( item => item.day() !== 6 && item.day() !== 0 ).reduce((acc,date) =>{
      acc.push(moment(date).toDate())
      return acc
    },[]) ;


    planningService.addShortAvailabilities({
      dates : arrayDateForApi
    }).then( response =>{
      props.onCancel(response.data)
    })
  }

  return(
    <div className="content" style={{background: '#F2F2F4', borderRadius: 8}}>
      <p className="title">Nouvelle dispo courte</p>
      <div className="form">
        <div className="form-row">
          <label htmlFor="">Début de la dispo *</label>
          <DatePicker onUpdateState={ value => setDate(value) }/>
        </div>
        <div className="form-row">
          <label htmlFor="">Durée de la dispo (en jours) *</label>
          <HalfDaysButton onUpdateState={ value => setDuration(value) } />
        </div>
        <hr/>
        <p className='blue-text'>
          Vous allez déclarer les dispos. suivantes :
          <strong>{ summary }</strong>
        </p>
      </div>
      <div className="actions">
        <button className="outline" onClick={ props.onCancel }>Annuler</button>
        <ButtonWithControlledDisable text="Valider" onClick={validate} />
      </div>
    </div>
  )
}

const PlanningEditContainer = () => {

    const [modal, setModal] = React.useContext(ModalContext);
    const [data, setnewData] = React.useState(null);

    return (
        <div className="page container mb-3 my-lg-5 py-lg-4 dispo-courte">
          <header className="col-md-12">
            <h1>
              Mes dispos courtes
            </h1>
            <div className="action">
              <button style={{marginLeft: 24}} onClick={ () =>
                setModal({
                  ...modal,
                  display: true,
                  component: <ModalComponent onCancel={ data => {
                    setnewData(data)
                    setModal({...modal, display: false})
                  } }/>
                })
              } className="button button-lg">
                <div className="flex" style={{
                  display: 'flex',
                  alignItems: 'center'
                }}>
                  <svg style={{marginRight: 13}} width="14" height="14" viewBox="0 0 14 14" fill="none"
                       xmlns="http://www.w3.org/2000/svg">
                    <path d="M8 0H6V6H0V8H6V14H8V8H14V6H8V0Z" fill="white"/>
                  </svg>
                  Nouvelle dispo courte
                </div>
              </button>
            </div>
          </header>
          <ErreurPlannif />
          <DispoCourteManager data={data} />
        </div>
      )
}

export default connect(null,null)(PlanningEditContainer)
