import cx from 'classnames/bind';
import moment from 'moment';
import React from 'react'
import useIsLongText from '../../../hooks/useIsLongText';

const ToolTip = ({text}) => {
  return(
    <div className="card" style={{zIndex: 1040}}>
      <span>
        <svg width="12" height="24" viewBox="0 0 12 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M12 0V24L0 12L12 0Z" fill="white"/>
        </svg>
      </span>
      <div className="content">
        <p>{text}</p>
      </div>
    </div>
  )
}


const LineListingExchange = props => {

  const commentSpan = React.useRef();
  const commentSpan2 = React.useRef();

  const longtext = useIsLongText(props, commentSpan, 120)
  const longtext2 = useIsLongText(props, commentSpan2, 120)

  return (
    <tr>
      <td>
        <span>{props.data.title}</span>
      </td>
      <td>
        <span>{moment(props.data.contacDate).format('DD/MM/YYYY')}</span>
      </td>
      <td>
      <span ref={commentSpan} className={cx({
        "has-tooltip": longtext,
        "cut-text": longtext
      })}>
          <em style={{maxWidth: 350, display:'block', wordBreak: 'break-all'}}>{props.data.commentary}</em>
        </span>
      <ToolTip text={props.data.commentary} />
    </td>
      <td style={{textAlign: 'center'}}>
        <button style={{
          paddingTop: 12,
          paddingBottom: 12,
          background: props.data.status === 'No Go' ? '#F33F3F' : '#7ED321'
        }}>
          {props.data.status}
        </button>
      </td>
      <td>
        <span ref={commentSpan2} className={cx({
          "has-tooltip": longtext2,
          "cut-text": longtext2
        })}>
          <em style={{maxWidth: 350, display:'block', wordBreak: 'break-all'}}>{props.data.statusReason}</em>
        </span>
        <ToolTip text={props.data.statusReason} />
      </td>
    </tr>
  )
}

export default LineListingExchange
