import React, { useState } from 'react';
import ErreurPlannigPopup from './ErreurPlannigPopup';

const ErreurPlannif = (props) => {

    const [displayPopup, setDisplayPopup] = useState(false)

    return (
      <div className="box col-md-12 col-lg-12">
        <button className="button outline" onClick={ () =>setDisplayPopup( true )}>
          <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fillRule="evenodd" clipRule="evenodd" d="M6.61214 8.00208C7.18756 4.73872 10.2995 2.55971 13.5629 3.13513C16.8262 3.71054 19.0053 6.8225 18.4298 10.0859L17.7634 13.8653L18.407 18.2052L3.85661 15.6396L5.94573 11.7815L6.61214 8.00208ZM13.2156 5.10474C11.04 4.72113 8.96537 6.1738 8.58176 8.34938L7.85899 12.4484L6.9366 14.1519L16.0216 15.7538L15.7375 13.8376L16.4602 9.73856C16.8438 7.56299 15.3912 5.48835 13.2156 5.10474Z" fill="#003D71"/>
            <path d="M5.46319 7.79949C5.05083 10.1381 4.21516 12.3819 2.99725 14.4206L2.87141 14.6312L2.01294 14.1184L2.13878 13.9077C3.29429 11.9735 4.08714 9.84468 4.47839 7.62584L5.46319 7.79949Z" fill="#003D71"/>
            <path d="M19.6315 17.3536C19.1843 15.0214 19.1664 12.6271 19.5788 10.2884L20.5636 10.4621C20.1723 12.6809 20.1893 14.9526 20.6136 17.1653L20.6598 17.4063L19.6777 17.5946L19.6315 17.3536Z" fill="#003D71"/>
            <path d="M10.7845 18.892C10.2406 18.7961 9.87746 18.2775 9.97337 17.7336L8.00375 17.3863C7.71604 19.018 8.80555 20.5739 10.4372 20.8617C12.0689 21.1494 13.6249 20.0599 13.9126 18.4282L11.943 18.0809C11.8471 18.6248 11.3284 18.9879 10.7845 18.892Z" fill="#003D71"/>
          </svg>
          Signaler une erreur de planification
        </button>
       { displayPopup && <ErreurPlannigPopup hidePopup={ () => setDisplayPopup(false) } /> }
      </div>
    )
}

export default ErreurPlannif;
