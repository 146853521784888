import React from 'react'
import {AffectContext} from "../../../context/AffectContext";
import ModalAffectComponent from "./ModalAffectComponent";

const AffectButton = props => {
  const [modalAffect, setModalAffect] = React.useContext(AffectContext);

  return (
    <span
      style={{ cursor: 'pointer' }}
      onClick={() => setModalAffect({
        ...modalAffect,
        display: true,
        consultantsSelected: props.mission.matching.reduce((acc,item) => {
          acc.push({
            id: item.user_id,
            fullname: item.fullname && item.fullname,
            profilePhoto: item.photo && item.photo.contentUrl
          })
          return acc
        },[]),
        component: <ModalAffectComponent mission={props.mission} />
      })}
    >
        <svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path fillRule="evenodd" clipRule="evenodd" d="M21.293 15.293L22.7073 16.7072L20.4144 19.0001H30.0002V21.0001H20.4144L22.7073 23.293L21.293 24.7072L16.5859 20.0001L21.293 15.293Z" fill="#00B5D4"/>
          <path fillRule="evenodd" clipRule="evenodd" d="M26.703 23.293L25.2888 24.7072L27.5817 27.0001H17.9959V29.0001H27.5817L25.2888 31.293L26.703 32.7072L31.4102 28.0001L26.703 23.293Z" fill="#00B5D4"/>
          <path d="M0 0V-1H-1V0H0ZM48 0H49V-1H48V0ZM48 48V49H49V48H48ZM0 48H-1V49H0V48ZM0 1H48V-1H0V1ZM47 0V48H49V0H47ZM48 47H0V49H48V47ZM1 48V0H-1V48H1Z" fill="#00B5D4"/>
        </svg>
    </span>
  )
}

export default AffectButton
