import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import BoxComponent from '../../../components/BoxComponent';
import { ModalContext } from "../../../context/ModalContext";
import CircleSelect from './CircleSelect';
import { circleCreators } from '../../../reducers/Circle/actions';
import { userCircleService } from '../../../_services/userCircleService';
import { applicantCircleService } from '../../../_services/applicantCircleService';

const ConfirmDeleteCircle = ({ onCancel, onConfirm }) => {
    return (
        <>
            <header className="salmon">
                <svg width="94" height="96" viewBox="0 0 94 96" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <circle cx="47.5" cy="52.5" r="39.5" fill="white" />
                    <path fillRule="evenodd" clipRule="evenodd"
                        d="M47.5001 56.8016L30.51 73.7917C29.3221 74.9796 27.3962 74.9796 26.2084 73.7917C25.0206 72.6039 25.0206 70.678 26.2084 69.4902L43.1985 52.5001L26.2084 35.51C25.0206 34.3221 25.0206 32.3962 26.2084 31.2084C27.3962 30.0206 29.3221 30.0206 30.51 31.2084L47.5001 48.1985L64.4902 31.2084C65.678 30.0206 67.6039 30.0206 68.7917 31.2084C69.9796 32.3962 69.9796 34.3221 68.7917 35.51L51.8016 52.5001L68.7917 69.4902C69.9796 70.678 69.9796 72.6039 68.7917 73.7917C67.6039 74.9796 65.678 74.9796 64.4902 73.7917L47.5001 56.8016Z"
                        fill="#F33F3F" />
                </svg>
            </header>
            <div className="content">
                <p className="title">Êtes-vous sûre de vouloir supprimer ce cercle?</p>
                <p>La suppression du cercle sera irréversible.</p>
                <div className="actions">
                    <button className="white" onClick={() => onCancel()}>Annuler</button>
                    <button className="blue" onClick={() => onConfirm()}>Valider</button>
                </div>
            </div>
        </>
    )
}

const CircleNames = ({ circlLabel, subCircles }) => {
    return (
        <ul className="circle-listing flex justify-content-start">
            <li className="t-header-x-large">{circlLabel}</li>
            {subCircles.map((subItem, index) => (<li key={index}>{subItem.subCircle.name}</li>))}
        </ul>
    )
}

function CircleItem({ circleResource, canManageCircle, subCircleResourceName, triggerSuccessToast, ...props }) {
    const [modal, setModal] = React.useContext(ModalContext);
    const [isSelectActive, toggleSelect] = React.useState(false);

    const circleLabel = circleResource.circle.name;

    const defaultCircle = {
        circles: [{
            value: circleResource.circle.id,
            label: circleResource.circle.name,
        }],
        subCircles: circleResource[subCircleResourceName].map(subItem => ({
            value: subItem.subCircle.id,
            label: subItem.subCircle.name,
        })),
    };

    const deleteCircle = id => {
        setModal({
            ...modal,
            display: true,
            component: <ConfirmDeleteCircle
                onCancel={() => setModal({ ...modal, display: false, component: null })}
                onConfirm={() => doConfirm(id)}
            />
        })

    }

    const doConfirm = id => {
        setModal({ ...modal, display: false, component: null });

        if (subCircleResourceName === 'userSubCircles') {
            userCircleService.delete_user_circle(id)
                .then(() => triggerSuccessToast('supprimé'));
        } else if (subCircleResourceName === 'applicantSubCircles') {
            applicantCircleService.delete_applicant_circle(id)
                .then(() => triggerSuccessToast('supprimé'));
        }
    }

    const selectCircle = () => {
        toggleSelect(true);
        props.getCircle(circleResource.circle.id);
    }

    const updateCircle = (data) => {
        toggleSelect(false);
        const prevSubCircles = circleResource[subCircleResourceName];
        const newSubCircles = data.subCircles;

        let payload = {
            [subCircleResourceName]: newSubCircles.map(sub => {
                const subPayload = {
                    "subCircle": { "id": sub.value }
                }
                const prevSubCircle = prevSubCircles.find(subItem => subItem.subCircle.id === sub.value);

                return prevSubCircle ? {
                    ...subPayload,
                    "id": prevSubCircle.id
                } : subPayload
            })
        };

        if (subCircleResourceName === 'userSubCircles') {
            userCircleService.update_user_circle(circleResource.id, payload)
                .then(() => triggerSuccessToast());
        } else if (subCircleResourceName === 'applicantSubCircles') {
            payload = {
                ...payload,
                "id": circleResource.id,
                "applicant": {
                    "id": props.applicantId
                },
                "circle": {
                    "id": circleResource.circle.id
                },
            }
            applicantCircleService.update_applicant_circle(circleResource.id, payload)
                .then(() => triggerSuccessToast());
        }
    }

    return (
        <BoxComponent custom_class='box-profile col-md-11 row mr-auto ml-auto'>
            <span style={{
                position: 'absolute',
                top: 0,
                left: 0
            }}>
                <svg width="71" height="102" viewBox="0 0 71 102" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <g clipPath="url(#clip0_8520:114968)">
                        <path fillRule="evenodd" clipRule="evenodd" d="M9.57535 109.982L-7.44451 129.599C-8.99005 131.259 -11.4668 130.264 -11.4668 127.938V86.043C1.53103 93.3555 17.3131 93.6906 30.3109 87.0383V127.932C30.3109 130.258 27.8341 131.259 26.5958 129.593L9.57596 109.976L9.57535 109.982Z" fill="#F3F3F5" />
                        <path fillRule="evenodd" clipRule="evenodd" d="M10.4961 0C32.1575 0 49.7969 18.9514 49.7969 42.224C49.7969 65.4966 32.1575 84.448 10.4961 84.448C-11.1653 84.448 -28.8047 65.4966 -28.8047 42.224C-28.8047 18.9514 -11.1653 0 10.4961 0ZM16.376 35.5771L24.4205 36.5724C26.2781 36.9025 26.8973 39.2334 25.6589 40.5638L19.4718 47.2111L21.0173 55.5239C21.3245 57.5197 19.4718 59.1802 17.9214 58.1849L10.184 54.1935L3.06586 58.1849C1.52032 59.1802 -0.649204 57.8548 -0.337267 55.8591L1.20827 46.5508L-4.67161 40.5637C-6.21714 39.2332 -5.29079 36.9074 -3.43329 36.5723L4.92321 35.2419L8.32633 27.2641C9.25273 25.2684 11.7295 25.2684 12.3486 27.2641L16.3709 35.577L16.376 35.5771Z" fill="#F3F3F5" />
                    </g>
                    <defs>
                        <clipPath id="clip0_8520:114968">
                            <rect width="121" height="130" fill="white" transform="translate(-50)" />
                        </clipPath>
                    </defs>
                </svg>
            </span>
            <div className="col-md-12">
                {
                    canManageCircle &&
                    (
                        isSelectActive ? null : <div style={{
                            display: 'flex',
                            flexDirection: 'row',
                            justifyContent: 'flex-end',
                            float: 'right'
                        }}>
                            <span onClick={selectCircle} className="edit">
                                <svg width="25" height="25" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <rect width="24" height="24" rx="12" fill="#00B5D4" />
                                    <path fillRule="evenodd" clipRule="evenodd"
                                        d="M13.9356 4.93644C14.6842 4.18785 15.9033 4.18785 16.6733 4.93644L18.0636 6.32669C18.8121 7.07528 18.8121 8.29442 18.0636 9.0644L9.05905 18.0689C8.69545 18.4325 8.20352 18.625 7.6902 18.625H5.01665C4.65305 18.625 4.375 18.347 4.375 17.9833V15.3098C4.375 14.7965 4.58888 14.3046 4.93109 13.9409L13.9356 4.93644ZM15.7536 5.85615C15.4969 5.59949 15.0906 5.59949 14.8339 5.85615L13.6148 7.07528L15.9247 9.38523L17.1439 8.16609C17.4005 7.90943 17.4005 7.50305 17.1439 7.24639L15.7536 5.85615ZM15.0264 10.2622L12.7165 7.95221L5.82941 14.8393C5.70108 14.9676 5.63691 15.1173 5.63691 15.2884V17.3203H7.66881C7.83991 17.3203 8.01102 17.2561 8.11796 17.1278L15.0264 10.2622Z"
                                        fill="white" />
                                </svg>
                            </span>
                            <span onClick={() => deleteCircle(circleResource.id)} className="outline ml-1" style={{ cursor: 'pointer' }}>
                                <svg width="25" height="25" viewBox="0 0 33 33" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <circle cx="16.0608" cy="16.8896" r="16" fill="#00B5D4" />
                                    <path d="M22.624 9.38794H19.1862V8.76288C19.1862 7.72891 18.345 6.8877 17.311 6.8877H14.8107C13.7768 6.8877 12.9356 7.72891 12.9356 8.76288V9.38794H9.49771C8.63607 9.38794 7.93506 10.089 7.93506 10.9506V13.1383C7.93506 13.4835 8.21493 13.7634 8.56012 13.7634H8.90172L9.44173 25.1037C9.48943 26.1052 10.3122 26.8897 11.3148 26.8897H20.807C21.8096 26.8897 22.6323 26.1052 22.68 25.1037L23.22 13.7634H23.5616C23.9068 13.7634 24.1867 13.4835 24.1867 13.1383V10.9506C24.1867 10.089 23.4857 9.38794 22.624 9.38794ZM14.1857 8.76288C14.1857 8.41824 14.4661 8.13782 14.8107 8.13782H17.311C17.6556 8.13782 17.9361 8.41824 17.9361 8.76288V9.38794H14.1857V8.76288ZM9.18518 10.9506C9.18518 10.7783 9.32539 10.6381 9.49771 10.6381H22.624C22.7963 10.6381 22.9366 10.7783 22.9366 10.9506V12.5133C22.7439 12.5133 9.98343 12.5133 9.18518 12.5133V10.9506ZM21.4313 25.0442C21.4154 25.3781 21.1411 25.6396 20.807 25.6396H11.3148C10.9806 25.6396 10.7063 25.3781 10.6905 25.0442L10.1532 13.7634H21.9685L21.4313 25.0442Z" fill="white" />
                                    <path d="M16.0606 24.3896C16.4058 24.3896 16.6857 24.1097 16.6857 23.7645V15.6387C16.6857 15.2935 16.4058 15.0137 16.0606 15.0137C15.7154 15.0137 15.4355 15.2935 15.4355 15.6387V23.7645C15.4355 24.1097 15.7154 24.3896 16.0606 24.3896Z" fill="white" />
                                    <path d="M19.1861 24.3896C19.5313 24.3896 19.8112 24.1097 19.8112 23.7645V15.6387C19.8112 15.2935 19.5313 15.0137 19.1861 15.0137C18.8409 15.0137 18.561 15.2935 18.561 15.6387V23.7645C18.561 24.1097 18.8409 24.3896 19.1861 24.3896Z" fill="white" />
                                    <path d="M12.9354 24.3896C13.2806 24.3896 13.5604 24.1097 13.5604 23.7645V15.6387C13.5604 15.2935 13.2806 15.0137 12.9354 15.0137C12.5902 15.0137 12.3103 15.2935 12.3103 15.6387V23.7645C12.3103 24.1097 12.5901 24.3896 12.9354 24.3896Z" fill="white" />
                                </svg>
                            </span>
                        </div>
                    )
                }
                <div>
                    {
                        (isSelectActive && props.circle) ?
                            <>
                                <span className="t-header-x-large text-center d-block mt-2">
                                    {circleLabel}
                                </span>
                                <div className="box-blue" style={{
                                    justifyContent: 'center',
                                    width: 'calc(100% + 90px)',
                                    marginLeft: -45,
                                    position: "relative",
                                    top: 30
                                }}>
                                    <CircleSelect
                                        defaultValue={defaultCircle}
                                        circles={[props.circle]}
                                        required={true}
                                        circleLabel="Cercle"
                                        subCircleLabel="Sous-cercle"
                                        isEditAction={true}
                                        submitCircle={updateCircle}
                                        onCancel={() => toggleSelect(false)}
                                    />
                                </div>
                            </>
                            :
                            <>
                                <CircleNames circlLabel={circleLabel} subCircles={circleResource[subCircleResourceName]} />
                            </>
                    }
                </div>
            </div>
        </BoxComponent >
    )
}

const mapStateToProps = (state) => ({
    circle: state.Circle.circle,
})

function mapDispatchToProps(dispatch) {
    return bindActionCreators({
        getCircle: circleCreators.get_circle,
    }, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(CircleItem)
