import axios from 'axios';
import { newConnectConfig } from '../../_services/contant';

export const REQUEST_CR = 'request CR';
export const FETCH_CR = 'fetch CR';
export const FETCH_CR_SUCCESS = 'fetch CR success';
export const FETCH_CR_ERROR= 'fetch CR error';
export const CR_STATUS= 'CR_STATUS';

export const TRY_ADD_COMMENT = 'TRY_ADD_COMMENT';
export const ADD_COMMENT_SUCCESS = 'ADD_COMMENT_SUCCESS';

export const TRY_PUBLISH_CR = 'TRY_PUBLISH_CR';
export const PUBLISH_CR_SUCCESS = 'PUBLISH_CR_SUCCESS';

export const requestCR = () => ({
    type: REQUEST_CR,
});

export const fetchCrSuccess = data => ({
    type: FETCH_CR_SUCCESS,
    data
});

export const crStatus = data => ({
    type: CR_STATUS,
    data
});

export const publisStatusData = data => ({
    type: FETCH_CR_SUCCESS,
    data
});


export const fetchCrError = error => ({
    type: FETCH_CR_ERROR,
    error
});

export const fetchCR = () => dispatch => {

    dispatch( requestCR() );

    const url = `${newConnectConfig.API_PATH}/c_rs/details`;

    return axios({
        method:'get',
        headers: {
          "Authorization" : `Bearer ${localStorage.getItem('token')}`
        },
        url,
      })
      .then( data => { dispatch( fetchCrSuccess(data.data) ) } )
      .catch( error => fetchCrError( error.response ) )

}


export const publishCr = ( draftValue = false, crs_id ) => dispatch => {

    const url = `${newConnectConfig.API_PATH}/c_rs/${crs_id}`;

    return axios({
        method:'put',
        headers: {
          "Authorization" : `Bearer ${localStorage.getItem('token')}`
        },
        url,
        data: {
            draft : draftValue
        },
      })
      .then( data => { 
          if( !draftValue ) {
            publisStatusData(true)
          }else {
            publisStatusData(false)
          }

          dispatch( fetchCR() )

      } )
      .catch( error => fetchCrError( error.response ) )

}

export const tryAddComment = ( commentValue, rowId ) => dispatch => {
    const url =  `${newConnectConfig.API_PATH}/logs/${rowId}`;

    return axios({
        method:'put',
        headers: {
          "Authorization" : `Bearer ${localStorage.getItem('token')}`
        },
        url,
        data: {
            comment: commentValue,
        },
      })
                
}
