import { newConnectConfig } from './contant';
import Axios from 'axios';

export const applicantCircleService = {
    get_applicant_circles,
    create_applicant_circle,
    update_applicant_circle,
    delete_applicant_circle,
}

function get_applicant_circles(filters = null) {
    const url = `${newConnectConfig.API_PATH}${newConnectConfig.APPLICANT_CIRCLE_ENDPOINT}${filters || ''}`;

    return Axios({
        method: 'GET',
        url,
        headers: { "Authorization": `Bearer ${localStorage.getItem('token')}` }
    });
}

function create_applicant_circle(data) {
    const url = `${newConnectConfig.API_PATH}${newConnectConfig.APPLICANT_CIRCLE_ENDPOINT}`;

    return Axios({
        method: 'POST',
        url,
        headers: {
            "Authorization": `Bearer ${localStorage.getItem('token')}`,
            "Content-Type": 'application/json',
        },
        data
    });
}

function update_applicant_circle(applicantCircleId, data) {
    const url = `${newConnectConfig.API_PATH}${newConnectConfig.APPLICANT_CIRCLE_ENDPOINT}/${applicantCircleId}`;

    return Axios({
        method: 'PUT',
        url,
        headers: {
            "Authorization": `Bearer ${localStorage.getItem('token')}`,
            "Content-Type": 'application/json',
        },
        data
    });
}

function delete_applicant_circle(applicantCircleId) {
    const url = `${newConnectConfig.API_PATH}${newConnectConfig.APPLICANT_CIRCLE_ENDPOINT}/${applicantCircleId}`;

    return Axios({
        method: 'DELETE',
        url,
        headers: { "Authorization": `Bearer ${localStorage.getItem('token')}` }
    });
}
