import React, {Component} from 'react'
import * as _ from 'lodash';
import RouteGenerator from './route_generator';
import {ClearCacheProvider} from 'react-clear-cache';

class Router extends Component {

    render() {
        return (
            <ClearCacheProvider duration={5000}>
                <RouteGenerator routes={_.values(this.props.routes)}/>
            </ClearCacheProvider>
        )
    }
}

export default Router
