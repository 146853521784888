export const helper = {
  hasCap
}

export function buildUrl(filters) {
  return Object.entries(filters).reduce((acc, item) => {
    if (item[1] !== 'null' && item[1] !== null) {
      if (typeof item[1] === Object) {
        acc += `${item[0]}=${encodeURIComponent(item[1].join(','))}&`
      } else {
        acc += `${item[0]}=${encodeURIComponent(item[1])}&`
      }

    }

    return acc
  }, '');
}

export function getStatusBackground(status) {
  switch (status) {
    case 'PRE_AFFECTED':
      return '#F2DD19'
    case 'WAITING_FOR_CUSTOMER_FEEDBACK':
      return '#FFA300'
    case 'FILLED':
      return '#7ed321'
    case 'LOST':
      return '#CF2323'
    case 'CANCELED':
      return '#F33F3F'
    default:
      return '#003D71'
  }
}

export function addParameterToURL(param, sorting = '') {

  if (param !== '') {
    let url = window.location.href.split('?')[0];
    url += (
      url.split('?')[1] ? '&' : '?'
    ) + param;
    return `${url}${sorting.sortMode !== undefined ? sorting.sortMode : ''}`;
  }

  return window.location.href
}

export function delete_competence_endpoint(pass) {
  switch (pass) {
    case 'userSkills':
      return 'user_skills';
    case 'userSolutions':
      return 'user_solutions'
    case 'userFields':
      return 'user_fields'
    case 'userLanguages':
      return 'user_languages'
    default:
      break
  }
}

function get_all_rights(role) {
  switch (role) {
    case 'CONSULTANT_CRH':
      return ['CONSULTANT_CRH']
    case 'EXTERNE':
      return ['EXTERNE', 'CONSULTANT_CRH']
    case 'CONSULTANT_SENIOR':
      return ['CONSULTANT_CRH', 'CONSULTANT_SENIOR']
    case 'MANAGER':
      return ['CONSULTANT_CRH', 'CONSULTANT_SENIOR', 'MANAGER']
    case 'SENIOR_MANAGER':
      return ['CONSULTANT_CRH', 'CONSULTANT_SENIOR', 'MANAGER', 'SENIOR_MANAGER']
    case 'RH':
      return ['CONSULTANT_CRH', 'CONSULTANT_SENIOR', 'MANAGER', 'SENIOR_MANAGER', 'RH']
    case 'PARTNER':
      return ['CONSULTANT_CRH', 'CONSULTANT_SENIOR', 'MANAGER', 'SENIOR_MANAGER', 'RH', 'PARTNER']
    case 'ADMIN':
      return ['CONSULTANT_CRH', 'CONSULTANT_SENIOR', 'MANAGER', 'SENIOR_MANAGER', 'RH', 'PARTNER', 'ADMIN']
    default:
  }
}

export function hasCap(role, user_role, strict = false) {
  if (user_role) {
    if (!strict && (
      user_role === 'ADMIN' || user_role === 'RH'
    )) {
      return true;
    }
    return role ? get_all_rights(user_role).includes(role) : get_all_rights('CONSULTANT_CRH')
  }
  return false
}


export function hasPrivilege(role) {
  return true;
}

export const levelDatas = [
  {label: 'Notions', value: 1},
  {label: 'Connaissance', value: 2},
  {label: 'Maîtrise', value: 3},
  {label: 'Expertise', value: 4}
]

export function downloadAction(file) {
  const downloadLink = document.createElement('a')
  downloadLink.setAttribute('href', file)
  downloadLink.setAttribute('id', 'download-action')
  downloadLink.setAttribute('download', true)
  document.querySelector('body').insertAdjacentElement('afterbegin', downloadLink)
  downloadLink.click()
  document.getElementById('download-action').remove()
}

export const monthNames = [
  'Janvier',
  'Février',
  'Mars',
  'Avril',
  'Mai',
  'Juin',
  'Juillet',
  'Aout',
  'Septembre',
  'Octobre',
  'Novembre',
  'Décembre'
]

export const circleTab = {
  tab: {
    key: 'CIRCLE',
    text: 'Cercles'
  }
}

export const updateFilters = (value, type, setFilters, filters) => {
  if (value === null) {
    setFilters(() =>
      Object.keys(filters)
            .filter(key => type !== key)
            .reduce((obj, key) => {
              obj[key] = filters[key];
              return obj;
            }, {})
    )

  } else {
    setFilters(prevState => (
      {...prevState, [type]: value}
    ))
  }
}

export function getQualificationStatusBackground(key) {
  let background

  switch (key) {
    case 'TO_CONTACT':
      background = '#003D71'
      break;
    case 'SILVER':
      background = '#BFBFC2'
      break;
    case 'EXPLORER':
      background = '#00B5D4'
      break;
    case 'PLATINUM':
      background = '#5264A5'
      break;
    case 'GOLD':
      background = '#DDB76F'
      break;
    case 'KO':
      background = '#F33F3F'
      break;
    default:
      background = '#003D71'
  }

  return background
}

export function getCoordinatesBetween(
  xStart,
  xEnd,
  yStart,
  yEnd,
  xIncrementer,
  yMax
) {
  let curX = xStart;
  let curY = yStart;
  const res = []; // Array for result coordinates

  while (curX <= xEnd) {
    while (curY <= yEnd) {
      res.push({x: curX, y: curY});
      curY++;
    }
    curX++;
    curY = yStart;
  }

  return res.reduce((acc, item) => {
    acc.push({
      ...item,
      xGrid: item.x + xIncrementer,
      yGrid: yMax - item.y
    });

    return acc;
  }, []);
}

export function firstLetters(str) {
  return str ? str
    .split(/\s/)
    .reduce((response, word) => (
      response + word.slice(0, 1)
    ), "") : '';
}

export function debounce(func, time = 100) {
  let timer;
  return function (event) {
    if (timer) clearTimeout(timer);
    timer = setTimeout(func, time, event);
  };
}

export function sleep(ms) {
  return new Promise(resolve => setTimeout(resolve, ms));
}

export function getAverageValue(ratings) {
  if (ratings.length === 0) {
    return null
  }

  const total = ratings.reduce((acc, item) => {
    acc += item.rating;
    return acc;
  }, 0)

  return total / ratings.length
}

export const toNumbers = arr => arr.map(Number);

export function userProfileAttrsHasRight(attributes, user) {
  for (const element of attributes) {
    const searchAttribute = Object.keys(user).find(key => key === element)
    if (searchAttribute !== undefined && user[element] === true) {
      return true;
    }
  }
  return false;
}

export const performanceMapping = [
  'INITIATION:1',
  'PRACTICE:2',
  'MASTERY:3',
  'EXPERTISE:4'
]

export function getPerformanceNote(data) {
  if(data) {
    const match = performanceMapping.find(i => i.includes(data.careerCommitteeReport.latestCareerCommitteeReportDecisionDraft.performanceStatus))
    if (match) {
      return match.split(':')[1]
    }
  }

  return null
}
