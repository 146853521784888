import {consultant_service} from "../../../_services/consultant";
import Axios from 'axios';
import {newConnectConfig} from "../../../_services/contant";
import {
  EMPTY_LESS_SKILLED_LIST,
  GET_LESS_SKILLED_LIST_FAIL,
  GET_LESS_SKILLED_LIST_START,
  GET_LESS_SKILLED_LIST_SUCCESS,
  TOGGLE_DISPLAY_MATCH_BLOCKS
} from "../actions-types";

export const consultant_creators = {
  search_user_by_role,
  get_list_constultants,
  filterConsultant,
  resetChef,
  get_list_externe,
  get_list_externeadmin,
  get_less_skilled_list,
  toggleToDisplayMatchBlocks,
  getAdminConsultantList
};

function resetChef() {
  return function (dispatch) {
    dispatch({
      type: 'UPDATE_CONSULTANTS_LIST',
      payload: null
    })
  }
}

function search_user_by_role(term, role) {
  return function (dispatch) {
    Axios.get(
      `${newConnectConfig.API_PATH}/users?name=${term}&role.name=${role}`,
      {
        headers: {"Authorization": `Bearer ${localStorage.getItem('token')}`}
      }
    )
         .then(function (response) {
           dispatch({
             type: 'UPDATE_CONSULTANTS_LIST',
             payload: {data: response.data, filter: true}
           })
           dispatch({
             type: 'NOTIFY_MATCHING_CONSULTANT_FETCHING',
             payload: false
           })
         })
         .catch(function (thrown) {
           if (Axios.isCancel(thrown)) {
             console.log('Request canceled', thrown.message);
           }
         });
  }
}

function filterConsultant(term, externe = 0) {
  return function (dispatch) {
    dispatch({
      type: 'NOTIFY_MATCHING_CONSULTANT_FETCHING',
      payload: true
    })

    Axios.get(
      `${newConnectConfig.API_PATH}/users?name=${term}&externe=${externe}`,
      {
        headers: {"Authorization": `Bearer ${localStorage.getItem('token')}`}
      }
    )
         .then(function (response) {
           dispatch({
             type: 'UPDATE_CONSULTANTS_LIST',
             payload: {data: response.data, filter: true}
           })
           dispatch({
             type: 'NOTIFY_MATCHING_CONSULTANT_FETCHING',
             payload: false
           })
         })
         .catch(function (thrown) {
           if (Axios.isCancel(thrown)) {
             console.log('Request canceled', thrown.message);
           } else {
             // handle error
           }
         });
  }
}

function get_list_externeadmin(next, filter, empty = false) {
  return function (dispatch) {

    dispatch({
      type: 'NOTIFY_MATCHING_CONSULTANT_FETCHING',
      payload: true
    })

    if (empty) {
      dispatch({
        type: 'UPDATE_CONSULTANTS_LIST',
        payload: null
      })
    } else {
      consultant_service.get_lists_externe(next, filter).then(function (response) {
        dispatch({
          type: 'UPDATE_CONSULTANTS_LIST',
          payload: {data: response.data, filter: filter}
        })
        dispatch({
          type: 'NOTIFY_MATCHING_CONSULTANT_FETCHING',
          payload: false
        })
      });
    }
  };
}

function get_list_externe(next, filter, empty = false) {
  return function (dispatch) {

    dispatch({
      type: 'NOTIFY_MATCHING_CONSULTANT_FETCHING',
      payload: true
    })


    if (empty) {
      dispatch({
        type: 'UPDATE_CONSULTANTS_LIST',
        payload: null
      })
    } else {
      consultant_service.get_lists_externe(next, filter).then(function (response) {
        dispatch({
          type: 'UPDATE_CONSULTANTS_LIST',
          payload: {data: response.data, filter: filter}
        })
        dispatch({
          type: 'NOTIFY_MATCHING_CONSULTANT_FETCHING',
          payload: false
        })
      });
    }
  };
}

function get_list_constultants(next, filter, empty = false, toKeepPrevList = false) {
  return function (dispatch) {

    dispatch({
      type: 'NOTIFY_MATCHING_CONSULTANT_FETCHING',
      payload: true
    })

    if (empty) {
      dispatch({
        type: 'UPDATE_CONSULTANTS_LIST',
        payload: null
      })
    } else {
      consultant_service.get_lists(next, filter).then(function (response) {
        dispatch({
          type: 'UPDATE_CONSULTANTS_LIST',
          payload: {data: response.data, filter: filter, toKeepPrevList: toKeepPrevList}
        })
        dispatch({
          type: 'NOTIFY_MATCHING_CONSULTANT_FETCHING',
          payload: false
        })
      });
    }
  };
}

function getAdminConsultantList(filters) {
  return (dispatch) => {
    consultant_service.getList(filters)
                      .then(response => {
                        return dispatch({
                          type: 'UPDATE_CONSULTANTS_LIST',
                          payload: {data: response.data, toKeepPrevList: false}
                        });
                      })
  }
}

// Not-skilled list actions
function get_less_skilled_list_start() {
  return dispatch => dispatch({
    type: GET_LESS_SKILLED_LIST_START
  })
}

function get_less_skilled_list_success(data) {
  return {
    type: GET_LESS_SKILLED_LIST_SUCCESS,
    payload: data
  }
}

function get_less_skilled_list_fail() {
  return dispatch => dispatch({
    type: GET_LESS_SKILLED_LIST_FAIL
  })
}

function empty_less_skilled_list() {
  return dispatch => dispatch({
    type: EMPTY_LESS_SKILLED_LIST
  })
}

function get_less_skilled_list(next, filter, empty = false) {
  return dispatch => {
    if (empty) {
      dispatch(empty_less_skilled_list());
      return;
    }
    dispatch(get_less_skilled_list_start())
    consultant_service.get_lists(next, filter)
                      .then(response => dispatch(get_less_skilled_list_success(response.data)))
                      .catch(error => {
                        console.log('fetching less skilled consultants error: ', error);
                        dispatch(get_less_skilled_list_fail())
                      })
  }
}

function toggleToDisplayMatchBlocks(toDisplay = false) {
  return dispatch => dispatch({
    type: TOGGLE_DISPLAY_MATCH_BLOCKS,
    payload: toDisplay
  })
}
