import React from 'react'
import {profileService} from '../../../_services/profile_service';
import BoxComponent from "../../../components/BoxComponent";
import Competences from "../Competences";

const Expertise = (props) => {
  return (
    <>
      <div className="box col-md-10 offset-md-1">
        <ul className="mission_legende">
          <li>
            <svg width="17" height="15" viewBox="0 0 17 15" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path fillRule="evenodd" clipRule="evenodd"
                    d="M8.5 12L3.79772 14.4721L4.69577 9.23607L0.891548 5.52786L6.14886 4.76393L8.5 0L10.8511 4.76393L16.1085 5.52786L12.3042 9.23607L13.2023 14.4721L8.5 12Z"
                    fill="#00B5D4"/>
            </svg>
            Évaluation par le chef de projet
          </li>
          <li>
            <svg width="17" height="15" viewBox="0 0 17 15" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path fillRule="evenodd" clipRule="evenodd"
                    d="M8.5 12L3.79772 14.4721L4.69577 9.23607L0.891548 5.52786L6.14886 4.76393L8.5 0L10.8511 4.76393L16.1085 5.52786L12.3042 9.23607L13.2023 14.4721L8.5 12Z"
                    fill="#003D71"/>
            </svg>
            Évaluation par le consultant
          </li>
          <li>
            <svg width="17" height="16" viewBox="0 0 17 16" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path fillRule="evenodd" clipRule="evenodd"
                    d="M6.97598 1.74957C6.97598 1.11385 7.51177 0.663121 8.08668 0.666523C9.61395 0.673861 10.8806 1.88521 10.8806 3.41675V3.43862C10.8806 3.8019 10.7952 4.16337 10.6254 4.49066L10.6247 4.49206L10.4495 4.83287H12.5248C13.4341 4.83287 14.3528 5.37998 14.6417 6.32494C14.8006 6.84662 14.7532 7.35374 14.5644 7.78606C14.933 8.16201 15.1666 8.67343 15.1666 9.24948C15.1666 9.82557 14.933 10.337 14.5643 10.713C14.6748 10.9657 14.7375 11.2453 14.7375 11.5416C14.7375 12.4205 14.1998 13.1497 13.451 13.4754C13.4192 14.5221 12.5422 15.3332 11.4996 15.3332C10.0056 15.3332 8.52428 15.0754 7.12171 14.5739L4.80531 13.7442C4.6282 13.6925 4.4427 13.666 4.25647 13.666H1.83325V5.66636H4.25405C4.31464 5.66636 4.36896 5.64053 4.40371 5.60195L6.93494 2.78879L6.93645 2.78712C6.96238 2.75849 6.97598 2.72177 6.97598 2.68628V1.74957Z"
                    fill="#7ED321"/>
            </svg>
            Est intéressé.e par
          </li>
        </ul>
      </div>
      <div className=" col-md-10 offset-md-1">
        {
          !props.props.profile.completedCoreSkillsRating &&
          <div className="warning-notice row mb-4">
            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path
                d="M10.29 3.85923L1.82002 17.9992C1.64539 18.3017 1.55299 18.6445 1.55201 18.9937C1.55103 19.343 1.64151 19.6864 1.81445 19.9898C1.98738 20.2931 2.23675 20.546 2.53773 20.7231C2.83871 20.9002 3.18082 20.9954 3.53002 20.9992H20.47C20.8192 20.9954 21.1613 20.9002 21.4623 20.7231C21.7633 20.546 22.0127 20.2931 22.1856 19.9898C22.3585 19.6864 22.449 19.343 22.448 18.9937C22.4471 18.6445 22.3547 18.3017 22.18 17.9992L13.71 3.85923C13.5318 3.56533 13.2807 3.32235 12.9812 3.15371C12.6817 2.98508 12.3438 2.89648 12 2.89648C11.6563 2.89648 11.3184 2.98508 11.0188 3.15371C10.7193 3.32235 10.4683 3.56533 10.29 3.85923V3.85923Z"
                stroke="#FFA300" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
              <path d="M12 9V13" stroke="#FFA300" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
              <path d="M12 17H12.01" stroke="#FFA300" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
            </svg>
            <p className="t-label-medium ml-2">Veuillez vous évaluer sur toutes les compétences socle.</p>
          </div>
        }
        <BoxComponent custom_class="box-profile row">
          <Competences
            label={'Compétences socles'}
            labelTooltipContent={[
              {rating: 1, label: 'Notions de bases'},
              {rating: 2, label: 'En cours d’acquisition'},
              {rating: 3, label: 'Bien acquis'},
              {rating: 4, label: 'Maîtrise / Expertise'}
            ]}
            col={4}
            datas={props.props.profile.userCoreSkills}
            hasInterest={true}
            type={'CoreSkills'}
            props={props.props}
            canAdd={props.props.profile.completedCoreSkillsRating}
          />
        </BoxComponent>
        <BoxComponent custom_class="box-profile row">
          <Competences
            label={'Connaissances métiers'}
            labelTooltipContent={[
              {rating: 1, label: 'Notions de bases'},
              {rating: 2, label: 'En cours d’acquisition'},
              {rating: 3, label: 'Bien acquis'},
              {rating: 4, label: 'Maîtrise / Expertise'}
            ]}
            hasParent
            datas={() => profileService.loadExperience(`parent_skills?experiences.userExperiences.user.id=${props.props.profile.id}`)}
            col={4}
            hasInterest={true}
            type={'skills'}
            subType={'userExperiences'}
            props={props.props}
          />
        </BoxComponent>
        <BoxComponent custom_class="box-profile row">
          <Competences
            label={'Solutions SIRH'}
            labelTooltipContent={[
              {rating: 1, label: 'Notions de bases'},
              {rating: 2, label: 'En cours d’acquisition'},
              {rating: 3, label: 'Bien acquis'},
              {rating: 4, label: 'Maîtrise / Expertise'}
            ]}
            hasParent
            datas={() => profileService.loadExperience(`parent_solutions?experiences.userExperiences.user.id=${props.props.profile.id}`)}
            col={4}
            hasInterest={true}
            type={'solutions'}
            subType={'userExperiences'}
            props={props.props}
          />
        </BoxComponent>
        <BoxComponent custom_class="box-profile row">
          <Competences
            label={'Contexte d’application'}
            labelTooltipContent={[
              {rating: 1, label: 'Peu ou pas mobilisé'},
              {rating: 2, label: 'Rarement mobilisé'},
              {rating: 3, label: 'Souvent mobilisé'},
              {rating: 4, label: 'Très souvent mobilisé'}
            ]}
            hasParent
            datas={() => profileService.loadExperience(`parent_fields?experiences.userExperiences.user.id=${props.props.profile.id}`)}
            col={6}
            type={'fields'}
            subType={'userExperiences'}
            props={props.props}
          />
        </BoxComponent>
        <BoxComponent custom_class="box-profile row">
          <Competences
            label="Langues"
            labelTooltipContent={[
              {rating: 1, label: 'Mission non réalisable, niveau insuffisant à l’écrit et/ou à l’oral'},
              {rating: 2, label: 'Mission réalisable, niveau intermédiaire'},
              {rating: 3, label: 'Mission réalisable, niveau avancé'},
              {rating: 4, label: 'Mission réalisable, niveau maîtrise'}
            ]}
            col={6}
            datas={props.props.profile.userLanguages}
            type={'languages'}
            props={props.props}
          />
        </BoxComponent>
      </div>
    </>
  )
}

export default Expertise
