import React from 'react'
import {hasCap} from "../../../helpers/app";
import {bindActionCreators} from "redux";
import {profile_creators} from "../../../reducers/Profile/actions/_update_profile";
import {connect} from "react-redux";
import {withRouter} from "react-router-dom";
import Select from "react-select";

const Icon = props => <span {...props}><svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path fillRule="evenodd" clipRule="evenodd" d="M15.0606 5.56144C15.8092 4.81285 17.0283 4.81285 17.7983 5.56144L19.1886 6.95169C19.9371 7.70028 19.9371 8.91942 19.1886 9.6894L18.5956 10.2823L14.4682 6.15383L15.0606 5.56144ZM13.592 7.03001L6.05609 14.5659C5.71388 14.9296 5.5 15.4215 5.5 15.9348V18.6083C5.5 18.972 5.77805 19.25 6.14165 19.25H8.8152C9.32852 19.25 9.82045 19.0575 10.1841 18.6939L17.7194 11.1585L13.592 7.03001Z" fill="#00B5D4"/>
    </svg>
  </span>

const EditField = props => {

  const [edit, setEdit] = React.useState(false);
  const [value, setValue] = React.useState(props.defaultValue);

  const keyPress  = e => {
    if(props.numberOnly !== undefined && !validateNumber(e)) {
      e.preventDefault()
    }

    if (e.keyCode === 13) {
      props.onChange( e.target.value , props.type)
      setEdit(false)
    }
  }

  const validateNumber = event => {
    var key = window.event ? event.keyCode : event.which;
    if (event.keyCode === 8 || event.keyCode === 46) {
      return true;
    } else if ( key < 48 || key > 57 ) {
      return false;
    } else {
      return true;
    }
  }

  return (
    ( props.match.path === "/my-profile" || hasCap('PARTNER', props.role) ) &&
    <div className="edit-field">
      <Icon onClick={() => setEdit(!edit)}/>
      { edit && props.fieldType === undefined ?
      <div className="edit-field__input">
        <input type="text" value={value} onKeyDown={keyPress} onChange={ e => setValue(e.target.value) }/>
        <span>Appuyez sur entrée pour sauvegarder</span>
      </div> :  edit && <div className="edit-field__input">
          <Select
            options={props.options}
            isMulti={false}
            onChange={ (value) =>  {
              props.onChange( {id: value.value } , props.type)
              setEdit(false)
            }}
            value={ props.options.find( item => item.value === props.defaultValue ) }
          />
        </div>
        }
    </div>
  )
}

const mapStateToProps = (state) => ({
  user_edit : state.App.editing_profile,
  role: state.Role.role
})

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({
    updateProfile: profile_creators.update_profile
  },dispatch)
}


export default connect( mapStateToProps, mapDispatchToProps)( withRouter(EditField ) )
