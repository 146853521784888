import React from 'react'
import cx from "classnames/bind";

const Legend = ({data, role}) => {
  return (
    data &&
    <div className="col-md-12">
      <ul className='mission_legende'>
        { data && Object.entries(data).map( (status,index) =>
          <li key={`status-${index}`}>
            <div className={cx({
              round: true,
              'round--search': status[0] === 'Positionnement par le collaborateur',
              'round--send': status[0] === 'Livrable réalisé',
              'round--archived': status[0] === 'Livrable porté',
              'round--go': status[0] === 'Go',
              'round--nogo': status[0] === 'No Go'
            })}>{status[1]}</div>
            { status[0] }
          </li>
        ) }
      </ul>
    </div>
  )
}

export default Legend
