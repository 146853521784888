const GET_NEED_FISCAL_YEARS = 'GET_NEED_FISCAL_YEARS';
const GET_AVAILABLE_POSITION_MONTHS_START = 'GET_AVAILABLE_POSITION_MONTHS_START';
const GET_AVAILABLE_POSITION_MONTHS_SUCCESS = 'GET_AVAILABLE_POSITION_MONTHS_SUCCESS';
const GET_AVAILABLE_POSITION_MONTHS_FAIL = 'GET_AVAILABLE_POSITION_MONTHS_FAIL';

export {
    GET_NEED_FISCAL_YEARS,
    GET_AVAILABLE_POSITION_MONTHS_START,
    GET_AVAILABLE_POSITION_MONTHS_FAIL,
    GET_AVAILABLE_POSITION_MONTHS_SUCCESS,
}
