export const menu_actions = {
    toggle_mobile_menu
};

function toggle_mobile_menu(value = true) {
    return function (dispatch) {
        dispatch({
            type : 'TOGGLE_MOBILE_MENU',
            payload : value
        })
    };
}