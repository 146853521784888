import React from 'react'
import cx from 'classnames/bind'
import FilterComponentSelect from "../../../../../components/FilterComponentSelect";

const Grade = props => {
  const getSelectedValues = () => props.value ?
    props.select_data["position"].filter(
      item => props.value.includes(item.value)
    ) : []

  return (
    <div className={cx({
      "filter-box": true,
      "filter-box--right" : props.filterXPos === 'right'
    })}>
      <span style={{
        color: '#000',
        fontSize: 14,
        marginBottom: 8,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between'
      }}>
        Filtrer par...
        { getSelectedValues().length > 0 &&
        <span style={{color: '#00B5D4', marginBottom: 8}} className="reset-filter"
             onClick={() => props.onFilterChange( 'requiredPositions_id' , null)}>
          Ré-initialiser
        </span>
        }
      </span>
      <FilterComponentSelect
        filter="position"
        select={ values => {
          const selectedValues = values.map(selectedItem => selectedItem.value)
          props.onFilterChange('requiredPositions_id', selectedValues.length > 0 ? selectedValues : null)
        }}
        data={props.select_data["position"]}
        value={getSelectedValues()}
      />
    </div>
  )
}

export default Grade
