import React from 'react'

const CheckBoxField = ({field, ...props}) => {

  const [checked, setChecked] = React.useState(field.value);

  React.useEffect(() => {
    props.setFieldValue(field.name,checked)
  }, [checked]);


  return(
  <div className="form-group">
    <div className="checkbox-field">
      <input type="checkbox" {...props} className="form-control" {...field} checked={checked}/>
      <label className="label" htmlFor={props.id} onClick={() => setChecked(!checked) }>
        {props.label}
      </label>
    </div>
  </div>
  )
};

export default CheckBoxField
