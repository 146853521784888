const HYDRA_MEMBER = "hydra:member";
const HYDRA_VIEW = "hydra:view";
const HYDRA_TOTAL_ITEMS = 'hydra:totalItems';
const HYDRA_PREV = 'hydra:previous';
const HYDRA_NEXT = 'hydra:next';

export {
    HYDRA_MEMBER,
    HYDRA_VIEW,
    HYDRA_TOTAL_ITEMS,
    HYDRA_PREV,
    HYDRA_NEXT
};
