import { circleService } from '../../_services/circleService';
import { HYDRA_MEMBER } from '../hydra';

export const circleCreators = {
    get_circles_list,
    get_circle,
}

function get_circles_list() {
    return (dispatch) => {
        circleService.get_circles()
            .then(response => {
                return dispatch({
                    type: 'GET_CIRCLES',
                    payLoad: response.data[HYDRA_MEMBER]
                });
            })
    }
}

function get_circle(circleId) {
    return (dispatch) => {
        circleService.get_circle(circleId)
            .then(response => {
                return dispatch({
                    type: 'GET_CIRCLE',
                    payLoad: response.data
                })
            })
    }
}
