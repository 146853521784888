import * as _ from 'lodash';

import {
  HYDRA_MEMBER,
  HYDRA_VIEW
} from '../hydra';
import initialState from './initialState'

function refere(state = initialState, action) {

    if (_.isUndefined(action)) {
        return state
    }

    const { type, payload } = action
    switch (type) {
      case 'UPDATE_REFERENT':
        if (payload === null) {
          return {referent: [], next_url: ''};
        } else if (payload.filter) {
          return {
            total: payload.data["hydra:totalItems"],
            referent: payload.data[HYDRA_MEMBER],
            next_url:
              payload.data[HYDRA_VIEW] &&
              payload.data[HYDRA_VIEW]["hydra:next"]
          };
        } else {
          return {
            total: payload.data["hydra:totalItems"],
            referent: [...state.referent, ...payload.data[HYDRA_MEMBER]],
            next_url:
              payload.data[HYDRA_VIEW] &&
              payload.data[HYDRA_VIEW]["hydra:next"]
          };
        }
      default:
        return state
    }

}

export default refere
