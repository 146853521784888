import React from 'react'
import InputBoxDoneTyping from "react-input-box-done-typing";

const DataListHeader = ({hasSearch, label, actions, searchChange}) => {
  return (
    <div className="header-data-list">
      <h1>{label}</h1>
      <div className="actions">
        { actions && actions.map( (button, index) =>
          button && button.url ?
            <a href={button.url} target="_blank" rel="noopener noreferrer" key={index} className={button.classes}>{button.label}</a>
            :
            button ? <button type={button.type || "submit"} data-tooltip={button.tooltip}  key={index} onClick={ () => button.onAction ? button.onAction() : null} className={button.classes}>
              {button.label}
            </button> : null
        ) }
        { hasSearch && <InputBoxDoneTyping doneTyping={ value => searchChange(value)} placeholder="Chercher par mots clés"/> }

      </div>
    </div>
  )
}

export default DataListHeader
