
import { newConnectConfig } from "./contant";
import Axios from "axios";

export const profileService = {
  getProfile,
  updateProfile,
  getSelectData,
  removeCompetence,
  update,
  getTowns,
  get_user_missions,
  upload_cv,
  deleteUser,
  exportSkills,
  downloadCv,
  addExpertise,
  updateExpertise,
  addRating,
  loadExperience
};

function loadExperience(endpoint) {
  const url = `${newConnectConfig.API_PATH}/${endpoint}`;
  return Axios({
    method:'get',
    url,
    headers: {"Authorization" : `Bearer ${localStorage.getItem('token')}`}
  })
}

function addRating(type, data) {
  const url = `${newConnectConfig.API_PATH}/user_${type.slice(0,-1)}_ratings`;
  return Axios({
    method:'POST',
    url,
    headers: {"Authorization" : `Bearer ${localStorage.getItem('token')}`},
    data
  })
}

function updateExpertise(type, expertiseID, data) {
  const url = `${newConnectConfig.API_PATH}/user_${type}/${expertiseID}`;
  return Axios({
    method:'put',
    url,
    headers: {"Authorization" : `Bearer ${localStorage.getItem('token')}`},
    data
  })
}

function addExpertise(type, data) {
  const url = `${newConnectConfig.API_PATH}/user_${type}`;
  return Axios({
    method:'POST',
    url,
    headers: {"Authorization" : `Bearer ${localStorage.getItem('token')}`},
    data
  })
}

function downloadCv(resumeId) {
  const url = `${newConnectConfig.API_PATH}/resumes/${resumeId}`;
  return Axios({
    method:'get',
    url,
    headers: {"Authorization" : `Bearer ${localStorage.getItem('token')}`}
  })
}

function deleteUser(user_id) {
  const url = `${newConnectConfig.API_PATH}${newConnectConfig.USER_ENDPOINT}/${user_id}`;
  return Axios({
    method:'delete',
    url,
    headers: {"Authorization" : `Bearer ${localStorage.getItem('token')}`}
  })
}

function get_user_missions(user_id) {
  const url = `${newConnectConfig.API_PATH}${newConnectConfig.USER_ENDPOINT}/${user_id}/missions`;
    return Axios({
      method:'get',
      url,
      headers: {"Authorization" : `Bearer ${localStorage.getItem('token')}`}
    })
}

function exportSkills(user_id) {
  const url = `${newConnectConfig.API_PATH}${newConnectConfig.USER_ENDPOINT}/${user_id}/export_skills`;
    return Axios({
      method:'get',
      url,
      headers: {"Authorization" : `Bearer ${localStorage.getItem('token')}`}
    })
}

function getProfile( user_id = null) {
  const url = `${newConnectConfig.API_PATH}${newConnectConfig.USER_ENDPOINT}/${user_id}`;
    return Axios({
      method:'get',
      url,
      headers: {"Authorization" : `Bearer ${localStorage.getItem('token')}`}
    })
}

function getTowns( username, password ) {
  const url = `${newConnectConfig.API_PATH}/towns`;
    return Axios({
      method:'get',
      url,
      headers: {"Authorization" : `Bearer ${localStorage.getItem('token')}`}
    })
}


function removeCompetence( endpoint, index ) {
  const url = `${newConnectConfig.API_PATH}/${endpoint}/${index}`;
  return Axios({
    method:'delete',
    url,
    headers: {"Authorization" : `Bearer ${localStorage.getItem('token')}`}
  })
}

function getSelectData( username, password, index ) {
  const url = `${newConnectConfig.API_PATH}/${index}`;
  return Axios({
    method:'get',
    url,
    headers: {"Authorization" : `Bearer ${localStorage.getItem('token')}`}
  })
}

function updateProfile( user_id, content ) {
  const url = `${newConnectConfig.API_PATH}${newConnectConfig.USER_ENDPOINT}/${user_id}`;
  return Axios({
    method:'put',
    url,
    headers: {"Authorization" : `Bearer ${localStorage.getItem('token')}`},
    data: content
  })
}

function upload_cv( url,method, content ) {
  return Axios({
    method: method ,
    url,
    headers: {"Authorization" : `Bearer ${localStorage.getItem('token')}`},
    data: content
  })
}


function update( endpoint, index, content ) {
  const url = `${newConnectConfig.API_PATH}/${endpoint}/${index}`;
  return Axios({
    method:'put',
    url,
    headers: {"Authorization" : `Bearer ${localStorage.getItem('token')}`},
    data: content
  })
}
