import React from 'react'
import {Link} from "react-router-dom";

const CardUserMissionPreAffected = props => {
  return (
    <div className="card">
      <span>
        <svg width="12" height="24" viewBox="0 0 12 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M12 0V24L0 12L12 0Z" fill="white"/>
        </svg>
      </span>
      <div className="content">
        <div>
        {props.users.map((user, index) => <div key={index} className="mb-2 user flex align-items-center" style={{width: '100%', padding: 0}}>
          <img style={{margin: 0}} src={user.photo && user.photo.contentUrl} alt="thumbnail"/>
          <h3 className="t-base-small ml-2">{user.fullname}</h3>
        </div>)}
        </div>
        <Link target="_blank" rel="noreferrer noopener" style={{ marginTop: 12 }} to={`/missions/view/${props.missionId}`} className="button-ui button-ui--outline">
          <svg width="14" height="16" viewBox="0 0 14 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fillRule="evenodd" clipRule="evenodd" d="M4.74388 0.744141L5.92239 1.92265L4.01164 3.8334H11.9998V5.50006H4.01164L5.92239 7.41081L4.74388 8.58932L0.821289 4.66673L4.74388 0.744141Z" fill="#00B5D4"/>
            <path fillRule="evenodd" clipRule="evenodd" d="M9.25221 7.41081L8.0737 8.58932L9.98445 10.5001H1.99629V12.1667H9.98445L8.0737 14.0775L9.25221 15.256L13.1748 11.3334L9.25221 7.41081Z" fill="#00B5D4"/>
          </svg>
          Revoir l'affectation
        </Link>
      </div>
    </div>
  )
}

export default CardUserMissionPreAffected
