export const newConnectConfig = {
    API_HOST : process.env.REACT_APP_API_HOST,
    API_PATH: process.env.REACT_APP_API_HOST + process.env.REACT_APP_API_PATH,
    LOGOUT_URL: process.env.REACT_APP_LOGOUT_URL,
    LOGIN_URL: process.env.REACT_APP_LOGIN_URL,
    USER_ENDPOINT: "/users",
    MISSION_ENDPOINT: "/missions",
    COMMENT_ENDPOINT: "/comments",
    DISPO_COURTE_ENDPOINT: "/short_availabilities",
    NOTIFICATIONS_ENDPOINT: "/notifications",
    PROPALE_ENDPOINT: "/commercial_propositions",
    LIVRABLE_ENDPOINT: "/deliverables",
    APPLICATION_STATUS_HISTORY_ENDPOINT: "/application_status_histories",
    CIRCLE_ENDPOINT: "/circles",
    USER_CIRCLE_ENDPOINT: "/user_circles",
    USER_SUB_CIRCLE_ENDPOINT: "/user_sub_circles",
    APPLICANT_CIRCLE_ENDPOINT: '/applicant_circles',
    CORE_SKILL_ENDPOINT: '/core_skills',
    NEED_ENDPOINT: '/needs',
}
