import * as _ from "lodash";
import initialState from "./initialState";

function Consultants(state = initialState, action) {

  if (_.isUndefined(action)) {
    return state;
  }

  const { type, payload } = action;

  if (type === 'UPDATE_LIST_CANDIDATES_NEEDS') {
      return {
        ...state,
        data: payload.data
      }
  } else {
    return state;
  }
}

export default Consultants;
