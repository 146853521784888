import * as _ from 'lodash';
import { GET_CORE_SKILL } from './actions-types';
import initialState from './initialState';

function CoreSkill(state = initialState, action = {}) {
    if (_.isUndefined(action)) {
        return state;
    }

    const { type, payLoad } = action;

    if (type === GET_CORE_SKILL) {
        return {
            coreSkill: payLoad,
        }
    }

    return state;
}

export default CoreSkill;
