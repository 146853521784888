import React, { Component } from 'react'
import { connect } from 'react-redux';
import moment from 'moment';
import CalendarView from './components/CalendarView';
import CalendarHeader from './components/CalendarHeader';
import '../../styles/components/PlanningContainer.css'
import { bindActionCreators } from "redux";
import { fecthDispos } from '../../reducers/Planning/planning.actions';

class PlanningContainer extends Component {

    constructor(props) {
        super(props)
        this.state = {
            currentWeek: this.dates( moment().toDate() ),
            nextWeek: this.dates( moment().add('weeks', 1).toDate() ),
            thirdWeek: this.dates( moment().add('weeks', 2).toDate() ),
            weekView: 'currentWeek',
            dispoFilter: {value:null, label:'Tous' }
        }
    }

    dates(current) {
        let week = [];
        // Starting Monday not Sunday
        current.setDate((current.getDate() - current.getDay() +1));
        for (let i = 0; i < 7; i++) {
            week.push(
                new Date(current)
            );
            current.setDate(current.getDate() +1);
        }

        return week;
    }

    componentDidMount() {
        this.props.fecthDispos()
    }

    render() {
        return (
            <div className="planning-container container mb-3 my-lg-5 py-lg-4">
                { this.props.planning &&
                <>
                    <CalendarHeader
                        onChangeWeek={ (value) => this.setState({weekView: value}) }
                        onFilterDispo={ data => this.setState({ dispoFilter: data }) }
                        view={this.state[this.state.weekView]}
                        activeView={ this.state.weekView }
                        dispoFilter={this.state.dispoFilter}
                    />
                    <CalendarView
                        view={this.state[this.state.weekView]}
                        activeView={ this.state.weekView }
                        planning={ this.props.planning }
                        dispoFilter={this.state.dispoFilter}
                    />
                </>
                }
            </div>
        )
    }
}

const mapStateToProps = (state) => ({
    planning : state.planning.planning
})

const mapDispatchToProps = (dispatch) => {
    return bindActionCreators({
        fecthDispos
    },dispatch)
}

export default connect(mapStateToProps,mapDispatchToProps)(PlanningContainer)
