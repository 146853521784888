import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from "redux";

import { userCircleCreators } from '../../../reducers/UserCircle/actions';
import CircleItem from './CircleItem';

function UserCircleContainer(props) {
  React.useEffect(() => {
    props.getUserCircles(props.userId);
  }, [])

  return (
    <div>
      {
        props.userCircles.length > 0 &&
        props.userCircles.map((userCircle, index) =>
          <CircleItem
            key={index}
            circleResource={userCircle}
            subCircleResourceName="userSubCircles"
            triggerSuccessToast={props.triggerSuccessToast}
            canManageCircle={props.canManageCircle}
          />
        )
      }
    </div>
  )
}

const mapStateToProps = (state) => ({
  userCircles: state.UserCircle.userCircles,
})

function mapDispatchToProps(dispatch) {
  return bindActionCreators({
    getUserCircles: userCircleCreators.get_user_circles_list,
  }, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(UserCircleContainer)
