import Axios from 'axios';
import {newConnectConfig} from './contant';

function createFeeback(data, restUrl = '') {
  const url = `${newConnectConfig.API_PATH}/application_feedbacks${restUrl}`;

  return Axios({
    method: 'POST',
    url,
    headers: {
      "Authorization": `Bearer ${localStorage.getItem('token')}`,
      'Content-Type': 'application/json'
    },
    data
  });
}

function addSalaryCommentary(data) {
  const url = `${newConnectConfig.API_PATH}/application_salary_commentaries`;

  return Axios({
    method: 'POST',
    url,
    headers: {
      "Authorization": `Bearer ${localStorage.getItem('token')}`,
      'Content-Type': 'application/json'
    },
    data
  });
}

function addEnglishLevel(data) {
  const url = `${newConnectConfig.API_PATH}/application_english_level_evaluations`;

  return Axios({
    method: 'POST',
    url,
    headers: {
      "Authorization": `Bearer ${localStorage.getItem('token')}`,
      'Content-Type': 'application/json'
    },
    data
  });
}

function updateEnglishLevel(evaluationId, data) {
  const url = `${newConnectConfig.API_PATH}/application_english_level_evaluations/${evaluationId}`;

  return Axios({
    method: 'PUT',
    url,
    headers: {
      "Authorization": `Bearer ${localStorage.getItem('token')}`,
      'Content-Type': 'application/json'
    },
    data
  });
}

function updateSalaryCommentary(commentaryId, data) {
  const url = `${newConnectConfig.API_PATH}/application_salary_commentaries/${commentaryId}`;

  return Axios({
    method: 'PUT',
    url,
    headers: {
      "Authorization": `Bearer ${localStorage.getItem('token')}`,
      'Content-Type': 'application/json'
    },
    data
  });
}

function updateFeedback(applicationId, data, restUrl) {
  const url = `${newConnectConfig.API_PATH}/application_feedbacks/${applicationId}${restUrl}`;

  return Axios({
    method: 'PUT',
    url,
    headers: {
      "Authorization": `Bearer ${localStorage.getItem('token')}`,
      'Content-Type': 'application/json'
    },
    data
  });
}

function generatePromiseJobPreview(application_id, data) {
  const url = `${newConnectConfig.API_PATH}/applications/${application_id}/generate_employment_promise`;

  return Axios({
    method: 'PUT',
    url,
    headers: {
      "Authorization": `Bearer ${localStorage.getItem('token')}`,
      'Content-Type': 'application/json'
    },
    data
  });
}

function updateApplicant(application_id, data) {
  const url = `${newConnectConfig.API_PATH}/applications/${application_id}`;

  return Axios({
    method: 'PUT',
    url,
    headers: {
      "Authorization": `Bearer ${localStorage.getItem('token')}`,
      'Content-Type': 'application/json'
    },
    data
  });
}

function getFeedbacks(applicationID) {
  const url = `${newConnectConfig.API_PATH}/applications/${applicationID}`;

  return Axios({
    method: 'get',
    url,
    headers: {"Authorization": `Bearer ${localStorage.getItem('token')}`}
  });
}

function sendEmploymentPromise(applicationID) {
  const url = `${newConnectConfig.API_PATH}/applications/${applicationID}/send_employment_promise`;

  return Axios({
    method: 'get',
    url,
    headers: {"Authorization": `Bearer ${localStorage.getItem('token')}`}
  });
}

function filterFeedbacks(filter) {
  const url = `${newConnectConfig.API_PATH}/application_feedbacks${filter}`;

  return Axios({
    method: 'get',
    url,
    headers: {"Authorization": `Bearer ${localStorage.getItem('token')}`}
  });
}

function getDbalDataForSelect(type, forSelect = true) {
  return Axios.get(
    `${newConnectConfig.API_PATH}/dbal?type=${type}`, {
      headers: {
        "Authorization": `Bearer ${localStorage.getItem('token')}`,
        'Content-Type': 'application/json'
      }
    }
  ).then(response => {
    if (forSelect) {
      return response.data.reduce((acc, item) => {
        acc.push({
          label: item.text,
          value: item.key
        })
        return acc;
      }, []);
    }

    return response.data
  });
}

function cancelRdv(id, data) {
  const url = `${newConnectConfig.API_PATH}/application_feedbacks/${id}/delete`;
  return Axios({
    method: 'put',
    url,
    headers: {
      "Authorization": `Bearer ${localStorage.getItem('token')}`
    },
    data
  });
}

function deleteCandidate(applicationID) {
  const url = `${newConnectConfig.API_PATH}/applications/${applicationID}`;
  return Axios({
    method: 'delete',
    url,
    headers: {
      "Authorization": `Bearer ${localStorage.getItem('token')}`
    }
  });
}

function createApplication(data) {
  const url = `${newConnectConfig.API_PATH}/applications`;

  return Axios({
    method: 'POST',
    url,
    headers: {
      "Authorization": `Bearer ${localStorage.getItem('token')}`,
      'Content-Type': 'application/json'
    },
    data
  });
}

function uploadResume(file) {
  const formData = new FormData();
  formData.append('file', file)
  const url = `${newConnectConfig.API_PATH}/applications/upload_resume`;
  return Axios({
    method: 'post',
    url,
    headers: {
      "Authorization": `Bearer ${localStorage.getItem('token')}`,
      'content-type': 'multipart/form-data'
    },
    data: formData
  });
}

function getNeedsByPosition(positionId) {
  const url = `${newConnectConfig.API_PATH}/needs?pagination=false&position.id=${positionId}&order[month]=ASC`;

  return Axios({
    method: 'get',
    url,
    headers: {"Authorization": `Bearer ${localStorage.getItem('token')}`}
  });
}

function getEmploymentPromiseExtraData() {
  const url = `${newConnectConfig.API_PATH}/applications/get_employment_promise_extra_data`;

  return Axios({
    method: 'get',
    url,
    headers: {"Authorization": `Bearer ${localStorage.getItem('token')}`}
  });
}

function assignMonthArrival(applicationId, data) {
  const url = `${newConnectConfig.API_PATH}/applications/${applicationId}`;
  return Axios({
    method: 'put',
    url,
    headers: {
      "Authorization": `Bearer ${localStorage.getItem('token')}`
    },
    data
  });
}

export const AtsService = {
  getDbalDataForSelect,
  getEmploymentPromiseExtraData,
  getFeedbacks,
  updateFeedback,
  updateApplicant,
  createFeeback,
  cancelRdv,
  deleteCandidate,
  generatePromiseJobPreview,
  sendEmploymentPromise,
  addSalaryCommentary,
  updateSalaryCommentary,
  addEnglishLevel,
  updateEnglishLevel,
  filterFeedbacks,
  uploadResume,
  createApplication,
  getNeedsByPosition,
  assignMonthArrival
};
