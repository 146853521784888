import { newConnectConfig } from './contant';
import Axios from 'axios';

export const userCircleService = {
    get_user_circles,
    create_user_circle,
    update_user_circle,
    delete_user_circle,
}

function get_user_circles(userId = null) {
    let url = `${newConnectConfig.API_PATH}${newConnectConfig.USER_CIRCLE_ENDPOINT}`;

    if (userId) {
        url += `?user.id[]=${userId}`;
    }

    return Axios({
        method: 'GET',
        url,
        headers: { "Authorization": `Bearer ${localStorage.getItem('token')}` }
    });
}

function create_user_circle(data) {
    const url = `${newConnectConfig.API_PATH}${newConnectConfig.USER_CIRCLE_ENDPOINT}`;

    return Axios({
        method: 'POST',
        url,
        headers: {
            "Authorization": `Bearer ${localStorage.getItem('token')}`,
            "Content-Type": 'application/json',
        },
        data
    });
}

function update_user_circle(userCircleId, data) {
    const url = `${newConnectConfig.API_PATH}${newConnectConfig.USER_CIRCLE_ENDPOINT}/${userCircleId}`;

    return Axios({
        method: 'PUT',
        url,
        headers: {
            "Authorization": `Bearer ${localStorage.getItem('token')}`,
            "Content-Type": 'application/json',
        },
        data
    });
}

function delete_user_circle(userCircleId) {
    const url = `${newConnectConfig.API_PATH}${newConnectConfig.USER_CIRCLE_ENDPOINT}/${userCircleId}`;

    return Axios({
        method: 'DELETE',
        url,
        headers: { "Authorization": `Bearer ${localStorage.getItem('token')}` }
    });
}
