import React from 'react'
import {getStatusBackground} from '../../../../helpers/app';

const MissionStatus = ({mission, onChange}) => {
  const [editMode, setEditMode] = React.useState(false);

  function updateStatus(status) {
    setEditMode(false)
    onChange(status)
  }

  return (
    !editMode ?
    <div className="ui-dynamic-dropdown">
      <span style={{width: 250, background: getStatusBackground(mission.status)}}>
        {mission.statusData.text}
        {(mission.status !== 'LOST' || mission.status !== 'CANCELED') &&
        <button onClick={() => setEditMode(!editMode)} style={{padding: 0}} type="button" className="button-ui">
          <svg style={{margin: 0}} width="24" height="24" viewBox="0 0 24 24" fill="none"
               xmlns="http://www.w3.org/2000/svg">
            <path fillRule="evenodd" clipRule="evenodd"
                  d="M14.5606 5.56144C15.3092 4.81285 16.5283 4.81285 17.2983 5.56144L18.6886 6.95169C19.4371 7.70028 19.4371 8.91942 18.6886 9.6894L18.0956 10.2823L13.9682 6.15383L14.5606 5.56144ZM13.092 7.03001L5.55609 14.5659C5.21388 14.9296 5 15.4215 5 15.9348V18.6083C5 18.972 5.27805 19.25 5.64165 19.25H8.3152C8.82852 19.25 9.32045 19.0575 9.68405 18.6939L17.2194 11.1585L13.092 7.03001Z"
                  fill="white"/>
          </svg>
        </button>}
      </span>
    </div> :
    <div>
      <div className="radio-button-group flex" style={{width: '50%'}} >
        <button onClick={() => updateStatus('CANCELED')} style={{width: '50%'}} className="button-ui button-ui--white button-ui--c-black">Annulé</button>
        <button onClick={() => updateStatus('LOST')}  style={{width: '50%'}}  className="button-ui button-ui--white button-ui--c-black">Perdu</button>
      </div>
      <button style={{width: '50%'}} onClick={() => setEditMode(false)} className="button-ui button-ui--full-outline">Retour</button>
    </div>
  )
}

export default MissionStatus
