import * as _ from 'lodash'
import initialState from './initialState'

function Menu(state = initialState, action) {

    if (_.isUndefined(action)) {
        return state
    }

    const { type, payload } = action

    switch (type) {

      case 'TOGGLE_MOBILE_MENU':
        return { menu_mobile_open: payload }

      default:
        return state
    }

}

export default Menu
