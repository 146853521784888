import React from 'react'
import '../css/signin.css'
import axios from 'axios'
import { Formik, Field, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import { newConnectConfig } from '../../_services/contant';
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux';
import { profile_creators } from '../../reducers/Profile/actions/_update_profile';
import { notification_actions } from '../../reducers/Notifications/actions';
import loginBg from '../../assets/images/login-background.png'

const ComposantErreur = props => (
  <div className="text-danger">{props.children}</div>
);

const ComposantInput = ({
  field,
  form: { touched, errors },
  ...props
}) => (
  <div className="form-group login-form-row">
    <label> { props.label } </label>
    <input
        style={{
            // width: 275
        }}
        type="text"
        {...props}
        className="form-control"
        {...field}
    />
  </div>
);

const userSchema = Yup.object().shape({
    username: Yup.string().required("Obligatoire"),
    password: Yup.string().required('Obligatoire')
});



function signIn(props) {

    const [error, setError] = React.useState('')

    React.useEffect(() => {

        if( props.role.email && localStorage.getItem('token') ) {
            window.location.replace('#/signup')
        }

        return () => {
        };
    }, [props.role])

    const submit = (values, actions) => {

        axios.post(`${newConnectConfig.API_PATH}/login_check`, values)
             .then(function (response) {
                localStorage.setItem('token', response.data.token)
                localStorage.setItem('refresh_token', response.data.refresh_token)
                axios.get(
                    `${newConnectConfig.API_PATH}/users/me`,
                    { headers: {"Authorization" : `Bearer ${localStorage.getItem('token')}`} }
                )
                .then(response => {
                    actions.setSubmitting(false);
                    // If request is good...
                    localStorage.setItem('user_id', response.data.id)
                    if( !response.data.signedUp ) {

                    props.updateRole( localStorage.getItem('user_id') );
                    } else {
                        window.location.replace('#/my-profile')
                        props.updateRole( localStorage.getItem('user_id') );
                    }
                })
            })
            .catch(function (error) {
                actions.setSubmitting(false)
                switch (error.response.data.code) {
                    case 401:
                        setError('Login et/ou mot de passe invalide')
                        break;
                    default:
                        break;
                }
            });
    }

    return (
        <div className="signin-container">
            <div className="left" style={{
                background: `url(${loginBg})`,
                backgroundSize: 'cover'
            }}>
                <div className="text">
                    <h2>ConvictionsRH : Trouvez votre prochaine mission ! </h2>
                    <p style={{ marginTop: 30 }}>Notre plateforme vous permettra d'être référencé dans notre vivier d'experts et de trouver votre prochaine mission.</p>
                </div>
            </div>
            <div className="right">
                <Formik
                onSubmit={submit}
                initialValues={{
                    username: '',
                    password: ''
                }}
                validationSchema={userSchema}
                >
                {({
                handleSubmit,
                isSubmitting
                }) => (
                    <>
                    <form onSubmit={ handleSubmit } className="box_signin">
                        <h2>
                            Connectez-vous
                            <span>Accédez à votre espace</span>
                        </h2>
                    <Field name="username" label="Email" component={ComposantInput} />
                    <ErrorMessage name="username" component={ComposantErreur} />
                    <Field name="password" label="Mot de passe" type="password" component={ComposantInput} />
                    <ErrorMessage name="password" component={ComposantErreur} />
                    <button type="submit" className="btn_blue" disabled={isSubmitting} >
                        Envoyer
                    </button>
                    { error !== '' && <span style={{
                            color: 'red',
                            marginTop: 20,
                            display: 'flex'
                    }} className="error">{error}</span> }
                     <p style={{
                         marginTop: 20
                     }}>
                         Vous êtes un consultant interne à ConvictionsRH?
                         <a style={{
                            display: 'block',
                            textAlign: 'center',
                            color: '#00b5d4',
                            fontWeight: 'bold',
                            marginTop: 3
                         }} href={ `${newConnectConfig.LOGIN_URL}` }>Connectez-vous ici</a>
                    </p>
                    </form>
                    </>
                )}
                </Formik>
            </div>
        </div>
    )
}


const mapStateToProps = (state) => ({
    role : state.Role
})

  const mapDispatchToProps = (dispatch) => {
    return bindActionCreators({
      updateSelectData: profile_creators.update_select_data,
      updateRole: profile_creators.updateRole,
      new_notification: notification_actions.notificationCheck
    },dispatch)
  }
export default connect(mapStateToProps,mapDispatchToProps)(signIn)
