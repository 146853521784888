import React from 'react'

const EmptyStar = props => <span onClick={ () => props.update()}><svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
  <path d="M12.3628 19.2439L12 19.0433L11.6372 19.2439L5.57246 22.5964L6.74022 15.4375L6.80116 15.0639L6.53682 14.793L1.55613 9.68813L8.40507 8.64171L8.80068 8.58126L8.97073 8.21898L12 1.76513L15.0293 8.21898L15.1993 8.58126L15.5949 8.64171L22.4439 9.68813L17.4632 14.793L17.1988 15.0639L17.2598 15.4375L18.4275 22.5964L12.3628 19.2439Z" fill="#D4E0E3" fillOpacity="0.4" stroke="#D4E0E3" strokeWidth="1.5"/>
</svg></span>

const CheckedStar = props => <span className="checked" onClick={ () => props.update() }><svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
  <path d="M12.3628 19.2439L12 19.0433L11.6372 19.2439L5.57246 22.5964L6.74022 15.4375L6.80116 15.0639L6.53682 14.793L1.55613 9.68813L8.40507 8.64171L8.80068 8.58126L8.97073 8.21898L12 1.76513L15.0293 8.21898L15.1993 8.58126L15.5949 8.64171L22.4439 9.68813L17.4632 14.793L17.1988 15.0639L17.2598 15.4375L18.4275 22.5964L12.3628 19.2439Z" fill="#003D71" stroke="#003D71" strokeWidth="1.5"/>
</svg></span>



const StarsRating = props => {

  const [rate, setRate] = React.useState(props.value);
  const items = []

  React.useEffect(() => {
    setRate(props.value)
  }, [props.value]);

  React.useEffect(() => {
    if( props.editMode ) {
      props.onStarClick(rate)
    }
  }, [rate]);

  const displayStars = () => {
    for ( let i = 0; i < props.stars; i++ ) {
      if( rate > i ) {
        items.push(<CheckedStar key={i} update={() => props.editMode && setRate(i + 1)} />)
      } else {
        items.push(<EmptyStar key={i} update={() => props.editMode && setRate(i + 1)} />)
      }
    }

    return items
  }

  return (
    <div className="star-rating">
      { displayStars() }
    </div>
  )
}

export default StarsRating
