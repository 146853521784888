import React from 'react'
import cx from 'classnames/bind'

const CustomDate = props => {
  return (
    <div className={cx({
      "filter-box": true,
      "filter-box--right" : props.filterXPos === 'right'
    })}>
      {props.sorting &&
      <div style={{color: '#00B5D4', marginBottom: 8}} className="reset-filter" onClick={() => props.updateSorting('', '')}>
        Ré-initialiser
      </div>
      }
      <button onClick={() => props.updateSorting(props.termSearch, props.prefix !== undefined ? `order[${props.prefix}${props.termSearch}]=asc` : `order[${props.termSearch}]=asc`) }>Trier par, ascendant</button>
      <button onClick={() => props.updateSorting(props.termSearch, props.prefix !== undefined ? `order[${props.prefix}${props.termSearch}]=desc` : `order[${props.termSearch}]=desc`) }>Trier par, descendant</button>
    </div>
  )
}

export default CustomDate
