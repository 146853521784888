import React from 'react'

const index = props => {
  return (
    <th
      style={{...props.styles}}
      onClick={e => !e.target.closest('.filter-box') && props.setFilterOpen(props.header.id)}
      className={
        `col-${props.header.id} ${props.sorting.key === props.header.id ? 'active_sorting' : ''} ${(
                                                                                                     props.filterOpen === props.header.id || (
                                                                                                       props.filter !== undefined && props.filter !== null
                                                                                                     )
                                                                                                   ) ? 'opened' : ''}`}
    >
      <span dangerouslySetInnerHTML={{__html: props.header.label}}/>
      {props.header.filter &&
        <>
            <span>
              <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M2 4.66663H14V5.99996H2V4.66663Z" fill="#99999B"/>
                  <path d="M4 7.33329H12V8.66663H4V7.33329Z" fill="#99999B"/>
                  <path d="M10 9.99996H6V11.3333H10V9.99996Z" fill="#99999B"/>
              </svg>
            </span>
          {props.filterOpen === props.header.id &&
            <props.header.filter
              value={props.filter}
              sorting={props.sorting}
              updateSorting={(key, sortMode) => props.updateSorting(key, sortMode)}
              select_data={props.select_data}
              filterXPos={props.header.filterXPos}
              onFilterChange={(type, value) => props.onFilterChange(type, value)}
              termSearch={props.termSearch}
              operator={props.operator}
              prefix={props.prefix}
              options={props.selectOptions}
              filterId={props.header.id}
              sortFilterId={props.header.sortId}
              {...props.header}
            />}
        </>
      }
    </th>
  )
}

export default index
