import React from 'react'
import { DragDropContextProvider } from 'react-dnd';
import HTML5Backend from 'react-dnd-html5-backend';
import App from '../App';

const DragAndDropContext = props => {

    const _window = props.window || window

    return (
        <DragDropContextProvider
            backend={HTML5Backend}
            window={_window}
        >
            <App/>
        </DragDropContextProvider>
    )
}

export default DragAndDropContext
