import React, {useRef} from 'react'
import cx from 'classnames/bind'
import LineRatingItem from '../LineRatingItem';

const ParentExpertiseContainer = ({data, sectionOpened, setSectionOpened, type, role, profile, subType, onEdit, labelTooltipContent, col}) => {
  const ref = useRef()

  return (
    <div ref={ref} className="section-accordion">
      {data && data.sort((a,b) => a.position - b.position).map((section) => section[type].length > 0 && <div className={cx({
        'section-accordion--opened': sectionOpened === section.id
      })} onClick={() => setSectionOpened(section.id)}>
        <span className="t-label-large c-blue">
          {section.name}
          <svg width="31" height="32" viewBox="0 0 31 32" fill="none" xmlns="http://www.w3.org/2000/svg">
            <circle cx="15.7677" cy="15.2511" r="15.1545" fill="#F2F2F4"/>
            <path fillRule="evenodd" clipRule="evenodd" d="M6.96973 12.9601L8.38394 11.5459L15.6768 18.8388L22.9697 11.5459L24.3839 12.9601L15.6768 21.6672L6.96973 12.9601Z" fill="#003D71"/>
          </svg>
        </span>
        <div className="content row">
          {section[type] && section[type].map((value, index) => <LineRatingItem
            name={value.name}
            onEdit={(t, d) => onEdit(t, d, data, section )}
            profile={profile}
            col={col}
            role={role}
            key={index}
            data={value[subType][0] || []}
            type={type}
            ratings={[]}
            labelTooltipContent={labelTooltipContent}
          />)}
        </div>
      </div>)}
    </div>
  )
}

export default ParentExpertiseContainer
