import { profileService } from "../../../_services/profile_service";
import { _getUserProfile } from "../../App/actions/user";
import { toast } from "react-toastify";
import {consultant_service} from "../../../_services/consultant";

export const profile_creators = {
    update_profile,
    deleteCompetence,
    update_select_data,
    update,
    getTowns,
    updateRole,
    get_user_missions,
    upload_cv,
    deleteUser,
    deleteUserExterne
};

function deleteUserExterne(user_id) {
    return function (dispatch) {
        profileService.deleteUser( user_id ).then(function(response){
            consultant_service.get_lists_externe(null, true).then(function(response){
                dispatch({
                    type : 'UPDATE_CONSULTANTS_LIST',
                    payload : { data: response.data, filter: true}
                });
            });
        })
    };
}

function deleteUser(user_id) {
    return function (dispatch) {
        profileService.deleteUser( user_id ).then(function(response){
            consultant_service.get_lists().then(function(response){
                dispatch({
                    type : 'UPDATE_CONSULTANTS_LIST',
                    payload : { data: response.data, filter: true}
                });
            });
        })
    };
}

function get_user_missions(user_id) {
    return function (dispatch) {
        profileService.get_user_missions( user_id ).then(function(response){
            dispatch({
                type : 'GET_USER_MISSION',
                payload : response.data['hydra:member']
            })
        })
    };
}


function updateRole(user_id) {
    return function (dispatch) {
        profileService.getProfile( user_id ).then(function(response){
            dispatch({
                type : 'SET_ROLE',
                payload : response.data
            })
        })
    };

}

function update_profile(user_id , content) {
    return function (dispatch) {
        profileService.updateProfile(user_id, content ).then(function(response){
            dispatch( _getUserProfile(user_id) )
            toast.success("Profil mis à jour avec succès", {
                position: toast.POSITION.BOTTOM_LEFT
            });
        })
    };
}

function deleteCompetence(endpoint,index, user) {
    return function (dispatch) {
        profileService.removeCompetence( endpoint, index ).then(function(response){
            dispatch( _getUserProfile(user) )
        })

    };
}

function getTowns() {
    return function (dispatch) {
        profileService.getTowns( 'admin@newconnect.com', 'demo' ).then(function(response){
            let temp = [];
            for (let i = 0; i < response.data['hydra:member'].length; i++) {
                temp.push({
                    'value' : response.data['hydra:member'][i]['id'],
                    'label': response.data['hydra:member'][i]['name']
                });
            }
            dispatch({
                type : 'GET_TOWNS',
                payload : temp
            })
        })
    }
}


function update_select_data(index) {
    return function (dispatch) {
        profileService.getSelectData( 'admin@newconnect.com', 'demo', index+'s' ).then(function(response){
            let temp = {};
            temp[index] = [];
            for (let i = 0; i < response.data['hydra:member'].length; i++) {
                temp[index].push({
                    'value' : response.data['hydra:member'][i]['id'],
                    'label': response.data['hydra:member'][i]['formattedName'] ? response.data['hydra:member'][i]['formattedName'] : response.data['hydra:member'][i]['name']
                });
            }

            dispatch({
                type : 'PROFILE_UPDATE_SELECT',
                payload : temp
            })
        })
    };
}

function upload_cv( url,method,content, user_id ){
    return function (dispatch) {
        profileService.upload_cv( url, method, content ).then(function(response){
            dispatch( _getUserProfile(user_id) )
        })

    };
}

function update (endpoint,index,content, user_id) {
    return function (dispatch) {
        profileService.update( endpoint, index, content ).then(function(response){
            dispatch( _getUserProfile(user_id) )
        })

    };
}
