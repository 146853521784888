import moment from 'moment';
import React, {useState} from 'react'
import {connect} from 'react-redux';
import {missions_service} from '../../../../_services/missions';
import Listing from '../../../../components/ui/Listing/Listing';
import HeaderThead from '../../../../containers/Missions/listing/header';
import {getStatusBackground} from '../../../../helpers/app';

const LineListing = ({history}) => {
  return (
    <tr>
      <td>
        <div className="ui-dynamic-dropdown">
          <span className="center t-base-medium" style={{
            justifyContent: 'center',
            textAlign: 'center',
            background: getStatusBackground(history.previousStatus.key)
          }}>{history.previousStatus.text}</span>
        </div>
      </td>
      <td>
        <span>{history.changedBy.fullName}</span>
      </td>
      <td>
        <div className="ui-dynamic-dropdown">
          <span className="center t-base-medium" style={{
            justifyContent: 'center',
            textAlign: 'center',
            background: getStatusBackground(history.status.key)
          }}>{history.status.text}</span>
        </div>
      </td>
      <td>
        <span>{moment(history.changedAt).format('DD/MM/YY')}</span>
      </td>
    </tr>
  )
}


const StatusHistory = ({mission}) => {

  const [histories, setHistories] = useState(null);
  const [sorting, setSorting] = useState('');
  const [filterOpen, setFilterOpen] = useState(null);
  const [filters, setFilters] = useState({});

  const listingHeader = [
    {label: 'Statut avant action', id: 'status_before', filter: null, termSearch: 'name', filterXPos: 'left', styles: {width: '285px'}},
    {label: 'Responsable de l\'action', id: 'responsable', termSearch: null,  filter: null, filterXPos: 'left', styles: {width: '300px'}},
    {label: 'Statut après action', id: 'status_after', filter: null, filterXPos: 'left',  styles: {width: '285px'}},
    {label: 'Date de l\'action', id: 'date', filter: null, filterXPos: 'right'}
  ]

  React.useEffect(() => {
    missions_service.getStatusHistory(mission.id).then(response => setHistories(response.data['hydra:member']))
  }, []);

  const updateFilters = (value, type) => {
    if (value === null) {
      setFilters(
        Object.keys(filters)
              .filter(key => type !== key)
              .reduce((obj, key) => {
                obj[key] = filters[key];
                return obj;
              }, {})
      )

    } else {
      setFilters({
        ...filters,
        [type]: value
      })
    }

  }

  return (
    histories &&
    <div className="mt-5">
      <Listing>
        <Listing.Header>
          {
            listingHeader.map(
              header =>
                <HeaderThead
                  key={header.id}
                  termSearch={header.termSearch}
                  filter={filters[header.id]}
                  setFilterOpen={value => setFilterOpen(value === filterOpen ? null : value)}
                  filterOpen={filterOpen}
                  sorting={sorting}
                  header={header}
                  onFilterChange={(type, value) => updateFilters(value, type)}
                  updateSorting={(key, sortMode) => setSorting({key, sortMode})}
                  styles={header.styles}
                />
            )
          }
        </Listing.Header>
        <Listing.Content>
          {histories.map((history, index) => <LineListing key={index} history={history} />)}
        </Listing.Content>
      </Listing>
    </div>
  )
}


export default connect(null, null)(StatusHistory)
