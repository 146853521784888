import React from 'react'
import cx from 'classnames/bind'
import Select from 'react-select';

const CustomSelect = props => {
  const [selectOpts, setSelectOpts] = React.useState([]);
  const [selected, setSelected] = React.useState(null);

  React.useEffect(() => {
    if (typeof props.options === 'function') {
      if (selectOpts && selectOpts.length === 0) {
        props.options().then(opts => {
          setSelectOpts((
            opts
          ))
        })
      }
    } else {
      setSelectOpts(props.options)
    }
  }, [props.options]);

  React.useEffect(() => {
    if(selectOpts && selectOpts.length > 0) {
      setSelected(selectOpts.find(item => item.value == props.value))
    }
  }, [selectOpts]);

  return (
    selectOpts.length > 0 ?
    <div className={cx({
      "filter-box": true,
      "filter-box--right": props.filterXPos === 'right'
    })}>
      <span style={{
        color: '#000',
        fontSize: 14,
        marginBottom: 8,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between'
      }}>
        Filtrer par...
        {selectOpts && selectOpts.find(item => item.value == props.value) !== undefined &&
        <span style={{color: '#00B5D4'}} className="reset-filter"
              onClick={() => props.onFilterChange(props.termSearch, null)}>
          Ré-initialiser
        </span>}
      </span>
      <Select
        isSearchable={true}
        placeholder={'Choisissez dans la liste'}
        isMulti={false}
        value={selected}
        onChange={value => props.onFilterChange(props.termSearch, value.value)}
        options={selectOpts}
      />
    </div> : null
  )
}

export default CustomSelect
