import React from 'react'
import InputBoxDoneTyping from 'react-input-box-done-typing';
import {usePrevious} from '../../../hooks/usePrevious';

const ListingPagination = ({onChangePage, activePage, maxPages}) => {

  const [currentPage, setCurrentPage] = React.useState(activePage);
  const input = React.useRef(null);
  const prevCurrentPage = usePrevious(currentPage)

  React.useEffect(() => {
    input.current.querySelector('input').value = activePage
  }, [maxPages]);

  React.useEffect(() => {
    if(prevCurrentPage !== undefined) {
      onChangePage(currentPage)
    }
  }, [currentPage]);

  const decreaseValue = () => {
    if(currentPage > 1) {
      const newVal = currentPage - 1
      setCurrentPage(newVal)
      input.current.querySelector('input').value = newVal
    }
  }

  const increaseValue = () => {
    if(currentPage < maxPages) {
      const newVal = currentPage + 1
      setCurrentPage(newVal)
      input.current.querySelector('input').value = newVal
    }
  }

  return (
    <div className="flex flex--vertical-space-c flex--horizontal-space-center mt-4 listing-pagination">
      <span className="prev" onClick={decreaseValue}>
        <svg width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
         <path fillRule="evenodd" clipRule="evenodd" d="M14.793 3.29285L16.2073 4.70706L8.91436 12L16.2073 19.2928L14.793 20.7071L6.08594 12L14.793 3.29285Z" fill={ currentPage <= 1 ? '#737375' : '#00B5D4' } />
        </svg>
      </span>
      <span className="pagination-numbers flex">
        <>
          <span ref={input}>
            <InputBoxDoneTyping
              ref={input}
              defaultValue={activePage}
              doneTyping={value =>{
                setCurrentPage(parseInt(value))
              }}
            />
          </span>
          <span className="total-pages relative">
            <span style={{
              position: 'absolute',
              left: 8
            }}>/</span>{maxPages}</span>
        </>
      </span>
      <span className="next" onClick={increaseValue}>
        <svg width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path fillRule="evenodd" clipRule="evenodd" d="M16.0859 12L8.79297 4.70706L10.2072 3.29285L18.9143 12L10.2072 20.7071L8.79297 19.2928L16.0859 12Z" fill={ currentPage >= maxPages ? '#737375' : '#00B5D4' }/>
        </svg>
      </span>
    </div>
  )
}

export default ListingPagination
