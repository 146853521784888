import React, { createContext, useState } from 'react';
import { disableBodyScroll, clearAllBodyScrollLocks } from 'body-scroll-lock';

export const ModalContext = createContext();

export function ModalProvider({ children }) {
  const [data, setData] = useState({
    display: false,
    data: {
      component: null
    }
  });

  React.useEffect(() => {
    if(data.display) {
      disableBodyScroll(document.querySelector('.modal-container__content'));
    } else {
      clearAllBodyScrollLocks()
    }
  }, [data.display]);


  return (
    <ModalContext.Provider value={
      [data, setData]
    }>
      {children}
    </ModalContext.Provider>
  );
}
