import React from 'react'
import moment from "moment";
import StarsRating from "../../../../../../components/StarsRating";
import cx from 'classnames/bind'
import useIsLongText from "../../../../../../hooks/useIsLongText";

const ToolTip = ({text}) => {
  return(
    <div className="card">
      <span>
        <svg width="12" height="24" viewBox="0 0 12 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M12 0V24L0 12L12 0Z" fill="white"/>
        </svg>
      </span>
      <div className="content">
        <p>{text}</p>
      </div>
    </div>
  )
}

const LineListingFeedbackMission = props => {

  const commentSpan = React.useRef();
  const commentSpan2 = React.useRef();

  const longtext = useIsLongText(props.data, commentSpan, 120)
  const longtext2 = useIsLongText(props.data, commentSpan2, 120)

  return (
    <tr>
      <td>
        <span className="black">
          {props.data.subject}
          <em style={{
            display: 'block',
            color: '#06b5d3'
          }}>{props.data.client}</em>
        </span>
      </td>
      <td style={{width: 130}}>
          <span className="date" style={{ marginRight: 25}}>
            <span>du {moment(props.data.startDate).format('DD/MM/YY')}</span>
            <span>au {moment(props.data.finishDate).format('DD/MM/YY')}</span>
          </span>
      </td>
      <td><span>{props.data.evaluatedByFullName}</span></td>
      <td style={{width: 250}}>
        <span ref={commentSpan} className={cx({
          "has-tooltip": longtext,
          "cut-text": longtext
        })}>
          <svg style={{ marginRight: 4 }} width="17" height="17" viewBox="0 0 17 17" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fillRule="evenodd" clipRule="evenodd" d="M4.16859 5.07966C3.08511 5.83809 2.50033 6.82192 2.50033 7.83333C2.50033 8.79516 3.02797 9.72988 4.01123 10.4726L4.43359 10.7916L3.74151 12.3488L6.11232 11.5021L6.31235 11.5551C6.9889 11.7342 7.72645 11.8333 8.50033 11.8333C10.2213 11.8333 11.7507 11.344 12.8321 10.587C13.9155 9.82857 14.5003 8.84475 14.5003 7.83333C14.5003 6.82192 13.9155 5.83809 12.8321 5.07966C11.7507 4.32269 10.2213 3.83333 8.50033 3.83333C6.77938 3.83333 5.24996 4.32269 4.16859 5.07966ZM3.40397 3.98735C4.73546 3.05531 6.53937 2.5 8.50033 2.5C10.4613 2.5 12.2652 3.05531 13.5967 3.98735C14.9261 4.91791 15.8337 6.26742 15.8337 7.83333C15.8337 9.39925 14.9261 10.7488 13.5967 11.6793C12.2652 12.6114 10.4613 13.1667 8.50033 13.1667C7.68918 13.1667 6.90698 13.0718 6.175 12.8955L1.25914 14.6512L2.7946 11.1964C1.80543 10.3076 1.16699 9.14572 1.16699 7.83333C1.16699 6.26742 2.07459 4.91791 3.40397 3.98735Z" fill="#737375"/>
          </svg>
          <em>{props.data.strongPoint}</em>
        </span>
        <ToolTip text={props.data.strongPoint} />
      </td>
      <td style={{width: 250}}>
        <span ref={commentSpan2} className={cx({
          "has-tooltip": longtext2,
          "cut-text": longtext2
        })}>
          <svg style={{ marginRight: 4 }} width="17" height="17" viewBox="0 0 17 17" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fillRule="evenodd" clipRule="evenodd" d="M4.16859 5.07966C3.08511 5.83809 2.50033 6.82192 2.50033 7.83333C2.50033 8.79516 3.02797 9.72988 4.01123 10.4726L4.43359 10.7916L3.74151 12.3488L6.11232 11.5021L6.31235 11.5551C6.9889 11.7342 7.72645 11.8333 8.50033 11.8333C10.2213 11.8333 11.7507 11.344 12.8321 10.587C13.9155 9.82857 14.5003 8.84475 14.5003 7.83333C14.5003 6.82192 13.9155 5.83809 12.8321 5.07966C11.7507 4.32269 10.2213 3.83333 8.50033 3.83333C6.77938 3.83333 5.24996 4.32269 4.16859 5.07966ZM3.40397 3.98735C4.73546 3.05531 6.53937 2.5 8.50033 2.5C10.4613 2.5 12.2652 3.05531 13.5967 3.98735C14.9261 4.91791 15.8337 6.26742 15.8337 7.83333C15.8337 9.39925 14.9261 10.7488 13.5967 11.6793C12.2652 12.6114 10.4613 13.1667 8.50033 13.1667C7.68918 13.1667 6.90698 13.0718 6.175 12.8955L1.25914 14.6512L2.7946 11.1964C1.80543 10.3076 1.16699 9.14572 1.16699 7.83333C1.16699 6.26742 2.07459 4.91791 3.40397 3.98735Z" fill="#737375"/>
          </svg>
          <em>{props.data.improvementApproach}</em>
        </span>
        <ToolTip text={props.data.improvementApproach} />

      </td>
      <td>
        <StarsRating stars={4} value={props.data.expertise}/>
      </td>
      <td>
        <StarsRating stars={4} value={props.data.knowHow}/>
      </td>
    </tr>
  )
}

export default LineListingFeedbackMission
