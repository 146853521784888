import Axios from 'axios';
import { newConnectConfig } from './contant';
import {
    getHeaders,
    REQUEST_METHOD_GET
} from './utils/headers';

export const coreSkillService = {
    get_core_skill,
}

function get_core_skill(coreSkillId = null) {
    const url = `${newConnectConfig.API_PATH}${newConnectConfig.CORE_SKILL_ENDPOINT}/${coreSkillId}`;

    return Axios({
        method: REQUEST_METHOD_GET,
        url,
        headers: getHeaders(REQUEST_METHOD_GET)
    });
}
