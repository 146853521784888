import initialState from "./initialState";
import * as actions from './planning.actions';

export default (state = initialState, action) => {
    switch (action.type) {
        case actions.REQUEST_DISPOS: {
            return {
                ...state,
                isLoading: true
            }
        }
        case actions.FETCH_PLANNING_SUCCESS: {
            return {
                ...state,
                isLoading: false,
                error: null,
                planning: action.data
            }
        }
        case actions.FETCH_USER_DISPOS_SUCCESS: {
            return {
                ...state,
                isLoading: false,
                error: null,
                userDispos: action.data['hydra:member']
            }
        }
        case actions.FETCH_DISPOS_ERROR: {
            return {
                ...state,
                isLoading: false,
                error: action.error,
            }
        }
        default: {
            return state
        }
    }
}