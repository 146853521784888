export const REQUEST_METHOD_POST = 'POST';
export const REQUEST_METHOD_PUT = 'PUT';
export const REQUEST_METHOD_DELETE = 'DELETE';
export const REQUEST_METHOD_GET = 'GET';

const DEFAULT_HEADERS = {
    "Authorization": `Bearer ${localStorage.getItem('token')}`
}

export const getHeaders = (method = REQUEST_METHOD_GET) =>
    [REQUEST_METHOD_POST, REQUEST_METHOD_PUT].includes(method) ?
        {
            ...DEFAULT_HEADERS,
            "Content-Type": 'application/json'
        } : DEFAULT_HEADERS
