const GET_LESS_SKILLED_LIST_START = 'GET_LESS_SKILLED_LIST_START';
const GET_LESS_SKILLED_LIST_SUCCESS = 'GET_LESS_SKILLED_LIST_SUCCESS';
const GET_LESS_SKILLED_LIST_FAIL = 'GET_LESS_SKILLED_LIST_FAIL';
const EMPTY_LESS_SKILLED_LIST = 'EMPTY_LESS_SKILLED_LIST';
const TOGGLE_DISPLAY_MATCH_BLOCKS = 'TOGGLE_DISPLAY_MATCH_BLOCKS';
const GET_CONSULTANT_MISSIONS_START = 'GET_CONSULTANT_MISSIONS_START';
const GET_CONSULTANT_MISSIONS_SUCCESS = 'GET_CONSULTANT_MISSIONS_SUCCESS';
const GET_CONSULTANT_MISSIONS_FAIL = 'GET_CONSULTANT_MISSIONS_FAIL';
const EMPTY_CONSULTANT_MISSIONS = 'EMPTY_CONSULTANT_MISSIONS';

export {
    GET_LESS_SKILLED_LIST_START,
    GET_LESS_SKILLED_LIST_SUCCESS,
    GET_LESS_SKILLED_LIST_FAIL,
    EMPTY_LESS_SKILLED_LIST,
    TOGGLE_DISPLAY_MATCH_BLOCKS,
    GET_CONSULTANT_MISSIONS_START,
    GET_CONSULTANT_MISSIONS_SUCCESS,
    GET_CONSULTANT_MISSIONS_FAIL,
    EMPTY_CONSULTANT_MISSIONS,
}
