import React from 'react'
import { connect } from 'react-redux'
import '../css/onboard.css'
import axios from 'axios'
import { Formik, Field, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import BoxComponent from '../../components/BoxComponent';
import { newConnectConfig } from '../../_services/contant';
import { bindActionCreators } from 'redux';
import { profile_creators } from '../../reducers/Profile/actions/_update_profile';

const ComposantErreur = props => (
    <div className="text-danger">{props.children}</div>
);

const ComposantInput = ({field,form: { touched, errors }, ...props}) => (
    <div className="form-group">
        <label className="label"> { props.label } </label>
        <input type="text" {...props} className="form-control" {...field} />
    </div>
);

const TjmInput = ({
    field,
    form: { touched, errors },
    ...props
    }) => (
    <div className="form-group tjm-input" style={{ position: 'relative' }}>
        <label className="label"> { props.label } </label>
        <input
            type="text"
            {...props}
            className="form-control"
            {...field}
        />
        <span>€ / Jour HT</span>
    </div>
);

const userSchema = Yup.object().shape({
    experience: Yup.string().required('Obligatoire'),
    tjm: Yup.number('Doit être numérique').required('Obligatoire'),
    city: Yup.string().required('Obligatoire'),
    zip: Yup.string().required('Obligatoire'),
    country: Yup.string().required('Obligatoire'),
    tel: Yup.number().required('Obligatoire'),
    cvfr: Yup.mixed().required('Obligatoire')
});

function onBoarding(props) {

    const [,setcvfr] = React.useState()
    const cvinput = React.useRef()
    React.useEffect(() => {

        props.updateRole(
            localStorage.getItem('user_id')
        )

        return () => {
        };

    }, [])

    const submit = (values, actions) => {

        actions.setSubmitting(true);

        axios.put(
            `${newConnectConfig.API_PATH}/users/${localStorage.getItem('user_id')}`,
            values,
            { headers: {"Authorization" : `Bearer ${localStorage.getItem('token')}`} }
        ).then( () => {
                const formData = new FormData();
                formData.append('file', values.cvfr, values.cvfr.filename);
                const url = `${newConnectConfig.API_PATH}/media_objects`;
                const request =  axios({
                    method:'post',
                    url,
                    headers: {
                        'Content-Type': 'multipart/form-data',
                        'Authorization' : `Bearer ${localStorage.getItem('token')}`
                    },
                    data: formData
                })

                request.then( function(response){
                    axios.post(
                        `${newConnectConfig.API_PATH}/resumes`,
                        {
                            path : response.data.contentUrl,
                            language : {"id" : 21 },
                            user : {"id": parseInt(localStorage.getItem('user_id'))}
                        },
                        { headers: {"Authorization" : `Bearer ${localStorage.getItem('token')}`} }
                    ).then( () => window.location.replace('#/my-profile') )
                    .catch( () =>  actions.setSubmitting(false) )

                });
        })

    }

    return (
        props.role.full_name ?
        <div className="onboard">
            <h2>Bienvenue sur MyStaff { props.role.fullName } !</h2>
            <h3>Complétez votre profil professionnel</h3>
            <Formik
            onSubmit={submit}
            initialValues={{
                experience: '',
                tjm: '',
                city: '',
                zip:'',
                country: '',
                tel: '',
                cvfr:''
            }}
            validationSchema={userSchema}
            >
            {({
                values,
            handleSubmit,
            setFieldValue,
            isSubmitting}) => (
                <form onSubmit={ handleSubmit } className="box_signin">

                    <BoxComponent custom_class='box'>
                        <div className="row">
                        <div className="col-md-6">
                        <h2 style={{ fontSize: 20, margin: 0 }} className="label_box">Années d’expériences *</h2>
                        <Field type="string" name="experience" label="" component={ComposantInput} />
                        <ErrorMessage name="experience" component={ComposantErreur} />
                        </div>
                        </div>
                    </BoxComponent>

                    <BoxComponent custom_class='box'>
                        <div className="row">
                        <div className="col-md-6">
                            <h2 style={{ fontSize: 20, margin: 0 }} className="label_box">Taux Journalier Moyen *</h2>
                            <Field type="number" name="tjm" label="" component={TjmInput} />
                            <ErrorMessage name="tjm" component={ComposantErreur} />
                        </div>
                        <div className="col-md-12" style={{ marginTop: 30 }}>
                            <label>Ajouter un commentaire au sujet de votre tarification</label>
                            <textarea
                                onChange={(event) => {
                                    setFieldValue("tjmComment", event.target.value);
                                }}
                                name="tjmComment"
                                style={{
                                    width: '100%',
                                    border: '1px solid #eee',
                                    marginTop: 10,
                                    minHeight: 90,
                                    padding: 10
                            }}/>
                        </div>
                        </div>


                    </BoxComponent>

                    <BoxComponent custom_class='box'>
                        <h2 style={{ fontSize: 20, margin: 0 }} className="label_box">Localisation *</h2>
                        <div className="row">
                            <div className="col-md-6">
                                <Field name="city" placeholder="Ville" component={ComposantInput} />
                                <ErrorMessage name="city" component={ComposantErreur} />
                            </div>
                            <div className="col-md-6">
                                <Field name="zip" placeholder="Code postal" component={ComposantInput} />
                                <ErrorMessage name="zip" component={ComposantErreur} />
                            </div>
                            <div className="col-md-6">
                                <Field name="country" placeholder="Pays" component={ComposantInput} />
                                <ErrorMessage name="country" component={ComposantErreur} />
                            </div>
                        </div>
                    </BoxComponent>

                    <BoxComponent custom_class='box'>
                        <div className="row">
                            <div className="col-md-6">
                                <h2 style={{ fontSize: 20, margin: 0 }} className="label_box">Téléphone*</h2>
                                <Field name="tel" placeholder="numéro" component={ComposantInput} />
                                <ErrorMessage name="tel" component={ComposantErreur} />
                            </div>

                        </div>
                    </BoxComponent>

                    <div className="uploader" style={{ flexFlow: 'column' }}>

                        <input
                            style={{ display: 'none'}}
                            id="file"
                            name="cvfr"
                            type="file"
                            onChange={(event) => {
                                setFieldValue("cvfr", event.currentTarget.files[0]);
                                setcvfr( event.currentTarget.files[0] )
                            }}
                            className="form-control"
                            ref={ cvinput }
                        />

                        <button
                            onClick={ e => cvinput.current.click() }
                            type="button"
                            style={{
                                borderRadius: 6,
                                border: 'solid 1px #00b5d4',
                                backgroundColor: '#ffffff',
                                padding: '26px 0',
                                color: "#00b5d4",
                                width: '100%',
                                fontSize: 20,
                                marginBottom: 15
                            }}
                        >
                            Importer CV Francais*
                        </button>

                        { values.cvfr !== '' && <span style={{ fontWeight:'bold', marginBottom: 10 }}> Fichier choisi: { values.cvfr.name } </span> }

                        <ErrorMessage name="cvfr" component={ComposantErreur} />

                    </div>

                    <div className='actions_onboard'>
                        <button type="submit" className="btn_darkblue" disabled={isSubmitting}>
                            { isSubmitting ? 'Traitement en cours...' : 'Suivant' }
                        </button>
                    </div>
                </form>
            )}
            </Formik>
        </div> : null
    )
}

const mapStateToProps = (state) => ({
    role : state.Role
})


function mapDispatchToProps(dispatch){
    return bindActionCreators({
        updateRole: profile_creators.updateRole
    },dispatch);
  }
export default connect(mapStateToProps,mapDispatchToProps)(onBoarding)
