import moment from 'moment';
import React from 'react'
import {connect} from 'react-redux';
import CustomSelect from '../../components/ui/CustomSelect';

const FiscalYear = ({field, form: {initialValues }, ...props}) => {

  const [opts, setOpts] = React.useState([]);
  const [val, setVal] = React.useState(field.value);

  const defaultValue = props.currentFiscalYear;

  React.useEffect(() => {
    setOpts([
      moment().subtract(2, 'year').format('YYYY'),
      moment().subtract(1, 'year').format('YYYY'),
      moment().format('YYYY'),
      moment().add(1, "year").format('YYYY'),
      moment().add(2, "year").format('YYYY'),
      moment().add(3, "year").format('YYYY')
    ])
  }, []);

  React.useEffect(() => {
    if(props.edit) {
      setVal(initialValues.fiscalYear)
    } else {
      props.setFieldValue(field.name, defaultValue)
    }
  }, [initialValues.fiscalYear]);

  return(
    <div className="form-group">
      <label className="label">
        {props.label}
        {props.required && <span style={{ color: '#00b5d4', marginLeft: 8 }} className='required'>*</span>}
      </label>
      {
        opts.length > 0 &&
        <CustomSelect
        onChange={value => props.setFieldValue(field.name, parseInt(value))}
        placeholder={'Chercher par mots clés'}
        value={props.edit ? val : defaultValue}
        options={opts}
        fullW
        style={{
          marginRight: 16
        }}
      />
      }
    </div>
  )
};

const mapStateToProps = (state) => ({
  currentFiscalYear: state.App.currentFiscalYear
});

export default connect(mapStateToProps, null)(FiscalYear);
