import React from 'react'

const ListingNavigation = ({prev, next, navigationChange}) => {
  return (
    <div className="navigation" style={{
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      marginTop: 32
    }}>
      {prev &&
      <div onClick={() => navigationChange(prev)} style={{
        transform: 'rotate(90deg)',
        cursor: 'pointer'
      }}>
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path fillRule="evenodd" clipRule="evenodd"
                d="M3.29297 9.70718L4.70718 8.29297L12.0001 15.5859L19.293 8.29297L20.7072 9.70718L12.0001 18.4143L3.29297 9.70718Z"
                fill="#00B5D4"/>
        </svg>
      </div>
      }
      {next &&
      <div onClick={() => navigationChange(next)} style={{
        transform: 'rotate(-90deg)',
        cursor: 'pointer'
      }}>
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path fillRule="evenodd" clipRule="evenodd"
                d="M3.29297 9.70718L4.70718 8.29297L12.0001 15.5859L19.293 8.29297L20.7072 9.70718L12.0001 18.4143L3.29297 9.70718Z"
                fill="#00B5D4"/>
        </svg>
      </div>
      }
    </div>
  )
}

export default ListingNavigation
