import {developpmentService} from "../_services/DevelopmentService";

export default function useDataListNavigation(endpoint) {
    return new Promise((resolve) => {
      developpmentService.loadNavigation(endpoint).then(
        data => {
          resolve({
            status: data.data['status'],
            data: data.data['hydra:member'],
            next: data.data['hydra:view'] && data.data['hydra:view']['hydra:next'],
            prev: data.data['hydra:view'] && data.data['hydra:view']['hydra:previous'],
            lastUpdate: data.data['lastUpdate']
          })
        }
      )
    });
}
