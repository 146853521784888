import React, {useEffect, useState} from 'react'
import InputBoxDoneTyping from "react-input-box-done-typing";
import DropdownFilters from "./DropdownFilters";
import {bindActionCreators} from "redux";
import {consultant_creators} from "../../../reducers/Consultants/actions/getListConsultants";
import {connect} from "react-redux";

const USER_SKILL_FILTER_NAME = 'userSkills.skill.id';
const USER_SOLUTION_FILTER_NAME = 'userSolutions.solution.id';
const USER_FIELD_FILTER_NAME = 'userFields.field.id';
const USER_LANGUAGE_FILTER_NAME = 'userLanguages.language.id';

const LESS_SKILLED_ITEMS_PER_PAGE = 8;

const initalFilters = {
  'userSkills.skill.id': [],
  'userSolutions.solution.id': [],
  'userFields.field.id': [],
  'userLanguages.language.id': [],
  'position.id': [],
  'experience[between]': '',
  'workplace.id': [],
  externe: null,
  term: ""
}

const expOptions = [
  {'label': 'De 0 à 3 ans', value: '0..3'},
    {'label': 'De 3 à 6 ans', value: '3..6'},
    {'label': 'De 6 à 9 ans', value:'6..9'},
    {'label': 'De 9 à 12 ans', value:'9..12'},
    {'label': 'De 12 à 15 ans', value:'12..15'},
    {'label': 'De 15 à 18 ans', value:'15..18'},
    {'label': 'De 18 à 22 ans', value:'18..22'},
    {'label': 'De 22 à 25 ans', value:'22..25'},
    {'label': 'Plus de 25 ans', value:'25..40'}
  ]

const Filters = props => {
  const [filters, setFilters] = useState(null);
  const inputSearch = React.useRef();

  useEffect(() => {
    setFilters({
      ...initalFilters,
      'position.id' : prepareData(props.mission.requiredPositions, null),
      'userSkills.skill.id': prepareData(props.mission.missionSkills, 'experience'),
      'userSolutions.solution.id': prepareData(props.mission.missionSolutions, 'experience'),
      'userFields.field.id': prepareData(props.mission.missionFields, 'experience'),
      'userLanguages.language.id': prepareData(props.mission.missionLanguages, 'experience'),
      'experience[between]': findMatchExp(props.mission.requiredExperience)
    })
  }, [props.mission]);

  function findMatchExp(value) {
      const reduced = expOptions.reduce( (acc, item) =>{
        acc.push( item.value.split('..') )
        return acc;
      },[])

      const match = reduced.filter( item =>  parseInt( item[0]) <= value )
      return match[match.length - 1].join('..')
  }

  const resetConsultantsLists = () => {
    props.updateListConsultant(null, null, true);
    props.getLessSkilledConsultants(null, null, true);
  }

  useEffect(() => {
    // empty consultants list + less-skilled consultants list on component will un-mount
    return () => resetConsultantsLists()
  }, []);

  useEffect(() => {
    if( filters ) {
      resetConsultantsLists();
      const toActiveMatchFiltering =
        (filters[USER_SKILL_FILTER_NAME] && filters[USER_SKILL_FILTER_NAME].length > 0) ||
        (filters[USER_SOLUTION_FILTER_NAME] && filters[USER_SOLUTION_FILTER_NAME].length > 0) ||
        (filters[USER_FIELD_FILTER_NAME] && filters[USER_FIELD_FILTER_NAME].length > 0) ||
        (filters[USER_LANGUAGE_FILTER_NAME] && filters[USER_LANGUAGE_FILTER_NAME].length > 0);
      props.displayMatchBlocks(toActiveMatchFiltering);

      // fetch skilled consultants || default consultants list
      let filtersParams = buildUrl(filters).slice(0, -1);
      filtersParams += toActiveMatchFiltering ? `&itemsPerPage=${props.skilledItemsPerPage}` : '';
      props.updateListConsultant(`?${filtersParams}`, true);

      // fetch less skilled consultants
      if (toActiveMatchFiltering) {
        let lessSkilledFiltersParams = buildUrl(filters, true).slice(0, -1);
        lessSkilledFiltersParams += `&itemsPerPage=${LESS_SKILLED_ITEMS_PER_PAGE}`
        props.getLessSkilledConsultants(`?${lessSkilledFiltersParams}`, true);
      }
    }
  }, [filters]);

  const buildObjectFilter = (item, withLessSkilled = null) => {
    let queryValues = '';
    if(item[1]) {
      const reduced = item[1].length > 0 ? item[1].reduce((acc, item) => {
        if (item.value) {
          acc.push(item.value);
        }
        return acc
      }, []) : null

      if (reduced === null) {
        return '';
      }

      if ([USER_SKILL_FILTER_NAME,
        USER_SOLUTION_FILTER_NAME,
        USER_FIELD_FILTER_NAME,
        USER_LANGUAGE_FILTER_NAME].includes(item[0])) {
        // ⚠️sensitive params
        const comapraisonExpr = withLessSkilled ? ':lt:3' : ':gte:3';
        queryValues = reduced.join(`${comapraisonExpr},`);
        queryValues += queryValues.length > 0 ? comapraisonExpr : '';
      } else {
        queryValues = reduced.join(',');
      }
    }
    return `${item[0]}=${encodeURIComponent(queryValues)}&`;
  }

  function buildUrl(filtersObj, withLessSkilled = null) {
    return  Object.entries(filtersObj).reduce((acc, item)=>{
      if( typeof item[1] === 'object' ) {
         acc += buildObjectFilter(item, withLessSkilled);
      } else {
        acc += item[1] !== '' ? `${item[0]}=${encodeURIComponent(item[1])}&` : ''
      }

      return acc
    }, '');
  }

  const search = value => {
    updateFilters(value, 'term')
  }

  const updateFilters = (value, type) => {
    setFilters({
      ...filters,
      [type] : value
    })
  }

  const resetFilters = () => {
    if( inputSearch.current ) {
      inputSearch.current.querySelector('input').value = ''
    }
    setFilters(initalFilters)
  }

  const prepareData = (array, type) => {
    return array.reduce( (acc, item) => {
      if( type && item[type] ) {
        acc.push({ value:item[type].id })
      } else {
        acc.push({value: item.id})
      }
      return acc;
    }, [])
  }

  return (
    <div className="filters">
      <div className="input">
        <span ref={inputSearch}>
          <InputBoxDoneTyping  value={filters && filters.term} doneTyping={ search } placeholder="Nom et prénom"/>
        </span>
        <svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path fillRule="evenodd" clipRule="evenodd" d="M26.0001 15C22.1341 15 19.0001 18.134 19.0001 22C19.0001 23.5723 19.5185 25.0236 20.3937 26.1922L15.293 31.2929L16.7072 32.7071L21.8079 27.6064C22.9765 28.4816 24.4277 29 26.0001 29C29.8661 29 33.0001 25.866 33.0001 22C33.0001 18.134 29.8661 15 26.0001 15ZM21.0001 22C21.0001 19.2386 23.2386 17 26.0001 17C28.7615 17 31.0001 19.2386 31.0001 22C31.0001 24.7614 28.7615 27 26.0001 27C23.2386 27 21.0001 24.7614 21.0001 22Z" fill="#00B5D4"/>
        </svg>
      </div>
      <DropdownFilters
        selectDatas={props.selectDatas}
        onUpdateFilters={ (value, type) => updateFilters(value, type) }
        state={filters}
        expOptions={expOptions}
      />
      <button className="link" onClick={resetFilters}>Réinitialiser les filtres</button>
    </div>
  )
}

const mapStateToProps = (state) => ({
  list_consultant: state.Consultants.list,
  next: state.Consultants.next_url,
  total: state.Consultants.total,
  selectDatas: state.SelectData,
  role: state.Role.role
});

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      updateListConsultant: consultant_creators.get_list_constultants,
      getLessSkilledConsultants: consultant_creators.get_less_skilled_list,
      displayMatchBlocks: consultant_creators.toggleToDisplayMatchBlocks
    },
    dispatch
  );
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Filters);
