import initialState from "./initialState";
import * as actions from './cr.actions';

export default (state = initialState, action) => {
    switch (action.type) {
        case actions.REQUEST_CR: {
            return {
                ...state,
                isLoading: true
            }
        }
        case actions.FETCH_CR_SUCCESS: {
            return {
                ...state,
                isLoading: false,
                error: null,
                data: action.data
            }
        }
        case actions.FETCH_CR_ERROR: {
            return {
                ...state,
                isLoading: false,
                error: action.error,
            }
        }
        case actions.CR_STATUS: {
            return {
                ...state,
                isLoading: false,
                publish: action.data,
            }
        }
        case actions.PUBLISH_CR_SUCCESS: {
            return {
                ...state,
                isLoading: false,
                publish: action.data,
            }
        }
        default: {
            return state
        }
    }
}