import { AdminService } from "../../../_services/AdminService";
import { GET_CURRENT_FISCAL_YEAR } from "./action-types";

export default function getCurrentFiscalYearAction() {
    return (dispatch) => AdminService.getCurrentFiscalYear()
        .then(response => {
            return dispatch({
                type: GET_CURRENT_FISCAL_YEAR,
                payload: response.data.current_fiscal_year || null
            });
        })
}
