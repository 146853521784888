import React from 'react'
import { Link, Redirect } from 'react-router-dom';
import { toast } from 'react-toastify';

import { ModalContext } from '../context/ModalContext';
import {hasCap} from '../helpers/app';
import { livrableService } from '../_services/livrable';
import { propaleService } from '../_services/propale';

const ModalDeleteMarketingConfirm = ({ marketingResource, onCancel, onConfirm }) => (
  <>
    <header className="salmon">
      <svg width="94" height="96" viewBox="0 0 94 96" fill="none" xmlns="http://www.w3.org/2000/svg">
        <circle cx="47.5" cy="52.5" r="39.5" fill="white" />
        <path fillRule="evenodd" clipRule="evenodd" d="M47.5001 56.8016L30.51 73.7917C29.3221 74.9796 27.3962 74.9796 26.2084 73.7917C25.0206 72.6039 25.0206 70.678 26.2084 69.4902L43.1985 52.5001L26.2084 35.51C25.0206 34.3221 25.0206 32.3962 26.2084 31.2084C27.3962 30.0206 29.3221 30.0206 30.51 31.2084L47.5001 48.1985L64.4902 31.2084C65.678 30.0206 67.6039 30.0206 68.7917 31.2084C69.9796 32.3962 69.9796 34.3221 68.7917 35.51L51.8016 52.5001L68.7917 69.4902C69.9796 70.678 69.9796 72.6039 68.7917 73.7917C67.6039 74.9796 65.678 74.9796 64.4902 73.7917L47.5001 56.8016Z" fill="#F33F3F" />
      </svg>
    </header>
    <div className="content">
      <p className="title">Es-tu sûr.e de vouloir supprimer {marketingResource} ?</p>
      <p>Cette action sera irréversible et toutes les données seront définitivement supprimées.</p>
      <div className="actions">
        <button className="white" onClick={() => onCancel()}>Annuler</button>
        <button className="blue" onClick={() => {
          onConfirm();
          onCancel();
        }}>Supprimer</button>
      </div>
    </div>
  </>
);

const HeadersActions = ({
    role,
    status,
    actions,
    hasAtLeastOneUserToEvaluate,
    id,
    assessments,
    reOpen,
    doArchive,
    uploadModal,
    isOpen,
    isOwner,
    statuses,
    reminderModal,
    urlEvaluate,
    urlViewEvaluation,
    path = null,
    notAllowedStatuses = [],
  }) => {
  const [modal, setModal] = React.useContext(ModalContext)
  const [redirect, setRedirect] = React.useState(false)

  const isAdmin = hasCap('ADMIN', role);
  const isSingleMarketingViewPage = ['/livrable/view/:id', '/propale/view/:id'].includes(path);

  const deleteMarketing = () => {
    if (isSingleMarketingViewPage) {
      const isDeliverbaleView = path === '/livrable/view/:id';

      setModal({
        ...modal,
        display: true,
        component: <ModalDeleteMarketingConfirm
          marketingResource={isDeliverbaleView ? 'ce livrable' : 'cette propale'}
          onConfirm={() => isDeliverbaleView ?
            livrableService.remove(id)
              .then(() => {
                toast.success("Livrable supprimé", {
                  position: toast.POSITION.BOTTOM_LEFT,
                  onClose: () => setRedirect(`/livrables`)
                });
              }) :
            propaleService.remove(id)
              .then(() => {
                toast.success("Propale supprimée", {
                  position: toast.POSITION.BOTTOM_LEFT,
                  onClose: () => setRedirect(`/propale`)
                });
              })
          }
          onCancel={() => setModal({
            ...modal,
            display: false
          })
          }
        />
      })
    }
  }

  return (
    actions === undefined  ? <>
        {redirect && <Redirect to={redirect} />}
        <div className="actions">
          {
            ((hasCap('PARTNER', role) || isOwner) && status === statuses.SENDED && hasAtLeastOneUserToEvaluate)
            ? <Link to={urlEvaluate} className="button-ui button-ui--medium button-ui--blue-outline">Évaluer</Link>
            : isAdmin && status === statuses.SENDED && <button style={{marginRight: 12}} onClick={doArchive} className="button-ui button-ui--full-outline">Archiver</button>
          }
          {
              ( ( hasCap('ADMIN', role) && !isOpen && (status !== statuses.EVALUATED && status !== statuses.ARCHIVED && status !== statuses.RESEARCH))
                  ||
               (!isOpen && isOwner && (status !== statuses.EVALUATED && status !== statuses.ARCHIVED && status !== statuses.RESEARCH)) ) && <button onClick={reOpen} className="button-ui button-ui--outline">Réouvrir</button>
          }
          {isSingleMarketingViewPage &&
            (isAdmin || isOwner) &&
            !notAllowedStatuses.includes(status) &&
            <button
              className="btn btn-link border-0"
              onClick={deleteMarketing}
            >
              <u>
                Supprimer
              </u>
            </button>
          }
          {(
            (isOwner && status === statuses.RESEARCH) ||
            (isAdmin && !notAllowedStatuses.includes(status))
          ) &&
            <button onClick={reminderModal} className="button-ui button-ui--blue">Nouvelle notification</button>
          }
          { !isOpen && isOwner && (status !== statuses.EVALUATED && status !== statuses.ARCHIVED  && status !== statuses.SENDED) &&
          <button onClick={uploadModal} className="button-ui button-ui--outline">
            <svg width="15" height="18" viewBox="0 0 15 18" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path fillRule="evenodd" clipRule="evenodd"
                    d="M6.54696 17.707L6.54696 4.12124L1.77501 9.41414L0.499986 7.99992L7.44854 0.292817L14.3971 7.99992L13.1221 9.41414L8.35012 4.12124L8.35012 17.707L6.54696 17.707Z"
                    fill="#00B5D4"/>
            </svg>
            Uploader le document final
          </button>
          }
          {
            (status === statuses.EVALUATED || status === statuses.ARCHIVED) &&
            isAdmin && status !== statuses.ARCHIVED &&
            <button style={{marginRight: 12}} onClick={ () => doArchive() } className="button-ui button-ui--full-outline">Archiver</button>
          }
          {
              ( isOwner || hasCap('PARTNER', role) ) && assessments.length > 0  && <Link to={urlViewEvaluation} className="button-ui button-ui--white">Consulter l’évaluation</Link>
          }
        </div>
      </> : actions.map(
        button => button && <button
          disabled={button.disabled}
          key={button.label}
          type="submit"
          onClick={e => button.onAction && button.onAction(e)}
          className={button.classes}
          dangerouslySetInnerHTML={{ __html: button.label }}
        />
      )
  )
}

export default HeadersActions
