import React, { Component } from 'react'
import '../styles/components/BoxComponent.css';


export default class BoxComponent extends Component {
  render() {
    const classes = `box ${this.props.custom_class}`;
    return (
      <div className={classes} style={{...this.props.style}}>
          {this.props.children}
      </div>
    )
  }
}
