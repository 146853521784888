import { notif_service } from "../../_services/notifications_service";
import { profileService } from "../../_services/profile_service";
import { toast } from "react-toastify";

export const notification_actions = {
    get_notifications,
    new_notification,
    notificationCheck,
    updateEmailPreferences
};

function updateEmailPreferences( user_id,data ) {
    return function (dispatch) {
        profileService.updateProfile( user_id, data ).then( response => {
            toast.success("Préférence email enregistrée.", {
                position: toast.POSITION.BOTTOM_LEFT,
            });
        })
    };
}

function notificationCheck() {
    return function (dispatch) {
        notif_service.notificationCheck().then(function(response){
            dispatch({
                type : 'UPDATE_HAS_NOTIF',
                payload : response.data
            })
        });   
    };
}

function get_notifications() {
    return function (dispatch) {
        notif_service.get_user_notifs().then(function(response){
            dispatch({
                type : 'GET_NOTIFICATIONS',
                payload : { data: response.data['hydra:member']}
            })
        });   
    };
}

function new_notification(value = true) {
    return function (dispatch) {
        dispatch({
            type : 'UPDATE_HAS_NOTIF',
            payload : value
        })
    };
}