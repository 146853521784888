import React from 'react'
import ListingHeader from "./ListingHeader";
import ListingContent from "./ListingContent";
import ListingPagination from './ListingPagination';
import ListingPerPageDropDown from './ListingPerPageDropDown';

const Listing = props => {

  const [showPagination, setShowPagination] = React.useState(false);
  const [activePage] = React.useState(props.activePage);

  React.useEffect(() => {
    if(props.listing) {
      if(parseInt(props.listing['hydra:totalItems']) || props.listing.total > parseInt(props.perPages) ) {
        setShowPagination(true)
      } else {
        setShowPagination(false)
      }
    }
  }, [props.perPages, props.listing]);

  return (
      <>
        { props.optionsPerPages &&
        <ListingPerPageDropDown
          label={props.label !== undefined ? props.label : 'candidat'}
          value={props.perPages}
          totalResultats={props.hideTotalResult === undefined ? parseInt(props.listing['hydra:totalItems']) || props.listing.total : null}
          optionsPerPages={props.optionsPerPages}
          onChangePerLines={props.onChangePerLines}
        />
        }
        <div className="tableWrapper">
        <table className={"listing"}>
            {props.children}
        </table>
        </div>
        { props.hasPagination && showPagination &&
        <ListingPagination maxPages={Math.ceil((parseInt(props.listing['hydra:totalItems']) || props.listing.total) / parseInt(props.perPages))} activePage={activePage} onChangePage={props.onChangePage}/>
        }
      </>
    )
}

Listing.Header = ListingHeader
Listing.Content = ListingContent

export default Listing
