
import { newConnectConfig } from "./contant";
import Axios from "axios";
import { getHeaders, REQUEST_METHOD_DELETE } from "./utils/headers";

export const livrableService = {
  get_lists,
  add,
  edit,
  fetch,
  searchPartner,
  searchOwner,
  searchConsultant,
  applyOnPropale,
  validateSelection,
  deleteUser,
  reOpen,
  search,
  validateOrDecline,
  processUpload,
  removeUploadedFile,
  evaluate,
  archive,
  paysByGrade,
  sendReminder,
  exportGoal,
  getFiscalYearsDatas,
  remove,
};

function getFiscalYearsDatas() {
  const url =  `${newConnectConfig.API_PATH}/deliverables/fiscal_years`;
  return Axios({
    method:'get',
    url,
    headers: {
      "Authorization" : `Bearer ${localStorage.getItem('token')}`
    }
  });
}

function exportGoal(filters) {
  const url =  `${newConnectConfig.API_PATH}/deliverables/export_goal${filters}`;
  return Axios({
    method:'get',
    url,
    headers: {
      "Authorization" : `Bearer ${localStorage.getItem('token')}`
    }
  });
}

function sendReminder(id) {
  const url =  `${newConnectConfig.API_PATH}/deliverables/${id}/reminder`;
  return Axios({
    method:'get',
    url,
    headers: {
      "Authorization" : `Bearer ${localStorage.getItem('token')}`
    }
  });
}

function paysByGrade() {
  const url =  `${newConnectConfig.API_PATH}/deliverable_assessment_pays`;

  return Axios({
    method:'get',
    url,
    headers: {"Authorization" : `Bearer ${localStorage.getItem('token')}`}
  });
}

function archive(id) {
  const url =  `${newConnectConfig.API_PATH}/deliverables/${id}/archive`;

  return Axios({
    method:'get',
    url,
    headers: {"Authorization" : `Bearer ${localStorage.getItem('token')}`}
  });
}

function evaluate( data ) {
  const url =  `${newConnectConfig.API_PATH}/deliverable_assessments`;
  return Axios({
    method:'POST',
    url,
    headers: {"Authorization" : `Bearer ${localStorage.getItem('token')}`},
    data
  });
}

function removeUploadedFile(uploadId) {
  const url =  `${newConnectConfig.API_PATH}/deliverable_uploads/${uploadId}`;
  return Axios({
    method:'delete',
    url,
    headers: {
      "Authorization" : `Bearer ${localStorage.getItem('token')}`
    }
  });
}

function processUpload(files, livrableId) {
  const formData = new FormData();
  for (let index = 0; index < files.length; index++) {
    formData.append(`files_${index}`, files[index])
  }
  const url =  `${newConnectConfig.API_PATH}${newConnectConfig.LIVRABLE_ENDPOINT}/${livrableId}/upload`;
  return Axios({
    method:'post',
    url,
    headers: {
      "Authorization" : `Bearer ${localStorage.getItem('token')}`,
      'content-type': 'multipart/form-data'
    },
    data: formData
  });
}

function get_lists(term) {
  const url =  `${newConnectConfig.API_PATH}${newConnectConfig.LIVRABLE_ENDPOINT}${term.replace('/api/deliverables', '')}`;

  return Axios({
    method:'get',
    url,
    headers: {"Authorization" : `Bearer ${localStorage.getItem('token')}`}
  });
}

function add(data, method = 'POST') {
  const url =  `${newConnectConfig.API_PATH}${newConnectConfig.LIVRABLE_ENDPOINT}`;

  return Axios({
    method,
    url,
    headers: {"Authorization" : `Bearer ${localStorage.getItem('token')}`},
    data
  });
}

function validateOrDecline( id, data ) {
  const url =  `${newConnectConfig.API_PATH}${newConnectConfig.LIVRABLE_ENDPOINT}/${id}/validate_or_reject`;

  return Axios({
    method: 'PUT',
    url,
    headers: {
      "Authorization" : `Bearer ${localStorage.getItem('token')}`,
      'Content-Type': 'application/json'
    },
    data
  });
}

function edit(data, id) {
  const url =  `${newConnectConfig.API_PATH}${newConnectConfig.LIVRABLE_ENDPOINT}/${id}`;

  return Axios({
    method: 'PUT',
    url,
    headers: {"Authorization" : `Bearer ${localStorage.getItem('token')}`},
    data
  });
}

function fetch(id) {
  const url =  `${newConnectConfig.API_PATH}${newConnectConfig.LIVRABLE_ENDPOINT}/${id}`;

  return Axios({
    method:'GET',
    url,
    headers: {"Authorization" : `Bearer ${localStorage.getItem('token')}`}
  });
}

function searchPartner(value) {
  const url =  `${newConnectConfig.API_PATH}${newConnectConfig.USER_ENDPOINT}/partner`;

  return Axios({
    method:'GET',
    url,
    headers: {"Authorization" : `Bearer ${localStorage.getItem('token')}`},
    params: {
      term: value
    }
  });
}

function searchOwner(value) {
  const url =  `${newConnectConfig.API_PATH}${newConnectConfig.USER_ENDPOINT}/project_manager`;

  return Axios({
    method:'GET',
    url,
    headers: {"Authorization" : `Bearer ${localStorage.getItem('token')}`},
    params: {
      term: value
    }
  });
}

function searchConsultant( term, externe = 0 ) {
  const url =  `${newConnectConfig.API_PATH}${newConnectConfig.USER_ENDPOINT}`;

  return Axios({
    method:'GET',
    url,
    headers: {"Authorization" : `Bearer ${localStorage.getItem('token')}`},
    params: {
      name: term,
      externe
    }
  });
}

function applyOnPropale( data ) {
  const url =  `${newConnectConfig.API_PATH}/deliverable_volunteers`;
  return Axios({
    method:'POST',
    url,
    headers: {"Authorization" : `Bearer ${localStorage.getItem('token')}`},
    data
  });
}

function validateSelection( data ) {
  const url =  `${newConnectConfig.API_PATH}/deliverable_users`;
  return Axios({
    method:'POST',
    url,
    headers: {"Authorization" : `Bearer ${localStorage.getItem('token')}`},
    data
  });
}

function deleteUser( id, endpoint = 'deliverable_volunteers' ) {
  const url =  `${newConnectConfig.API_PATH}/${endpoint}/${id}`;
  return Axios({
    method:'DELETE',
    url,
    headers: {"Authorization" : `Bearer ${localStorage.getItem('token')}`}
  });
}

function reOpen( id ) {
  const url =  `${newConnectConfig.API_PATH}${newConnectConfig.LIVRABLE_ENDPOINT}/${id}/reopen`;
  return Axios({
    method:'GET',
    url,
    headers: {
      'Content-Type': 'application/json',
      "Authorization" : `Bearer ${localStorage.getItem('token')}`
    }
  });
}

function search( value ) {
  const url =  `${newConnectConfig.API_PATH}${newConnectConfig.LIVRABLE_ENDPOINT}?term=${value}`;

  return Axios({
    method:'GET',
    url,
    headers: {"Authorization" : `Bearer ${localStorage.getItem('token')}`}
  });
}

function remove (id) {
  return Axios({
    method: REQUEST_METHOD_DELETE,
    url: `${newConnectConfig.API_PATH}${newConnectConfig.LIVRABLE_ENDPOINT}/${id}`,
    headers: getHeaders(REQUEST_METHOD_DELETE)
  });
}
