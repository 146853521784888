import React from 'react'
import '../../index.scss'
import {ModalContext} from "../../../../context/ModalContext";
import {hasCap} from "../../../../helpers/app";

const ConfirmDelete = props => {
    return (
        <>
            <header className="salmon">
                <svg width="94" height="96" viewBox="0 0 94 96" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <circle cx="47.5" cy="52.5" r="39.5" fill="white"/>
                    <path fillRule="evenodd" clipRule="evenodd"
                          d="M47.5001 56.8016L30.51 73.7917C29.3221 74.9796 27.3962 74.9796 26.2084 73.7917C25.0206 72.6039 25.0206 70.678 26.2084 69.4902L43.1985 52.5001L26.2084 35.51C25.0206 34.3221 25.0206 32.3962 26.2084 31.2084C27.3962 30.0206 29.3221 30.0206 30.51 31.2084L47.5001 48.1985L64.4902 31.2084C65.678 30.0206 67.6039 30.0206 68.7917 31.2084C69.9796 32.3962 69.9796 34.3221 68.7917 35.51L51.8016 52.5001L68.7917 69.4902C69.9796 70.678 69.9796 72.6039 68.7917 73.7917C67.6039 74.9796 65.678 74.9796 64.4902 73.7917L47.5001 56.8016Z"
                          fill="#F33F3F"/>
                </svg>
            </header>
            <div className="content">
                <p className="title">Êtes-vous sur de vouloir supprimer cette ligne du compte rendu?</p>
                <div className="actions">
                    <button className="white" onClick={() => props.onCancel()}>Non</button>
                    <button className="blue" onClick={() => props.onConfirm()}>Oui</button>
                </div>
            </div>
        </>
    )
}

const AddCommentModal = props => {
    const [value, setValue] = React.useState(props.value);
    return (
        <>
            <div className="content">
                <p className="title">Ajouter un commentaire</p>
                <textarea defaultValue={value} onChange={e => setValue(e.target.value)}/>
                <div className="actions">
                    <button className="white" onClick={() => props.onCancel()}>Annuler</button>
                    <button className="blue" onClick={() => props.onConfirm(value)}>Valider</button>
                </div>
            </div>
        </>
    )
}

const lineContent = props => {

    const [modal, setModal] = React.useContext(ModalContext);

    function getBck(status) {
        switch (status) {
            case 'En recherche':
                return '#003d71'
            case 'Pourvu':
                return '#7ed321'
            case 'En attente de validation':
                return '#ff7500'
            case 'Pré-affecté':
                return 'rgb(242, 221, 25)'
            case 'Attente retour client':
                return 'rgb(255, 163, 0)'
            case 'Annulé':
                return '#F33F3F'
            default:
                return '#6c757d'
        }
    }

    const deleteLine = () => {
        setModal({
            ...modal,
            display: true,
            component: <ConfirmDelete
                onCancel={() => setModal({...modal, display: false})}
                onConfirm={() => props.deleteRow(props.data.id)}
            />
        })
    }

    const commentLine = value => {
        setModal({
            ...modal,
            display: true,
            component: <AddCommentModal
                onCancel={() => setModal({...modal, display: false})}
                onConfirm={comment => props.saveComment(props.data.id, comment)}
                value={value}
            />
        })
    }

    return (
        <tr>
            <td>
          <span style={{color: '#000'}}>
            {props.data.besoin.title}
              <span><i className='far fa-user'/> {`Chef de projet: ${props.data.besoin.leader}`}</span>
            <span><i className='far fa-user'/> {`Client: ${props.data.besoin.client}`}</span>
          </span>
            </td>
            <td>
                <div
                    className='button'
                    style={{
                        padding: 6,
                        width: '100%',
                        color: '#fff',
                        textAlign: 'center',
                        background: getBck(props.data.statusBefore),
                        borderRadius: '6px',
                        height: 'initial',
                        fontSize: '12px'
                    }}
                >
                    {props.data.statusBefore}
                </div>
            </td>
            <td>{props.data.consultantsBefore.map(c => (<span>{c.fullname}</span>))}</td>
            <td>
                <span>{props.data.action}</span>
            </td>
            <td>
                <div
                    className='button'
                    style={{
                        padding: 6,
                        width: '100%',
                        color: '#fff',
                        textAlign: 'center',
                        background: getBck(props.data.statusAfter),
                        borderRadius: '6px',
                        height: 'initial',
                        fontSize: '12px'
                    }}
                >
                    {props.data.statusAfter}{' '}
                </div>
            </td>
            <td>
          <span>
             {props.data.consultantsAfter && props.data.consultantsAfter.map(c => <span>{c.fullname}</span>)}
          </span>
            </td>
            <td>
                <button className="edit" onClick={() => commentLine(props.data.comment)}>
                    Commenter
                    <i className="fa fa-edit"/>
                </button>
                <span style={{color: '#000'}}>
            {props.data.comment}
          </span>
                <button className="delete" onClick={deleteLine}>
                    Supprimer
                    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path fillRule="evenodd" clipRule="evenodd"
                              d="M8.00001 8.94416L3.80475 13.1394L2.86194 12.1966L7.0572 8.00135L2.86194 3.80609L3.80475 2.86328L8.00001 7.05854L12.1953 2.86328L13.1381 3.80609L8.94282 8.00135L13.1381 12.1966L12.1953 13.1394L8.00001 8.94416Z"
                              fill="#F33F3F"/>
                    </svg>
                </button>
            </td>

        </tr>
    )
}

export default lineContent
