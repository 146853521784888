import React, { Component } from 'react';
import '../../../styles/components/EditAboutForm.css'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux';
import { profile_creators } from '../../../reducers/Profile/actions/_update_profile';
import { Editor } from '@tinymce/tinymce-react';

class EditAboutForm extends Component {

    constructor(props) {
        super(props)
        this.state = {
            'about_text' : ''
        }
    }

    render() {
        return (
        <form className="add-about">
            <div className="form-group">
                <Editor
                    apiKey='utaf8ts9rx8ehhx85zfl9wuxid70b773mz3fkm0qm1337xd2'
                    initialValue={this.props.text}
                    init={{
                        plugins: 'link',
                        toolbar: 'bold italic'
                    }}
                    onEditorChange={ content =>  {
                        this.setState({
                            'about_text': content
                        })
                    }}
                />
                <legend className="text-left">Présentez-vous en quelques lignes pour permettre aux entreprises de mieux comprendre votre offre.</legend>
            </div>
            <div className="group-buttons row">
                <div className="col-lg-4 text-center text-lg-left mb-3 mb-lg-0">
                    <button type="button" className="btn btn-danger">Supprimer</button>
                </div>
                <div className="col-lg-8 text-center text-lg-right">
                    <button type="button" onClick={ this.handleCLick.bind(this) } className="btn btn-secondary">Annuler</button>
                    <button type="button" onClick={ this.handleSave.bind(this) } className="btn btn-primary">Enregistrer</button>
                </div>
            </div>
        </form>
        )
    }

    handleCLick() {
        this.props.handleEdit( false ); 
    }
  
    handleSave() {
        let content = {
            "about": this.state.about_text
        }
        this.props.updateProfile(this.props.editing, content);
        this.props.handleEdit( false )
    }
}

const mapStateToProps = (state) => ({
  editing : state.App.editing_profile
})

const mapDispatchToProps = (dispatch) => {
    return bindActionCreators({
      updateProfile: profile_creators.update_profile
    },dispatch)
}


export default connect(mapStateToProps, mapDispatchToProps)(EditAboutForm)
