import React from 'react'
import '../ui/style.scss'
import cx from 'classnames/bind'

const SlidingModal = props => {
  return (
    <div className={cx({
      "sliding-modal": true,
      "sliding-modal__visible": props.data.display
    })}>
      <div className="sliding-modal__backdrop" onClick={ props.onClose }/>
      <div className="sliding-modal__content">
        <div className="close" onClick={ props.onClose}>
          <svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fillRule="evenodd" clipRule="evenodd" d="M24.0001 25.4143L17.7072 31.7072L16.293 30.293L22.5859 24.0001L16.293 17.7072L17.7072 16.293L24.0001 22.5859L30.293 16.293L31.7072 17.7072L25.4143 24.0001L31.7072 30.293L30.293 31.7072L24.0001 25.4143Z" fill="#00B5D4"/>
          </svg>
        </div>
        {props.data.component}
      </div>
    </div>
  )
}

export default SlidingModal
