import React from 'react';
import moment from 'moment';

const Header = (props) => {
    return (
         props.index < 5 && 
        <div className="header_calendar__day">
            <span className="day">{ moment(props.day).format('dddd') }</span>
            <span className="day_number">{ moment(props.day).format('DD') }</span>
        </div>
        
    )
}

export default Header;