import React from 'react'
import {AtsService} from '../../../_services/AtsService';
import BoxComponent from "../../../components/BoxComponent";
import BooleanToggler from '../../../components/ui/BooleanToggler';
import DynamicDropDown from '../../../components/ui/DynamicDropDown';
import DataListMission from '../../../features/development/tabs/components/feedbacks/DataListMission';
import PictureProfileContainer from "../PictureProfileContainer";
import ExtraitAboutComponent from "../ExtraitAboutComponent";
import Slider, {createSliderWithTooltip} from "rc-slider";
import MonthAvailability from "../../../components/MonthAvailability";
import CvUploader from "./CvUploader";
import {profileService} from "../../../_services/profile_service";
import {getQualificationStatusBackground, getStatusBackground, hasCap} from "../../../helpers/app";
import {ModalContext} from "../../../context/ModalContext";
import {bindActionCreators} from "redux";
import {profile_creators} from "../../../reducers/Profile/actions/_update_profile";
import {Link, withRouter} from "react-router-dom";
import {connect} from "react-redux";
import "../../../features/development/style.scss"
import moment from "moment";
import {toast} from "react-toastify";
import {_getUserProfile} from "../../../reducers/App/actions/user";
import UserCircleContainer from '../Circle/UserCircleContainer';
import AddUserCircleComponent from '../Circle/AddUserCircleComponent';
import {userCircleService} from '../../../_services/userCircleService';
import {userCircleCreators} from '../../../reducers/UserCircle/actions';
import ModalEditExpertData from './edits/ModalEditExpertData';

const SliderWithTooltip = createSliderWithTooltip(Slider);

function percentFormatter(v) {
  return `${v}`;
}

const Resume = props => {

  const [exp, setExp] = React.useState(props.props.profile.experience);
  const [editCrhComment, setEditCrhComment] = React.useState(false);
  const [modal, setModal] = React.useContext(ModalContext);
  const [loggedUserRole, setLoggedUserRole] = React.useState(null);
  const [open, setIsOpen] = React.useState(false);
  const [prioStatuses, setPrioStatuses] = React.useState(null);
  const [statusValue, setStatusValue] = React.useState(null);


  const exportToCsv = e => {
    e.preventDefault()
    profileService.exportSkills(props.props.profile.id).then(response => downloadFile(response))
  }

  React.useEffect(() => {
    AtsService.getDbalDataForSelect('user_priority_type').then(res => {
      setPrioStatuses(res.reduce((acc, item) => {
        acc.push({
          text: item.label,
          key: item.value,
          background_color: getQualificationStatusBackground(item.value)
        })
        return acc;
      }, []))
    })
  }, []);

  React.useEffect(() => {
    if (prioStatuses) {
      if (props.props.profile.priorityData) {
        setStatusValue(prioStatuses.find(p => p.key === props.props.profile.priorityData.key))
      } else {
        setStatusValue(prioStatuses[0])
      }
    }
  }, [props.props.profile, prioStatuses]);

  const doConfirm = data => {
    props.updateProfile(
      props.props.profile.id,
      data
    )
    setModal({...modal, display: false, component: null})
  }

  const editExpertsData = data => {
    setModal({
      ...modal,
      display: true,
      component: <ModalEditExpertData
        onConfirm={data => doConfirm(data)}
        data={data}
        role={props.props.role}
        onCancel={() => setModal({...modal, display: false, component: null})}
      />
    })
  }

  const downloadCv = resumeId => {
    profileService.downloadCv(resumeId).then(response => {
      downloadFile(response)
    });
  }

  function downloadFile(response) {
    if (response.data.file) {
      const downloadLink = document.createElement('a')
      downloadLink.setAttribute('href', response.data.file)
      downloadLink.setAttribute('id', 'download-action')
      downloadLink.setAttribute('download', true)
      document.querySelector('body').insertAdjacentElement('afterbegin', downloadLink)
      downloadLink.click()
      document.getElementById('download-action').remove()
      props.getUserProfile(
        props.props.profile.id
      )
    }
  }

  // Identify if the 'Resume' is for the logged-in user
  const loggedUserId = parseInt(localStorage.getItem('user_id'));
  const isLoggedUserProfile = loggedUserId === props.props.profile.id;

  React.useEffect(() => {
    if (!isLoggedUserProfile) {
      profileService.getProfile(loggedUserId)
                    .then(res => {
                      setLoggedUserRole(res.data.role.name);
                    })
    }
  }, []);

  const connectedUserRole = loggedUserRole || props.props.profile.role.name;
  const canManageCircle = !props.props.profile.externe &&
    props.props.profile.role &&
    hasCap('CONSULTANT_CRH', props.props.profile.role.name) &&
    (
      connectedUserRole &&
      (
        hasCap('ADMIN', connectedUserRole) ||
        (
          hasCap('CONSULTANT_CRH', connectedUserRole) && isLoggedUserProfile
        )
      )
    );

  const createUserCircle = (selectedData) => {
    const data = {
      "user": {"id": props.props.profile.id},
      "circle": {"id": selectedData.circle.value},
      "userSubCircles": selectedData.subCircles.map(sub => (
        {
          "subCircle": {"id": sub.value}
        }
      ))
    }

    userCircleService.create_user_circle(data)
                     .then(() => triggerSuccessToast('ajouté'));
  }

  const triggerSuccessToast = (action = 'edité') => {
    toast.success(`Cercle ${action}`, {
      position: toast.POSITION.BOTTOM_LEFT,
      onClose: () => props.reloadUserCircles(props.props.profile.id)
    });
  }

  return (
    <>
      <BoxComponent custom_class="box-profile col-md-11 row mr-auto ml-auto">
        <div className="col-xs-12 col-md-7 section-resume">
          <PictureProfileContainer
            user_id={props.props.match.params.id}
            profile={props.props.profile}
            role={props.props.role}
            onProfileChange={(value, type) => props.onProfileChange({
              [type]: value
            })}
          />
          <hr/>
          <ExtraitAboutComponent
            firstName={props.props.profile.firstName}
            text={props.props.profile.about}
            canEdit={props.props.match.path === "/my-profile" || hasCap('ADMIN', props.props.role)}
          />
          {
            canManageCircle &&
            <AddUserCircleComponent userCircles={props.props.profile.userCircles} submitCircle={createUserCircle}/>
          }
        </div>
        <div className="col-md-5">
          <div className="box-blue">
            <ul>
              {props.props.profile.role.name === 'EXTERNE' &&
              <>
                {(
                  props.props.match.path === "/my-profile" || hasCap('ADMIN', props.props.role)
                ) && <span onClick={() => editExpertsData(props.props.profile)}
                           style={{position: 'absolute', right: 10, top: -7, cursor: 'pointer'}}>
                   <svg width="26" height="25" viewBox="0 0 26 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <rect x="0.789062" width="25" height="24.4444" rx="12.2222" fill="#00B5D4"/>
                    <path fillRule="evenodd" clipRule="evenodd"
                          d="M15.7247 5.80483C16.4733 5.07287 17.6924 5.07287 18.4624 5.80483L19.8526 7.16418C20.6012 7.89613 20.6012 9.08818 19.8526 9.84105L19.2597 10.4208L15.1323 6.38405L15.7247 5.80483ZM14.2561 7.24076L6.72015 14.6092C6.37794 14.9648 6.16406 15.4458 6.16406 15.9477V18.5618C6.16406 18.9173 6.44211 19.1892 6.80571 19.1892H9.47926C9.99258 19.1892 10.4845 19.001 10.8481 18.6455L18.3835 11.2775L14.2561 7.24076Z"
                          fill="white"/>
                    </svg>
                    </span>
                }
                {props.props.role !== 'EXTERNE' && <>
                <li>
                  <span>Statut</span>
                  <BooleanToggler
                    onLabel="Actif"
                    offLabel="Inactif"
                    onToggle={value => props.onProfileChange({"actif": value})}
                    defaultValue={props.props.profile.actif}
                    disabled={!hasCap('PARTNER', props.props.role)}
                    background="#ffffff"
                  />
                </li>
                <li>
                  <span>Qualification</span>
                  <DynamicDropDown
                    id={props.props.profile.id}
                    open={open}
                    setIsOpen={setIsOpen}
                    readOnly={!hasCap('PARTNER', props.props.role)}
                    defaultValue={statusValue}
                    center={true}
                    onStatusChange={value => {
                      props.onProfileChange({"priority": value.key})
                    }}
                    dropDownStatuses={prioStatuses}
                  />
                </li>
                </>
                }
                <li>
                  <span>TJM</span>
                  <h4>
                    {props.props.profile.tjm} €/j
                    <small style={{
                      display: 'block',
                      fontSize: 10,
                      color: '#00b5d4'
                    }}>{props.props.profile.tjmComment}</small>
                  </h4>
                </li>
                <li>
                  <span>Disponibilité</span>
                  <h4>{props.props.profile.detailsAvailability}</h4>
                </li>
              </>
              }
              {props.props.profile.role.name !== 'EXTERNE' &&
              <li>
                <span>Disponibilité</span>
                <MonthAvailability availabilities={props.props.profile.availabilitiesData}/>
              </li>
              }
              <li>
                <span>Expérience</span>
                <SliderWithTooltip
                  disabled={(
                    props.props.match.path !== "/my-profile" && !hasCap('ADMIN', props.props.role)
                  )}
                  value={parseInt(exp)}
                  min={1}
                  max={45}
                  tipFormatter={percentFormatter}
                  tipProps={{overlayClassName: 'foo'}}
                  onChange={value => setExp(value)}
                  onAfterChange={value => props.onProfileChange({
                    "experience": parseInt(value.toString())
                  })}
                />
                <input type="text" value={`${parseInt(exp)} ${parseInt(exp) > 1 ? 'ans' : 'an'}`}/>
              </li>
              {props.props.profile.role.name === 'EXTERNE' && props.props.profile.position && <>
                <li>
                  <span>Grade</span>
                  <h4>{props.props.profile.position.name}</h4>
                </li>
              </>
              }

              {props.props.profile.role.name === 'EXTERNE' &&
              <li>
                <span>En mission</span>
                <h4 className="relative has-tooltip-children" style={{textDecoration: 'underline'}}>
                  {props.props.profile.inProgressMissions.length > 0 ? `${props.props.profile.inProgressMissions.length} missions en cours` : 'Aucune mission en cours'}
                  {props.props.profile.inProgressMissions.length > 0 &&
                  <div className="tooltip-ui --bottom">
                    {props.props.profile.inProgressMissions.map(mission =>
                      <div className="mt-3 mb-3">
                        <Link className="block t-base-small text-underline" target="_blank" rel="noopener noreferrer"
                              to={`/missions/view/${mission.id}`}>
                          {mission.title}
                        </Link>
                        <p className="mt-1">
                          <small className="t-label-small u-color-primary">{mission.client} - </small>
                          <small className="t-label-small"
                                 style={{color: getStatusBackground(mission.statusData.key)}}>{mission.statusData.text} - </small>
                          <small className="t-label-small u-color-primary">{mission.etp.name}</small>
                        </p>
                      </div>
                    )}
                  </div>
                  }
                </h4>
              </li>
              }

              {props.props.profile && props.props.profile.arrivalDate &&
              <li>
                <span>Date d’arrivée</span>
                {moment(props.props.profile.arrivalDate).format('DD/MM/YY')}
              </li>
              }
              {props.props.profile.role.name !== 'EXTERNE' && props.props.profile.myReferent &&
              <>
                <li>
                  <span>Référent</span>
                  <h4>{props.props.profile.myReferent.firstName} {props.props.profile.myReferent.lastName}</h4>
                </li>
                {props.props.profile.referentRh &&
                <li>
                  <span>Référent RH</span>
                  <h4>{props.props.profile.referentRh.firstName} {props.props.profile.referentRh.lastName}</h4>
                </li>
                }
              </>
              }
              {hasCap('RH', props.props.role) && props.props.profile.externe === false && <li>
                <span>Période d’essai</span>
                <BooleanToggler
                  onLabel="Validée"
                  offLabel="En cours"
                  onToggle={value => props.updateProfile(props.props.profile.id, {isInTrialPeriod: !value})}
                  defaultValue={!props.props.profile.isInTrialPeriod}
                  disabled={false}
                />
              </li>}
              <li>
                <span>CV</span>
                {props.props.profile.resumes.map(resume => <div key={resume.id} className="file-uploader">
                  {(
                    props.props.match.path === "/my-profile" || hasCap('ADMIN', props.props.role)
                  ) && <CvUploader languageID={resume.language.id} resumes={props.props.profile.resumes}/>}
                  <button disabled={resume.path === null} onClick={() => downloadCv(resume.id)} type="button"
                          className="download">PPT ({resume.language.code})
                  </button>
                  {resume.downloadedAt && <span className="date">{moment(resume.downloadedAt).format('DD/MM/Y')}</span>}
                </div>)}
                <div className="file-uploader">
                  <button onClick={exportToCsv} type="button" className="download">Excel</button>
                </div>
              </li>
            </ul>
          </div>
        </div>
      </BoxComponent>
      {hasCap('CONSULTANT_SENIOR', props.props.role) && props.props.profile.externe &&
      <BoxComponent custom_class="box-profile col-md-11 row mr-auto ml-auto">
        <div className="col-md-12">
          <h2>
            Commentaire CRH
            {hasCap('ADMIN', props.props.role) &&
            <span onClick={() => setEditCrhComment(!editCrhComment)} className="edit_rounded_button" style={{
              width: 30,
              height: 30,
              fontSize: '0.938rem'
            }}>
                 <i style={{
                   width: 30,
                   height: 30
                 }} className="fas fa-pen"/>
              </span>
            }
          </h2>
          {editCrhComment ? <><textarea
            id="crhComments"
            style={{
              width: '100%',
              height: 100,
              padding: 10
            }} defaultValue={props.props.profile.crhComments}/>
            <button onClick={() => {
              props.onProfileChange({crhComments: document.getElementById('crhComments').value});
              setEditCrhComment(false)
            }} style={{marginTop: 8}} className="button blue">Enregistrer
            </button>
          </> : <p style={{lineHeight: '150%'}}>{props.props.profile.crhComments} </p>}
        </div>
      </BoxComponent>
      }

      {props.props.profile.externe &&
      <div className="box box-profile col-md-11 row mr-auto ml-auto externe_feedback">
        <DataListMission userId={props.props.match.params.id} role={props.props.role}/>
      </div>
      }

      {
        !props.props.profile.externe &&
        hasCap('CONSULTANT_CRH', connectedUserRole) &&
        <UserCircleContainer
          userId={props.props.profile.id}
          triggerSuccessToast={triggerSuccessToast}
          canManageCircle={canManageCircle}
        />
      }
    </>
  )
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({
    updateProfile: profile_creators.update_profile,
    getUserProfile: _getUserProfile,
    reloadUserCircles: userCircleCreators.get_user_circles_list
  }, dispatch);
}

export default withRouter(
  connect(null, mapDispatchToProps)(Resume)
)
