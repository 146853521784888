import React from 'react'
import "../../../features/development/style.scss"
import DataList from './DataList';

const ExterneFeedbacks = ({userId}) => {
  return (
    <div>
      <DataList userId={userId}/>
    </div>
  )
}

export default ExterneFeedbacks
