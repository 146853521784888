import { createStore, applyMiddleware, compose } from 'redux';
import thunkMiddleware from 'redux-thunk';
import moduleReducers from '../reducers';

const configureStore = initialState => {
  let store = false

  const composeEnhancers =
      window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose


  store = createStore(
      moduleReducers,
      composeEnhancers(
          applyMiddleware(
            // createLogger(),
            thunkMiddleware
            // observer(updateHandler)
          )
      )
  )

  return store
}

export default configureStore
