import React, { Component } from "react";
import "../../styles/components/ConsulttantsContainer.css";
import ConsultantItemFiche from "../../components/consultant/ConsultantItemFiche";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import ExpAdderField from '../../components/ExpAdders/ExpAdderField';
import { consultant_creators } from "../../reducers/Consultants/actions/getListConsultants";
import FilterComponentSelect from "../../components/FilterComponentSelect";
import InputBoxDoneTyping from "react-input-box-done-typing";
import "rc-slider/assets/index.css";
import Slider from "rc-slider";
import FilterComponentRadio from "../../components/FilterComponentRadio";
import FilterAvailibity from "../../components/FilterAvailibity";
import { hasCap } from '../../helpers/app';
import { Redirect } from "react-router-dom";
import {consultant_service} from "../../_services/consultant";
import {toast} from "react-toastify";
import CircleAdderField from "../../components/ExpAdders/CircleAdderField";
import { USER_CIRCLE_QUERY_PARAM, USER_SUB_CIRCLE_QUERY_PARAM } from '../../helpers/QueryParams';

const createSliderWithTooltip = Slider.createSliderWithTooltip;
const Range = createSliderWithTooltip(Slider.Range);

class ConsulttantsContainer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      "userSkills.skill.id": [],
      "userSolutions.solution.id": [],
      "userFields.field.id": [],
      "userLanguages.language.id": [],
      "userCircles.circle.id": [],
      "userCircles.userSubCircles.subCircle.id": [],
      position: [],
      town: "",
      experience: "",
      term: "",
      hasErrorPlanif: "Non",
      availibity: null,
      workplace: "",
      defaultExpRangeValue: [0, 40],
      lowerBound: 0,
      upperBound: 40,
      status: { label: "Tout", value: "tout" }
    };
  }

  componentDidMount() {
    if (this.props.match.params.status === "externe") {
      this.props.listExterne();
    } else {
      this.props.updateListConsultant('', true);
    }
  }

  componentWillUnmount() {
    this.props.updateListConsultant("", "", true);
  }

  handleMore() {
    if (this.props.match.params.status === "externe") {
      this.props.listExterne(this.props.next);
    } else {
      this.props.updateListConsultant(this.props.next);
    }
  }

  filterChange = (val, filter) => {
    switch (filter) {
      case "userSkills.skill.id":
        this.setState({
          "userSkills.skill.id": val
        });
        break;
      case "userSolutions.solution.id":
        this.setState({
          "userSolutions.solution.id": val
        });
        break;
      case "userFields.field.id":
        this.setState({
          "userFields.field.id": val
        });
        break;
      case "userLanguages.language.id":
        this.setState({
          "userLanguages.language.id": val
        });
        break;
      case USER_CIRCLE_QUERY_PARAM:
        this.setState({
          "userCircles.circle.id": val
        });
        break;
      case USER_SUB_CIRCLE_QUERY_PARAM:
        this.setState({
          "userCircles.userSubCircles.subCircle.id": val
        });
        break;
      case "position":
        this.setState({
          position: val
        });
        break;
      case "workplace":
        this.setState({
          workplace: val
        });
        break;
      case "experience":
        this.setState({
          experience: parseInt(val)
        });
        break;
      case "term":
        this.setState({
          term: val
        });
        break;
      case "hasErrorPlanif":
        this.setState({
          hasErrorPlanif: val
        });
        break;
      case "availibity":
        this.setState({
          availibity: val
        });
        break;
      case "status":
        this.setState({
          status: val
        });
        break;
      case "priority":
        this.setState({
          priority: val
        });
        break;
      default:
        break;
    }
  };

  componentDidUpdate(prevProps, prevState) {
    if (prevState !== this.state) {
      if (this.state.stopProp === undefined || !this.state.stopProp) {
        if (this.props.match.params.status === "externe") {
          this.props.listExterne(this.buildFilterUrl(), true);
        } else {
          this.props.updateListConsultant(this.buildFilterUrl(), true);
        }
      }
    }

    if (prevProps.match.params.status !== this.props.match.params.status) {
      if (this.props.match.params.status === "externe") {
        this.props.listExterne(this.buildFilterUrl(), true);
      } else {
        this.props.updateListConsultant(this.buildFilterUrl(), true);
      }
    }
  }

  onSliderChange = (value) => {
    this.setState({
      defaultExpRangeValue: value.reduce( (acc, item) =>{
        acc.push(parseInt(item))
        return acc
      } , []),
      lowerBound: parseInt(value[0]),
      upperBound: parseInt(value[1]),
      stopProp: true
    });
  };

  reloadExp = () => {
    const { lowerBound, upperBound } = this.state;
    this.setState({ defaultExpRangeValue: [lowerBound, upperBound] }, () =>
      this.filterChange("", "experience")
    );
  };

  buildFilterUrl() {
    let url = "";

    Object.keys(this.state).forEach((k, index) => {
      const symbol = index === 0 ? '?' : '&';

      if (
        k !== "dispo_number_change" &&
        k !== "lowerBound" &&
        k !== "experience" &&
        k !== "upperBound"
      ) {
        switch (k) {
          case 'defaultExpRangeValue':
            url += symbol + "experience[between]=";
            url += `${this.state.defaultExpRangeValue[0]}..${this.state.defaultExpRangeValue[1]}`;
            break;
          case 'userSkills.skill.id':
          case 'userSolutions.solution.id':
          case 'userFields.field.id':
          case 'userLanguages.language.id':
          case 'term':

            url += `${symbol + encodeURIComponent(k)}=`;
            url += this.state[k];
            break;
          case USER_CIRCLE_QUERY_PARAM:
            url = this.buildCircleUrlFilters(url, symbol, USER_CIRCLE_QUERY_PARAM)
            break;
          case USER_SUB_CIRCLE_QUERY_PARAM:
            url = this.buildCircleUrlFilters(url, symbol, USER_SUB_CIRCLE_QUERY_PARAM)
            break;
          case 'availibity':
            url = this.buildAvailabilityFilter(url, symbol);
            break;
          case 'status':
            if (this.state.status.value === "actif") {
              url += symbol + "actif=1";
            } else if (this.state.status.value === "inactif") {
              url += symbol + "actif=0";
            }
            break;
          case 'priority':
            if (this.state["priority"].value !== "") {
              url += `${symbol}priority=${this.state["priority"].value}`;
            }
            break;
          case 'hasErrorPlanif':
            if (this.state[k] === "Oui") {
              url += `${symbol + encodeURIComponent(k)}=`;
              url += true;
            }
            break;
          default:
            url = this.buildDefaultFilter(url, k, symbol);
        }
      }
    });

    return url;
  }

  // Used to reduce Sonar code smells
  buildAvailabilityFilter(url, symbol) {
    if (this.state.availibity !== null) {
      url +=
        symbol +
        `filterAvailability[months]=${this.state[
          "availibity"
        ].months.join(",")}&filterAvailability[inf]=${this.state["availibity"].days.min
        }&filterAvailability[sup]=${this.state["availibity"].days.max}`;
    }
    return url;
  }

  // Used to reduce Sonar code smells
  buildCircleUrlFilters(url, symbol, queryParamKey) {
    const encodedKey = encodeURIComponent(queryParamKey);
    this.state[queryParamKey].forEach(id => url += `${symbol}${encodedKey}[]=${id}`);

    return url;
  }

  // Used to reduce Sonar code smells
  buildDefaultFilter(url, k, symbol) {
    url += `${symbol + encodeURIComponent(k)}.id=`;
    const filter_count = Object.keys(this.state[k]).length;
    Object.keys(this.state[k]).map(
      (val, i) =>
      (
        url +=
        this.state[k][val].value + (
          i + 1 >= filter_count ? "" : ","
        )
      )
    );
    return url;
  }

  exportResult() {
    const isExpertsView = this.props.match.params.status === "externe";
    const url = isExpertsView ? `${this.buildFilterUrl()}&externe=1` : this.buildFilterUrl()

    consultant_service.exportSearch(url)
      .then( response => {
        if (response.data.file) {
          const link = document.createElement("a");
          link.href = response.data.file;
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
        } else {
          toast.error(`Une erreur s'est produite lors de l'export des ${isExpertsView ? 'experts' : 'consultants'}.`, {
            position: toast.POSITION.BOTTOM_LEFT
          });
        }
      })
      .catch( () => {
        toast.error("Désole une erreur s'est produite", {
          position: toast.POSITION.BOTTOM_LEFT
        });
      })
  }

  buildExperienceFilter(values, filter, type) {
    const res = values.reduce((acc, item) => {
      acc += `${item[type].id}:${item.rating},`
      return acc;
    } , '')
    this.filterChange(res.slice(0,-1), filter)
  }

  buildCircleFilter(values) {
    const circleValues = [];
    const subCircleValues = [];

    // since the values are an autogenerated DB IDs, we have no duplicates
    values.forEach(element => {
      circleValues.push(element.circle.id);
      if (element.subCircle.length > 0) {
        subCircleValues.push(...element.subCircle);
      }
    });

    this.filterChange(circleValues, USER_CIRCLE_QUERY_PARAM)
    this.filterChange(subCircleValues, USER_SUB_CIRCLE_QUERY_PARAM)
  }

  render() {
    const { list_consultant } = this.props;
    return ["CONSULTANT_CRH"].includes(this.props.role) &&
      this.props.match.params.status === "externe" ? (
      <Redirect to="/#/my-profile" />
    ) : (
      <div className="container mb-3 my-lg-5 py-lg-4">
        <div className="row row-md-reverse">
          <div className="col-md-6 col-lg-9">
            <div className="row">
              {list_consultant.length === 0 ? (
                <p
                  className="box"
                  style={{ width: "100%", textAlign: "center" }}
                >
                  Aucun résutat
                </p>
              ) : (
                list_consultant.map((item, i) => (
                  <ConsultantItemFiche
                    role={this.props.role}
                    consultant={item}
                    key={i}
                    isLink={this.props.role !== 'EXTERNE'}
                  />
                ))
              )}
            </div>
            {this.props.next ? (
              <div className="content-button text-center">
                <button
                  className="button-lg"
                  onClick={this.handleMore.bind(this)}
                >
                  Voir plus
                </button>
              </div>
            ) : null}
          </div>
          <div className="col-md-6 col-lg-3">
            <div className="content-filter">
              <h2 className="title-filter">
                Filtres
                <span>{this.props.total} résultat(s)</span>
              </h2>
              { hasCap('CONSULTANT_SENIOR', this.props.role) &&
              <button
                style={{width: '100%', marginBottom: 16, marginTop: -8}}
                className="button blue"
                onClick={() => this.exportResult()}
              >Exporter les résultats</button>
              }
              {this.props.match.params.status === "externe" &&
                this.props.role !== "EXTERNE"  &&  (
                  <div className="box-filter">
                    <h3>Statut</h3>
                    <FilterComponentSelect
                      filter="status"
                      selected={this.state["status"]}
                      multiple={false}
                      select={this.filterChange.bind(this)}
                      data={[
                        { label: "Tout", value: "tout" },
                        { label: "Actif", value: "actif" },
                        { label: "Inactif", value: "inactif" }
                      ]}
                    />
                  </div>
                )}
              {this.props.match.params.status === "externe" &&
                this.props.role !== "EXTERNE" && (
                  <div className="box-filter">
                    <h3>Priorité</h3>
                    <FilterComponentSelect
                      filter="priority"
                      selected={this.state["priority"]}
                      multiple={false}
                      select={this.filterChange.bind(this)}
                      data={[
                        { label: "Tout", value : "" },
                        { label: "Gold", value: "Gold" },
                        { label: "OK", value: "OK" },
                        { label: "Non évalué", value: "Non évalué" },
                        { label: "Low", value: "Low" }
                      ]}
                    />
                  </div>
                )}
              <div className="box-filter">
                <h3>Recherche par mot-clé</h3>
                <InputBoxDoneTyping
                  className="form-control"
                  autoComplete="off"
                  value={this.state.keyword}
                  doneTyping={(value) => this.filterChange(value, "term")}
                  doneTypingInterval={700}
                />
              </div>
              {this.props.match.params.status !== "externe" && (
                <>
                  <div className="box-filter">
                    <h3>Disponibilité</h3>
                    <FilterAvailibity
                      filter={(data) => this.filterChange(data, "availibity")}
                      onReset={() => this.filterChange(null,"availibity")}
                    />
                  </div>
                  <div className="box-filter">
                    <h3>Erreur de planif?</h3>
                    <FilterComponentRadio
                      choices={["Oui", "Non"]}
                      select={(value) =>
                        this.filterChange(value, "hasErrorPlanif")
                      }
                    />
                  </div>

                  <div className="box-filter">
                    <h3>Localisation</h3>
                    <FilterComponentSelect
                      filter="workplace"
                      selected={this.state["workplace"]}
                      select={this.filterChange.bind(this)}
                      data={this.props.select_data["workplace"]}
                    />
                  </div>

                  <div className="box-filter exp-adder">
                    <CircleAdderField
                      name="circle"
                      label="Cercle"
                      onChange={values => this.buildCircleFilter(values)}
                    />
                  </div>
                </>
              )}
              <div className="box-filter exp-adder">
                <ExpAdderField
                  type="skill"
                  name="missionSkills"
                  label="Connaissances métiers"
                  labelTooltipContent={[
                    {rating: 1, label: 'Notions de bases'},
                    {rating: 2, label: 'En cours d’acquisition'},
                    {rating: 3, label: 'Bien acquis'},
                    {rating: 4, label: 'Maîtrise / Expertise'}
                  ]}
                  selectDatas={this.props.select_data['skill']}
                  onChange={values => this.buildExperienceFilter(values, 'userSkills.skill.id', 'skill')}
                />
              </div>

              <div className="box-filter exp-adder">
                <ExpAdderField
                  type="field"
                  labelTooltipContent={[
                    {rating: 1, label: 'Peu ou pas mobilisé'},
                    {rating: 2, label: 'Rarement mobilisé'},
                    {rating: 3, label: 'Souvent mobilisé'},
                    {rating: 4, label: 'Très souvent mobilisé'}
                  ]}
                  name="missionSkills"
                  label="Contextes d'applications"
                  selectDatas={this.props.select_data['field']}
                  onChange={values => this.buildExperienceFilter(values, 'userFields.field.id', 'field')}
                />
              </div>

              <div className="box-filter exp-adder">
                <ExpAdderField
                  type="solution"
                  name="missionSkills"
                  labelTooltipContent={[
                    {rating: 1, label: 'Notions de bases'},
                    {rating: 2, label: 'En cours d’acquisition'},
                    {rating: 3, label: 'Bien acquis'},
                    {rating: 4, label: 'Maîtrise / Expertise'}
                  ]}
                  label="Solutions SIRH"
                  selectDatas={this.props.select_data['solution']}
                  onChange={values => this.buildExperienceFilter(values, 'userSolutions.solution.id', 'solution')}
                />
              </div>

              <div className="box-filter exp-adder">
                <ExpAdderField
                  type="language"
                  name="missionSkills"
                  labelTooltipContent={[
                    {rating: 1, label: 'Mission non réalisable, niveau insuffisant à l’écrit et/ou à l’oral'},
                    {rating: 2, label: 'Mission réalisable, niveau intermédiaire'},
                    {rating: 3, label: 'Mission réalisable, niveau avancé'},
                    {rating: 4, label: 'Mission réalisable, niveau maîtrise'}
                  ]}
                  label="Langues"
                  selectDatas={this.props.select_data['language']}
                  onChange={values => this.buildExperienceFilter(values, 'userLanguages.language.id', 'language')}
                />
              </div>

              <div className="box-filter">
                <h3>Grade</h3>
                <FilterComponentSelect
                  filter="position"
                  select={this.filterChange.bind(this)}
                  data={this.props.select_data["position"]}
                />
              </div>

              <div className="box-filter">
                <h3>Années d’expérience</h3>
                <span
                  style={{
                    textAlign: "left",
                    display: "block",
                    fontSize: "12px",
                    margin: "10px 0"
                  }}
                >
                  Entre
                  <InputBoxDoneTyping
                    className="input-interval"
                    autoComplete="off"
                    placeholder={this.state.lowerBound.toString()}
                    onChange={(value) => this.setState({ lowerBound: value })}
                    doneTyping={() => this.reloadExp()}
                    doneTypingInterval={500}
                  />
                  et
                  <InputBoxDoneTyping
                    className="input-interval"
                    autoComplete="off"
                    placeholder={this.state.upperBound.toString()}
                    onChange={(value) => this.setState({ upperBound: value })}
                    doneTyping={(value) => this.reloadExp()}
                    doneTypingInterval={500}
                  />
                  an(s)
                </span>
                <Range
                  allowCross={false}
                  value={this.state.defaultExpRangeValue}
                  onChange={this.onSliderChange}
                  min={0}
                  max={40}
                  filter="experience"
                  onAfterChange={() => {
                    this.props.match.params.status === "externe"
                      ? this.props.listExterne(this.buildFilterUrl(), true)
                      : this.props.updateListConsultant(
                          this.buildFilterUrl(),
                          true
                        );
                    this.setState({
                      stopProp: false
                    });
                  }}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  list_consultant: state.Consultants.list,
  next: state.Consultants.next_url,
  total: state.Consultants.total,
  select_data: state.SelectData,
  role: state.Role.role
});

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      updateListConsultant: consultant_creators.get_list_constultants,
      listExterne: consultant_creators.get_list_externe
    },
    dispatch
  );
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ConsulttantsContainer);
