import React from 'react'
import {Provider} from 'react-redux'
import {ListingCandidatesProvider} from './context/ListingPaginationContext';
import configureStore from './stores'
import {ThemeProvider} from 'styled-components'
import Theme from './ui/theme'
import DragAndDropContext from './containers/DragAndDropContext';
import {ModalProvider} from "./context/ModalContext";
import {AffectProvider} from "./context/AffectContext";

const Initialize = props => {
    const store = configureStore(props.initialState)
    return (
        <Provider store={store}>
        <ThemeProvider theme={Theme}>
            <ModalProvider>
                <AffectProvider>
                    <ListingCandidatesProvider>
                        <DragAndDropContext/>
                    </ListingCandidatesProvider>
                </AffectProvider>
            </ModalProvider>
        </ThemeProvider>
    </Provider>
)
}

export default Initialize
