import React from 'react'

const ListingHeader = props => {
    return <thead>
        <tr>
            {props.children}
        </tr>
    </thead>
}

export default ListingHeader
