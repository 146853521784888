
import { referent_service } from "../../_services/referent";

export const refere_creator = {
    get_list
};

function get_list(userId, next, filter, empty = false) {
    return function (dispatch) {
        if( empty ) {
            dispatch({
                type : 'UPDATE_REFERENT',
                payload : null
            })
        } else {
            referent_service.get_lists( userId, next, filter ).then(function(response){
                dispatch({
                    type : 'UPDATE_REFERENT',
                    payload : {data: response.data, filter: filter}
                })
            });
        }
    };

}
