import moment from 'moment';
import React from 'react'
import {connect} from 'react-redux';
import Select from 'react-select';
import {bindActionCreators} from 'redux';
import {profileService} from '../../_services/profile_service';
import {levelDatas} from '../../helpers/app';
import {_getUserProfile} from '../../reducers/App/actions/user';
import StarsRating from './components/StarsRating';

const ConsultantItem = props => {
  return (
    <div className="consultant-item">
      <div className="consultant-name">
        <img src={props.data.addedByData.profilePhoto} alt=""/>
        <span>
          <strong>{props.data.addedByData.fullName}</strong>
          <em>{moment(props.data.createdAt).format('DD/MM/YYYY')}</em>
        </span>
      </div>
      <div className="rating">
        <StarsRating stars={4} rating={props.data.rating} isEvaluatedByOwner={props.owner}/>
      </div>
    </div>
  )
}

const ToolTipRatings = ({isEvaluatedByOwner, ratings, profileId, average, skillId, getUserProfile, type, labelTooltipContent}) => {

  const [levelState, setLevelState] = React.useState(null);

  React.useEffect(() => {
    if(ratings) {
      const match = ratings.find(rate => rate.addedByData.id === parseInt(localStorage.getItem('user_id')))
      if (match !== undefined) {
        if(labelTooltipContent) {
          setLevelState(labelTooltipContent.reduce((acc,item) =>{
            acc.push({
              label: item.label,
              value: item.rating
            })
            return acc
          }, []).find(lvl => lvl.value === (
            match.rating > 4 ? 4 : match.rating
          )))
        } else {
          setLevelState(levelDatas.find(lvl => lvl.value === (
            match.rating > 4 ? 4 : match.rating
          )))
        }

      }
    }

  }, []);

  function capitalizeFirstLetter(string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
  }

  const handleChange = value => {
    setLevelState(value)
    const uriRatingType = type === 'CoreSkills' ? 'core_skills' : type;
    // const match = ratings.find( rate => rate.addedByData.id === parseInt(localStorage.getItem('user_id')))
    profileService.addRating(uriRatingType, {
      [`user${capitalizeFirstLetter(type.slice(0,-1))}`] : {
        "id": skillId
      },
      "rating": value.value
    }).then(() =>{
      getUserProfile(
        profileId
      )
    })
  }

  return (
    ratings ?
    <div className="tooltip-ratings">
      <span style={{
        marginBottom: 8,
        display: 'block',
        maxWidth: '100%'
      }}>
        <em style={{
          marginBottom: 4,
          fontWeight: 'bold',
          display: 'block'
        }}>Votre évaluation</em>
        <Select
          isSearchable={true}
          isMulti={false}
          value={levelState}
          options={labelTooltipContent ? labelTooltipContent.reduce((acc,item) =>{
            acc.push({
              label: item.label,
              value: item.rating
            })
            return acc
          }, []) :levelDatas}
          onChange={handleChange}
        />
      </span>
      {!isEvaluatedByOwner &&
      <>
        <span className="title">
          Moyenne chef de projet : {average.toFixed(2)}
        </span>
        <div className="cdp-ratings">
          {ratings.filter(rate => rate.addedByData.id !== profileId).map(c => <ConsultantItem data={c} owner={false}/>)}
        </div>
      </>
      }
      {ratings.filter(rate => rate.addedByData.id === profileId).map(c => {
        return (
          <>
            <span className="title-consultant">
              Note consultant :
            </span>
            <ConsultantItem owner={true} data={c}/>
          </>
        )
      })}
    </div> : null
  )
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({
    getUserProfile: _getUserProfile
  }, dispatch);
}

export default connect(null, mapDispatchToProps)(ToolTipRatings)
