import * as _ from 'lodash';
import initialState from './initialState';

function Circle(state = initialState, action = {}) {
    if (_.isUndefined(action)) {
        return state;
    }

    const {type, payLoad} = action;

    if (type === 'GET_EXPERTS') {
        return {
            data: payLoad,
        }
    }

    return state;
}

export default Circle;
