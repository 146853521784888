import React from 'react'
import Switch from './Switch';

function FilterAvailibity(props) {
  const [disabInputs, setDisabInputs] = React.useState(false);

  const [swicthValues, setSwicthValues] = React.useState({
    m0: true,
    m1: false,
    m2: false
  });

  const [dayssegment, setDayssegment] = React.useState({
    min: null,
    max: null
  });

  React.useEffect(() => {

    const identifiers = Object.keys(swicthValues)
    const months = identifiers.filter(function (id) {
      return swicthValues[id]
    })
    if (months.length === 0) {
      setDisabInputs(true)
    } else {
      setDisabInputs(false)
    }

    if (dayssegment.min !== null && dayssegment.max >= dayssegment.min && months.length > 0) {

      props.filter({
        months: months,
        days: dayssegment
      })
    }
  }, [swicthValues])


  React.useEffect(() => {
    const identifiers = Object.keys(swicthValues)
    const months = identifiers.filter(function (id) {
      return swicthValues[id]
    })

    if (dayssegment.min !== null && dayssegment.max >= dayssegment.min && months.length > 0) {
      props.filter({
        months: months,
        days: dayssegment
      })
    } else if(isNaN(dayssegment.min) && isNaN(dayssegment.max)) {
      props.onReset()
    }
  }, [dayssegment])


  return (
    <div className="filters_available">
      <label>
        <span>Mois en cours</span>
        <Switch
          isOn={swicthValues.m0}
          handleToggle={() => setSwicthValues({...swicthValues, m0: !swicthValues.m0})}
          onColor="#00b5d4"
        />
      </label>
      <label>
        <span>Mois en cours +1</span>
        <Switch
          isOn={swicthValues.m1}
          handleToggle={() => setSwicthValues({...swicthValues, m1: !swicthValues.m1})}
          onColor="#00b5d4"
        />
      </label>
      <label>
        <span>Mois en cours +2</span>
        <Switch
          isOn={swicthValues.m2}
          handleToggle={() => setSwicthValues({...swicthValues, m2: !swicthValues.m2})}
          onColor="#00b5d4"
        />
      </label>

      <div className="segment_days">
        Entre
        <input
          disabled={disabInputs}
          type="text"
          onChange={(e) => setDayssegment({...dayssegment, min: parseInt(e.target.value)})}/>
        et
        <input
          disabled={disabInputs}
          type="text"
          onChange={(e) => setDayssegment({...dayssegment, max: parseInt(e.target.value)})}/>
        jours
      </div>

    </div>
  )
}

export default FilterAvailibity
