import React from 'react'
import cx from 'classnames/bind'

const SubDropdownFilter = props => {

  const [open, setOpen] = React.useState(false);
  const [selections, setSelection] = React.useState(null);

  const applyFilter = value => {
    if( props.radio ) {
      setSelection(value)
    } else {
      if( props.values.find( item => item.value === value.value ) ) {
        setSelection( props.values.filter( item => item.value !== value.value ) )
      } else {
        setSelection([
          ...props.values,
          value
        ])
      }
    }
  }

  React.useEffect(() => {
    if( props.onUpdateFilters && selections ) {
      props.onUpdateFilters(selections)
    }
  }, [selections]);


  return(
    <div className="subfilter">
      <span className="label" onClick={() => setOpen(!open)} style={props.isGray ? {backgroundColor: '#F2F2F4'} : {}}>
        {props.title}
        <svg width="16" height="11" viewBox="0 0 16 11" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path fillRule="evenodd" clip-rule="evenodd" d="M0.743896 2.17851L1.92241 1L7.99982 7.07741L14.0772 1L15.2557 2.17851L7.99982 9.43443L0.743896 2.17851Z" fill="#00B5D4" stroke="#00B5D4"/>
        </svg>
      </span>
      {
        props.options.length > 0 && open &&
          <ul>
            { props.options.map( opt => <li
              key={opt.value}
              className={cx({
                selected: props.values.find( item => item.value === opt.value ) !== undefined
              })}
              onClick={ () => applyFilter(opt) }
            >
              { props.values.find( item => item.value === opt.value ) !== undefined && <svg width="16" height="11" viewBox="0 0 16 11" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path fillRule="evenodd" clip-rule="evenodd" d="M15.1591 1.87684L6.125 10.911L0.465881 5.25184L1.65912 4.05859L6.125 8.52447L13.9659 0.683594L15.1591 1.87684Z" fill="#003D71"/>
              </svg>}
              {opt.label}
            </li>) }
          </ul>
      }
    </div>
  )
}

const SubDropdownExp =  props => {
  const [open, setOpen] = React.useState(false);
  const [selections, setSelection] = React.useState(null);

  const applyFilter = value => {
    setSelection(value)
  }

  React.useEffect(() => {
    if( props.onUpdateFilters && selections ) {
      props.onUpdateFilters(selections)
    }
  }, [selections]);

  return(
    <div className="subfilter">
      <span className="label" onClick={() => setOpen(!open)} style={props.isGray ? {backgroundColor: '#F2F2F4'} : {}}>
        {props.title}
        <svg width="16" height="11" viewBox="0 0 16 11" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path fillRule="evenodd" clip-rule="evenodd" d="M0.743896 2.17851L1.92241 1L7.99982 7.07741L14.0772 1L15.2557 2.17851L7.99982 9.43443L0.743896 2.17851Z" fill="#00B5D4" stroke="#00B5D4"/>
        </svg>
      </span>
      {
        props.options.length > 0 && open &&
        <ul>
          { props.options.map( opt => <li
            key={opt.value}
            className={cx({
              selected: opt.value === props.value
            })}
            onClick={ () => applyFilter(opt) }
          >
            {  opt.value === props.value && <svg width="16" height="11" viewBox="0 0 16 11" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path fillRule="evenodd" clipRule="evenodd" d="M15.1591 1.87684L6.125 10.911L0.465881 5.25184L1.65912 4.05859L6.125 8.52447L13.9659 0.683594L15.1591 1.87684Z" fill="#003D71"/>
            </svg>}
            {opt.label}
          </li>) }
        </ul>
      }
    </div>
  )
}

const DropdownFilters = props => {

  const [open, setOpen] = React.useState(false);

  return (
    <div className={cx({
      "ui-dropdown-filter": true,
      "ui-dropdown-filter--open": open
    })}>
      <span className="title" onClick={() => setOpen(!open)}>
        Filtrer par
        <svg width="49" height="48" viewBox="0 0 49 48" fill="none" xmlns="http://www.w3.org/2000/svg">
         <path fillRule="evenodd" clipRule="evenodd" d="M16.293 21.7072L17.7072 20.293L25.0001 27.5859L32.293 20.293L33.7072 21.7072L25.0001 30.4143L16.293 21.7072Z" fill="white" stroke="white"/>
        </svg>
      </span>
      {open &&
      <div className="ui-dropdown-filter__content">
        <SubDropdownFilter
          radio
          isGray
          values={[{value: props.state['externe']}]}
          onUpdateFilters={ value => props.onUpdateFilters(value.value, 'externe') }
          title="Type de consultant"
          options={[
            {'label': 'Interne', value: 0},
            {'label': 'Externe', value: 1}
          ]}
        />
        <SubDropdownFilter
          isGray
          values={props.state['position.id']}
          onUpdateFilters={value => props.onUpdateFilters(value, 'position.id')}
          title="Grades"
          options={props.selectDatas.position}
        />
        <SubDropdownFilter
          isGray
          values={props.state['workplace.id']}
          onUpdateFilters={ value => props.onUpdateFilters(value, 'workplace.id') }
          title="Localisation"
          options={[
            {'label': 'Bordeaux', value: 3},
            {'label': 'Lisbonne', value: 4},
            {'label': 'Lyon', value: 2},
            {'label': 'Paris 9ème', value: 1}
          ]}
        />
        <SubDropdownExp
          radio
          isGray
          value={props.state['experience[between]'] }
          onUpdateFilters={ value => props.onUpdateFilters(value.value, 'experience[between]') }
          title="Années d’expérience"
          options={props.expOptions}
        />
        <SubDropdownFilter
          type='userSkills.skill.id'
          values={props.state['userSkills.skill.id']}
          onUpdateFilters={ value => props.onUpdateFilters(value, 'userSkills.skill.id') }
          title="Connaissances métiers"
          options={ props.selectDatas.skill }
        />
        <SubDropdownFilter
          values={props.state['userSolutions.solution.id']}
          onUpdateFilters={ value => props.onUpdateFilters(value, 'userSolutions.solution.id') }
          title="Solutions SIRH"
          options={ props.selectDatas.solution }
        />
        <SubDropdownFilter
          values={props.state['userFields.field.id']}
          onUpdateFilters={ value => props.onUpdateFilters(value, 'userFields.field.id') }
          title="Contextes d’application"
          options={ props.selectDatas.field }
        />
        <SubDropdownFilter
          values={props.state['userLanguages.language.id']}
          onUpdateFilters={ value => props.onUpdateFilters(value, 'userLanguages.language.id') }
          title="Langues"
          options={ props.selectDatas.language }
        />
        <button className="validate" onClick={()=>setOpen(false)}>Valider</button>
      </div>
      }
    </div>
  )
}

export default DropdownFilters
