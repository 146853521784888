import * as _ from "lodash";
import initialState from "./initialState";

function Missions(state = initialState, action) {
  if (_.isUndefined(action)) {
    return state;
  }

  const { type, payload } = action;

  switch (type) {
    case "UPDATE_MISSIONS_LIST":
      if (payload === null) {
        return {
          list: [],
          mission_view: null,
          next_url: "",
        };
      } else if (payload.filter) {
        return {
          total: payload.data["hydra:totalItems"],
          list: payload.data["hydra:member"],
          mission_view: null,
          next_url:
            payload.data["hydra:view"] !== undefined
              ? payload.data["hydra:view"]["hydra:next"]
              : "",
        };
      } else {
        return {
          total: payload.data["hydra:totalItems"],
          list: [...state.list, ...payload.data["hydra:member"]],
          mission_view: null,
          next_url:
            payload.data["hydra:view"] !== undefined
              ? payload.data["hydra:view"]["hydra:next"]
              : "",
        };
      }

    case "UPDATE_MISSION_SINGLE":
      return {
        total: payload.data ? payload.data["hydra:totalItems"] : 0,
        list: [],
        mission_view: payload.data,
        next_url: "",
      };

    case "POST_COMMENT":
      return { mission_view: payload.data, next_url: "" };

    default:
      return state;
  }
}

export default Missions;
