
import { newConnectConfig } from "./contant";
import Axios from "axios";

export const planningService = {
  fetch_user_dispo,
  try_add_user_dispo,
  try_edit_user_dispo,
  fetch_planning,
  book_dispo,
  doExportShortAvailabilities,
  deleteShortAvailability,
  addShortAvailabilities
};

function deleteShortAvailability(id) {
  const url = `${newConnectConfig.API_PATH}${newConnectConfig.DISPO_COURTE_ENDPOINT}/${id}`;

  return Axios({
    method:'DELETE',
    headers: {
      "Authorization" : `Bearer ${localStorage.getItem('token')}`
    },
    url,
  })
}

function addShortAvailabilities(data) {
  const url = `${newConnectConfig.API_PATH}${newConnectConfig.DISPO_COURTE_ENDPOINT}`;

  return Axios({
    method:'POST',
    headers: {
      "Authorization" : `Bearer ${localStorage.getItem('token')}`
    },
    url,
    data
  })
}

function book_dispo( short_availabilities_id, content, unbook = false ) {
  if( !unbook ) {
    let url = `${newConnectConfig.API_PATH}${newConnectConfig.DISPO_COURTE_ENDPOINT}/${short_availabilities_id}/book`;
    return Axios({
      method:'post',
      url,
      headers: {"Authorization" : `Bearer ${localStorage.getItem('token')}`},
      data: content
    })
  } else {
    let url = `${newConnectConfig.API_PATH}${newConnectConfig.DISPO_COURTE_ENDPOINT}/${short_availabilities_id}/unbook`;
    return Axios({
      method:'post',
      url,
      headers: {"Authorization" : `Bearer ${localStorage.getItem('token')}`},
      data: {}
    })
  }

}

function fetch_planning() {
  let url = `${newConnectConfig.API_PATH}${newConnectConfig.DISPO_COURTE_ENDPOINT}/planning`;
    return Axios({
      method:'get',
      url,
      headers: {"Authorization" : `Bearer ${localStorage.getItem('token')}`}
    })
}

function try_edit_user_dispo(content, id) {
  const url = `${newConnectConfig.API_PATH}${newConnectConfig.DISPO_COURTE_ENDPOINT}/${id}`;

  return Axios({
    method:'put',
    headers: {
      "Authorization" : `Bearer ${localStorage.getItem('token')}`
    },
    url,
    data: content
  })
}

function try_add_user_dispo(content) {
    let url = `${newConnectConfig.API_PATH}${newConnectConfig.DISPO_COURTE_ENDPOINT}`;

    return Axios({
      method:'post',
      headers: {
        "Authorization" : `Bearer ${localStorage.getItem('token')}`
      },
      url,
      data: content
    })
}

function fetch_user_dispo() {
    const url = `${newConnectConfig.API_PATH}${newConnectConfig.DISPO_COURTE_ENDPOINT}`;
    return Axios({
      method:'get',
      url,
      headers: {"Authorization" : `Bearer ${localStorage.getItem('token')}`}
    })
}

function doExportShortAvailabilities() {
  const url = `${newConnectConfig.API_PATH}${newConnectConfig.DISPO_COURTE_ENDPOINT}/export_unbooked`;
  return Axios({
    method:'get',
    url,
    headers: {"Authorization" : `Bearer ${localStorage.getItem('token')}`}
  })
}


