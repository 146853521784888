import * as _ from 'lodash';
import initialState from "./initialState";
import {
    GET_NEED_FISCAL_YEARS,
    GET_AVAILABLE_POSITION_MONTHS_START,
    GET_AVAILABLE_POSITION_MONTHS_SUCCESS,
    GET_AVAILABLE_POSITION_MONTHS_FAIL,
} from './actions-types';

function Need(state = initialState, action = {}) {
    if (_.isUndefined(action)) {
        return state;
    }

    const { type, payLoad } = action;

    switch (type) {
        case GET_NEED_FISCAL_YEARS:
            return {
                ...state,
                fiscalYears: payLoad,
            }
        case GET_AVAILABLE_POSITION_MONTHS_START:
            return {
                ...state,
                monthYearAvailablities: [],
                loading: true,
            }
        case GET_AVAILABLE_POSITION_MONTHS_SUCCESS:
            return {
                ...state,
                monthYearAvailablities: payLoad,
                loading: false,
            }
        case GET_AVAILABLE_POSITION_MONTHS_FAIL:
            return {
                ...state,
                monthYearAvailablities: [],
                loading: false,
            }
        default: return state;
    }
}

export default Need
