
import { newConnectConfig } from "./contant";
import Axios from "axios";
import {
  getHeaders,
  REQUEST_METHOD_GET
} from "./utils/headers";

export const missions_service = {
    get_lists,
    get,
    save,
    update,
    comment,
    get_affected_missions,
    updateMissionStatus,
    search,
    getFiscalYearsDatas,
    searchByTerm,
    getStatusHistory,
    get_consultant_recent_missions,
};


function getFiscalYearsDatas() {
  const url =  `${newConnectConfig.API_PATH}/missions/get_fiscal_years`;
  return Axios({
    method:'get',
    url,
    headers: {
      "Authorization" : `Bearer ${localStorage.getItem('token')}`
    }
  });
}

function searchByTerm(filter) {
  const url =  `${newConnectConfig.API_PATH}/missions${filter}`;
  return Axios({
    method:'get',
    url,
    headers: {"Authorization" : `Bearer ${localStorage.getItem('token')}`}
  });
}

function search(userId) {
  const url =  `${newConnectConfig.API_PATH}/missions?matchedUser.id=${userId}`;
  return Axios({
    method:'get',
    url,
    headers: {"Authorization" : `Bearer ${localStorage.getItem('token')}`}
  });
}

function getStatusHistory(missionId) {
  const url =  `${newConnectConfig.API_PATH}/mission_status_histories?mission.id=${missionId}`;
  return Axios({
    method:'get',
    url,
    headers: {"Authorization" : `Bearer ${localStorage.getItem('token')}`}
  });
}

function updateMissionStatus(mission_id, content) {
    const url = `${newConnectConfig.API_PATH}/missions${mission_id}`;
    return Axios({
        method:'put',
        url,
        headers: {"Authorization" : `Bearer ${localStorage.getItem('token')}`},
        data: content
    });
}

function comment( comment ) {
    const url = `${newConnectConfig.API_PATH}${newConnectConfig.COMMENT_ENDPOINT}`;
    return Axios({
      method:'post',
      url,
      headers: {"Authorization" : `Bearer ${localStorage.getItem('token')}`},
      data: comment
    });
}

function update( mission_id, content  ) {
    let url = '';

    if( mission_id !== null ) {
       url =  `${newConnectConfig.API_PATH}${newConnectConfig.MISSION_ENDPOINT}/${mission_id}`;
    } else {
       url =  `${newConnectConfig.API_PATH}${newConnectConfig.MISSION_ENDPOINT}`;
    }

    return Axios({
      method:'put',
      url,
      headers: {"Authorization" : `Bearer ${localStorage.getItem('token')}`},
      data: content
    });
}

function save( content, mission_id = null ) {
    let url = '';

    if( mission_id !== null ) {
       url =  `${newConnectConfig.API_PATH}${newConnectConfig.MISSION_ENDPOINT}/${mission_id}`;
    } else {
       url =  `${newConnectConfig.API_PATH}${newConnectConfig.MISSION_ENDPOINT}`;
    }
    return Axios({
      method:'post',
      url,
      headers: {"Authorization" : `Bearer ${localStorage.getItem('token')}`},
      data: content
    });
}

function get_affected_missions(url_next = null, filter = false) {
    let url = ''
    if( url_next ) {
      url = `${newConnectConfig.API_HOST}${url_next}`;
    } else {
      url =  `${newConnectConfig.API_PATH}${newConnectConfig.MISSION_ENDPOINT}?statut=EN_RECHERCHE,EN_ATTENTE_DE_VALIDATION`;
    }
    return Axios({
      method:'get',
      url,
      headers: {"Authorization" : `Bearer ${localStorage.getItem('token')}`}
    });
}

function get( mission_id ) {
    let url =  `${newConnectConfig.API_PATH}${newConnectConfig.MISSION_ENDPOINT}/${mission_id}`;
    return Axios({
      method:'get',
      url,
      headers: {"Authorization" : `Bearer ${localStorage.getItem('token')}`}
    });
}

function get_lists( url_next = null, filter = false) {
    let url = ''
    if( !filter ) {
      url = ( url_next === null ) ? `${newConnectConfig.API_PATH}${newConnectConfig.MISSION_ENDPOINT}` : `${newConnectConfig.API_HOST}${url_next}`;
    } else {
      url =  `${newConnectConfig.API_PATH}${newConnectConfig.MISSION_ENDPOINT}${url_next}`;
    }
    return Axios({
      method:'get',
      url,
      headers: {"Authorization" : `Bearer ${localStorage.getItem('token')}`}
    });
}

function get_consultant_recent_missions(consultantId) {
  const url = `${newConnectConfig.API_PATH}${newConnectConfig.MISSION_ENDPOINT}/get_upcoming_active_missions_for_user/${consultantId}`;
  return Axios({
    method: REQUEST_METHOD_GET,
    url,
    headers: getHeaders()
  });
}
