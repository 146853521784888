import Axios from "axios";
import {newConnectConfig} from "./contant";

export const AppService = {
    checkMaintenance,
};

function checkMaintenance(filter) {
    const url = `${newConnectConfig.API_PATH}/get-maintenance-mode${filter}`;
    return Axios({
        method: 'get',
        url,
    });
}