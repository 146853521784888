import React from 'react'
import { Formik, Field, ErrorMessage } from 'formik';
import * as Yup from "yup";
import SelectField from "../../../../Formik/Fields/SelectField";
import {withRouter} from "react-router-dom";
import {connect} from "react-redux";
import ErrorMesage from "../../../../Formik/ErrorMesage";
import {profileService} from "../../../../_services/profile_service";
import {bindActionCreators} from "redux";
import {_getUserProfile} from "../../../../reducers/App/actions/user";
import {profile_creators} from "../../../../reducers/Profile/actions/_update_profile";
import CoreSkillDescriptions from '../CoreSkillDescriptions';

const CoreSkills = props => {

  const [datas, setDatas] = React.useState(null);
  const [coreSkillsLevelData, setCoreSkillsLevelData] = React.useState([]);
  const [initialRating, setInitialRating] = React.useState(null);

  const submit = (values) => {
    if (props.data === null) {
      profileService.addExpertise('core_skills', {
        "user": {
          "id": props.user_id
        },
        "coreSkill": {
          "id": values.competence
        },
        "ratings": [{ "rating": values.level }]
      }).then(() => {
        props.setEditMode(false)
      })
    } else {
      profileService.addRating('core_skills', {
        "userCoreSkill": {
          "id": props.data.id
        },
        "rating": values.level
      }).then(() => {
        props.setEditMode(false)
      })
    }
  }

  const schema = Yup.object().shape({
    competence: Yup.string().required('Obligatoire'),
    level: Yup.string().required('Obligatoire')
  });

  React.useState(() => {
    if (props.data) {
      setInitialRating(
        props.data.ratings.find(rate => rate.addedByData.id === parseInt(localStorage.getItem('user_id')))
      );
    }
  }, []);

  React.useEffect(() => {
    setCoreSkillsLevelData(props.labelTooltipContent.map(level => ({
      label: level.label,
      value: level.rating
    })))
  }, [props.labelTooltipContent])

  React.useEffect(() => {

    if( props.data === null ) {
      const disabledIds = props.currentDatas.reduce((acc,item)=>{
        acc.push(item.coreSkill.id)
        return acc
      },[])
      setDatas( props.select_data['core_skill'].filter( item => !disabledIds.includes(item.value) ) )
    } else {
      setDatas( props.select_data['core_skill'].filter( item => item.value === props.data.coreSkill.id ) )
    }
  }, [props.currentDatas]);

  function deleteCompetence(id) {
    props.remove('user_core_skills',id, props.user_id)
    props.setEditMode(false)
  }

  return (
    datas &&
    <div className="edit-mode">
      <div className="wrapper">
        <span className="title">
          { props.data === null ? 'Nouvelle compétence socle' : 'Modifier une compétence socle'}
        </span>
        <Formik
          onSubmit={submit}
          initialValues={
            props.data !== null ? {
              "competence":  { label:datas[0].label, value:datas[0].value},
              "level":  coreSkillsLevelData.find(data => data.value === initialRating.rating),
            } : {
              "competence": '',
              "level": '',
            }
          }
          validationSchema={props.data === null ? schema : {}}
        >
          {(
            { handleSubmit, setFieldValue, values}) => (
            <form onSubmit={ handleSubmit }>
              <div className="flex">
                <div>
                  <Field setFieldValue={setFieldValue} datas={datas} name="competence" placeholder="" label="Compétence" required component={SelectField} />
                  <ErrorMessage name="competence" component={ErrorMesage} />
                </div>
                {
                  values.competence &&
                  <div className="form-group">
                    <CoreSkillDescriptions
                      userCoreSkillData={props.data}
                      selectedCoreSkill={values.competence}
                    />
                  </div>
                }
                <div>
                  <Field
                    setFieldValue={setFieldValue}
                    datas={props.labelTooltipContent ? props.labelTooltipContent.map(item => ({
                      label: item.label,
                      value: item.rating
                    })) : []}
                    name="level" placeholder="" label="Niveau" required component={SelectField} />
                  <ErrorMessage name="level" component={ErrorMesage} />
                </div>
              </div>
              <div className="actions">
                <button className="button fullw" type="submit">Valider</button>
                <button onClick={ () => props.setEditMode(false) } className="button outline fullw" type="button">Annuler</button>
                {props.data &&
                <button style={{borderColor: '#F44336', color: '#F44336'}}
                        onClick={() => deleteCompetence(props.data.id)} className="button outline fullw"
                        type="button">Supprimer</button>
                }
              </div>
            </form>
          )}
        </Formik>
      </div>
    </div>
  )
}

const mapStateToProps = (state) => ({
  select_data : state.SelectData
})

function mapDispatchToProps(dispatch){
  return bindActionCreators({
    getUserProfile:_getUserProfile,
    remove: profile_creators.deleteCompetence
  },dispatch);
}


export default withRouter(
  connect(mapStateToProps, mapDispatchToProps )(CoreSkills)
)
