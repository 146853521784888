import React, {Component} from 'react'
import '../../styles/components/MissionComments.css';
import CommentItem from '../../components/missions/CommentItem';
import {connect} from 'react-redux';

class MissionComments extends Component {

  constructor(props) {
    super(props)
    this.state = {
      comment: ''
    }
  }

  postComment() {
    if (this.state.comment !== '') {
      this.props.onPostComment(this.state.comment)
    }

    this.setState({
      comment: ''
    })

  }

  render() {

    const {comments} = this.props;

    return (
      <React.Fragment>
        <div className="card-comment rounded-0">
          <div className="card-body">
            <div className="row no-gutters">
              <div className="col-md-1 mb-3 mb-md-0">
                <span className="card-comment__thumbnail">
                  <img alt="profilepic" src={this.props.profile_pic} className="img rounded-circle img-fluid"/>
                </span>
              </div>
              <div className="col-md-11 pl-3">
                <textarea
                  value={this.state.comment}
                  onChange={(e) => this.setState({comment: e.target.value})}
                  className="form-control noresize" rows="2" placeholder="Laissez votre commentaire"
                  required="required"
                />
                <button
                  disabled={this.state.comment === ''}
                  onClick={this.postComment.bind(this)}
                  style={{marginTop: '10px'}}
                  className="button-ui button-ui--outline">Poster
                </button>
              </div>
            </div>
          </div>
        </div>
        {comments.map((item, i) => <CommentItem comment={item} key={i}/>)}
      </React.Fragment>
    )
  }

}

export default connect(
  state => (
    {
      profile_pic: state.Role.pofile_pic
    }
  )
)(MissionComments)
