import React from 'react'
import cx from 'classnames/bind'
import InputBoxDoneTyping from "react-input-box-done-typing";

const Client = props => {
  return (
    <div className={cx({
      "filter-box": true,
      "filter-box--right" : props.filterXPos === 'right'
    })}>
       <span>
      {props.value &&
      <div style={{color: '#00B5D4', marginBottom: 8}} className="reset-filter"
           onClick={() => props.onFilterChange( 'client' , null)}>
        Ré-initialiser
      </div>
      }
      </span>
      <InputBoxDoneTyping
        className="filter-list-input"
        doneTyping={ value => props.onFilterChange('client',value) }
        placeholder="Tapez pour chercher..."
        defaultValue={props.value}
      />
      <hr/>
      <button onClick={() => props.updateSorting('client','order[client]=asc') }>Trier par, ascendant</button>
      <button onClick={() => props.updateSorting('client','order[client]=desc') }>Trier par, descendant</button>
    </div>
  )
}

export default Client
