import React from 'react'

const InputField = ({field, form: { touched, errors }, ...props}) => (
  <div className="form-group">
    <label className="label">
      { props.label }
      {(props.required || props.obligatoire) && <span style={{ color: '#00b5d4', marginLeft: 8 }} className='required'>*</span>}
    </label>
    <input type="text" {...props} {...field} />
  </div>
);

export default InputField
