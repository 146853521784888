import React, {useEffect} from 'react'
import cx from 'classnames/bind.js'
import {missions_service} from '../../../_services/missions';
import HeadersActions from '../../../components/HeadersActions';
import ModalConfirmArchive from '../../../components/ModalConfirmArchive';
import {AffectContext} from '../../../context/AffectContext';
import {ModalContext} from '../../../context/ModalContext';
import ModalAffectComponent from './ModalAffectComponent';

const Header = props => {

  const [modalAffect, setModalAffect] = React.useContext(AffectContext);
  const [modal, setModal] = React.useContext(ModalContext);

  useEffect(() => {
    if (modalAffect.affected) {
      props.onReload();
    }
  }, [modalAffect]);

  const affectModal = () => {
    setModalAffect({
      ...modalAffect,
      display: true,
      consultantsSelected: props.mission.matching.reduce((acc, item) => {
        acc.push({
          id: item.user_id,
          fullname: item && item.fullname,
          profilePhoto: item.photo && item.photo.contentUrl
        })
        return acc
      }, []),
      component: <ModalAffectComponent mission={props.mission} from="view"/>
    })
  }

  const doArchive = () => {
    setModal({
      ...modal,
      display: true,
      component: <ModalConfirmArchive
        icon={<svg width="94" height="96" viewBox="0 0 94 96" fill="none" xmlns="http://www.w3.org/2000/svg">
          <circle cx="47.9878" cy="52.5" r="39.5" fill="white"/>
          <path
            d="M20.2192 69.4024V69.4245H55.2025C56.8557 73.5921 60.9102 76.5537 65.6603 76.5537C71.8703 76.5537 76.9236 71.5029 76.9236 65.2904C76.9236 60.9737 74.4589 57.2646 70.8902 55.371L75.044 42.5257C75.1572 42.1724 75.0937 41.7833 74.8812 41.4797C74.6631 41.1816 74.3154 40.9994 73.9428 40.9994L66.6398 40.9967V38.6451C66.6398 36.3212 64.901 34.4334 62.7757 34.4334H43.3594L38.1872 28.8223C37.9691 28.5822 37.6572 28.447 37.3316 28.447L20.2194 28.4497C19.5763 28.4497 19.0547 28.9714 19.0547 29.6144V68.2351C19.0547 68.8064 19.4714 69.2949 20.0372 69.386C20.1007 69.3942 20.1642 69.4025 20.2194 69.4025L20.2192 69.4024ZM74.5884 65.2818C74.5884 70.2056 70.5809 74.2183 65.6518 74.2183C60.7197 74.2183 56.7152 70.2108 56.7152 65.2818C56.7152 60.3496 60.7283 56.3452 65.6518 56.3452C70.5753 56.3452 74.5884 60.3582 74.5884 65.2818ZM72.328 43.3289L68.7179 54.4989C67.7353 54.2174 66.7252 54.0187 65.6515 54.0187C59.4415 54.0187 54.3826 59.0695 54.3826 65.2876C54.3826 65.9086 54.4681 66.5021 54.5675 67.0954L21.8092 67.0982L29.5093 43.3288L72.328 43.3289ZM21.3863 30.7818H36.8261L41.9983 36.3929C42.2164 36.633 42.5283 36.7682 42.8539 36.7682H62.7867C63.6202 36.7682 64.3268 37.6294 64.3268 38.6561V41.0076L28.6702 41.0048C28.1679 41.0048 27.7152 41.3305 27.5607 41.8107L21.3783 60.8738V30.7813L21.3863 30.7818Z"
            fill="#7ED321"/>
          <path
            d="M71.4745 64.1752H66.6004V59.2983C66.6004 58.7132 66.1256 58.2385 65.5405 58.2385C64.9554 58.2385 64.4807 58.7132 64.4807 59.2983V64.1724H59.6065L59.6038 64.1752C59.0187 64.1752 58.5439 64.6499 58.5439 65.235C58.5439 65.8202 59.0187 66.2949 59.6038 66.2949H64.4779V71.169H64.4807C64.4807 71.7542 64.9554 72.2289 65.5405 72.2289C66.1256 72.2289 66.6004 71.7542 66.6004 71.169V66.2673H71.4745C72.0596 66.2673 72.5344 65.7925 72.5344 65.2074C72.5344 64.6223 72.0596 64.1476 71.4745 64.1476V64.1752Z"
            fill="#7ED321"/>
        </svg>
        }
        desc="Vous trouverez dans ce cas votre besoin à la page “Besoins archivés”."
        label="Souhaitez vous archiver ce besoin ?"
        onCancel={() => setModal({...modal, display: false, component: null})}
        onConfirm={() => {
          missions_service.update(props.mission.id, {
            isArchived: true
          }).then(() => {
            setModal({...modal, display: false, component: null})
            props.onReload()
          })
        }}
      />
    })
  }

  const getActionsAvailable = (status, isArchived) => {

    if (isArchived) {
      return [{
        label: 'Revoir l’affectation',
        classes: 'button-ui button-ui--blue',
        onAction: affectModal
      },{
        label: 'Archivé <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">\n' +
          '<path fill-rule="evenodd" clip-rule="evenodd" d="M20.7072 7.70712L10.0001 18.4142L3.29297 11.7071L4.70718 10.2929L10.0001 15.5858L19.293 6.29291L20.7072 7.70712Z" fill="white"/>\n' +
          '</svg>',
        classes: 'button-ui button-ui--full-outline c-white mr-3',
        onAction: null
      }]
    }

    let actions = [{
      label: ['IN_RESEARCH'].includes(status) ? 'Affecter' : 'Revoir l’affectation',
      classes: 'button-ui button-ui--blue',
      onAction: affectModal
    }];

    if (['FILLED', 'CANCELED', 'LOST'].includes(status)) {
      actions = [
        ...actions,
        {
          label: 'Archiver',
          classes: 'button-ui button-ui--full-outline c-white mr-3',
          onAction: doArchive
        }
      ]
    }

    return actions
  }

  return (
    <div className={cx({
      "header-propale": true,
      "header-propale--white": props.backgroundMode !== undefined && props.backgroundMode === 'white'
    })}>
      <div className="container">
        <div>
          <div style={{cursor: 'pointer'}} onClick={() => window.history.back()} className="back">
            <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
              <rect width="40" height="40" rx="20" fill="white"/>
              <path fillRule="evenodd" clipRule="evenodd"
                    d="M22.2929 11.293L23.7071 12.7072L16.4142 20.0001L23.7071 27.293L22.2929 28.7072L13.5858 20.0001L22.2929 11.293Z"
                    fill="black"/>
            </svg>
          </div>
          <h1>
            {props.title}
            {props.subtitle && <span style={{display: 'flex'}} dangerouslySetInnerHTML={{__html: props.subtitle}}/>}
          </h1>
        </div>
        <div>
          <HeadersActions actions={getActionsAvailable(props.mission.status, props.mission.isArchived).reverse()}/>
        </div>
      </div>
    </div>
  )
}

export default Header
