import React, { Component } from 'react'
import Moment from 'react-moment';
import 'moment/locale/fr';
import {Link} from 'react-router-dom';

export default class NotfiComponent extends Component {

  generateLink = () => {
    if( this.props.data.missionID ) {
      return `missions/view/${this.props.data.missionID }`
    } else if ( this.props.data.notifType && this.props.data.notifType === 'CR' ) {
      return `/cr_matching`
    }  else if ( this.props.data.notifType && this.props.data.notifType === 'DISPO_COURTE' ) {
      return `/planning`
    } else if( this.props.data.path ) {
      return this.props.data.path
    }
    else {
      return `consultant/view/${this.props.data.consultantID }`
    }
  }

  render() {
    return (
        <Link to={ this.generateLink() } className="card-notifs">
            <span className="card-notifs__name">{ this.props.data.content }</span>
            <div className="card-notifs__clock">
                <i className="far fa-clock" /><Moment fromNow>{ this.props.data.creationDate }</Moment>
            </div>
        </Link>
    )
  }
}
