import React from "react";
import {ErrorMessage, Field, Formik} from "formik";
import {connect} from 'react-redux';
import * as Yup from "yup";
import {AdminService} from '../../../../_services/AdminService';
import ErrorMesage from '../../../../Formik/ErrorMesage';
import CheckBoxField from '../../../../Formik/Fields/CheckBoxField';
import InputField from '../../../../Formik/Fields/InputField';
import SelectField from '../../../../Formik/Fields/SelectField';
import TextareaInput from '../../../../Formik/Fields/TextareaInput';
import {hasCap} from '../../../../helpers/app';
import {HYDRA_MEMBER} from '../../../../reducers/hydra';

const ModalEditExpertData = props => {

  const [expertPosition, setExpertPosition] = React.useState(null);

  const submit = values => {
    console.log('DEBUG', values)
    props.onConfirm({
      ...values,
      tjm: parseInt(values.tjm),
      position: {id: values.position.value ? values.position.value : values.position}
    })
  }

  React.useEffect(() => {
    AdminService.customGet('positions?isInternal=0').then(response => {
      setExpertPosition(
        response.data[HYDRA_MEMBER].reduce((acc,item) => {
          acc.push({
            label: item.name,
            value:item.id
          })
          return acc;
        }, [])
      )
    })
  }, []);


  const schema = Yup.object().shape({
    position: Yup.string().required('Obligatoire'),
    tjm: Yup.string().required('Obligatoire'),
    tjmComment: Yup.string().required('Obligatoire'),
    detailsAvailability: Yup.string().required('Obligatoire'),
  });

  const schemaExpert = Yup.object().shape({
    tjm: Yup.string().required('Obligatoire'),
    tjmComment: Yup.string().required('Obligatoire'),
    detailsAvailability: Yup.string().required('Obligatoire'),
  });

  return (
    expertPosition &&
    <>
      <div className="content" style={{background: '#F2F2F4', borderRadius: 8}}>
        <Formik
          onSubmit={submit}
          initialValues={hasCap('PARTNER', props.role) ? {
            tjmComment: props.data.tjmComment ? props.data.tjmComment : '',
            tjm: props.data.tjm ? props.data.tjm : '',
            detailsAvailability: props.data.detailsAvailability ? props.data.detailsAvailability : '',
            position: props.data.position ? {label: props.data.position.name, value: props.data.position.id} : '',
            hasRealizedMission: !!props.data.hasRealizedMission
          } : {
            tjmComment: props.data.tjmComment ? props.data.tjmComment : '',
            tjm: props.data.tjm ? props.data.tjm : '',
            detailsAvailability: props.data.detailsAvailability ? props.data.detailsAvailability : '',
            hasRealizedMission: !!props.data.hasRealizedMission
          }}
          validationSchema={hasCap('PARTNER', props.role) ? schema : schemaExpert}
        >
          {(
            {handleSubmit, setFieldValue, values}) => (
            <form onSubmit={handleSubmit}>
              <h2>Modifier les détails du profil</h2>
              <div className="container">
                <div className="row">
                  <div className="col-md-12">
                    <Field obligatoire name="tjm" placeholder="€/Jour HT" label="TJM" component={InputField}/>
                    <ErrorMessage name="tjm" component={ErrorMesage}/>
                  </div>
                  <div className="col-md-12">
                    <Field obligatoire name="tjmComment" placeholder="Commentaire sur le TJM" label="Commentaire sur le TJM" component={TextareaInput}/>
                    <ErrorMessage name="tjmComment" component={ErrorMesage}/>
                  </div>
                  {hasCap('PARTNER', props.role) &&
                  <div className="col-md-12">
                    <Field setFieldValue={setFieldValue} datas={expertPosition} required name="position" placeholder="" label="Grade"
                      component={SelectField}/>
                    <ErrorMessage name="position" component={ErrorMesage}/>
                  </div>}
                  <div className="col-md-12">
                    <Field obligatoire name="detailsAvailability" placeholder="Ex : Dispo ASAP (positionnée sur FML / MAJ 21/09/21)" label="Disponibilités "
                      component={InputField}/>
                    <ErrorMessage name="detailsAvailability" component={ErrorMesage}/>
                  </div>
                  {(hasCap('PARTNER', props.role) || props.role === 'EXTERNE') &&
                  <div className="col-md-12">
                    <Field setFieldValue={setFieldValue} name="hasRealizedMission" placeholder="" label="Missions réalisées avec CRH" component={CheckBoxField}/>
                    <ErrorMessage name="hasRealizedMission" component={ErrorMesage}/>
                  </div>
                  }
                </div>
              </div>
              <div className="actions">
                <button onClick={props.onCancel} className="button-ui button-ui--blue-outline" type="button">Annuler
                </button>
                <button className="button-ui button-ui--blue" type="submit">Valider</button>
              </div>
            </form>
          )}
        </Formik>
      </div>
    </>
  )
}

const mapStateToProps = (state) => (
  {
    selectData: state.SelectData,
    role: state.Role.role
  }
);


export default connect(mapStateToProps, null)(ModalEditExpertData);
