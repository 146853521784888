import React from 'react'
import {matcher_service} from '../../../../_services/matcher_service';
import ButtonWithControlledDisable from '../../../../ui/ButtonWithControlledDisable';
import ConsultantItem from '../ConsultantItem';

const AffectArea = ({mission, onReload, readOnly}) => {
  const [state, setState] = React.useState([]);
  const [processing, setProcessing] = React.useState(false);

  const affect = () => {
    setProcessing(true)
    const matchingId = (
                         state && state[0].matching_id
                       ) ? state[0].matching_id : mission.matching.find(
      match => match.user_id === mission.matchedUser.id
    ).matching_id
    matcher_service.validate(matchingId).then(() => jobDone())
  }

  const sendValidationClient = () => {
    setProcessing(() => true)
    matcher_service.affect({
      "mission": {
        "id": mission.id,
        "status": "WAITING_FOR_CUSTOMER_FEEDBACK"
      },
      matchings: state.reduce((acc, item) => {
        acc.push({
          "user": {"id": item.user_id}
        })
        return acc;
      }, [])
    }).then(() => {
      jobDone()
      setState([])
    })
  }

  const jobDone = () => {
    onReload()
    setProcessing(false)
    setState(() => null)
  }

  const addToSelection = (user) => {
    if (state && state.find(u => u.user_id === user.user_id) !== undefined) {
      setState(state.filter(u => u.user_id !== user.user_id))
    } else {
      setState([
        ...state,
        user
      ])
    }

  }

  return (
    !['IN_RESEARCH'].includes(mission.status) &&
    <div className="affet-mission-area">
      <div className="info col-md-12">
        <div className="row flex flex-column">
          <span className="info__label">
            {mission.matchedUser === null ? 'Selectionnez le consultant que vous souhaitez affecter à cette mission :' : 'Consultant affecté'}
          </span>

          <span className="info__text">
            <div className="flex flex flex-row justify-content-between flex-wrap">
            {mission.matchedUser === null
             ?
             mission.matching.map(match =>
               <span className="mb-1" style={{width: '49%'}}>
                 {readOnly === undefined ?
                  <ConsultantItem
                    key={match.matching_id}
                    outsideModal
                    consultant={match}
                    isExterne={match.externe}
                    onChecked={user => addToSelection(user)}
                    checked={state && state.find(u => u.user_id === match.user_id) !== undefined}
                    user={state}
                  /> : <ConsultantItem
                    readOnly
                    key={match.matching_id}
                    outsideModal
                    consultant={match}
                    isExterne={match.externe}
                    onChecked={user => addToSelection(user)}
                    checked={state && state.find(u => u.user_id === match.user_id) !== undefined}
                    user={state}
                  />}
                </span>
             ) : <ConsultantItem
               key={mission.matchedUser.id}
               outsideModal
               consultant={mission.matchedUser}
               isExterne={mission.matchedUser.externe}
               readOnly
             />}
              </div>
            {state && state.length > 0 &&
              <div className="mt-4 actions flex flex-row justify-content-between">
                {(
                   mission.matching.length === 1 && mission.status === 'WAITING_FOR_CUSTOMER_FEEDBACK'
                 ) ? <ButtonWithControlledDisable
                   isTargetDisabled={processing || state.length > 1}
                   style={{flex: '1 1 0%'}}
                   classes="button-ui button-ui--blue"
                   text="Valider"
                   onClick={affect}
                 /> : <>
                   <ButtonWithControlledDisable
                     isTargetDisabled={processing}
                     style={{flex: 1, marginRight: 4, marginLeft: 4}}
                     classes="button-ui button-ui--outline"
                     text="Envoyer en validation client"
                     onClick={sendValidationClient}
                   />

                   <ButtonWithControlledDisable
                     isTargetDisabled={processing || state.length > 1}
                     style={{flex: '1 1 0%'}}
                     classes="button-ui button-ui--blue"
                     text="Valider"
                     onClick={affect}
                   />
                 </>
                }
              </div>
            }

          </span>
        </div>
      </div>
    </div>
  )
}

export default AffectArea
