import React, {useState} from 'react';
import Axios from "axios";
import { newConnectConfig } from '../../../_services/contant';
import { toast } from "react-toastify";

const ErreurPlannigPopup = (props) => {

    const [errorMessage, seterrorMessage] = useState('')

    const addPlanifError = () => {
        const url = `${newConnectConfig.API_PATH}/plan_errors`;

        Axios({
            method:'post',
            url,
            headers: {"Authorization" : `Bearer ${localStorage.getItem('token')}`},
            data : {
                content: errorMessage
            }
        }).then(
            () => {
                props.hidePopup() ;
                toast.success(
                  "Erreur de planif transmise au Contrôleur de gestion et au Référent",
                  { position: toast.POSITION.BOTTOM_LEFT }
                );
            }
        );
    }

    return(
        <React.Fragment>
        <div className="modal fade show" aria-modal="true" style={{display: 'block'}}>
            <div className="modal-dialog modal-dialog-centered" style={{ maxWidth: '50%' }}>
                <div className="modal-content">
                    <div className="modal-header" style={{ border: 0 }}>
                        <div className="w-100">
                            <h2 className="title" style={{ margin: '0' }}>Signaler une erreur de planification</h2>
                        </div>
                    </div>
                    <div className="modal-body">
                        <textarea
                            placeholder="Décrire l'erreur (mois concerné, nombre de jours en +/-, missions et chefs de projet concernés, etc.)"
                            className="textarea_erreur_plannig"
                            defaultValue={ errorMessage }
                            onChange={ (e) => seterrorMessage(e.target.value) }
                        />
                    </div>
                    <div className="modal-footer"  style={{ border: 0, flexFlow : 'wrap' }}>
                        <button
                            style={{margin: 0}}
                            className="btn_blue"
                            onClick={ () => addPlanifError() }
                        >Confirmer</button>
                        <button
                            style={{margin: 0, marginLeft: '10px'}}
                            className="btn_blue outline"
                            onClick={ () => props.hidePopup() }
                        >Annuler</button>
                    </div>
                </div>
            </div>
        </div>
        <textarea className="modal-backdrop fade show" onClick={() => props.hidePopup()} />
        </React.Fragment>
    )
}

export default ErreurPlannigPopup;
