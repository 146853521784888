import React from 'react'
import axios from 'axios'
import { Formik, Field, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import '../css/signup.css'
import { connect } from 'react-redux'
import { newConnectConfig } from '../../_services/contant';

const ComposantErreur = props => (
    <div className="text-danger" r>{props.children}</div>
);

const Checkbox = ({
    field: { name, value, onChange, onBlur },
    form: { errors, touched, setFieldValue },
    id,
    label,
    className,
    ...props
  }) => {
    return (
      <div style={{ display: 'none' }}>
        <input
          name={name}
          id={id}
          type="checkbox"
          value={value}
          checked={value}
          onChange={onChange}
          onBlur={onBlur}
        />
      </div>
    );
  };

const ComposantInput = ({
    field,
    form: { touched, errors },
    ...props
    }) => (
    <div className="form-group">
        <label> { props.label } </label>
        <input type="text" {...props} className="form-control" {...field} />
    </div>
);

const userSchema = Yup.object().shape({
    firstname: Yup.string().required('Obligatoire'),
    lastname: Yup.string().required('Obligatoire'),
    password: Yup.string().required("Obligatoire"),
    cgu: Yup.bool().oneOf([true], "Vous devez accepter les conditions d’utilisation de Connect")
    // cgu: Yup.bool()
    //   .test(
    //     'consent',
    //     'You have to agree with our Terms and Conditions!',
    //     value => value === true
    //   )
});

function signUp(props) {
    const submit = (values, actions) => {

        actions.setSubmitting(false);

        axios.post(
            `${newConnectConfig.API_PATH}/users/signup`,
            values,
            { headers: {"Authorization" : `Bearer ${localStorage.getItem('token')}`} }
        ).then( () => window.location.replace('#/onboarding') )
    }

    React.useEffect(() => {

        if( props.role.signedUp ) {
            window.location.replace('#/onboarding')
        }
        return () => {
        };
    }, [props] )

    return (
        props.role.email ?
        <div className="signup">

            <span className="baseline">
                <img style={{'maxWidth':'51px'}} src={require('../../assets/images/logo-mystaff.png')} alt="New Connect" />
            </span>

            <Formik
            onSubmit={submit}
            initialValues={{
                firstname: '',
                lastname: '',
                password: '',
                cgu: false
            }}
            validationSchema={userSchema}
            >
            {({
            handleSubmit,
            isSubmitting}) => (
                <form onSubmit={ handleSubmit } className="box_signup">
                <Field name="lastname" label="Nom" component={ComposantInput} />
                <ErrorMessage name="lastname" component={ComposantErreur} />
                <Field name="firstname" label="Prénom" component={ComposantInput} />
                <ErrorMessage name="firstname" component={ComposantErreur} />
                <Field disabled="disabled" placeholder={props.role.email} name="email" label="Email" type="email" component={ComposantInput} />
                <Field  name="password" label="Personnalisez votre mot de passe" type="password" component={ComposantInput} />
                <ErrorMessage name="password" component={ComposantErreur} />
                <div style={{
                    display: 'flex',
                    justifyContent: 'space-between'
                }}>
                <span onClick={ () => document.querySelector('#cgu').click() } style={{
                        width: 20,
                        height: 20,
                        border: 'solid 1px #cfd1d2',
                        display: 'inline-block',
                        borderRadius: '50%',
                        verticalAlign: 'middle',
                        cursor: 'pointer'
                    }}>
                        { document.querySelector('#cgu') && document.querySelector('#cgu').checked && <i style={{
                            width: 12,
                            height: 12,
                            background: '#00b5d4',
                            display: 'block',
                            borderRadius: '50%',
                            margin: '0 auto',
                            top: 3,
                            position: 'relative'
                        }} /> }
                    </span>

                <label for="cgu" style={{
                    maxWidth: '85%'
                }}>

                    <Field
                        component={Checkbox}
                        name="cgu"
                        id="cgu"
                        label="Agree to something"
                    />
                    J'accepte les conditions d’utilisation de <a style={{color: '#00b5d4'}} href="https://www.convictionsrh.com/fr/" target="_blank" rel="noopener noreferrer">Connect</a>
                </label>

                </div>

                <ErrorMessage name="cgu" component={ComposantErreur} />

                <button type="submit" className="btn_blue" disabled={isSubmitting}>
                    Je m’inscris !
                </button>
                </form>
            )}
            </Formik>
        </div> : null
    )
}

const mapStateToProps = (state) => ({
    role : state.Role
})

export default connect(mapStateToProps,null)(signUp)
