import React, {useState,useEffect} from 'react'

const StarImage = props => {
  return( props.item.checked ?  <svg width="17" height="15" viewBox="0 0 17 15" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path fillRule="evenodd" clipRule="evenodd" d="M8.5 12L3.79772 14.4721L4.69577 9.23607L0.891548 5.52786L6.14886 4.76393L8.5 0L10.8511 4.76393L16.1085 5.52786L12.3042 9.23607L13.2023 14.4721L8.5 12Z" fill="#003D71"/>
      </svg>
     : <svg width="16" height="15" viewBox="0 0 16 15" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path fillRule="evenodd" clipRule="evenodd" d="M8 12L3.29772 14.4721L4.19577 9.23607L0.391548 5.52786L5.64886 4.76393L8 0L10.3511 4.76393L15.6085 5.52786L11.8042 9.23607L12.7023 14.4721L8 12Z" fill="#D4E0E3"/>
      </svg>
  )
}

const StarImageCDP = props => {
  return( props.item.checked ?  <svg width="16" height="15" viewBox="0 0 16 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                               <path fillRule="evenodd" clipRule="evenodd" d="M8.3916 12L3.68932 14.4721L4.58738 9.23607L0.783149 5.52786L6.04046 4.76393L8.3916 0L10.7427 4.76393L16.0001 5.52786L12.1958 9.23607L13.0939 14.4721L8.3916 12Z" fill="#00B5D4"/>
                             </svg>
                             : <svg width="16" height="15" viewBox="0 0 16 15" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fillRule="evenodd" clipRule="evenodd" d="M8 12L3.29772 14.4721L4.19577 9.23607L0.391548 5.52786L5.64886 4.76393L8 0L10.3511 4.76393L15.6085 5.52786L11.8042 9.23607L12.7023 14.4721L8 12Z" fill="#D4E0E3"/>
          </svg>
  )
}

const StarsRating = props => {
  const [rating, setRating] = useState(null);
  const [renderer, setRenderer] = useState([]);

  useEffect(() => {
    if( props.rating >= props.stars ) {
      setRating(4)
    } else {
      setRating(Math.round(props.rating))
    }
  }, [props.rating, props.stars]);

  useEffect(() => {
    if( rating ) {
      const values = []
      for ( let i = 0; i < props.stars; i++ ) {
        values.push({
          isEvaluatedByOwner: props.isEvaluatedByOwner,
          checked: rating > i
        })
      }
      setRenderer(values)
    }
  }, [rating]);

  return (
    <>
      {
        renderer.map( (item, index) =>
          (item.isEvaluatedByOwner !== undefined && !item.isEvaluatedByOwner) ? <StarImageCDP key={index} item={item} /> : <StarImage key={index} item={item} />
        )
      }
    </>
  )
}

export default StarsRating
