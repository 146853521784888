import Axios from 'axios';
import React from 'react'
import InputBoxDoneTyping from "react-input-box-done-typing";
import {newConnectConfig} from '../../_services/contant';

const CustomSearch = ({field, form: {touched, errors, initialValues}, ...props}) => {

  const [results, setResults] = React.useState([]);

  const search = value => {
    if (value.length >= 1) {
      const url = `${newConnectConfig.API_PATH}/${props.endpoint}&value=${value}&filter_attribute=customer.value`;
      Axios({
        method: 'GET',
        url,
        headers: {"Authorization": `Bearer ${localStorage.getItem('token')}`}
      }).then(
        response => setResults(formatData(response.data))
      );
    } else {
      setResults([])
    }
  }

  function formatData(arr) {
    return arr.reduce((acc, item) => {
      acc.push({
        label: item[props.dataDisplay],
        customerValue: item[props.selectedDisplay],
        value: item.id
      })
      return acc
    }, [])
  }

  const select = data => {
    props.setFieldValue(field.name, data.customerValue)
    props.handleChange(field.name, data)
    document.getElementById(`searh-field-${field.name}`).value = `${data.customerValue}`
    setResults([])
  }

  return (
    initialValues &&
    <div className="form-group">
      <label className="label">
        {props.label}
        {props.required && <span style={{color: '#00b5d4', marginLeft: 8}} className="required">*</span>}
      </label>
      <div className="search-field-container">
        <InputBoxDoneTyping
          autoComplete={'off'}
          id={`searh-field-${field.name}`}
          doneTyping={search}
          placeholder={props.placeholder}
        />
        {
          results.length > 0 && <div className="search-field-container__results">
            {
              results.map(match =>
                <div className="consultant-line" onClick={() => select(match)}>
                  <div className="name">
                    {match.label}
                  </div>
                </div>
              )
            }
          </div>
        }
      </div>
    </div>
  )
};

export default CustomSearch
