import React from 'react';
import moment from 'moment';
import Select from 'react-select';
import UnStaffed from '../../../assets/images/un-staffed.png'
import Staffed from '../../../assets/images/staffed.png'
import {planningService} from "../../../_services/planningService";

const CalendarHeader = (props) => {

  const downloadLink = React.useRef();

  const exportShortAvailabilities = () => {
    planningService.doExportShortAvailabilities().then(res =>{
      if(downloadLink.current) {
        downloadLink.current.setAttribute('href', res.data.file)
        downloadLink.current.click()
      }
    })
  }
  return (
    <div className="calendar-header">
      <a href="/" download ref={downloadLink} style={{display:'none', pointerEvents: 'none'}}>download</a>
      <header className="header_calendar col-md-10 offset-md-1">
            <div className="week_view">
                <button
                    onClick={ () => { props.activeView === 'nextWeek' ? props.onChangeWeek('currentWeek') :  props.onChangeWeek('nextWeek') }  }
                    disabled={ props.activeView === 'currentWeek' }
                    className="action-change-week"
                >
                    <i className="fas fa-chevron-left" />
                </button>
                <span>
                    { moment(props.view[0]).format('D') } - { moment(props.view[4]).format('D') } { moment(props.view[4]).format('MMM' )}
                </span>
                <button
                    onClick={ () => { props.activeView === 'nextWeek' ? props.onChangeWeek('thirdWeek') :  props.onChangeWeek('nextWeek') } }
                    disabled={ props.activeView === 'thirdWeek' }
                    className="action-change-week"
                >
                      <i className="fas fa-chevron-right" />
                </button>
                <button
                    className="action-change-week wa"
                    onClick={ () => props.onChangeWeek('currentWeek') }
                    disabled={ props.activeView === 'currentWeek' }
                >
                    Cette semaine</button>
            </div>
            <div className="actions">
              {props.activeView === 'currentWeek' &&
              <button
                className='button-lg'
                onClick={exportShortAvailabilities}
              >Exporter les disponibilités </button>
              }
              <Select
                isSearchable={false}
                isMulti={false}
                value={props.dispoFilter}
                onChange={ value => props.onFilterDispo(value) }
                options={[
                    {value:null, label : 'Tous'},
                    {value:true, label : 'Déjà staffé.e'},
                    {value:false, label : 'Non-staffé.e'}
                ]}
              />
            </div>
        </header>
      <div className="col-md-12">
        <ul className='mission_legende'>
          <li>
            <img src={UnStaffed} alt="unstaffed" title="Déjà staffé.e" />
            Déjà staffé.e
          </li>
          <li>
            <img src={Staffed} alt="staffed" title='Non-staffé.e' />
            Non-staffé.e
          </li>
          <li>
            <i className="green_point" title="Facturable" />
            Facturable
          </li>
        </ul>
      </div>
    </div>
  )
}

export default CalendarHeader;
