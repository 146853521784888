import React from 'react'
import ParcoursLine from "./ParcoursLine";
import {hasCap} from "../../../helpers/app";
import FormationsForm from "./edits/FormationsForm";
import MissionsForm from "./edits/MissionsForm";

const IconPlus = props => {
  return (
    (
      props.match.path === "/my-profile" || hasCap('MANAGER', props.role)
    ) &&
    <span onClick={props.onClick}>
       <svg width="25" height="25" viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
          <rect width="25" height="25" rx="12.5" fill="#00B5D4"/>
          <path d="M13.1071 6.25H11.3929V11.3929H6.25V13.1071H11.3929V18.25H13.1071V13.1071H18.25V11.3929H13.1071V6.25Z"
                fill="white"/>
        </svg>
    </span>
  )
}

const ParcoursListing = props => {

  const [editMode, setEditMode] = React.useState(false);

  const setEditingComponent = (type, data = null) => {
    switch (type) {
      case 'missions':
        editMode === false ? setEditMode(
          <MissionsForm
            data={data}
            setEditMode={setEditMode}
            currentDatas={props.datas}
            user_id={props.userId}
            type="professionalExperiences"
          />
        ) : setEditMode(false)
        break;
      case 'formations':
        editMode === false ? setEditMode(
          <FormationsForm
            user_id={props.userId}
            currentDatas={props.datas}
            data={data}
            setEditMode={setEditMode}
            type="trainings"
          />
        ) : setEditMode(false)
        break;
      default:
        setEditMode(false)
        break;
    }
  }

  return (
    <div className="parcours-listing">
      <h3>
        {props.label}
        <IconPlus {...props} onClick={() => setEditingComponent(props.type)}/>
      </h3>
      {
        editMode ?
        editMode : props.datas &&
          props.datas.map(
            (data, index) => <ParcoursLine
              role={props.role}
              key={`pline-${index}`}
              onEdit={d => setEditingComponent(props.type, d)}
              data={data}
              activeTab={props.activeTab}/>
          )}
    </div>
  )
}

export default ParcoursListing
