import React from 'react'
import ConsultantItem from "./ConsultantItem";
import PrevNextNavigators from './PrevNextNavigators';

const ConsultantsList = props => {
  const disableLoad = navItemName => !props[navItemName] || props.fetching;

  const navButtons = props.prevNextNavigation ?
    (
      (props.prev || props.next) &&
      <PrevNextNavigators
        prevDisabled={disableLoad('prev')}
        nextDisabled={disableLoad('next')}
        loadPrev={props.loadPrev}
        loadNext={props.loadNext}
        pagesTotal={Math.ceil(parseInt(props.total) / props.skilledItemsPerPage)}
      />
    ) : (
      props.next &&
      <button className="btn btn-link" style={{ margin: '0 auto', display: 'table', color: '#00B5D4' }}
        disabled={disableLoad('next')}
        onClick={() => props.loadNext()}>
        Charger Plus
      </button>
    );

  const totalLabel = `${props.total} consultant${props.total !== 1 ? 's' : ''}`;

  return (
    props.data &&
    <div className="p-3" style={props.listStyle || {}}>
      <div className="d-flex justify-content-between">
        <span>{props.label}</span>
        <span>{!props.fetching ? totalLabel : ''}</span>
      </div>
      <div className="consultants-list">
        {props.data.map((consultant, index) =>
          <ConsultantItem toDispalyRecentMissions key={index} consultant={consultant} isExterne={consultant.externe}
            itemStyle={props.itemStyle} checkerStyle={props.checkerStyle} />)}
      </div>
      {navButtons}
    </div>
  )
}

export default ConsultantsList;
