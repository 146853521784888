
import { matcher_service } from "../../_services/matcher_service";
import { toast } from "react-toastify";
import { missions_action } from "../Missions/actions/getMissionsList";

export const matcher_actions = {
  affect,
  add_more,
  validate,
  reject,
  deleteUser,
  deleteAffectedUserMatching
};

function deleteAffectedUserMatching( user_id, mission_id ) {
  return function (dispatch) {
    matcher_service.deleteUser( user_id );
  }
}
  

function deleteUser( user_id, mission_id ) {
  return function (dispatch) {
    matcher_service.deleteUser( user_id ).then(function(response){
      dispatch( missions_action.get_detail_mission(mission_id) )
    });
  };
}

function reject(mission_id) {
  return function (dispatch) {
    matcher_service.reject( mission_id ).then(function(response){
      dispatch( missions_action.get_detail_mission(mission_id) )
    });
  };
}

function validate( matching, mission_id ) {
  return function (dispatch) {
    matcher_service.validate( matching ).then(function(response){
      dispatch( missions_action.get_detail_mission(mission_id) )
    });
  };
}

function add_more(state) {
  return function (dispatch) {
    dispatch({
        type : 'AFFECT_MISSION',
        payload : state
    });
  }
}

function affect( content, more = false ) {
    return function (dispatch) {
      matcher_service.affect( content ).then(function(response){
        dispatch({
            type : 'AFFECT_MISSION',
            payload : 'continue'
        })
        if( !more ) {
          dispatch( missions_action.destroyAfftectedMissions() )
          dispatch( missions_action.get_affected_missions() )
        } else {
          dispatch( missions_action.get_affected_missions() )
        }
       
      }).then( () => {
      
        toast.success("Matching sauvegardé", {
          position: toast.POSITION.BOTTOM_LEFT,
        });

      }).catch( () => {
        toast.error("une erreur s'est produite", {
          position: toast.POSITION.BOTTOM_LEFT,
        });
      });
    };
}
