import React, {useEffect, useState} from 'react'
import CustomDate from '../../../../../components/Filters/CustomDate';
import ListingInputFilter from '../../../../../components/ListingInputFilter';
import DataListHeader from "../DataListHeader";
import Listing from "../../../../../components/ui/Listing/Listing";
import HeaderThead from "../../../../../containers/Missions/listing/header";
import {developpmentService} from "../../../../../_services/DevelopmentService";
import LineListingFeedbackMission from "./line-listing/LineListingFeedbackMission";
import ListingNavigation from "./ListingNavigation";
import {buildUrl} from "../../../../../helpers/BuildFilters";
import useDataListNavigation from "../../../../../hooks/useDataListNavigation";

const DataListMission = props => {

  const [sorting, setSorting] = useState('');
  const [filterOpen, setFilterOpen] = useState(null);
  const [filters, setFilters] = useState({
    ...props.customFilter || {},
    'user.id': props.userId
  });
  const [data, setData] = useState(null)

  const updateFilters = (value, type) => {
    if( value === null ) {
      setFilters(
        Object.keys(filters)
          .filter(key => type !== key)
          .reduce((obj, key) => {
            obj[key] = filters[key];
            return obj;
          }, {})
      )

    } else {
      setFilters({
        ...filters,
        [type] : value
      })
    }

  }

  const listingHeader = [
    { label: 'Mission', id: 'title', filter: ListingInputFilter, termSearch: 'title',filterXPos: 'left', styles:{width: '35%'} },
    { label: 'Période', id: 'startDate', filter: CustomDate, termSearch: 'startDate', filterXPos: 'left', styles:{width: '10%'} },
    { label: 'Chef de projet', id: 'evaluatedBy.fullName', filter: ListingInputFilter, termSearch: 'evaluatedBy.fullName', filterXPos: 'right',styles:{width: '10%'} },
    { label: 'Points forts',id: 'weight', filter: null, filterXPos: 'right'},
    { label: 'Axes d’amélioration', id: 'owner.fullName', filter: null, termSearch: 'owner.fullName' ,filterXPos: 'right' },
    { label: 'Savoir être', id: 'know-be', filter: null, termSearch: 'referentPartner.fullName', filterXPos: 'right' },
    { label: 'Savoir faire', id: 'know-how',filter: null, filterXPos: 'right' }
  ]

  const searchChange = value => {
    updateFilters(value, 'term')
  }

  useEffect(() => {
    developpmentService.fetch_missions_feedback(`?${buildUrl(filters).slice(0, -1)}&${sorting !== '' && sorting.sortMode}`).then(
      data => setData({
        data: data.data['hydra:member'],
        next: data.data['hydra:view'] && data.data['hydra:view']['hydra:next'],
        prev: data.data['hydra:view'] && data.data['hydra:view']['hydra:previous']
      })
    )
    setFilterOpen(!filterOpen)
  }, [filters, sorting]);

  async function navigationChange(endpoint){
    const result = await useDataListNavigation(endpoint)
    setData(result)
  }

  return (
    <div className="box col-md-12">
      <DataListHeader hasSearch={true} label="Feedbacks : Missions" searchChange={value => searchChange(value)} />
      <Listing>
        <Listing.Header>
          {
            listingHeader.map(
              header =>
                <HeaderThead
                  key={header.id}
                  termSearch={header.termSearch}
                  filter={filters[header.id]}
                  setFilterOpen={ value => setFilterOpen( value === filterOpen ? null :value) }
                  filterOpen={filterOpen}
                  select_data={props.select_data}
                  sorting={sorting}
                  header={header}
                  onFilterChange={ (type, value) => updateFilters(value, type) }
                  updateSorting={ (key, sortMode) => setSorting({ key, sortMode}) }
                  styles={header.styles}
                />
            )
          }
        </Listing.Header>
        <Listing.Content>
          { data && data.data.map( data => <LineListingFeedbackMission key={data.id} listingHeader={listingHeader} sorting={sorting} data={data} /> )}
        </Listing.Content>
      </Listing>
      { data && <ListingNavigation next={data.next} prev={data.prev} navigationChange={endpoint => navigationChange(endpoint) }/> }
    </div>
  )
}

export default DataListMission
