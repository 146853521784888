import React from 'react'
import {Route, HashRouter} from "react-router-dom";
import Modal from '../../components/Modal';
import SlidingModal from '../../components/SlidingModal';
import Header from '../../containers/Header';
import {AffectContext} from '../../context/AffectContext';
import {ModalContext} from '../../context/ModalContext';
import ProtectedRoute from './PrivateRoute';
import {connect} from 'react-redux'
import MenuMobileComponent from '../../components/App/MenuMobileComponent';
import ValidateToken from "../ValidateToken";
import RequestInterceptor from "../RequestInterceptor";
import MaintenanceChecker from "../MaintenanceChecker";
import {useClearCache} from "react-clear-cache";

const RouteGenerator = props => {
    const [dataModal, setDataModal] = React.useContext(ModalContext)
    const [affectModal, setAffectModal] = React.useContext(AffectContext)
    const {isLatestVersion, emptyCacheStorage} = useClearCache();

    return (
            <HashRouter>
                <>
                    {dataModal.display && <Modal data={dataModal} onClose={() => setDataModal({
                        ...dataModal,
                        display: false,
                        component: null
                    })}/>}
                    <SlidingModal data={affectModal}
                                  onClose={() => setAffectModal({...affectModal, display: false, component: null})}/>
                    {props.menu.menu_mobile_open && props.role ?
                        <MenuMobileComponent current_user={props.current_user}/> : null}
                    <Header/>
                    <MaintenanceChecker>
                        <ValidateToken>
                            <RequestInterceptor>
                                <div className="wrapper" style={{marginTop: 103, display: 'flex', flexFlow: 'column'}}>
                                    {
                                        props.routes.map((route, key) => {
                                            switch (route.type) {
                                                case 'public':
                                                    return (route.strict ?
                                                        <Route exact key={key} path={route.path}
                                                               component={route.handler}/> :
                                                        <Route key={key} path={route.path} component={route.handler}/>)
                                                case 'private':
                                                    return props.role ? route.strict ?
                                                        <ProtectedRoute user={props.current_user}
                                                                        attributesUserProtection={route.attributesUserProtection}
                                                                        exact
                                                                        current_role={props.role}
                                                                        protection={route.role}
                                                                        key={key} path={route.path}
                                                                        component={route.handler}/> :
                                                        <ProtectedRoute key={key} role={route.role} strict={route.strict}
                                                                        path={route.path} user={props.current_user}
                                                                        attributesUserProtection={route.attributesUserProtection}
                                                                        component={route.handler}/> : null
                                                default:
                                                    return null
                                            }
                                        })
                                    }
                                </div>
                            </RequestInterceptor>
                        </ValidateToken>
                        {!isLatestVersion && (
                            <div className="flash-new-app-version">
                                <div className="container d-flex">
                                    <p className="t-base-medium">
                                        Une nouvelle version de MyStaff est disponible, merci de mettre à jour pour profiter
                                        des nouvelles fonctionnalités.
                                    </p>
                                    <a
                                        href="#"
                                        className="button-ui button-ui--white"
                                        onClick={e => {
                                            e.preventDefault();
                                            emptyCacheStorage().then(() => window.location.reload());
                                        }}
                                    >
                                        Mettre à jour
                                    </a>
                                </div>
                            </div>
                        )}
                    </MaintenanceChecker>
                    <footer className="main-footer">
                        ConvictionsRH / MyStaff
                    </footer>
                </>
            </HashRouter>
        )
}

const mapStateToProps = (state) => ({
    role: state.Role.role,
    current_user: state.Role,
    menu: state.Menu
})

export default connect(mapStateToProps)(RouteGenerator)

