import React from 'react'
import Select from "react-select";

const SelectField = ({field, ...props}) => {

  const [value, setValue] = React.useState(field.value || null);
  const [selectOpts, setSelectOpts] = React.useState([]);
  const [loading, setLoading] = React.useState(false);

  const handleChange = data => {
    if( field.name === 'isInterested') {
      props.setFieldValue(field.name, data.value === 2 )
    } else if(props.missionForm) {
      props.setFieldValue(field.name, {id: data.value } )
    } else if(props.isMulti && props.requiredPositions) {
      props.setFieldValue(field.name, data.reduce((acc,item) =>{
        acc.push({id: item.value})
        return acc
      },[]))
    } else {
      props.setFieldValue(field.name, data.value)
      if(props.handleChange !== undefined) {
        props.handleChange({
          field:field.name,
          value:data.value
        })
      }
    }
    setValue(data)
  }

  React.useEffect(() => {
    if (props.missionForm && field.value) {
      const match = selectOpts.find(data => data.value === field.value.id)
      if (match !== undefined) {
        setValue(match)
      } else {
        setValue(props.datas)
      }
    } else if (props.isMulti && props.requiredPositions && field.value) {
      setValue(Object.values(field.value).reduce((acc, item) => {
        acc.push({
          value: item.id,
          label: props.datas.find(data => data.value === item.id).label
        })
        return acc
      }, []))
    } else if (props.isDraftField !== undefined && field.value) {
      const match = selectOpts.find(data => data.value === field.value)
      if (match !== undefined) {
        setValue(match)
      }
    }
  }, [selectOpts]);

  const setLoadedOptions = options => {
    setLoading(false);
    setSelectOpts(options)
  }

  React.useEffect(() => {
    if(typeof props.datas === 'function') {
      if(selectOpts && selectOpts.length === 0 && !loading) {
        setLoading(true)
        props.datas()
          .then(opts => setLoadedOptions(opts))
          .catch(() => setLoadedOptions([]))
      }
    } else {
      setSelectOpts(props.datas)
    }
  }, [props.datas]);

  return(
    <div className="form-group">
      <label className="label">
        {props.label}
        {props.required && <span style={{ color: '#00b5d4', marginLeft: 8 }} className='required'>*</span>}
      </label>
      <Select
        menuPortalTarget={document.getElementById('select-2-portal')}
        isDisabled={props.disabled !== undefined ? props.disabled : false}
        placeholder={props.placeholder || 'Choisir...'}
        isSearchable={true}
        isMulti={props.isMulti !== undefined}
        value={value || field.value}
        onChange={handleChange}
        options={selectOpts}
        styles={{
          menuPortal: provided => ({ ...provided, zIndex: 999999999})
        }}
      />
    </div>
  )
};

export default SelectField
