import Axios from 'axios';
import React from 'react'
import {newConnectConfig} from '../../_services/contant';

const FileUploadMissionForm = ({field, form: { touched, errors }, ...props}) => {

  const [file, setFile] = React.useState(field.value || null);
  const fileInput = React.useRef();

  function onFileUpload(e) {
    const files = e.target.files || e.dataTransfer.files;
    if( !files )
      return;

    const file = files[0]; //this will not work.
    const formData = new FormData();
    formData.append('file', file, file.filename);
    const url = `${newConnectConfig.API_PATH}/media_objects`;
    const request =  Axios({
      method:'post',
      url,
      headers: {
        'Content-Type': 'multipart/form-data',
        'Authorization' : `Bearer ${localStorage.getItem('token')}`
      },
      data: formData
    })

    request.then(function(response){
      setFile({
        id : response.data.id,
        selectedFile : file
      });
    });
  }

  const openFilesSelector = () => {
    if(fileInput.current){
      fileInput.current.click()
    }
  }

  const removeFile = () => setFile(null)

  React.useEffect(() => {
    if( file && file.id ) {
      props.setFieldValue(field.name, {id: file.id})
    } else {
      fileInput.current.value = ''
      props.setFieldValue(field.name)
    }
  }, [file]);

  return(
    <div className="form-group upload-field" style={{}}>
      <label className="label">
        {props.label}
        {props.obligatoire && <span style={{color: '#00b5d4', marginLeft: 8}} className='required'>*</span>}
      </label>
      <button onClick={openFilesSelector} type="button" className="button-ui button-ui--blue-outline" style={{
        alignItems: 'center'
      }}>
        <input onChange={onFileUpload} type="file" id="file" multiple ref={fileInput} style={{ display:'none' }} />
        <svg width="17" height="19" viewBox="0 0 17 19" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path fillRule="evenodd" clipRule="evenodd" d="M7.49999 18.2072L7.49999 4.62137L2.20709 9.91426L0.79288 8.50005L8.49999 0.792939L16.2071 8.50005L14.7929 9.91426L9.49999 4.62137L9.49999 18.2072L7.49999 18.2072Z" fill="#00B5D4"/>
        </svg>
        Télécharger un fichier
      </button>
      { file && (file.selectedFile.name || file.selectedFile.fileName) &&
      <span className="file-name">
          {file.selectedFile.name || file.selectedFile.fileName}
          <i onClick={removeFile}>
            <svg width="22" height="25" viewBox="0 0 22 25" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M19.9575 3.125H15.464V2.34375C15.464 1.05142 14.3645 0 13.013 0H9.74505C8.39358 0 7.29405 1.05142 7.29405 2.34375V3.125H2.80055C1.67432 3.125 0.758057 4.00117 0.758057 5.07812V7.8125C0.758057 8.24395 1.12387 8.59375 1.57506 8.59375H2.02155L2.72738 22.7677C2.78973 24.0195 3.8651 25 5.17557 25H17.5825C18.893 25 19.9684 24.0195 20.0307 22.7677L20.7365 8.59375H21.183C21.6342 8.59375 22 8.24395 22 7.8125V5.07812C22 4.00117 21.0838 3.125 19.9575 3.125ZM8.92805 2.34375C8.92805 1.91299 9.29457 1.5625 9.74505 1.5625H13.013C13.4635 1.5625 13.83 1.91299 13.83 2.34375V3.125H8.92805V2.34375ZM2.39205 5.07812C2.39205 4.86274 2.57532 4.6875 2.80055 4.6875H19.9575C20.1828 4.6875 20.366 4.86274 20.366 5.07812V7.03125C20.1142 7.03125 3.43541 7.03125 2.39205 7.03125V5.07812ZM18.3985 22.6934C18.3778 23.1106 18.0193 23.4375 17.5825 23.4375H5.17557C4.73873 23.4375 4.38027 23.1106 4.35954 22.6934L3.65738 8.59375H19.1007L18.3985 22.6934Z" fill="#003D71"/>
              <path d="M11.3795 21.875C11.8307 21.875 12.1965 21.5252 12.1965 21.0938V10.9375C12.1965 10.5061 11.8307 10.1562 11.3795 10.1562C10.9283 10.1562 10.5625 10.5061 10.5625 10.9375V21.0938C10.5625 21.5252 10.9283 21.875 11.3795 21.875Z" fill="#003D71"/>
              <path d="M15.4647 21.875C15.9158 21.875 16.2816 21.5252 16.2816 21.0938V10.9375C16.2816 10.5061 15.9158 10.1562 15.4647 10.1562C15.0135 10.1562 14.6477 10.5061 14.6477 10.9375V21.0938C14.6477 21.5252 15.0135 21.875 15.4647 21.875Z" fill="#003D71"/>
              <path d="M7.29454 21.875C7.74573 21.875 8.11154 21.5252 8.11154 21.0938V10.9375C8.11154 10.5061 7.74573 10.1562 7.29454 10.1562C6.84335 10.1562 6.47754 10.5061 6.47754 10.9375V21.0938C6.47754 21.5252 6.8433 21.875 7.29454 21.875Z" fill="#003D71"/>
            </svg>
          </i>
      </span>
      }
    </div>
  )
};

export default FileUploadMissionForm
