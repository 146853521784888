import {ErrorMessage, Field, Formik} from 'formik';
import React from 'react'
import {connect} from "react-redux";
import {withRouter} from "react-router-dom";
import {bindActionCreators} from "redux";
import * as Yup from "yup";
import {profileService} from "../../../../_services/profile_service";
import ErrorMesage from "../../../../Formik/ErrorMesage";
import SelectField from "../../../../Formik/Fields/SelectField";
import {levelDatas} from '../../../../helpers/app';
import {profile_creators} from "../../../../reducers/Profile/actions/_update_profile";

const interestLevel = [
  {label: 'Neutre', value: ''},
  {label: 'Intéressé', value: 2}
]

const ExpertiseParentForm = props => {

  const [domain, setDomain] = React.useState([]);
  const [datas, setDatas] = React.useState([]);
  const [isDisabled, setIsDisabled] = React.useState(false);

  const submit = values => {
    if (props.data === null) {
      const data = {
        "user": {
          "id": props.user_id
        },
        [props.keySave]: {
          "id": values.competence
        },
        "isInterested": values.isInterested,
        "ratings": [
          {
            "rating": values.level
          }
        ]
      }
      profileService.addExpertise(props.parentKey, data).then(() => {
        props.setEditMode(false)
      })
    } else {
      profileService.updateExpertise(props.parentKey, props.data.id, {
        "isInterested": values.isInterested
      }).then(() => {
        props.setEditMode(false)
      })
    }
  }

  const schemaAdd = Yup.object().shape({
    parentExperience: Yup.string().required('Obligatoire'),
    competence: Yup.string().required('Obligatoire'),
    level: Yup.string().required('Obligatoire'),
    isInterested: Yup.string().required('Obligatoire')
  });

  React.useEffect(() => {
    if (props.parent !== null) {
      profileService.loadExperience(
        `${props.parentEndpont}`
      ).then(response => {
        setDomain(formatData(response.data['hydra:member']))
        profileService.loadExperience(
          `${props.parentEndpont}/${props.parent.id}`
        ).then(r => {
          setDatas(formatData(r.data[props.parentKey]))
        })
      })
    } else {
      profileService.loadExperience(
        `${props.parentEndpont}`
      ).then(response => {
        setDomain(formatData(response.data['hydra:member']))
      })
    }
  }, [props.parent]);

  function formatData(arr) {
    return arr.reduce((acc, item) => {
      acc.push({
        label: item.name,
        value: item.id
      })
      return acc
    }, [])
  }

  function deleteCompetence(id) {
    props.remove(`user_${props.parentKey}`, id, props.user_id)
    props.setEditMode(false)
  }

  const handle = val => {
    if (val.field === 'parentExperience') {
      setIsDisabled(true)
      profileService.loadExperience(
        `${props.parentEndpont}/${val.value}`
      ).then(response => {
        profileService.loadExperience(`${props.userExperinces}&id=${response.data.id}`).then(res => {
          const excludeIds = (res.data['hydra:member'].length > 0 && res.data['hydra:member'][0][props.parentKey]) ? res.data['hydra:member'][0][props.parentKey].reduce((acc, item) => {
            acc.push(item.id)
            return acc;
          }, []) : null

          if (excludeIds !== null) {
            setDatas(formatData(
              response.data[props.parentKey].filter(item => !excludeIds.includes(item.id))
            ))
          } else {
            setDatas(formatData(
              response.data[props.parentKey]
            ))
          }

          setIsDisabled(false)
        })

      })
    }
  }

  return (
    <div className="edit-mode">
      <div className="wrapper">
        <span className="title">
          {props.data === null ? 'Nouvelle compétence' : 'Modifier une compétence'}
        </span>
        <Formik
          onSubmit={submit}
          initialValues={
            props.data !== null ? {
              "parentExperience": {
                label: props.parent.name,
                value: props.parent.id
              },
              "competence": props.parent[props.parentKey].reduce((competences, skills) => {
                const match = skills.userExperiences.find(exp => exp.id === props.data.id)
                if (match !== undefined) {
                  competences.push({
                    label: skills.name,
                    value: match.id
                  })
                }
                return competences;
              }, []),
              "level": '',
              "isInterested": {
                value: props.data.isInterested ? 2 : 1,
                label: props.data.isInterested ? 'Intéressé' : 'Neutre'
              }
            } : {
              "parentExperience": '',
              "competence": '',
              "level": '',
              "isInterested": ''
            }
          }
          validationSchema={props.data === null ? schemaAdd : {}}
        >
          {(
            {handleSubmit, setFieldValue, handleChange}) => (
            <form onSubmit={handleSubmit}>
              <div className="flex">
                <div>
                  <Field
                    setFieldValue={setFieldValue}
                    handleChange={handle}
                    datas={domain}
                    name="parentExperience"
                    placeholder=""
                    label="Domaine"
                    disabled={props.data !== null}
                    required component={SelectField}
                  />
                  <ErrorMessage name="parentExperience" component={ErrorMesage}/>
                </div>
                <div>
                  <Field
                    disabled={isDisabled || props.data !== null}
                    setFieldValue={setFieldValue}
                    datas={datas}
                    name="competence"
                    placeholder=""
                    label="Compétence"
                    required component={SelectField}
                  />
                  <ErrorMessage name="competence" component={ErrorMesage}/>
                </div>
                {props.data === null &&
                <div>
                  <Field
                    setFieldValue={setFieldValue}
                    datas={props.labelTooltipContent ? props.labelTooltipContent.reduce((acc,item)=>{
                      acc.push({
                        label:item.label,
                        value:item.rating
                      })
                      return acc;
                    },[]) : levelDatas}
                    name="level"
                    placeholder=""
                    label="Niveau"
                    required
                    component={SelectField}
                  />
                  <ErrorMessage name="level" component={ErrorMesage}/>
                </div>
                }
                <div>
                  <Field
                    setFieldValue={setFieldValue}
                    datas={interestLevel}
                    name="isInterested"
                    placeholder=""
                    label="Intérêt"
                    required
                    component={SelectField}
                  />
                  <ErrorMessage name="isInterested" component={ErrorMesage}/>
                </div>
              </div>
              <div className="actions">
                <button className="button" type="submit">Valider</button>
                <button onClick={() => props.setEditMode(false)} className="button outline" type="button">Annuler
                </button>
                {props.data &&
                <button style={{borderColor: '#F44336', color: '#F44336'}}
                        onClick={() => deleteCompetence(props.data.id)} className="button outline"
                        type="button">Supprimer</button>
                }
              </div>
            </form>
          )}
        </Formik>
      </div>
    </div>
  )
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({
    remove: profile_creators.deleteCompetence
  }, dispatch);
}

export default withRouter(
  connect(null, mapDispatchToProps)(ExpertiseParentForm)
)
