import React, { useEffect, useState } from 'react'
import Axios from 'axios'
import { newConnectConfig } from '../../_services/contant'
import {withRouter, Link} from 'react-router-dom'
import {makeStyles} from '@material-ui/core/styles'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import Paper from '@material-ui/core/Paper'
import {hasCap} from '../../helpers/app'
import {connect} from 'react-redux'

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
    marginTop: theme.spacing(3),
    overflowX: 'auto'
  },
  table: {
    minWidth: 650
  }
}))

function getBck (status) {
  switch (status) {
    case 'En recherche':
      return '#003d71'
    case 'Pourvu':
      return '#7ed321'
    case 'En attente de validation':
      return '#ff7500'
    case 'Pré-affecté':
      return 'rgb(242, 221, 25)'
    case 'Attente retour client':
      return 'rgb(255, 163, 0)'
    case 'Annulé':
      return '#F33F3F'
    default:
      return '#6c757d'
  }
}

function CrDetail (props) {
  const classes = useStyles()
  const [datas, setData] = useState(null)

  useEffect(() => {
    Axios.get(
      `${newConnectConfig.API_PATH}/c_rs/details/${props.match.params.id}`,
      {
        headers: { Authorization: `Bearer ${localStorage.getItem('token')}` }
      }
    ).then(response => {
      setData(response.data)
    })
  }, [])

  return datas === null ? (
    <div className='app_loader'>Chargement...</div>
  ) : (
    <div className='container-full p-5 relative'>
      <h1
        className='title-page'
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center'
        }}
      >
        <span>
          Compte-rendu de réunion de staffing
          {(datas.publish || hasCap('ADMIN' +
            '', props.role)) && (
            <em
              style={{
                display: 'block',
                fontSize: '15px',
                marginTop: '10px',
                color: '#00b5d4'
              }}
            >
              {datas.title}
            </em>
          )}
        </span>
      </h1>

      <Link
        className='button-return button-return-dark mb-2 mb-md-0'
        to='/cr_matching/list'
      >
        <span className='button-return__icon'>
          <i className='fas fa-arrow-left' />
        </span>
        <span>retour</span>
      </Link>

      {datas.publish === true || hasCap('ADMIN', props.role) ? (
        <Paper className={classes.root}>
          <Table className={classes.table}>
            <TableHead>
              <TableRow>
                <TableCell>Nom du besoin</TableCell>
                <TableCell align='center' style={{ textAlign: 'center' }}>
                  Statut avant action
                </TableCell>
                <TableCell align='center' style={{ textAlign: 'center' }}>
                  Consultant(s) positionné(s)
                </TableCell>
                <TableCell align='center' style={{ textAlign: 'center' }}>
                  Action pendant la réunion de staffing
                </TableCell>
                <TableCell align='center' style={{ textAlign: 'center' }}>
                  Statut à date
                </TableCell>
                <TableCell align='center' style={{ textAlign: 'center' }}>
                  Consultant(s) positionné(s) à date
                </TableCell>
                <TableCell align='center' style={{ textAlign: 'center' }}>
                  Commentaire{' '}
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {props.isLoading && (
                <span
                  style={{
                    width: '100%',
                    display: 'block',
                    textAlign: 'center',
                    padding: 15
                  }}
                >
                  Chargement des données...
                </span>
              )}

              {datas.cr &&
                datas.cr.map((row, index) => (
                  <TableRow key={index}>
                    <TableCell align='center' style={{ minWidth: '250px' }}>
                      {row.besoin.title}
                      {row.besoin.leader !== '' && (
                        <span
                          style={{
                            fontWeight: 'bold',
                            fontSize: '12px',
                            textTransform: 'uppercase',
                            marginLeft: '20px',
                            display: 'block'
                          }}
                        >
                          <i className='far fa-user' /> Chef de projet :{' '}
                          {row.besoin.leader}
                        </span>
                      )}
                      {row.besoin.client !== '' && (
                        <span
                          style={{
                            fontWeight: 'bold',
                            fontSize: '12px',
                            textTransform: 'uppercase',
                            marginLeft: '20px',
                            display: 'block'
                          }}
                        >
                          <i className='far fa-user' /> Client :{' '}
                          {row.besoin.client}
                        </span>
                      )}
                    </TableCell>
                    <TableCell align='center'>
                      <div
                        className='button'
                        style={{
                          padding: 6,
                          width: '100%',
                          color: '#fff',
                          textAlign: 'center',
                          background: getBck(row.statusBefore),
                          borderRadius: '6px',
                          // minWidth: '190px',
                          fontSize: '12px'
                        }}
                      >
                        {row.statusBefore}
                      </div>
                    </TableCell>
                    <TableCell align='center'>
                      {row.consultantsBefore.map(c => (
                        <p>{c.fullname}</p>
                      ))}
                    </TableCell>
                    <TableCell align='center'>
                      {' '}
                      {row.action && row.action}{' '}
                    </TableCell>
                    <TableCell align='center'>
                      <div
                        className='button'
                        style={{
                          padding: 6,
                          width: '100%',
                          color: '#fff',
                          textAlign: 'center',
                          background: getBck(row.statusAfter),
                          borderRadius: '6px',
                          // minWidth: '190px',
                          fontSize: '12px'
                        }}
                      >
                        {' '}
                        {row.statusAfter}{' '}
                      </div>
                    </TableCell>
                    <TableCell align='center'>
                      {row.consultantsAfter &&
                        row.consultantsAfter.map(c => <p>{c.fullname}</p>)}
                    </TableCell>
                    <TableCell align='center'>
                      <div
                        style={{
                          display: 'flex',
                          flexFlow: 'column',
                          justifyContent: 'space-between',
                          miHheight: 75
                        }}
                      />
                      {row.comment}
                    </TableCell>
                  </TableRow>
                ))}
            </TableBody>
          </Table>
        </Paper>
      ) : (
        !props.isLoading && (
          <p>
            Le compte rendu de réunion n'est toujours pas publié, merci de
            revenir plus tard.
          </p>
        )
      )}
    </div>
  )
}

const mapStateToProps = state => ({
  role: state.Role.role,
  data: state.cr.data,
  isLoading: state.cr.isLoading
})

export default withRouter(
  connect(
    mapStateToProps,
    {}
  )(CrDetail)
)
