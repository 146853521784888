import React from 'react'
import { Formik, Field, ErrorMessage } from 'formik';
import * as Yup from "yup";
import SelectField from "../../../../Formik/Fields/SelectField";
import {withRouter} from "react-router-dom";
import {connect} from "react-redux";
import ErrorMesage from "../../../../Formik/ErrorMesage";
import {profileService} from "../../../../_services/profile_service";
import {bindActionCreators} from "redux";
import {levelDatas} from '../../../../helpers/app';
import {_getUserProfile} from "../../../../reducers/App/actions/user";
import {profile_creators} from "../../../../reducers/Profile/actions/_update_profile";

const interestLevel = [
  {label:'Neutre', value: ''},
  {label:'Intéressé', value: 2}
]

const LanguageForm = props => {

  const [datas, setDatas] = React.useState(null);

  const submit = (values) => {
    if( props.data === null ) {
      const data = {
        "user": {
          "id": props.user_id
        },
        "language": {
          "id": values.competence
        },
        "isInterested": values.isInterested,
        "ratings": [
          {
            "rating": values.level
          }
        ]
      }
      profileService.addExpertise('languages',data).then( response =>{
        props.setEditMode(false)
      })
    } else {
      profileService.updateExpertise('languages', props.data.id, {
        "isInterested": values.isInterested
      }).then( response =>{
        props.setEditMode(false)
      })
    }
  }

  const schema = Yup.object().shape({
    competence: Yup.string().required('Obligatoire'),
    level: Yup.string().required('Obligatoire'),
    isInterested: Yup.string().required('Obligatoire')
  });

  React.useEffect(() => {

    if( props.data === null ) {
      const disabledIds = props.currentDatas.reduce((acc,item)=>{
        acc.push(item.experience.id)
        return acc
      },[])

      setDatas( props.select_data.language.filter( item => !disabledIds.includes(item.value) ) )
    } else {
      setDatas( props.select_data.language.filter( item => item.value === props.data.experience.id ) )
    }
  }, [props.currentDatas]);

  function deleteCompetence(id) {
    props.remove('user_languages',id, props.user_id)
    props.setEditMode(false)
  }

  return (
    datas &&
    <div className="edit-mode">
      <div className="wrapper">
        <span className="title">
          { props.data === null ? 'Nouvelle langue' : 'Modifier une langue'}
        </span>
        <Formik
          onSubmit={submit}
          initialValues={
            props.data !== null ? {
              "competence": props.data === null ? '' : { label:datas[0].label, value:datas[0].value},
              "level": props.data === null ? '' : levelDatas.find( data => data.value === props.data.rating ),
              "isInterested": props.data === null ? '' : {value: props.data.isInterested ? 2 : 1, label: props.data.isInterested ? 'Intéressé' : 'Neutre' }
            } : {
              "competence": props.data === null ? '' : { label:datas[0].label, value:datas[0].value},
              "level": '',
              "isInterested": props.data === null ? '' : {value: props.data.isInterested ? 2 : 1, label: props.data.isInterested ? 'Intéressé' : 'Neutre' }
            }
          }
          validationSchema={props.data === null ? schema : {}}
        >
          {(
            { handleSubmit, setFieldValue}) => (
            <form onSubmit={ handleSubmit }>
              <div className="flex">
                <div>
                  <Field setFieldValue={setFieldValue} datas={datas} name="competence" placeholder="" label="Compétence" required component={SelectField} />
                  <ErrorMessage name="competence" component={ErrorMesage} />
                </div>
                {props.data === null &&
                <div>
                  <Field
                    setFieldValue={setFieldValue}
                    datas={props.labelTooltipContent ? props.labelTooltipContent.reduce((acc,item)=>{
                    acc.push({
                      label:item.label,
                      value:item.rating
                    })
                    return acc;
                  },[]) : levelDatas}
                    name="level" placeholder="" label="Niveau" required component={SelectField}/>
                  <ErrorMessage name="level" component={ErrorMesage}/>
                </div>
                }
                <div>
                  <Field setFieldValue={setFieldValue} datas={interestLevel} name="isInterested" placeholder="" label="Intérêt" required component={SelectField} />
                  <ErrorMessage name="isInterested" component={ErrorMesage} />
                </div>
              </div>
              <div className="actions">
                <button className="button fullw" type="submit">Valider</button>
                <button onClick={ () => props.setEditMode(false) } className="button outline fullw" type="button">Annuler</button>
                {props.data &&
                <button style={{borderColor: '#F44336', color: '#F44336'}}
                        onClick={() => deleteCompetence(props.data.id)} className="button outline fullw"
                        type="button">Supprimer</button>
                }
              </div>
            </form>
          )}
        </Formik>
      </div>
    </div>
  )
}

const mapStateToProps = (state) => ({
  select_data : state.SelectData
})

function mapDispatchToProps(dispatch){
  return bindActionCreators({
    getUserProfile:_getUserProfile,
    remove: profile_creators.deleteCompetence
  },dispatch);
}


export default withRouter(
  connect(mapStateToProps, mapDispatchToProps )(LanguageForm)
)
