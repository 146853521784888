import {newConnectConfig} from "./contant";
import Axios from "axios";

export const developpmentService = {
  createMissionAssessments,
  createVivaAssessments,
  fetch_missions_feedback,
  fetch_deliverables_feedback,
  fetch_propale_feedback,
  fetch_soutenance_feedback,
  fetch_actions,
  fetch_propale,
  fetch_deliverable,
  loadNavigation,
  searchFeedbacks,
  createAction,
  updateAction,
  addCommentAction,
  archived,
  createExterneExchange,
  fetchExterneExchange
};

function archived(id) {
  const url =  `${newConnectConfig.API_PATH}/actions/${id}/archive`;
  return Axios({
    method:'GET',
    url,
    headers: {"Authorization" : `Bearer ${localStorage.getItem('token')}`}
  });
}

function createAction(data) {
  const url =  `${newConnectConfig.API_PATH}/actions`;
  return Axios({
    method:'POST',
    url,
    headers: {"Authorization" : `Bearer ${localStorage.getItem('token')}`},
    data
  });
}

function createExterneExchange(data) {
  const url =  `${newConnectConfig.API_PATH}/exchanges`;
  return Axios({
    method:'POST',
    url,
    headers: {"Authorization" : `Bearer ${localStorage.getItem('token')}`},
    data
  });
}

function addCommentAction(data) {
  const url =  `${newConnectConfig.API_PATH}/action_commentaries`;
  return Axios({
    method:'POST',
    url,
    headers: {"Authorization" : `Bearer ${localStorage.getItem('token')}`},
    data
  });
}

function updateAction(data, id) {
  const url =  `${newConnectConfig.API_PATH}/actions/${id}`;
  return Axios({
    method:'PUT',
    url,
    headers: {"Authorization" : `Bearer ${localStorage.getItem('token')}`},
    data
  });
}

function createMissionAssessments(data) {
  const url =  `${newConnectConfig.API_PATH}/mission_assessments`;
  return Axios({
    method:'POST',
    url,
    headers: {"Authorization" : `Bearer ${localStorage.getItem('token')}`},
    data
  });
}

function createVivaAssessments(data) {
  const url =  `${newConnectConfig.API_PATH}/viva_assessments`;
  return Axios({
    method:'POST',
    url,
    headers: {"Authorization" : `Bearer ${localStorage.getItem('token')}`},
    data
  });
}

function fetchExterneExchange(filters) {
  const url =  `${newConnectConfig.API_PATH}/exchanges${filters}`;
  return Axios({
    method:'GET',
    url,
    headers: {"Authorization" : `Bearer ${localStorage.getItem('token')}`}
  });
}

function fetch_missions_feedback(filters) {
  const url =  `${newConnectConfig.API_PATH}/mission_assessments${filters}`;
  return Axios({
    method:'GET',
    url,
    headers: {"Authorization" : `Bearer ${localStorage.getItem('token')}`}
  });
}

function fetch_propale(filters) {
  const url =  `${newConnectConfig.API_PATH}/commercial_propositions/my_development${filters}`;
  return Axios({
    method:'GET',
    url,
    headers: {"Authorization" : `Bearer ${localStorage.getItem('token')}`}
  });
}

function fetch_deliverable(filters) {
  const url =  `${newConnectConfig.API_PATH}/deliverables/my_development${filters}`;
  return Axios({
    method:'GET',
    url,
    headers: {"Authorization" : `Bearer ${localStorage.getItem('token')}`}
  });
}

function fetch_actions(filters) {
  const url =  `${newConnectConfig.API_PATH}/actions${filters}`;
  return Axios({
    method:'GET',
    url,
    headers: {"Authorization" : `Bearer ${localStorage.getItem('token')}`}
  });
}

function fetch_deliverables_feedback(filter) {
  const url =  `${newConnectConfig.API_PATH}/deliverable_assessments${filter}`;
  return Axios({
    method:'GET',
    url,
    headers: {"Authorization" : `Bearer ${localStorage.getItem('token')}`}
  });
}

function fetch_propale_feedback(filter) {
  const url =  `${newConnectConfig.API_PATH}/commercial_proposition_assessments${filter}`;
  return Axios({
    method:'GET',
    url,
    headers: {"Authorization" : `Bearer ${localStorage.getItem('token')}`}
  });
}

function fetch_soutenance_feedback(filter) {
  const url =  `${newConnectConfig.API_PATH}/viva_assessments${filter}`;
  return Axios({
    method:'GET',
    url,
    headers: {"Authorization" : `Bearer ${localStorage.getItem('token')}`}
  });
}

function loadNavigation(endpoint) {
  const url =  `${newConnectConfig.API_HOST}${endpoint}`;

  return Axios({
    method:'GET',
    url,
    headers: {"Authorization" : `Bearer ${localStorage.getItem('token')}`}
  });
}

function searchFeedbacks(userId, term) {
  const url =  `${newConnectConfig.API_PATH}/users/${userId}/feedback_for_action?term=${term}`;
  return Axios({
    method:'GET',
    url,
    headers: {"Authorization" : `Bearer ${localStorage.getItem('token')}`}
  });
}
