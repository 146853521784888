import * as _ from 'lodash'
import initialState from './initialState'

function role(state = initialState, action) {

    if (_.isUndefined(action)) {
        return state
    }

    const { type, payload } = action;

    switch (type) {
      case 'SET_ROLE':
        return {
          role : payload.role.name,
          pofile_pic: payload.profilePhoto ? payload.profilePhoto.contentUrl : '',
          signedUp: payload.signedUp ? payload.signedUp : null,
          email: payload.email ? payload.email : '',
          fullName: payload.firstName + ' ' + payload.lastName,
          notificationEmail: payload.emailNotifiaction,
          isManagementController: payload.isManagementController ? payload.isManagementController : false,
        };
      case 'SET_PROFILE_PIC':
        return {
            ...state,
            pofile_pic : payload
        }
      default:
        return state
    }

}

export default role
