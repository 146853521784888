import { userCircleService } from '../../_services/userCircleService';
import { HYDRA_MEMBER } from '../hydra';

export const userCircleCreators = {
    get_user_circles_list,
}

function get_user_circles_list(userId = null) {
    return (dispatch) => {
        userCircleService.get_user_circles(userId)
            .then(response => {
                return dispatch({
                    type: 'GET_USER_CIRCLES',
                    payLoad: response.data[HYDRA_MEMBER]
                });
            })
    }
}
