export const downloadFile = url => {
    const downloadLink = document.createElement('a')
    downloadLink.setAttribute('href', url)
    downloadLink.setAttribute('target', '_blank')
    downloadLink.setAttribute('id', 'download-action')
    downloadLink.setAttribute('download', true)
    document.querySelector('body').insertAdjacentElement('afterbegin', downloadLink)
    downloadLink.click()
    document.getElementById('download-action').remove()
}
