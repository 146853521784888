import React, {Component} from 'react';
import equal from 'fast-deep-equal'
import {bindActionCreators} from 'redux';
import {ToastContainer} from "react-toastify";
import {connect} from 'react-redux'

import Router from '../../lib/router/router';
import {routes} from '../../routes';
import {profile_creators} from '../../reducers/Profile/actions/_update_profile';
import {notification_actions} from '../../reducers/Notifications/actions';
import getCurrentFiscalYearAction from '../../reducers/App/actions/getCurrentFiscalYearAction';

import "react-toastify/dist/ReactToastify.css";

const selectData = [
    'skill',
    'solution',
    'core_skill',
    'field',
    'language',
    'position',
    'etp',
    'invoicing_mode',
    'prioritie',
    'role',
    'workplace',
    'plage',
    'circle'
];

class App extends Component {
    componentDidMount() {

        if (!localStorage.getItem('token')) {
            return
        }

        this.props.updateRole(localStorage.getItem('user_id'));
        for (let index = 0; index < selectData.length; index++) {
            this.props.updateSelectData(selectData[index])
        }

        this.props.getCurrentFiscalYear();
    }

    componentDidUpdate(prevProps) {
        if (!equal(this.props.role, prevProps.role)) {
            if (this.props.role !== "EXTERNE") {
                this.props.new_notification()
                this.intervalNorif = setInterval(this.loadData.bind(this), 60000);
            }
        }
    }

    async loadData() {
        try {
            await this.props.new_notification();
        } catch (e) {

        }
    }

    render() {
        return (
            <React.Fragment>
                <ToastContainer autoClose={2000}/>
                <Router routes={routes}/>
                <div id="select-2-portal"/>
            </React.Fragment>
        )
    }
}

const mapStateToProps = (state) => ({
    profile: state.Profile.profile,
    select_data: state.SelectData,
    notifications: state.Notifications.notifications,
    hasNotif: state.App.hasNotif,
    role: state.Role.role
})

const mapDispatchToProps = (dispatch) => {
    return bindActionCreators({
        updateSelectData: profile_creators.update_select_data,
        updateRole: profile_creators.updateRole,
        // get_notifications:  notification_actions.get_notifications,
        new_notification: notification_actions.notificationCheck,
        getCurrentFiscalYear: getCurrentFiscalYearAction,
    }, dispatch)
}


export default connect(mapStateToProps, mapDispatchToProps)(App)
