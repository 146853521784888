import React, {useEffect, useRef, useState} from 'react'
import moment from 'moment'
import cx from 'classnames/bind'
import {withRouter} from "react-router";
import {hasCap} from "../../../helpers/app";

const ParcoursLine = props => {
  const [moreText, setMoreText] = useState(false);
  const [longText, setLongText] = useState(false);
  const description = useRef()
  const [canEdit, setCanEdit] = React.useState(false);


  useEffect(() => {

    if( description.current ) {
      setLongText(description.current.offsetHeight > 120)
    }

    if( props.match.path === '/my-profile' || hasCap('MANAGER', props.role )) {
      setCanEdit(true)
    }

    return () => {
    };
  }, []);

  return (
      <div className='row'>
          <div className="left col-md-5">
            {props.data.isFromCrh &&
            <svg style={{position: 'absolute', left: -30}} width="24" height="24" viewBox="0 0 24 24" fill="none"
                 xmlns="http://www.w3.org/2000/svg">
              <path fillRule="evenodd" clipRule="evenodd"
                    d="M22.0163 0.00021492H1.9837C0.914186 -0.0154712 0.0304455 0.829438 0 1.89675V22.0893C0.0229212 23.1621 0.908876 24.0154 1.9837 23.9998H22.0163C23.0911 24.0154 23.9771 23.1621 24 22.0893V1.89675C23.9696 0.829438 23.0858 -0.0154712 22.0163 0.00021492ZM12.7036 2.95664C12.7275 3.89778 12.5645 4.83427 12.2241 5.71131L11.7309 7.2126L8.47058 10.3529L11.909 1.88232C12.3408 2.07822 12.6413 2.4845 12.7036 2.95664ZM7.88637 4.56475C7.57809 4.09922 7.10417 3.80716 6.58819 3.76475L6.60118 3.7793C6.71368 4.50657 6.7959 5.09324 6.84783 5.5393C7.06629 7.60538 7.17467 9.68422 7.17237 11.7648L8.47055 8.27385V6.8193C8.42973 6.0281 8.23004 5.25743 7.88637 4.56475ZM15.4219 21.0874C14.2288 21.9484 12.7468 22.2897 11.3049 22.0355C9.96824 21.8249 8.77387 21.0696 7.99767 19.9441C7.19917 18.8429 6.89064 17.4525 7.14684 16.1097C7.36459 14.7486 8.1239 13.5385 9.24647 12.7634C10.4507 11.9289 11.9256 11.5992 13.3634 11.8432C14.1058 11.9897 14.8109 12.2886 15.4356 12.7216C16.1656 13.2738 16.7424 14.0088 17.1098 14.8549C16.3027 15.2269 15.3598 15.1198 14.6534 14.576C13.4752 13.6162 11.8347 13.4995 10.5364 14.2832C9.80665 14.7755 9.30991 15.5528 9.16413 16.4304C9.00374 17.2904 9.20259 18.1794 9.71306 18.8844C10.2008 19.6291 10.9713 20.1342 11.8401 20.2787C12.6972 20.4524 13.586 20.233 14.2691 19.6791C14.6196 19.4127 14.9176 19.0816 15.1474 18.7031C15.5849 17.8813 16.5342 17.4896 17.4117 17.7689C17.1994 19.0967 16.4917 20.2902 15.4356 21.1013L15.4219 21.0874ZM8.94116 11.2942C10.1902 10.6601 11.6025 10.4077 12.9977 10.5691L16.1638 7.43186C17.3935 6.10724 17.9589 5.14515 17.874 4.48981C17.832 4.22419 17.7199 3.97416 17.549 3.76475L8.94116 11.2942Z"
                    fill="#00B5D4"/>
            </svg>
            }
            <h2>{props.data.position || props.data.name}</h2>
            <h4>{props.data.company || props.data.university}</h4>
            <ul>
              <li>
                <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path fillRule="evenodd" clipRule="evenodd" d="M12.6667 2.00008H12V0.666748H10.6667V2.00008H5.33333V0.666748H4V2.00008H3.33333C2.59333 2.00008 2.00667 2.60008 2.00667 3.33341L2 12.6667C2 13.4001 2.59333 14.0001 3.33333 14.0001H12.6667C13.4 14.0001 14 13.4001 14 12.6667V3.33341C14 2.60008 13.4 2.00008 12.6667 2.00008ZM12.6667 12.6667H3.33333V5.33341H12.6667V12.6667ZM4.66667 6.66675H8V10.0001H4.66667V6.66675Z" fill="#99999B"/>
                </svg>
                <span>
                  { moment(props.data.startDate).format('MMM YYYY') } - { props.data.finishDate === null ? "aujourd'hui" : moment(props.data.finishDate).format('MMM YYYY') }
                </span>
              </li>
              { props.data.city !== null &&
              <li>
                <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path fillRule="evenodd" clipRule="evenodd" d="M8 1.06665C5.34628 1.06665 3.2 3.23678 3.2 5.91998C3.2 9.55998 8 14.9333 8 14.9333C8 14.9333 12.8 9.55998 12.8 5.91998C12.8 3.23678 10.6537 1.06665 8 1.06665ZM8 7.46665C7.1168 7.46665 6.4 6.74985 6.4 5.86665C6.4 4.98345 7.1168 4.26665 8 4.26665C8.8832 4.26665 9.6 4.98345 9.6 5.86665C9.6 6.74985 8.8832 7.46665 8 7.46665Z" fill="#99999B"/>
                </svg>
                <span>{props.data.city}</span>
              </li> }
            </ul>
          </div>
          <div className={cx({
            "right col-md-7": true,
            text_displayed: moreText
          })}>
            {
              props.data.description &&
              <>
              <p
                className={cx({
                  longText
                })}
                ref={description}
                dangerouslySetInnerHTML={{__html: props.data.description}}
              />
              { longText &&
              <div onClick={() => setMoreText(!moreText)} style={{ display: 'flex', justifyContent: 'center', cursor: 'pointer', marginTop: 8 }}>
                <svg width="16" height="4" viewBox="0 0 16 4" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path fillRule="evenodd" clipRule="evenodd" d="M2 0C0.9 0 0 0.9 0 2C0 3.1 0.9 4 2 4C3.1 4 4 3.1 4 2C4 0.9 3.1 0 2 0ZM14 0C12.9 0 12 0.9 12 2C12 3.1 12.9 4 14 4C15.1 4 16 3.1 16 2C16 0.9 15.1 0 14 0ZM8 0C6.9 0 6 0.9 6 2C6 3.1 6.9 4 8 4C9.1 4 10 3.1 10 2C10 0.9 9.1 0 8 0Z" fill="#00B5D4"/>
                </svg>
              </div> }
              </>
            }
            {canEdit &&
            <div className="edit" onClick={() => props.onEdit(props.data)}>
              <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <rect width="24" height="24" rx="12" fill="#00B5D4"/>
                <path fillRule="evenodd" clipRule="evenodd"
                      d="M14.4356 4.93644C15.1842 4.18785 16.4033 4.18785 17.1733 4.93644L18.5636 6.32669C19.3121 7.07528 19.3121 8.29442 18.5636 9.0644L9.55905 18.0689C9.19545 18.4325 8.70352 18.625 8.1902 18.625H5.51665C5.15305 18.625 4.875 18.347 4.875 17.9833V15.3098C4.875 14.7965 5.08888 14.3046 5.43109 13.9409L14.4356 4.93644ZM16.2536 5.85615C15.9969 5.59949 15.5906 5.59949 15.3339 5.85615L14.1148 7.07528L16.4247 9.38523L17.6439 8.16609C17.9005 7.90943 17.9005 7.50305 17.6439 7.24639L16.2536 5.85615ZM15.5264 10.2622L13.2165 7.95221L6.32941 14.8393C6.20108 14.9676 6.13691 15.1173 6.13691 15.2884V17.3203H8.16881C8.33991 17.3203 8.51102 17.2561 8.61796 17.1278L15.5264 10.2622Z"
                      fill="white"/>
              </svg>
            </div>
            }
          </div>
      </div>
  )
}

export default withRouter(ParcoursLine)
