import React from 'react'
import cx from 'classnames/bind'

const TabsSwitcher = ({tabs, onChange, active}) => {
  return (
    <div className="mission-tabs flex">
      {tabs.map(tab => <span key={tab.key} className={cx({
        "t-label-medium": true,
        "u-color-primary": true,
        "mission-tabs__tab" : true,
        "mission-tabs__tab--active" : active === tab.key
      })} onClick={() => onChange(tab.key)}>{tab.label}</span>)}
    </div>
  )
}

export default TabsSwitcher
