
import { newConnectConfig } from "./contant";
import Axios from "axios";

export const consultant_service = {
    get_lists,
  getList,
    get_lists_externe,
    exportSearch,
    search,
    getConsultant,
    getExperts,
    createExpert,
    deleteExpert,
    searchOnlyRefereeOrRefereeRh,
    getUsers
};

function deleteExpert(id) {
  const url =  `${newConnectConfig.API_PATH}${newConnectConfig.USER_ENDPOINT}/${id}`;
  return Axios({
    method:'delete',
    url,
    headers: {"Authorization" : `Bearer ${localStorage.getItem('token')}`},
  });
}

function createExpert(data) {
  const url =  `${newConnectConfig.API_PATH}${newConnectConfig.USER_ENDPOINT}/create`;
  return Axios({
    method:'post',
    url,
    headers: {"Authorization" : `Bearer ${localStorage.getItem('token')}`},
    data
  });
}

function getExperts(filters) {
  const url =  `${newConnectConfig.API_PATH}${newConnectConfig.USER_ENDPOINT}?externe=1&${filters}`;
  return Axios({
    method:'get',
    url,
    headers: {"Authorization" : `Bearer ${localStorage.getItem('token')}`}
  });
}

function getConsultant(id) {
  const url =  `${newConnectConfig.API_PATH}${newConnectConfig.USER_ENDPOINT}/${id}`;
  return Axios({
    method:'get',
    url,
    headers: {"Authorization" : `Bearer ${localStorage.getItem('token')}`}
  });
}

function searchOnlyRefereeOrRefereeRh(term, dataTypeFilter = 'referent') {
  const url =  `${newConnectConfig.API_PATH}${newConnectConfig.USER_ENDPOINT}?${dataTypeFilter}=1&term=${term}`;
  return Axios({
    method:'get',
    url,
    headers: {"Authorization" : `Bearer ${localStorage.getItem('token')}`}
  });
}

function getUsers(term) {
  const url =  `${newConnectConfig.API_PATH}${newConnectConfig.USER_ENDPOINT}?${term}`;
  return Axios({
    method:'get',
    url,
    headers: {"Authorization" : `Bearer ${localStorage.getItem('token')}`}
  });
}

function search(value, role= 'CONSULTANT_CRH', externe = true) {
  const url =  externe ? `${newConnectConfig.API_PATH}${newConnectConfig.USER_ENDPOINT}/granted?role=${role}&term=${value}` : `${newConnectConfig.API_PATH}${newConnectConfig.USER_ENDPOINT}/granted?role=${role}&term=${value}&externe=0`;
  return Axios({
    method:'get',
    url,
    headers: {"Authorization" : `Bearer ${localStorage.getItem('token')}`}
  });
}

function exportSearch(data) {
  const url =  `${newConnectConfig.API_PATH}${newConnectConfig.USER_ENDPOINT}/export_external${data}`;
  return Axios({
    method:'get',
    url,
    headers: {"Authorization" : `Bearer ${localStorage.getItem('token')}`}
  });
}

function get_lists_externe( url_next = null, filter = false) {
  let url = ''
  if( !filter ) {
    url = (url_next === null) ? `${newConnectConfig.API_PATH}${newConnectConfig.USER_ENDPOINT}?externe=1&actif=1` : `${newConnectConfig.API_HOST}${url_next}`;
  } else {
    if( url_next ) {
      url =  `${newConnectConfig.API_PATH}${newConnectConfig.USER_ENDPOINT}${url_next}&externe=1`;
    } else {
      url =  `${newConnectConfig.API_PATH}${newConnectConfig.USER_ENDPOINT}?externe=1`;
    }
  }
  return Axios({
    method:'get',
    url,
    headers: {"Authorization" : `Bearer ${localStorage.getItem('token')}`}
  });

}

function get_lists( url_next = null, filter = false) {
    let url = ''
    if( !filter ) {
      url = (url_next === null) ? `${newConnectConfig.API_PATH}${newConnectConfig.USER_ENDPOINT}` : `${newConnectConfig.API_HOST}${url_next}`;
    } else {
      url =  `${newConnectConfig.API_PATH}${newConnectConfig.USER_ENDPOINT}${url_next}`;
    }
    return Axios({
      method:'get',
      url,
      headers: {"Authorization" : `Bearer ${localStorage.getItem('token')}`}
    });
}

function getList(filters) {
  const  url =  `${newConnectConfig.API_PATH}${newConnectConfig.USER_ENDPOINT}?${filters}`;

  return Axios({
    method:'get',
    url,
    headers: {"Authorization" : `Bearer ${localStorage.getItem('token')}`}
  });
}

