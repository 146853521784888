import React from 'react'

export default function useIsLongText(deps, element, size = 80) {
  const [isLong, setIsLong] = React.useState(false);

  React.useEffect(() => {
    if( element.current ) {
      setIsLong(element.current.offsetHeight > size)
    }
  }, [deps]);

  return isLong;
}
