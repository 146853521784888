import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { circleCreators } from '../../../reducers/Circle/actions';
import { HYDRA_MEMBER } from '../../../reducers/hydra';
import { circleService } from '../../../_services/circleService';
import CircleSelect from './CircleSelect';
import loadingGif from '../../../assets/images/processing.gif';

function AddUserCircleComponent(props) {
  const [isSelectActive, toggleSelect] = React.useState(false);
  const [circles, setCircles] = React.useState([]);

  React.useEffect(() => {
    if (isSelectActive) {
      deactivateAdd();
    }
  }, [props.userCircles]);

  const activateAdd = () => {
    circleService.get_circles()
      .then(response => {
        toggleSelect(true);
        const fecthedCircles = response.data[HYDRA_MEMBER];
        const notExistantCircles = [];

        fecthedCircles.forEach(circle => {
          const userCircle = props.userCircles.find(item => item.circle.id === circle.id);
          if (!userCircle) {
            notExistantCircles.push(circle);
          }
        });

        setCircles(notExistantCircles);
      });
  }

  const deactivateAdd = () => {
    toggleSelect(false);
    setCircles([]);
  }

  const addUserCircle = (data) => {
    deactivateAdd();
    props.submitCircle(data);
  }

  return (
    <>
      {
        !isSelectActive &&
        <button className="button button-ui mt-4" style={{color: 'white'}} onClick={activateAdd}>
          <svg style={{ marginRight: 13 }} width="14" height="14" viewBox="0 0 14 14" fill="none"
            xmlns="http://www.w3.org/2000/svg">
            <path d="M8 0H6V6H0V8H6V14H8V8H14V6H8V0Z" fill="white" />
          </svg>
          Ajouter un cercle
        </button>
      }
      {
        isSelectActive &&
        (circles.length > 0 ?
          <CircleSelect
            circles={circles}
            required={true}
            circleLabel="Ajouter un cercle"
            subCircleLabel="Ajouter un sous-cercle"
            isEditAction={false}
            submitCircle={addUserCircle}
            onCancel={() => toggleSelect(false)}
          /> : <img src={loadingGif} alt="Loading..." style={{
            height: '1.875rem',
            width: '1.875rem'
          }} />)
      }
    </>
  )
}

const mapStateToProps = (state) => ({
  circles: state.Circle.circles,
  userCircles: state.UserCircle.userCircles,
})

function mapDispatchToProps(dispatch) {
  return bindActionCreators({
    getCircles: circleCreators.get_circles_list,
  }, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(AddUserCircleComponent)
