import * as _ from "lodash";
import initialState from "./initialState";

function ApplicantCircle(state = initialState, action = {}) {
    if (_.isUndefined(action)) {
        return state;
    }

    const { type, payload } = action;

    if (type === 'UPDATE_APPLICANT_CIRCLE_LIST') {
        return {
            ...state,
            data: payload.data
        }
    }

    return state;
}

export default ApplicantCircle;
