import React from 'react'
import { Editor } from '@tinymce/tinymce-react';
import TooltipLabelForm from '../../components/TooltipLabelForm';

const TextareaInput = ({field, form: { touched, errors }, ...props}) => (

  <div className="form-group" style={{}}>
    <label className="label d-flex align-items-center">
      { props.label }
      {props.obligatoire && <span style={{ color: '#00b5d4', marginLeft: 8 }} className='required'>*</span>}
      {props.tooltip && <TooltipLabelForm tooltip={props.tooltip}/>}
    </label>
    { props.richtext !== undefined ?
      <Editor
        apiKey='utaf8ts9rx8ehhx85zfl9wuxid70b773mz3fkm0qm1337xd2'
        value={ field.value }
        init={{
          plugins: 'link',
          toolbar: 'bold italic'
        }}
        onEditorChange={ content => props.setFieldValue(field.name, content)}
      />
      :
    <textarea {...props}  {...field}/>
    }
  </div>
);

export default TextareaInput
