import cx from 'classnames/bind';
import React from 'react'
import {usePrevious} from '../../../hooks/usePrevious';

const ListingPerPageDropDown = ({optionsPerPages, value, onChangePerLines, totalResultats, label}) => {
  const [open, setOpen] = React.useState(false);
  const [selected, setSelected] = React.useState(value);
  const prevSelected = usePrevious(selected)

  React.useEffect(() => {
    if(prevSelected !== undefined) {
      onChangePerLines(selected)
    }
  }, [selected]);

  return (
    <div className="flex flex--horizontal-space-b flex--vertical-space-c mb-3">
      {label &&
      <span
        className="t-label-large">{totalResultats > 1 ? `${totalResultats} ${label}s` : `${totalResultats} ${label}`} </span>
      }
      <span className="flex flex--vertical-space-c">
        <span className="t-label mr-4">Affichage</span>
        <span className="ui-select" style={{width:140, margin: 0}} onClick={ () => setOpen(!open)}>
          <span className={cx({selected})}>{selected} lignes</span>
            <i>
              <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M17.7072 9.29304L12.0001 3.58594L6.29297 9.29304L7.70718 10.7073L12.0001 6.41436L16.293 10.7073L17.7072 9.29304Z" fill="#00B5D4"/>
                <path d="M7.70718 13.293L12.0001 17.5859L16.293 13.293L17.7072 14.7073L12.0001 20.4144L6.29297 14.7073L7.70718 13.293Z" fill="#00B5D4"/>
              </svg>
            </i>
            {
              open &&
              <div className="choices flex flex--column">
                {
                  optionsPerPages.map((choice,index) => <span key={`key-${index}`} onClick={() => setSelected(choice)}>{choice}</span>)
                }
              </div>
            }
        </span>
      </span>
    </div>
  )
}

export default ListingPerPageDropDown
