import { missions_service } from "../../../_services/missions";
import { toast } from "react-toastify";

export const missions_action = {
    get_missions_list,
    get_detail_mission,
    save_mission,
    update_mission,
    postComment,
    get_affected_missions,
    destroyAfftectedMissions,
    unloadMission,
    updateMissionStatus
};



function updateMissionStatus(mission_id, content) {
    return function (dispatch) {
        missions_service.updateMissionStatus(mission_id,content).then( (response) => {
            dispatch({
                type : 'UPDATE_MISSION_SINGLE',
                payload : { data: response.data}
            })
        })
    }
}

function postComment( comment, mission_id ) {
    return function (dispatch) {
        missions_service.comment(comment).then(function(response){

            toast.success("Commentaire ajouté", {
                position: toast.POSITION.BOTTOM_LEFT
            });

            missions_service.get(mission_id).then(function(response){
                dispatch({
                    type : 'UPDATE_MISSION_SINGLE',
                    payload : { data: response.data}
                })
            });
        }).catch(error => {
          toast.error('Impossible de poster le commentaire', {
            position: toast.POSITION.BOTTOM_LEFT
          });
      });
    };
}

function update_mission( mission_id, content ) {
    return function (dispatch) {
        return  missions_service.update(mission_id,content)
    };
}

function unloadMission() {
    return function (dispatch) {
        dispatch({
            type : 'UPDATE_MISSION_SINGLE',
            payload : { data: null}
        })
    };
}

function get_detail_mission( mission_id ) {
    return function (dispatch) {
        missions_service.get(mission_id).then(function(response){
            dispatch({
                type : 'UPDATE_MISSION_SINGLE',
                payload : { data: response.data}
            })
        });
    };
}

function save_mission( data, mission_id = null, router = null ) {
    return function (dispatch) {
        return  missions_service.save(data,mission_id)
    };
}

function get_affected_missions(next , filter) {
    return function (dispatch) {
        missions_service.get_affected_missions(next, filter).then(function(response){
            dispatch({
                type : 'UPDATE_MISSIONS_LIST',
                payload : { data: response.data, filter: filter}
            })
        });
    }
}
function destroyAfftectedMissions() {
    return function (dispatch) {
        dispatch({
            type : 'UPDATE_MISSIONS_LIST',
            payload : null
        })
    }
}

function get_missions_list( next , filter, empty = false) {
    return function (dispatch) {
        if( empty ) {
            dispatch({
                type : 'UPDATE_MISSIONS_LIST',
                payload : null
            })
        } else {
            missions_service.get_lists(next, filter).then(function(response){
                dispatch({
                    type : 'UPDATE_MISSIONS_LIST',
                    payload : { data: response.data, filter: filter}
                })
            });
        }
    };
}
