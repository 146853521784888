import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { coreSkillCreators } from '../../../reducers/CoreSkill/actions';

const CoreSkillDescriptions = ({ selectedCoreSkill, userCoreSkillData, ...props }) => {
    const [prevSelected, setPrevSelected] = React.useState(null);
    const [descriptions, setDescriptions] = React.useState([]);

    // Since the state changes multiple times during the 1st render (Formik values set)
    // we use this custom on prevSelected check to avoid excessive API calls
    const updatePrevSelected = (value) => {
        if (value && prevSelected !== value) {
            setPrevSelected(value);
        }
    }

    React.useEffect(() => {
        updatePrevSelected(userCoreSkillData ? userCoreSkillData.coreSkill.id : selectedCoreSkill);
    }, [selectedCoreSkill, userCoreSkillData]);

    // If it is an Edit action, it is unnecessary to fetch descripitons since they are injected in `data` props
    // else we trigger a fecth to core_skill description
    React.useEffect(() => {
        if (userCoreSkillData) {
            setDescriptions(userCoreSkillData.coreSkill.coreSkillDescriptions);
        } else if (prevSelected) {
            props.getCoreSkill(prevSelected);
        }
    }, [prevSelected]);

    React.useEffect(() => {
        props.coreSkill && setDescriptions(props.coreSkill.coreSkillDescriptions);
    }, [props.coreSkill]);

    return (
        <ul>
            {descriptions.map((coreDesc, index, { length }) => <li key={coreDesc.id}>
                {coreDesc.description}
                {index < length - 1 && <><br /><br /></>}
            </li>)}
        </ul>
    )
}


const mapStateToProps = (state) => ({
    coreSkill: state.CoreSkill.coreSkill
})

function mapDispatchToProps(dispatch) {
    return bindActionCreators({
        getCoreSkill: coreSkillCreators.get_core_skill,
    }, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(CoreSkillDescriptions)
