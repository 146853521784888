import React from 'react'
import {connect} from 'react-redux';
import {Redirect} from 'react-router';
import {withRouter} from 'react-router-dom';
import {toast} from 'react-toastify';
import {bindActionCreators} from 'redux';
import {missions_action} from '../../reducers/Missions/actions/getMissionsList';
import FormCreateBesoin from './FormCreateBesoin';
import '../../features/development/style.scss'

const EditMission = props => {

  const [redirect, setRedirect] = React.useState(null);

  React.useEffect(() => {
    if(props.match.params.id || props.duplicateData) {
      props.loadMission(props.match.params.id || props.duplicateData)
    }
  }, [props.duplicateData]);

  const save = values => {
    const dataPayload = {
      ...values,
      businessCode: values.businessCode.value || values.businessCode,
      approach: values.approach.value ? values.approach.value : values.approach,
      invoicingMode: values.invoicingMode.value ? { 'id': values.invoicingMode.value } : values.invoicingMode,
      etp: values.etp.value ? { 'id': values.etp.value } : values.etp,
      priority: values.priority.value ? { 'id': values.priority.value } : values.priority,
      requiredPositions: values.requiredPositions[0].value ?
        values.requiredPositions.map(positionItem => ({ 'id': positionItem.value })) : values.requiredPositions
    };

    if( props.match.params.id !== undefined && !props.match.url.includes('duplicate') ) {
      props.updateMission( props.match.params.id, dataPayload).then(() => {
        toast.success("Mission éditée !", {
          position: toast.POSITION.BOTTOM_LEFT,
          onClose: () =>  setRedirect(`/missions/view/${props.match.params.id}`)
        });
      })
    } else {
      props.saveMission(dataPayload).then(() => {
        toast.success("Mission créée !", {
          position: toast.POSITION.BOTTOM_LEFT,
          onClose: () =>  setRedirect('/missions')
        });
      })
    }
  }

  const onDuplicate = values => {
    props.duplicate(values)
    setRedirect(`/missions/duplicate/${props.match.params.id}`)
  }

  return (
    <>
      {redirect && <Redirect to={redirect} />}
      <div className="profile create-feedback">
        <header />
        <div className="container">
            <div className="col-md-12 box">
                <div className="header-feedback">
                  <FormCreateBesoin
                    isDuplicata={props.match.url.includes('duplicate')}
                    selectData={props.selectData}
                    mission={props.mission}
                    onSubmit={values => save(values)}
                    missionId={props.match.params.id}
                    onDuplicate={() => onDuplicate(props.match.params.id)}
                    match={props.match}
                    currentFiscalYear={props.currentFiscalYear}
                  />
                </div>
            </div>
        </div>
    </div>
    </>
  )
}

const mapStateToProps = (state) => ({
  selectData : state.SelectData,
  mission : state.Missions.mission_view,
  duplicateData: state.App.duplicateMissionData,
  currentFiscalYear: state.App.currentFiscalYear,
})

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({
    saveMission:  missions_action.save_mission,
    updateMission : missions_action.update_mission,
    loadMission: missions_action.get_detail_mission,
    duplicate : (data) => dispatch({
      type : 'DUPLICATE_MISSION',
      payload : data
    })
  },dispatch)
}

export default withRouter( connect(mapStateToProps,mapDispatchToProps)(EditMission))

