import React from 'react';
import Select from 'react-select';

/**
 * Note: props.edit might be:
 * - a boolean
 * - an object (when editing an existing filter) of this format `{subCircle: [...], circle: {id: id_value}}`
 */
const CircleAdder = props => {
    const [data, setData] = React.useState({
        subCircle: props.edit.subCircle ? props.edit.subCircle : [],
        circle: { id: props.edit.subCircle ? props.edit.circle.id : null }
    });
    const [subCircles, setsubCircles] = React.useState([]);

    const add = () => {
        props.onAdd(data)
        props.onCancel()
    }

    const handleOnChangeCircle = (circle) => {
        setData({
            circle: { id: circle.value },
            subCircle: [],
        })
        setsubCircles(circle.subCircles);
    }

    const defaultCircle = props.edit.subCircle ?
        props.selectData.find(circle => circle.value === props.edit.circle.id) : null;

    // when editing the filter we have to set the subCircles' multi-select
    React.useEffect(() => {
        if (defaultCircle) {
            setsubCircles(defaultCircle.subCircles);
        }
    }, []);

    return (
        <>
            <div className="form-fields">
                <div className="form-group">
                    <label htmlFor="">Cercles</label>
                    <Select
                        isSearchable={true}
                        isMulti={props.isMulti !== undefined}
                        onChange={value => handleOnChangeCircle(value)}
                        options={props.selectData}
                        isDisabled={props.edit.subCircle}
                        defaultValue={
                            defaultCircle
                                ? {
                                    label: defaultCircle.label,
                                    value: props.edit.circle.id
                                } : null
                        }
                    />
                </div>
                {
                    subCircles.length > 0 &&
                    <div className="form-group">
                        <label htmlFor="">Sous cercle</label>
                        <Select
                            isSearchable={true}
                            isMulti={true}
                            onChange={values => setData({ ...data, subCircle: [...values.map(subCircle => subCircle.value)] })}
                            options={subCircles}
                            defaultValue={props.edit.subCircle ?
                                subCircles.filter(sub => props.edit.subCircle.includes(sub.value)) : []}
                        />
                    </div>
                }
            </div>
            <div className="actions" style={{
                display: 'flex',
                margin: '12px auto',
                flex: 1,
                justifyContent: 'flex-end'
            }}>
                <button onClick={props.onCancel} type="button" className="button-ui button-ui--white">Annuler</button>
                <button onClick={add} disabled={data.circle.id === null} type="button" className="button-ui button-ui--blue" style={{ marginLeft: 8 }}>Valider</button>
            </div>
        </>
    )
}

export default CircleAdder
