import React, { Component } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux';
import '../../styles/components/NotificationContainer.css'
import NotfiComponent from '../../components/Notifications/NotfiComponent';
import uniqid from 'uniqid';
import { notification_actions } from '../../reducers/Notifications/actions';

class NotificationContainer extends Component {

    constructor(props) {
        super(props)
        this.state = {
            notifEmail: this.props.emailNotifiaction
        }
    }

    componentDidMount() {
        this.props.get_notifications()
    }

    render () {
        return (
            <div className="container custom-md-container mb-3 my-lg-5 py-lg-4">

                <div className="content-notifs">
                    <h1 style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }} className="title-page title-border-bottom xs-flex-column">
                        Notifications
                        <span style={{ fontSize: 'initial', display: 'flex' }}>
                            <input
                                onChange={ this.activateEmailNotif }
                                id='notfiemail'
                                type="checkbox"
                                checked={this.state.notifEmail}
                                style={{ marginRight: '10px' }}
                            />
                            <label for='notfiemail'>Recevoir les notifications par email</label>
                        </span>
                    </h1>
                    { this.props.notifications && this.props.notifications.map( (item,i) =>  <NotfiComponent data={item} key={uniqid()} /> )   }
                    <div className="content-button text-center mt-4 mt-lg-5">
                    </div>
                </div>

            </div>
        )
    }

    activateEmailNotif = () => {
        this.setState({
            notifEmail: !this.state.notifEmail
        } , () => this.editNotifEmailPreferences() )
    }

    editNotifEmailPreferences = () => {
        this.props.updatePrefEmailNotif(
            localStorage.getItem('user_id') ,
            { emailNotifiaction :  this.state.notifEmail}
        )
    }
}



const mapStateToProps = (state) => ({
    'notifications' : state.Notifications.notifications,
    'hasNotif' : state.App.hasNotif,
    emailNotifiaction: state.Role.notificationEmail
})

const mapDispatchToProps = (dispatch) => {
    return bindActionCreators({
        get_notifications: notification_actions.get_notifications,
        updatePrefEmailNotif : notification_actions.updateEmailPreferences
    },dispatch)
}


export default connect(mapStateToProps,mapDispatchToProps)(NotificationContainer)
