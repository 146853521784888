import React, { Component } from 'react'
import '../../styles/components/MenuMobileComponent.css'
import { hasCap } from '../../helpers/app';
import uniqid from 'uniqid';
import { routePath } from '../../helpers/route_helper';
import {Link} from 'react-router-dom'
import { connect } from 'react-redux'
import classNames from 'classnames/bind';
import { bindActionCreators } from 'redux';
import { menu_actions } from '../../reducers/Menu/actions';

const menuItems = [
  {
    label: 'Recrutement',
    to: '',
    can_view: 'CONSULTANT_CRH',
    hideTo: ['EXTERNE'],
    notif: false,
    submenu: [
      {
        label: 'Liste des candidats',
        to: 'recruitment/candidates/list',
        can_view: 'SENIOR_MANAGER',
        hideTo: ['EXTERNE'],
        notif: false
      },
      {
        label: 'Plan de recrutement',
        to: 'recruitment/candidates/needs',
        can_view: 'SENIOR_MANAGER',
        hideTo: ['EXTERNE'],
        notif: false
      },
      {
        label: 'Mes entretiens',
        to: 'recruitment/my-interviews/list',
        can_view: 'CONSULTANT_SENIOR',
        hideTo: ['EXTERNE'],
        notif: false
      },
      {
        label: 'Mes cooptés',
        to: 'recruitment/my-coopted/list',
        can_view: 'CONSULTANT_CRH',
        hideTo: ['EXTERNE'],
        notif: false
      },
      {
        label: 'Ajouter un candidat',
        to: 'recruitment/candidates/forms',
        can_view: 'CONSULTANT_CRH',
        hideTo: ['EXTERNE'],
        notif: false
      }
    ]
  },
  {
    label: 'People',
    to: '',
    can_view: 'CONSULTANT_CRH',
    hideTo: [],
    notif: false,
    submenu: [
      {
        label: 'Consultants',
        to: 'consultant/list/interne',
        can_view: 'CONSULTANT_CRH',
        hideTo: ['EXTERNE'],
        notif: false
      },
      {
        label: 'Experts',
        to: 'consultant/list/externe',
        can_view: '',
        hideTo: ['CONSULTANT_CRH'],
        notif: false
      },
      {
        label: 'Mes référés',
        to: 'mes-referes',
        can_view: 'MANAGER',
        hideTo: [],
        notif: false
      },
      {
        label: 'Prochaines arrivées',
        to: 'recruitment/arrivals/list',
        can_view: 'SENIOR_MANAGER',
        hideTo: ['EXTERNE'],
        notif: false
      }
    ]
  },
  {
    label: 'Staffing',
    to: '',
    can_view: 'CONSULTANT_SENIOR',
    hideTo: ['EXTERNE'],
    notif: false,
    submenu: [
      {
        label: 'Dispos courtes',
        to: 'planning',
        can_view: 'CONSULTANT_SENIOR',
        hideTo: [''],
        notif: false
      },
      {
        label: 'Tous les besoins',
        to: 'missions',
        can_view: 'CONSULTANT_SENIOR',
        hideTo: [''],
        notif: false
      },
      {
        label: 'Créer un besoin',
        to: 'missions/edit',
        can_view: 'CONSULTANT_SENIOR',
        hideTo: [''],
        notif: false
      },
      {
        label: 'Mes besoins créés',
        to: 'my-missions',
        can_view: 'CONSULTANT_SENIOR',
        hideTo: [''],
        notif: false
      },
      {
        label: 'Comptes-rendus / Réunions staffing',
        to: 'cr_matching',
        can_view: 'CONSULTANT_SENIOR',
        hideTo: [''],
        notif: false
      }
    ]
  },
  {
    label: 'Développement',
    to: '',
    can_view: 'CONSULTANT_SENIOR',
    hideTo: ['EXTERNE'],
    notif: false,
    submenu: [
      {
        label: 'Créer un feedback',
        to: 'feedbacks/create',
        can_view: 'CONSULTANT_CRH',
        hideTo: [''],
        notif: false
      },
      {
        label: 'Comité carrière',
        to: 'comite-carriere/listing',
        can_view: 'MANAGER',
        hideTo: [''],
        notif: false
      }
    ]
  },
  {
    label: 'Objectifs',
    to: '',
    can_view: 'CONSULTANT_CRH',
    hideTo: ['EXTERNE'],
    notif: false,
    submenu: [
      {
        label: 'Tous les livrables',
        to: 'livrables',
        can_view: 'CONSULTANT_CRH',
        hideTo: [''],
        notif: false
      },
      {
        label: 'Créer un livrable',
        to: 'livrable/edit',
        can_view: 'CONSULTANT_SENIOR',
        hideTo: [''],
        notif: false
      },
      {
        label: 'Livrables à valider',
        to: 'livrables/validation',
        can_view: 'PARTNER',
        hideTo: ['CONSULTANT_SENIOR'],
        notif: false
      },
      {
        label: 'Toutes les propales',
        to: 'propale',
        can_view: 'CONSULTANT_CRH',
        hideTo: [''],
        notif: false
      },
      {
        label: 'Créer une propale',
        to: 'propale/edit',
        can_view: 'CONSULTANT_SENIOR',
        hideTo: [''],
        notif: false
      }
    ]
  }
]

class MenuMobileComponent extends Component {

    constructor(props){
        super(props);
        this.state = {
          active: null,
          hasNotif: false
        }
      }

    _handleClick(menuItem) {
        this.setState({ active: menuItem });
        this.props.toggle_mobile_menu(false)
    }

    closeMenuMobile()  {
        this.props.toggle_mobile_menu(false)
    }

    getClasses( notif, menuItemArg ) {
        return classNames({
          'nav-item': true,
          'hasSubmenu': menuItemArg.submenu.length > 0,
          'item-notifs' : this.state.hasNotif && notif,
          'relative' : true
        });
    }

    getFirstChilds(menuItemArgs) {
      return (
        menuItemArgs.to !== '' ?
        <Link
          className="nav-link"
          onClick={this._handleClick.bind(this, menuItemArgs.label)}
          to={routePath(menuItemArgs.to)}
        >
          {menuItemArgs.label}
        </Link> :
        <span className="nav-link" onClick={e => e.target.nextElementSibling.classList.toggle('show')}>
          {menuItemArgs.label}
        </span>
      )
    }

    render () {
        return (
            <React.Fragment>
                <div className="navbar-mobile">

                <ul className="navbar-nav">
                    <li className="nav-item nav-item--user">
                        <Link className="nav-link" onClick={this.closeMenuMobile.bind(this)} to={routePath('my-profile')}>
                            { this.props.current_user.fullName }
                            <span className="d-block mt-2 status available">{this.props.current_user.role}</span>
                        </Link>
                    </li>
                </ul>

                <ul className="navbar-nav">

                    { menuItems.map(menuItem =>
                    this.props.role && hasCap( menuItem.can_view, this.props.role ) && !menuItem.hideTo.includes(this.props.role)  ?
                        <li key={uniqid()} className={ this.getClasses(menuItem.notif, menuItem) }>
                          {this.getFirstChilds(menuItem)}
                          {
                            menuItem.submenu.length > 0 &&
                              <ul>
                                {  menuItem.submenu.map(subMenuItem =>
                                  this.props.role && hasCap( subMenuItem.can_view, this.props.role ) && !subMenuItem.hideTo.includes(this.props.role) && <Link
                                    className={this.state.active === subMenuItem.label ? 'nav-link active'  : 'nav-link' }
                                    onClick={this._handleClick.bind(this, subMenuItem.label)}
                                    to={routePath(subMenuItem.to)}
                                  >
                                    {subMenuItem.label}
                                  </Link>
                                )}
                              </ul>
                          }
                        </li> : null
                    )}

                </ul>

                <ul className="navbar-nav">

                    <li className="nav-item">
                        <Link onClick={this.closeMenuMobile.bind(this)} to={routePath('my-profile')} className="nav-link">Mon profil</Link>
                    </li>
                    { hasCap('CONSULTANT_SENIOR', this.props.role)  ?  <li className="nav-item"><Link onClick={this.closeMenuMobile.bind(this)}  to={routePath('mes-referes')} className="nav-link">Mes référés</Link></li> : null }
                    { hasCap('CONSULTANT_SENIOR', this.props.role) ? <li className="nav-item"><Link onClick={this.closeMenuMobile.bind(this)} to={routePath('planning/edit')} className="nav-link">Mes dispos courtes / Erreur plannif</Link> </li>: null }
                    { hasCap('CONSULTANT_SENIOR', this.props.role) ? <li className="nav-item"><Link onClick={this.closeMenuMobile.bind(this)} to={routePath(`development/feedbacks?user=${localStorage.getItem('user_id')}`)} className="nav-link">Mon développement</Link> </li>: null }
                    { hasCap('CONSULTANT_SENIOR', this.props.role) && <li className="nav-item">
                      <a href="https://convictionsrh.onelogin.com/client/apps/select/649858600" rel="noreferrer noopener" target="_blank" className="nav-link">My learning
                      </a></li> }


                </ul>

                <ul className="navbar-nav">
                    <li className="nav-item">
                        <span className="nav-link" onClick={ () => {
                            localStorage.removeItem('refresh_token');
                            localStorage.removeItem('token');
                            localStorage.removeItem('user_id');
                            window.location.replace(`/#/signin`);
                        } }>Déconnexion</span>
                    </li>
                </ul>

                </div>
                <div onClick={ () => this.props.toggle_mobile_menu(false) } className="backdrop show" />
            </React.Fragment>
        )
    }
}

const mapStateToProps = (state) => ({
    role : state.Role.role,
    hasNotif : state.App.hasNotif
})


const mapDispatchToProps = (dispatch) => {
    return bindActionCreators({
        toggle_mobile_menu: menu_actions.toggle_mobile_menu
    },dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps)(MenuMobileComponent)
