import React, {useState} from 'react'
import { hasCap } from '../../helpers/app'
import { connect } from 'react-redux'
import { fetchCR, tryAddComment, publishCr } from '../../reducers/Cr/cr.actions'
import Axios from 'axios'
import { newConnectConfig } from '../../_services/contant'
import {ModalContext} from "../../context/ModalContext";
import DatePicker from "../Planning/components/modal/DatePicker";
import axios from "axios";
import moment from "moment";
import Listing from "../../components/ui/Listing/Listing";
import HeaderThead from "../../containers/Missions/listing/header";
import LineContent from "./components/listing/lineContent";
import {Link} from "react-router-dom";

const ModalGenerateCr = props => {
  const [beginDate, setBeginDate] = React.useState(null);
  const [finishDate, setFinishDate] = React.useState(null);

  return(
    <div className="content" style={{background: '#F2F2F4', borderRadius: 8}}>
      <p className="title">Génération du CR</p>
      <div className="form" style={{display: "flex", justifyContent: "space-around"}}>
        <div className="form-row">
          <label htmlFor="">Date de début *</label>
          <DatePicker onUpdateState={ value => setBeginDate(value)} withoutPlage />
        </div>
        <div className="form-row">
          <label htmlFor="">Date de Fin *</label>
          <DatePicker onUpdateState={ value => setFinishDate(value)} withoutPlage/>
        </div>
      </div>
      <div className="actions">
        <button className="outline" onClick={ props.onCancel }>Annuler</button>
        <button className="blue" onClick={ () => props.onConfirm({ beginDate, finishDate }) }>Valider</button>
      </div>
    </div>
  )
  }

function CompteRenduReunion (props) {
  const [modal, setModal] = React.useContext(ModalContext);
  const [sorting] = useState('');
  const [filterOpen, setFilterOpen] = useState(null);

  const listingHeader = hasCap('RH', props.role) ? [
    { label: 'Nom du besoin', id: 'subject', filter: null ,filterXPos: 'left' },
    { label: 'Statut avant action', id: 'status', filter: null,filterXPos: 'left' },
    { label: 'Consultant(s) positionné(s)', id: 'prospect', filter: null ,filterXPos: 'left' },
    { label: 'Action pendant la réunion de staffing', id: 'grade', filter: null, filterXPos: 'left' },
    { label: 'Statut à date',id: 'chef_proj', filter: null, filterXPos: 'right'},
    { label: 'Consultant(s) positionné(s) à date',id: 'chef_proj', filter: null, filterXPos: 'right'},
    { label: 'Commentaire', id: 'date',filter: null,filterXPos: 'right', styles:{width: '200px'} }
  ] : [
    { label: 'Nom du besoin', id: 'subject', filter: null ,filterXPos: 'left' },
    { label: 'Statut avant action', id: 'status', filter: null,filterXPos: 'left' },
    { label: 'Consultant(s) positionné(s)', id: 'prospect', filter: null ,filterXPos: 'left' },
    { label: 'Action pendant la réunion de staffing', id: 'grade', filter: null, filterXPos: 'left' },
    { label: 'Statut à date',id: 'chef_proj', filter: null, filterXPos: 'right'},
    { label: 'Consultant(s) positionné(s) à date',id: 'chef_proj', filter: null, filterXPos: 'right'}
    ]

  React.useEffect(() => {
    props.fetchCR()
    return () => {}
  }, [])

  const saveComment = (rowID, comment) => {
    props
      .tryAddComment(comment, rowID)
      .then(() => {
        setModal({...modal, display: false, component: null})
        props.fetchCR()
      })}

  const deleteRow = rowId => {
      const url = `${newConnectConfig.API_PATH}/logs/${rowId}`
      return Axios({
        method: 'delete',
        url,
        headers: { Authorization: `Bearer ${localStorage.getItem('token')}` }
      })
        .then(() => {
          setModal({...modal, display: false, component: null})
          props.fetchCR()
        })

  }

  const generateCr = data => {
    const url = `${newConnectConfig.API_PATH}/c_rs`
    axios({
      method:'post',
      headers: {
        "Authorization" : `Bearer ${localStorage.getItem('token')}`
      },
      url,
      data: {
        "name" : "CR test",
        "beginDate" : `${moment(data.beginDate).format('YYYY-MM-DD')} 08:00:00`,
        "finishDate" : `${moment(data.finishDate).format('YYYY-MM-DD')} 19:00:00`
      }
    }).then(
      () =>{
        props.fetchCR()
        setModal({
          ...modal,
          display: false,
          component: null
        })
      }
    )
  }

  const generateCrModal = () => {
    setModal({
      ...modal,
      display: true,
      component: <ModalGenerateCr onCancel={ () => setModal({...modal, display: false, component: null})} onConfirm={ data => generateCr(data) }/>
    })
  }

  return (
    props.data &&
    <div className="page container mb-3 my-lg-5 py-lg-4">
      <header className="col-md-12">
        <h1>
          Compte rendu de réunion
          <Link
            style={{
              fontSize: 14,
              display: 'block',
              marginTop: -8
            }}
            to='/cr_matching/list'
          >
            Tous les comptes rendus
          </Link>
          {(props.data.publish || hasCap('RH', props.role)) && (
            <em
              style={{
                display: 'block',
                fontSize: '15px',
                color: '#00b5d4'
              }}
            >
              {props.data.title}
            </em>
          )}
        </h1>
        <div className="action">
          {hasCap('RH', props.role) && (
            <span className=''>
              <button
                onClick={ generateCrModal }
                className='action_cr outline'
              >
                Générer le CR
              </button>
            </span>
          ) }
          {hasCap('RH', props.role) && (
            <span className=''>
              <button
                onClick={() =>
                  props.publishCr(props.data.publish, props.data.id)
                }
                className='action_cr'
              >
                {!props.data.publish ? 'Publier le CR' : 'Dépublier le CR'}
              </button>
            </span>
          ) }
        </div>
      </header>
      <div className="col-md-12 col-lg-12">
        { props.data.publish || hasCap('RH', props.role) ?
        <>
          <Listing>
          <Listing.Header>
            {
              listingHeader.map(
                header =>
                  <HeaderThead
                    setFilterOpen={ value => setFilterOpen( value === filterOpen ? null :value) }
                    filterOpen={filterOpen}
                    select_data={props.select_data}
                    sorting={sorting}
                    header={header}
                    styles={header.styles}
                    displayed={header.displayed}
                    role={props.role}
                  />
              )
            }
          </Listing.Header>
          {props.data.cr &&
          <Listing.Content>
            {props.data.cr.map( data =>
              <LineContent
                deleteRow={ id => deleteRow(id) }
                saveComment={ (rowId, comment) => saveComment(rowId, comment) }
                data={data}
                role={props.role}
                listingHeader={listingHeader}
                sorting={sorting}
              />)}
          </Listing.Content>
          }
        </Listing>
          { props.data.length === 0 && <p
          style={{ width: "100%", textAlign: "center", padding: '20px 0' }}
          >
            Aucun résutat
          </p> }
        </>
          : !props.isLoading && <p> Le compte rendu de réunion n'est toujours pas publié, merci de revenir plus tard.</p> }
      </div>
    </div>
  )
}

const mapStateToProps = state => ({
  role: state.Role.role,
  data: state.cr.data,
  isLoading: state.cr.isLoading
})

export default connect(
  mapStateToProps,
  { fetchCR, tryAddComment, publishCr }
)(CompteRenduReunion)
