import React, {useEffect} from 'react';
import Header from './Header';
import moment from 'moment';
import LineConsultant from "./LineConsultant";

const CalendarView = (props) => {

  const [groupByUser, setGroupByUser] = React.useState(null);

  useEffect(() => {
    if( props.planning[props.activeView].length > 0 ) {
      const groupByUser =  props.planning[props.activeView].reduce((acc, item) => {
        acc[`${item.fullName}-${item.role}`] = {
          availabities:
            props.planning[props.activeView]
              .filter( data => data.fullName === item.fullName)
              .filter( data => props.dispoFilter.value !== null ? data.booked === props.dispoFilter.value : data )
              .sort( (a,b) => moment(a.dispo).unix() - moment(b.dispo).unix() ),
          nbDispo: props.planning[props.activeView].filter( data => data.staffer === null).length
        }
        return acc;
      }, []);
      setGroupByUser(groupByUser)
    } else {
      setGroupByUser(null)
    }
  }, [props.view]);

  return (
    <div className="calendar ">
      <header className="header_calendar header_calendar--bottom">
        { props.view.map( (day,index) => <Header key={index} day={day} index={index} /> )}
      </header>
      <div className="plage-horaire">
        <div>
          <span>AM</span>
          <span>PM</span>
        </div>
        <div>
          <span>AM</span>
          <span>PM</span>
        </div>
        <div>
          <span>AM</span>
          <span>PM</span>
        </div>
        <div>
          <span>AM</span>
          <span>PM</span>
        </div>
        <div>
          <span>AM</span>
          <span>PM</span>
        </div>
      </div>
      <div className="data">
        {
          groupByUser && Object.entries(groupByUser).map(
            (user,index) => <LineConsultant key={`line-${index}`} dispoFilter={props.dispoFilter} view={props.view} user={user} />
          )
        }
      </div>
    </div>
  )
}

export default CalendarView;
