import React, {useEffect, useRef} from 'react'
import InputBoxDoneTyping from "react-input-box-done-typing";
import {consultant_service} from "../../_services/consultant";

const UserSearchField = ({field, form: {initialValues}, ...props}) => {

  const [results, setResults] = React.useState([]);
  const input = useRef()

  const search = value => {
    if (value.length >= 1) {
      const role = props.role !== undefined ? props.role : 'CONSULTANT_CRH'
      consultant_service.search(value, role).then(data => {
        setResults(data.data['hydra:member'])
      })
    } else {
      setResults([])
    }
  }

  const select = consultant => {
    props.setFieldValue(field.name, {"id": consultant.id})
    document.getElementById(`searh-field-${field.name}`).value = `${consultant.fullName}`
    setResults([])
  }

  useEffect(() => {
    if(props.disabled) {
      input.current.querySelector('input').setAttribute('disabled', 'disabled');
    }
  }, [props])

  return (
    initialValues &&
    <div className="form-group">
      <label className="label">
        {props.label}
        {props.required && <span style={{color: '#00b5d4', marginLeft: 8}} className="required">*</span>}
      </label>
      <div className="search-field-container" ref={input}>
        <InputBoxDoneTyping
          autoComplete={'off'}
          id={`searh-field-${field.name}`}
          doneTyping={search}
          placeholder={props.placeholder}
          defaultValue={props.defaultValue}
          disabled={props.disabled}
        />
        {
          results.length > 0 && <div className="search-field-container__results">
            {
              results.map(match =>
                <div key={match.id} className="consultant-line" onClick={() => select(match)}>
                  <img src={match.profilePhoto && match.profilePhoto.contentUrl} alt=""/>
                  <div className="name">
                    {match.fullName}
                  </div>
                </div>
              )
            }
          </div>
        }
      </div>
    </div>
  )
};

export default UserSearchField
