import React from 'react'
import MissionResume from './view/MissionResume';
import StatusHistory from './view/StatusHistory';
import TabsSwitcher from './view/TabsSwitcher';

const MissionContent = ({mission, onReload, postComment}) => {
  const [tab, setTab] = React.useState('resume');

  const tabs = [
    {label:'Résumé', key: 'resume', component: <MissionResume postComment={postComment} mission={mission} onReload={onReload}/>},
    {label:'Historique des statuts', key: 'statutes', component: <StatusHistory  mission={mission}/>}
  ]

  return (
    tab &&
    <div className="page container mt-4 mission">
      <TabsSwitcher tabs={tabs} onChange={t => setTab(t)} active={tab} />
      {tabs.find(t => t.key === tab).component}
    </div>
  )
}

export default MissionContent
