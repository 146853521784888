import React, {Component} from 'react'
import {connect} from 'react-redux'
import {bindActionCreators} from 'redux';
import loginAction from '../../reducers/App/actions/login';
import logoutAction from '../../reducers/App/actions/logout';
import styled from 'styled-components';
import MenuComponent from '../../components/Header/MenuComponent';
import '../../styles/components/header.css'
import HeaderProfileComponent from '../../components/Header/HeaderProfileComponent';
import {routePath} from '../../helpers/route_helper';
import {Link} from 'react-router-dom'
import {withRouter} from 'react-router-dom'

const SCHeaderWrap = styled.header`
  padding: 16px 0;
  background-color: #ffffff;
  transition: all 350ms ease-in-out;
`

class Header extends Component {

    constructor(props) {
        super(props);
        this.header = React.createRef()
        this.ticking = false
    }

    componentDidMount() {
        let _self = this;

        window.addEventListener('scroll', function (e) {
            let scrollY = window.scrollY;

            if (!_self.ticking) {
                window.requestAnimationFrame(function () {
                    _self.handleScroll(scrollY)
                    _self.ticking = false;
                });
            }

            _self.ticking = true;
        });

    }


    handleScroll(scroll) {

        if (this.header.current) {
            let headerHeight = this.header.current.offsetHeight;

            if (scroll > headerHeight) {
                this.header.current.classList.add('minimize')
            } else {
                this.header.current.classList.remove('minimize')
            }
        }
    }

    render() {
        return (
            (
                this.props.location.pathname !== '/signin/' &&
                this.props.location.pathname !== '/signup' &&
                this.props.location.pathname !== '/onboarding'
            ) ?
                <SCHeaderWrap className="sticky" ref={this.header}>
                    <nav className="navbar navbar-expand-lg navbar-light main-nav">
                        <div className="container">
                            <div className="d-flex">
                                <Link className="navbar-brand" to={routePath('my-profile')}>
                                    <img style={{'maxWidth': 122}} src={require('../../assets/images/logo-mystaff.png')}
                                         alt="New Connect"/>
                                </Link>
                            </div>
                            {this.props.role &&
                            <div className="d-flex" style={{alignItems: 'center'}}>
                                <MenuComponent/>
                                <HeaderProfileComponent/>
                            </div>
                            }
                        </div>
                    </nav>
                </SCHeaderWrap> : null
        )
    }
}

const mapStateToProps = (state) => ({
    user: state.App.user,
    role: state.Role.role
})


const mapDispatchToProps = (dispatch) => {
    return bindActionCreators({
        loginAction,
        logoutAction
    }, dispatch)
}


export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Header))
