import * as _ from 'lodash'

function SelectData(state = [], action) {

    if (_.isUndefined(action)) {
        return state
    }

    const { type, payload } = action

    switch (type) {
      case 'PROFILE_UPDATE_SELECT':
       return Object.assign(payload,state)
      default:
        return state
    }

}

export default SelectData
