import React from 'react'
import InputBoxDoneTyping from 'react-input-box-done-typing';
import {Link} from 'react-router-dom';
import {matcher_service} from '../../../_services/matcher_service';
import {missions_service} from '../../../_services/missions';
import {ModalContext} from '../../../context/ModalContext';
import {HYDRA_MEMBER} from '../../../reducers/hydra';

const AffectMissionModalSuccess = props => {
  return (
    <>
      <header className="blue">
        <svg width="94" height="96" viewBox="0 0 94 96" fill="none" xmlns="http://www.w3.org/2000/svg">
          <circle cx="47.5" cy="52.5" r="39.5" fill="white"/>
          <path d="M26 49.3913L45.7353 69L87 28" stroke="#7ED321" strokeWidth="8" strokeLinecap="round"/>
        </svg>
      </header>
      <div className="content">
        <p className="title">
          L'expert vient d'être affecté sur la
          <Link
            style={{
              color: '#00b5d3',
              textDecoration: 'underline',
              marginLeft: 6
            }}
            target="_blank"
            rel="noreferrer noopener"
            to={`/missions/view/${props.missionId}`}>mission</Link>
        </p>
        <div className="actions">
          <button className="blue" onClick={() => props.onCancel()}>D'accord</button>
        </div>
      </div>
    </>
  )
}

const AffectMissionModal = props => {
  const [mission, setMission] = React.useState(null);
  const [results, setResults] = React.useState([]);

  const search = value => {
    if (value === '') {
      setResults([])
      setMission(null)
    } else {
      missions_service.searchByTerm(`?term=${value}&status=IN_RESEARCH,PRE_AFFECTED `).then(response => {
        setResults(response.data[HYDRA_MEMBER])
      })
    }
  }

  const select = (match) => {
    document.querySelector('.search-field-container > input').value = `${match.title}`
    setMission(() => match)
  }

  React.useEffect(() => {
    if (mission !== null) {
      setResults([])
    }
  }, [mission]);

  const validate = () => {
    matcher_service.matching({
      "user": {
        "id": props.user.id
      },
      "mission": {
        "id": mission.id
      }
    }).then(() => props.jobDone(mission.id))
  }

  return (
    <>
      <div className="content" style={{background: '#f3f3f3'}}>
        <form className="content">
          <div className="form-group" style={{margin: 0}}>
            <label className="label">
              Chercher un besoin
              <span style={{color: '#00b5d4', marginLeft: 8}} className="required">*</span>
            </label>
            <div className="search-field-container">
              <InputBoxDoneTyping
                autoComplete={'off'}
                doneTyping={search}
                placeholder={props.placeholder}
              />
              {
                results.length > 0 && <div className="search-field-container__results">
                  {
                    results.map(match => <div key={match.id} onClick={() => select(match)} className="consultant-line">
                      <span>{match.title}</span>
                    </div>)
                  }
                </div>
              }
            </div>
          </div>
        </form>
        <div className="actions">
          <button className="button-ui button-ui--blue-outline" onClick={() => props.onCancel()}>Annuler</button>
          <button
            disabled={mission === null}
            className="button-ui button-ui--blue"
            onClick={validate}
          >
            Valider
          </button>
        </div>
      </div>
    </>
  )
}

const AffectMissionPopup = ({user}) => {
  const [modal, setModal] = React.useContext(ModalContext);

  const modalAffect = () => {
    setModal({
      ...modal,
      display: true,
      component: <AffectMissionModal
        user={user}
        onCancel={() => setModal({...modal, display: false, component: null})}
        jobDone={missionId => setModal({
          ...modal,
          display: true,
          component: <AffectMissionModalSuccess
            missionId={missionId}
            onCancel={() => setModal({...modal, display: false, component: null})}
          />
        })}
      />
    })
  }
  return (
    <button
      style={{marginTop: 24}}
      onClick={modalAffect}
      className="button-ui button-ui--blue"
    >
      Affecter à un besoin
    </button>
  )
}

export default AffectMissionPopup
