import React, {useEffect, useState} from 'react'
import InputBoxDoneTyping from 'react-input-box-done-typing';
import {connect} from 'react-redux';
import {Link} from 'react-router-dom';
import {developpmentService} from '../../../_services/DevelopmentService';
import CustomDate from '../../../components/Filters/CustomDate';
import CustomSelect from '../../../components/Filters/CustomSelect';
import ListingInputFilter from '../../../components/ListingInputFilter';
import HeaderThead from "../../../containers/Missions/listing/header";
import ListingNavigation from '../../../features/development/tabs/components/feedbacks/ListingNavigation';
import Legend from '../../../features/development/tabs/components/livrables/Legend';
import {hasCap} from '../../../helpers/app';
import {buildUrl} from "../../../helpers/BuildFilters";
import {routePath} from '../../../helpers/route_helper';
import useDataListNavigation from "../../../hooks/useDataListNavigation";
import Listing from '../../../components/ui/Listing/Listing';
import LineListingExchange from './LineListingExchange';


const DataListHeader = ({hasSearch, label, actions, searchChange}) => {
  return (
    <div className="header-data-list">
      <h1>{label}</h1>
      <div className="actions">
        { hasSearch && <InputBoxDoneTyping doneTyping={ value => searchChange(value)} placeholder="Chercher par mots clés"/> }
        { actions && actions.map( (button, index) =>
          button.url ?
          <Link to={button.url} target="_blank" rel="noopener noreferrer" key={index} className={button.classes} style={{
            alignItems: 'center',
            marginRight: 16
          }}>
            <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M8 0H6V6H0V8H6V14H8V8H14V6H8V0Z" fill="white"/>
            </svg>
            {button.label}
          </Link>
                     :
          <button key={index} onClick={ () => button.onAction()} className={button.classes}>{button.label}</button>
        ) }
      </div>
    </div>
  )
}

const DataList = props => {

  const [sorting, setSorting] = useState('');
  const [filterOpen, setFilterOpen] = useState(null);
  const [filters, setFilters] = useState({
    'user.id': props.userId
  });
  const [data, setData] = useState(null)

  const updateFilters = (value, type) => {
    if( value === null ) {
      setFilters(
        Object.keys(filters)
              .filter(key => type !== key)
              .reduce((obj, key) => {
                obj[key] = filters[key];
                return obj;
              }, {})
      )

    } else {
      setFilters({
        ...filters,
        [type] : value
      })
    }

  }

  const listingHeader = [
    { label: 'Titre de la mission', id: 'title', filter: ListingInputFilter, termSearch: 'title',filterXPos: 'left', styles:{width: '35%'} },
    { label: 'Date de contact', id: 'contactDate', termSearch:'contactDate', filter: CustomDate, filterXPos: 'left', styles:{width: '15%'} },
    { label: 'Commentaire(s)', id: 'comment', filter: null, termSearch: 'evaluatedBy.fullName', filterXPos: 'right', styles:{width: '15%'} },
    {
      label: 'Statut',
      id: 'status',
      filter:  CustomSelect,
      filterXPos: 'right',
      styles:{width: '15%'},
      termSearch: 'status',
      options:[
        {label: "Go", value: 'GO'},
        {label: "No Go", value: 'NO_GO'}
      ]
    },
    { label: 'Raison', id: 'reason',filter: null, filterXPos: 'right', styles:{width: '20%'}  }
  ]

  const searchChange = value => {
    updateFilters(value, 'term')
  }


  useEffect(() => {
    developpmentService.fetchExterneExchange(`?${buildUrl(filters).slice(0, -1)}&${sorting.sortMode}`).then(
      data => setData({
        status: data.data['status'],
        data: data.data['hydra:member'],
        next: data.data['hydra:view'] && data.data['hydra:view']['hydra:next'],
        prev: data.data['hydra:view'] && data.data['hydra:view']['hydra:previous']
      })
    )
    setFilterOpen(!filterOpen)
  }, [filters, sorting]);

  async function navigationChange(endpoint){
    const result = await useDataListNavigation(endpoint)
    setData(result)
  }

  return (
    data &&
    <>
      <div className="col-md-11 mr-auto ml-auto">
        <Legend data={data.status} role={props.role} />
      </div>
      <div className="box col-md-11 mr-auto ml-auto externe_feedback">
        <DataListHeader
          hasSearch={true}
          label="Historique des échanges"
          searchChange={value => searchChange(value)}
          actions={
            hasCap('PARTNER', props.role) ? [{label: 'Créer un échange', classes: 'button-ui button-ui--blue', url : routePath( `feedback/externe/edit?userId=${props.userId}`)}] : null
          }/>
        <Listing>
          <Listing.Header>
            {
              listingHeader.map(
                header =>
                  <HeaderThead
                    key={header.id}
                    termSearch={header.termSearch}
                    filter={filters[header.id]}
                    setFilterOpen={ value => setFilterOpen( value === filterOpen ? null :value) }
                    filterOpen={filterOpen}
                    select_data={props.select_data}
                    sorting={sorting}
                    header={header}
                    onFilterChange={ (type, value) => updateFilters(value, type) }
                    updateSorting={ (key, sortMode) => setSorting({ key, sortMode}) }
                    styles={header.styles}
                    selectOptions={header.options}
                  />
              )
            }
          </Listing.Header>
          <Listing.Content>
            { data && data.data.map( data => <LineListingExchange role={props.role} listingHeader={listingHeader} sorting={sorting} data={data} /> )}
          </Listing.Content>
        </Listing>
        { data && <ListingNavigation next={data.next} prev={data.prev} navigationChange={endpoint => navigationChange(endpoint) }/> }
      </div>
    </>
  )
}

const mapStateToProps = (state) => ({
  role: state.Role.role
})

export default connect(mapStateToProps, null)(DataList)

