import React from 'react'

const FormFeedbackSwicther = ({onSelect, options}) => {

  const [formType, setFormType] = React.useState(options[0]);
  const [choiceOpen, setChoiceOpen] = React.useState(false);

  React.useEffect(() => {
    onSelect(formType)
    setChoiceOpen(false)
  }, [formType]);


  return (
    <div className="form-feedback-switcher">
      <span onClick={() => setChoiceOpen(!choiceOpen)}>
        {formType}
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M17.7072 9.29304L12.0001 3.58594L6.29297 9.29304L7.70718 10.7073L12.0001 6.41436L16.293 10.7073L17.7072 9.29304Z" fill="white"/>
          <path d="M7.70718 13.293L12.0001 17.5859L16.293 13.293L17.7072 14.7073L12.0001 20.4144L6.29297 14.7073L7.70718 13.293Z" fill="white"/>
        </svg>
      </span>
      {choiceOpen &&
      <div className="options">
        <ul>
          {options.filter(o => o !== formType).map(form => <li onClick={() => setFormType(form)}>{form}</li>)}
        </ul>
      </div>
      }
    </div>
  )
}

export default FormFeedbackSwicther
