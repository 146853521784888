import * as _ from "lodash";
import initialState from "./initialState";

function index(state = initialState, action) {

  if (_.isUndefined(action)) {
    return state;
  }

  const { type, payload } = action;

  switch (type) {
    case 'UPDATE_LIVRABLE_LIST': {
      return {
        ...state,
        list:  payload.append ? [...state.list, ...payload.data["hydra:member"]] : [...payload.data["hydra:member"]],
        next_url: payload.data["hydra:view"]["hydra:next"],
        status: payload.data['status']
      }
    }
    default:
      return state;
  }
}

export default index;
