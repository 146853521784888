import React from 'react'
import {Route, Redirect} from 'react-router-dom';
import {routePath} from '../../helpers/route_helper';
import {hasCap, userProfileAttrsHasRight} from '../../helpers/app';

const ProtectedRoute = ({component: Component, ...rest}) => (
  <Route
    render={props =>
      (
        hasCap(rest.protection, rest.current_role) || (
          rest.attributesUserProtection !== undefined && userProfileAttrsHasRight(rest.attributesUserProtection, rest.user)
        )
      ) ? <Component {...props} /> : <Redirect to={routePath('my-profile')}/>
    }
    {...rest}
  />
)

export default ProtectedRoute
