import React from 'react'
import {connect} from "react-redux";
import {bindActionCreators} from "redux";
import {tryEditUserDispoCourte} from "../../../../../reducers/Planning/planning.actions";
import {planningService} from "../../../../../_services/planningService";
import {ModalContext} from "../../../../../context/ModalContext";

const ConfirmDelete = props => {
  return(
    <>
      <header className="salmon">
        <svg width="94" height="96" viewBox="0 0 94 96" fill="none" xmlns="http://www.w3.org/2000/svg">
          <circle cx="47.5" cy="52.5" r="39.5" fill="white"/>
          <path fillRule="evenodd" clipRule="evenodd" d="M47.5001 56.8016L30.51 73.7917C29.3221 74.9796 27.3962 74.9796 26.2084 73.7917C25.0206 72.6039 25.0206 70.678 26.2084 69.4902L43.1985 52.5001L26.2084 35.51C25.0206 34.3221 25.0206 32.3962 26.2084 31.2084C27.3962 30.0206 29.3221 30.0206 30.51 31.2084L47.5001 48.1985L64.4902 31.2084C65.678 30.0206 67.6039 30.0206 68.7917 31.2084C69.9796 32.3962 69.9796 34.3221 68.7917 35.51L51.8016 52.5001L68.7917 69.4902C69.9796 70.678 69.9796 72.6039 68.7917 73.7917C67.6039 74.9796 65.678 74.9796 64.4902 73.7917L47.5001 56.8016Z" fill="#F33F3F"/>
        </svg>
      </header>
      <div className="content">
        <p className="title">Êtes-vous sur de vouloir supprimer cette dispo courte?</p>
        <div className="actions">
          <button className="white" onClick={ () => props.onCancel() }>Non</button>
          <button className="blue" onClick={ () => props.onConfirm() }>Oui</button>
        </div>
      </div>
    </>
  )
}

const LineListing = props => {

  const [modal, setModal] = React.useContext(ModalContext);

  const deleteItem = () => {
    setModal({
      ...modal,
      display: true,
      component : <ConfirmDelete onCancel={() => setModal({...modal, display: false, component: null})} onConfirm={executeConfirm}/>
    })
  }

  const executeConfirm = () => {
    planningService.deleteShortAvailability(props.dispo.id).then( () => {
      props.onReload()
      setModal({...modal, display: false, component: null})
    })
  }

  return (
    <tr>
      <td>
        <img src={ props.profile.pofile_pic } alt="profilepic"/>
      </td>
      <td>
        <span>{ props.dispo.date }</span>
      </td>
      <td>
        <span>{props.dispo.plage.label}</span>
      </td>
      <td>
        <button className="delete" onClick={deleteItem}>
          Supprimer
          <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fillRule="evenodd" clipRule="evenodd" d="M8.00001 8.94416L3.80475 13.1394L2.86194 12.1966L7.0572 8.00135L2.86194 3.80609L3.80475 2.86328L8.00001 7.05854L12.1953 2.86328L13.1381 3.80609L8.94282 8.00135L13.1381 12.1966L12.1953 13.1394L8.00001 8.94416Z" fill="#F33F3F"/>
          </svg>
        </button>
      </td>
    </tr>
  )
}

export default connect(
  state => ({
    profile: state.Role
  }),
  dispatch => {
    return bindActionCreators({ tryEditUserDispoCourte }, dispatch);
  }
)(LineListing);
