import React from 'react'
import ExpAdder from '../../components/missions/ExpAdder';
import StarsRating from '../../containers/profileComponent/components/StarsRating';

const LineExp = ({rating, labelData, deleteExp, editExp, id}) => {
  return (
    <div className="ex-line" style={{
      display: 'flex',
      alignItems: 'center',
      marginRight: 12,
      background: '#f2f2f4',
      padding: 8,
      borderRadius: 4,
      marginBottom: 8
    }}>
      <span style={{
        fontSize: '.875rem',
        color: '#003d71'
      }}>{labelData && labelData.label}</span>
      <div className="rating" style={{marginLeft: 40, marginTop: -4}}>
        <StarsRating stars={4} rating={rating}/>
        <span className="edit" style={{marginLeft: 4, cursor: 'pointer'}} onClick={() => editExp(id)}>
          <svg width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fillRule="evenodd" clipRule="evenodd"
                  d="M15.0604 5.56144C15.8089 4.81285 17.0281 4.81285 17.7981 5.56144L19.1883 6.95169C19.9369 7.70028 19.9369 8.91942 19.1883 9.6894L18.5954 10.2823L14.468 6.15383L15.0604 5.56144ZM13.5918 7.03001L6.05585 14.5659C5.71363 14.9296 5.49976 15.4215 5.49976 15.9348V18.6083C5.49976 18.972 5.7778 19.25 6.14141 19.25H8.81495C9.32827 19.25 9.82021 19.0575 10.1838 18.6939L17.7192 11.1585L13.5918 7.03001Z"
                  fill="#00B5D4"/>
          </svg>
        </span>
        <span className="delete" style={{marginLeft: 4, cursor: 'pointer'}} onClick={() => deleteExp(id)}>
          <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
              d="M17.6538 5.15385H14.6923V4.61538C14.6923 3.72467 13.9676 3 13.0769 3H10.9231C10.0324 3 9.30769 3.72467 9.30769 4.61538V5.15385H6.34615C5.60388 5.15385 5 5.75773 5 6.5V8.38462C5 8.68198 5.2411 8.92308 5.53846 8.92308H5.83273L6.29793 18.6922C6.33902 19.555 7.04777 20.2308 7.91146 20.2308H16.0885C16.9523 20.2308 17.661 19.555 17.7021 18.6922L18.1673 8.92308H18.4615C18.7589 8.92308 19 8.68198 19 8.38462V6.5C19 5.75773 18.3961 5.15385 17.6538 5.15385ZM10.3846 4.61538C10.3846 4.31849 10.6262 4.07692 10.9231 4.07692H13.0769C13.3738 4.07692 13.6154 4.31849 13.6154 4.61538V5.15385H10.3846V4.61538ZM6.07692 6.5C6.07692 6.35155 6.19771 6.23077 6.34615 6.23077H17.6538C17.8023 6.23077 17.9231 6.35155 17.9231 6.5V7.84615C17.7571 7.84615 6.76457 7.84615 6.07692 7.84615V6.5ZM16.6264 18.641C16.6127 18.9286 16.3764 19.1538 16.0885 19.1538H7.91146C7.62355 19.1538 7.3873 18.9286 7.37364 18.641L6.91087 8.92308H17.0891L16.6264 18.641Z"
              fill="#00B5D4"/>
            <path
              d="M12 18.0769C12.2974 18.0769 12.5385 17.8358 12.5385 17.5385V10.5385C12.5385 10.2411 12.2974 10 12 10C11.7026 10 11.4615 10.2411 11.4615 10.5385V17.5385C11.4615 17.8358 11.7026 18.0769 12 18.0769Z"
              fill="#00B5D4"/>
            <path
              d="M14.6923 18.0769C14.9896 18.0769 15.2307 17.8358 15.2307 17.5385V10.5385C15.2307 10.2411 14.9896 10 14.6923 10C14.3949 10 14.1538 10.2411 14.1538 10.5385V17.5385C14.1538 17.8358 14.3949 18.0769 14.6923 18.0769Z"
              fill="#00B5D4"/>
            <path
              d="M9.30775 18.0769C9.60511 18.0769 9.84621 17.8358 9.84621 17.5385V10.5385C9.84621 10.2411 9.60511 10 9.30775 10C9.01038 10 8.76929 10.2411 8.76929 10.5385V17.5385C8.76929 17.8358 9.01035 18.0769 9.30775 18.0769Z"
              fill="#00B5D4"/>
          </svg>
        </span>
      </div>
    </div>
  )
}

const ExpAdderField = ({field, form: {touched, errors}, ...props}) => {

  const [editMode, setEditMode] = React.useState(false);
  const [defaultValues, setDefaultValues] = React.useState([]);

  React.useEffect(() => {
    setDefaultValues(field.value)
  }, [field.value]);

  React.useEffect(() => {
    if (defaultValues.length > 0) {
      props.setFieldValue(field.name, defaultValues)
    }
    if (props.handleChange !== undefined) {
      props.handleChange(
        props.type,
        defaultValues
      )
    }
  }, [defaultValues]);


  const onAdd = appendedData => {
    if (defaultValues.find(data => data[props.type].id === appendedData[props.type].id) !== undefined) {
      setDefaultValues(
        defaultValues.map(data => {
          if (data[props.type].id === appendedData[props.type].id) {
            return {
              ...data,
              rating: appendedData.rating
            }
          }
          return data
        })
      )
    } else {
      setDefaultValues([
        ...defaultValues,
        appendedData
      ])
    }
  }

  return (
    <div className="form-group">
      <div style={{background: '#fff'}}>
        <label style={{
          color: "#000",
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
          padding: 12,
          margin: 0
        }}>
          {props.label}
          <span style={{cursor: 'pointer'}} onClick={() => setEditMode(!editMode)}>
            <svg width="26" height="25" viewBox="0 0 26 25" fill="none" xmlns="http://www.w3.org/2000/svg">
              <rect x="0.5" width="25" height="25" rx="12.5" fill="#00B5D4"/>
              <path
                d="M13.6071 6.25H11.8929V11.3929H6.75V13.1071H11.8929V18.25H13.6071V13.1071H18.75V11.3929H13.6071V6.25Z"
                fill="white"/>
            </svg>
          </span>
        </label>
        <div style={{
          padding: (
                     defaultValues.length > 0 || editMode
                   ) ? 12 : 0,
          background: editMode ? '#e0f3f8' : '#fff'
        }}>
          {
            editMode ? <ExpAdder
              selectData={props.selectDatas.filter(s => !defaultValues.reduce((acc,item) =>{
                acc.push(item[props.type].id)
                return acc
              },[]).includes(s.value)
              )}
              type={props.type}
              labelTooltipContent={props.labelTooltipContent}
              onAdd={data => onAdd(data)}
              onCancel={() => setEditMode(!editMode)}
              edit={editMode}
            /> : defaultValues && <div style={{
              display: 'flex',
              flexWrap: 'wrap'
            }}>
              {defaultValues.map((exp, index) =>
                <LineExp
                  key={index}
                  rating={exp.rating}
                  labelData={props.selectDatas.find(data => data.value === exp[props.type].id)}
                  id={exp[props.type].id}
                  editExp={id => {setEditMode(defaultValues.find(item => item[props.type].id === id))}}
                  deleteExp={id => setDefaultValues(defaultValues.filter(item => item[props.type].id !== id))}
                />
              )}
            </div>
          }
        </div>
      </div>
    </div>
  )
};

export default ExpAdderField
