import React from 'react'

const InterestLevel = props => {

  const renderPicto = (interest) => {
    if( interest === false ) {
      return(
        <svg width="14" height="16" viewBox="0 0 14 16" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path fillRule="evenodd" clipRule="evenodd" d="M5.47604 1.74982C5.47604 1.11409 6.01183 0.663365 6.58674 0.666767C8.11401 0.674105 9.38063 1.88545 9.38063 3.41699V3.43887C9.38063 3.80214 9.29523 4.16362 9.12549 4.4909L9.12476 4.49231L8.9496 4.83311H11.0249C11.9342 4.83311 12.8528 5.38023 13.1417 6.32518C13.3007 6.84686 13.2533 7.35398 13.0644 7.7863C13.4331 8.16226 13.6666 8.67367 13.6666 9.24973C13.6666 9.82581 13.433 10.3373 13.0644 10.7132C13.1748 10.9659 13.2376 11.2456 13.2376 11.5419C13.2376 12.4207 12.6998 13.1499 11.9511 13.4756C11.9193 14.5224 11.0422 15.3334 9.99966 15.3334C8.50569 15.3334 7.02434 15.0757 5.62177 14.5742L3.30537 13.7444C3.12826 13.6928 2.94276 13.6662 2.75654 13.6662H0.333313V5.6666H2.75411C2.8147 5.6666 2.86902 5.64077 2.90377 5.6022L5.435 2.78904L5.43651 2.78737C5.46244 2.75874 5.47604 2.72201 5.47604 2.68652V1.74982Z" fill="#D4E0E3"/>
        </svg>
      )
    } else if( interest === true ) {
      return(
        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path fillRule="evenodd" clipRule="evenodd" d="M6.47604 1.74982C6.47604 1.11409 7.01183 0.663365 7.58674 0.666767C9.11401 0.674105 10.3806 1.88545 10.3806 3.41699V3.43887C10.3806 3.80214 10.2952 4.16362 10.1255 4.4909L10.1248 4.49231L9.9496 4.83311H12.0249C12.9342 4.83311 13.8528 5.38023 14.1417 6.32518C14.3007 6.84686 14.2533 7.35398 14.0644 7.7863C14.4331 8.16226 14.6666 8.67367 14.6666 9.24973C14.6666 9.82581 14.433 10.3373 14.0644 10.7132C14.1748 10.9659 14.2376 11.2456 14.2376 11.5419C14.2376 12.4207 13.6998 13.1499 12.9511 13.4756C12.9193 14.5224 12.0422 15.3334 10.9997 15.3334C9.50569 15.3334 8.02434 15.0757 6.62177 14.5742L4.30537 13.7444C4.12826 13.6928 3.94276 13.6662 3.75654 13.6662H1.33331V5.6666H3.75411C3.8147 5.6666 3.86902 5.64077 3.90377 5.6022L6.435 2.78904L6.43651 2.78737C6.46244 2.75874 6.47604 2.72201 6.47604 2.68652V1.74982Z" fill="#7ED321"/>
        </svg>
      )
    } else {
      return(
        <svg width="14" height="16" viewBox="0 0 14 16" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path fillRule="evenodd" clipRule="evenodd" d="M5.47604 1.74982C5.47604 1.11409 6.01183 0.663365 6.58674 0.666767C8.11401 0.674105 9.38063 1.88545 9.38063 3.41699V3.43887C9.38063 3.80214 9.29523 4.16362 9.12549 4.4909L9.12476 4.49231L8.9496 4.83311H11.0249C11.9342 4.83311 12.8528 5.38023 13.1417 6.32518C13.3007 6.84686 13.2533 7.35398 13.0644 7.7863C13.4331 8.16226 13.6666 8.67367 13.6666 9.24973C13.6666 9.82581 13.433 10.3373 13.0644 10.7132C13.1748 10.9659 13.2376 11.2456 13.2376 11.5419C13.2376 12.4207 12.6998 13.1499 11.9511 13.4756C11.9193 14.5224 11.0422 15.3334 9.99966 15.3334C8.50569 15.3334 7.02434 15.0757 5.62177 14.5742L3.30537 13.7444C3.12826 13.6928 2.94276 13.6662 2.75654 13.6662H0.333313V5.6666H2.75411C2.8147 5.6666 2.86902 5.64077 2.90377 5.6022L5.435 2.78904L5.43651 2.78737C5.46244 2.75874 5.47604 2.72201 5.47604 2.68652V1.74982Z" fill="#D4E0E3"/>
        </svg>
      )
    }
  }

  return (
    <div>
      { renderPicto(props.interestLevel) }
    </div>
  )
}

export default InterestLevel
