import React from 'react'
import '../style.scss'
import Calendar from 'react-calendar';
import moment from 'moment'
import cx from 'classnames/bind'

const DatePickerField = ({field, form: { touched, errors }, ...props}) => {

  const [value, onChange] = React.useState(field.value || null);
  const [showCal, setShowCal] = React.useState(false);

  React.useEffect(() => {
    if( value ) {
      props.setFieldValue(field.name, moment(value).format('YYYY-MM-DD'))
      setShowCal(false)
    }
  }, [value]);

  React.useEffect(() => {
    if(field.value !== '') {
      onChange(field.value)
    }
  }, [field.value]);

  return (
    <div className="form-group">
      <label className="label">
        { props.label }
        {props.required && <span style={{ color: '#00b5d4', marginLeft: 8 }} className='required'>*</span>}
      </label>
      <div className="input-clone">
        <div>
          <span className={cx({
            date: true,
            empty: value === null
          })}>
            {value === null ? 'JJ' : moment(value).format('DD') }
          </span>
          <span className={cx({
            date: true,
            empty: value === null
          })}>  {value === null ? 'MM' : moment(value).format('MM') }
          </span>
          <span className={cx({
            date: true,
            empty: value === null
          })}>            {value === null ? 'AA' : moment(value).format('YY') }
          </span>
        </div>
        {!props.disabled &&
          <span onClick={() => setShowCal(!showCal)}>
          <svg width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
              d="M8.5 3C7.94772 3 7.5 3.44772 7.5 4V8C7.5 8.55228 7.94772 9 8.5 9C9.05228 9 9.5 8.55228 9.5 8V4C9.5 3.44772 9.05228 3 8.5 3Z"
              fill="#00B5D4"/>
            <path d="M6.5 5H3.5V21H21.5V5H18.5V7H19.5V19H5.5V7H6.5V5Z" fill="#00B5D4"/>
            <path d="M10.5 5V7H14.5V5H10.5Z" fill="#00B5D4"/>
            <path
              d="M15.5 4C15.5 3.44772 15.9477 3 16.5 3C17.0523 3 17.5 3.44772 17.5 4V8C17.5 8.55228 17.0523 9 16.5 9C15.9477 9 15.5 8.55228 15.5 8V4Z"
              fill="#00B5D4"/>
            <path d="M18.5 11H6.5V12H18.5V11Z" fill="#00B5D4"/>
          </svg>
        </span>
        }
        {showCal &&
        <div className="calendar">
          <Calendar
            onChange={onChange}
            defaultValue={value}
            value={field.value ? new Date(field.value) : new Date()}
            activeStartDate={field.value ? new Date(field.value) : new Date()}
          />
        </div> }
      </div>
    </div>
  )
}

export default DatePickerField
