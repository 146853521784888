import React from 'react'
import moment from 'moment'
import {getStatusBackground, hasCap} from '../../../helpers/app';
import {matcher_actions} from '../../../reducers/Matcher/actions';
import Filters from "./Filters";
import {bindActionCreators} from "redux";
import {consultant_creators} from "../../../reducers/Consultants/actions/getListConsultants";
import {connect} from "react-redux";
import ConsultantsList from "./ConsultantsList";
import {AffectContext} from "../../../context/AffectContext";
import RecapSelection from "./RecapSelection";
import {matcher_service} from "../../../_services/matcher_service";
import {missions_action} from "../../../reducers/Missions/actions/getMissionsList";

const SKILLED_ITEMS_PER_PAGE = 6;

const ModalAffectComponent = props => {

  const [affectModal,setAffectModal] = React.useContext(AffectContext);
  const [active,setActive] = React.useState(null);

  function positionRequiredSummary() {
    return props.mission.requiredPositions.reduce((acc,item)=>{
      acc.push(item.name)
      return acc
    }, [] ).join(', ')
  }

  function reloadList() {
    props.updateMissionsList()
  }

  function reloadView() {
    props.updateMission(props.mission.id)
  }

  const validateAffectation = (isValidated = false) => {
    const data = affectModal.consultantsSelected.map(item => ({
      "user": { "id": item.id }
    }));

    matcher_service.affect({
      "mission": {
        "id": props.mission.id
      },
      "matchings": data,
      "validated": isValidated
    }).then(() => {
      props.from && props.from === 'view' ? reloadView() : reloadList()
      closeModal()
    })
  }

  const closeModal = () => {
    setAffectModal({
      ...affectModal,
      display: false,
      affected: true
    })
  }

  const consultantGrids = props.toDisplayMatchBlocks ? [
    {
      code: 'skilled',
      label: 'Match expertises > 3 étoiles',
      data: props.listConsultants,
      total: props.total,
      fetching: props.fetching,
      prevNextNavigation: true,
      skilledItemsPerPage: SKILLED_ITEMS_PER_PAGE,
      listStyle: {
        backgroundColor: '#FFFFFF'
      },
      itemStyle: {
        backgroundColor: '#F6F6F6'
      },
      checkerStyle: {
        backgroundColor: '#FFFFFF'
      },
      ...(
        props.prev && {
          prev: props.prev,
          loadPrev: () => props.updateListConsultant(props.prev, false, false, true)
        }
      ),
      ...(
        props.next && {
          next: props.next,
          loadNext: () => props.updateListConsultant(props.next, false, false, true)
        }
      )
    }, {
      code: 'less-skilled',
      label: 'Match expertises < 3 étoiles',
      data: props.lessSkilledList,
      total: props.lessSkilledTotal,
      fetching: props.lessSkilledLoading,
      listStyle: {
        backgroundColor: '#F6F6F6'
      },
      ...(
        props.lessSkilledNextUrl && {
          next: props.lessSkilledNextUrl,
          loadNext: () => props.getLessSkilledConsultants(props.lessSkilledNextUrl, false),
        }
      )
    }
  ] : [
    {
      code: 'default',
      label: '',
      data: props.listConsultants,
      total: props.total,
      fetching: props.fetching,
      ...(
        props.next && {
          next: props.next,
          loadNext: () => props.updateListConsultant(props.next, false)
        }
      )
    }
  ]

  return (
    <div className="affect-modal">
      <div className="sticky-top" style={{zIndex: 99999999}}>
      <header className="affect-modal__header" style={{alignItems: 'flex-start'}}>
        <h3>Affecter les consultants</h3>
        <div className="d-flex justify-content-between">
          <button
            disabled={affectModal.consultantsSelected.length === 0}
            onClick={() => validateAffectation()}
            className="button-ui button-ui--outline"
          >Valider la pré-affectation</button>
          {
            hasCap('RH', props.role) && <button
              disabled={affectModal.consultantsSelected.length !== 1}
              onClick={() => validateAffectation(true)}
              className="button-ui button-ui--blue"
            >Valider l'affectation</button>
          }
        </div>
      </header>
      <div className="affect-modal__subheader">
        <span className="mission-title flex align-items-center justify-content-center">
          <a href={`/#/missions/view/${props.mission.id}`} style={{ color: '#00B5D4' }} rel="noopener noreferrer" target="_blank">
            <u>{props.mission.title}</u>
          </a>
          <span style={{display: 'flex', marginRight: 20}}>
            <button className="button-ui" style={{
              background: getStatusBackground(props.mission.status),
              padding: '0 5px',
              color: '#fff',
              borderRadius: 4,
              fontSize: 12,
              marginRight: 6
            }}>
              {props.mission.statusData.text}
            </button>
            {props.mission.isArchived &&
            <button className="button-ui button-ui--full-outline button-ui--c-black" style={{
              padding: '0 5px',
              color: '#fff',
              borderRadius: 4,
              fontSize: 12
            }}>
              Archivé
              <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path fillRule="evenodd" clipRule="evenodd" d="M20.7072 7.70712L10.0001 18.4142L3.29297 11.7071L4.70718 10.2929L10.0001 15.5858L19.293 6.29291L20.7072 7.70712Z" fill="black"/>
              </svg>
            </button>
            }
          </span>
        </span>
        <span>
          { positionRequiredSummary() } - Chef de projet  : {props.mission.leaderName} - {props.mission.priority.name} </span>
        <span className="ligther">du {moment(props.mission.startDate).format('DD/MM/YYYY')}  au {moment(props.mission.finishDate).format('DD/MM/YYYY')} </span>
        <hr/>
        <RecapSelection
          active={active}
          setActive={setActive}
          matching={props.mission.matching}
          mission={props.mission}
          selected={affectModal.consultantsSelected}
        />
      </div>
      </div>
      <div className="affect-modal__content">
        <Filters skilledItemsPerPage={SKILLED_ITEMS_PER_PAGE} mission={props.mission} />
        {
          (props.fetching || props.lessSkilledLoading) &&
          <div className="total-results mb-2">
            Reherche en cours...
          </div>
        }
        {
          consultantGrids.map((itemProps, index, { length }) =>
            <>
              <ConsultantsList key={itemProps.code} {...itemProps} />
              {index < length - 1 && <br />}
            </>
          )
        }
      </div>
    </div>
  )
}

const mapStateToProps = (state) => ({
  role : state.Role.role,
  listConsultants: state.Consultants.list,
  next: state.Consultants.next_url,
  prev: state.Consultants.prev_url,
  total: state.Consultants.total,
  fetching: state.App.matcher_list_consultant_fetching,
  // less-skilled props
  lessSkilledList: state.Consultants.lessSkilledList,
  lessSkilledNextUrl: state.Consultants.lessSkilledNextUrl,
  lessSkilledTotal: state.Consultants.lessSkilledTotal,
  lessSkilledLoading: state.Consultants.lessSkilledLoading,
  toDisplayMatchBlocks: state.Consultants.toDisplayMatchBlocks
});

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      updateListConsultant: consultant_creators.get_list_constultants,
      updateMissionsList: missions_action.get_missions_list,
      updateMission: missions_action.get_detail_mission,
      validateAffectation : matcher_actions.validate,
      // less-skilled actions
      getLessSkilledConsultants: consultant_creators.get_less_skilled_list,
    },
    dispatch
  );
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ModalAffectComponent);
