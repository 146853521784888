import {ErrorMessage, Field, Formik} from "formik";
import React from 'react'
import {connect} from "react-redux";
import {bindActionCreators} from "redux";
import * as Yup from "yup";
import {profileService} from "../../../../_services/profile_service";
import {ModalContext} from "../../../../context/ModalContext";
import ErrorMesage from "../../../../Formik/ErrorMesage";
import DatePickerField from "../../../../Formik/Fields/DatePickerField";
import InputField from "../../../../Formik/Fields/InputField";
import TextareaInput from "../../../../Formik/Fields/TextareaInput";
import {_getUserProfile} from "../../../../reducers/App/actions/user";
import {profile_creators} from "../../../../reducers/Profile/actions/_update_profile";

const ConfirmDelete = props => {
  return (
    <>
      <header className="salmon">
        <svg width="94" height="96" viewBox="0 0 94 96" fill="none" xmlns="http://www.w3.org/2000/svg">
          <circle cx="47.5" cy="52.5" r="39.5" fill="white"/>
          <path fillRule="evenodd" clipRule="evenodd"
                d="M47.5001 56.8016L30.51 73.7917C29.3221 74.9796 27.3962 74.9796 26.2084 73.7917C25.0206 72.6039 25.0206 70.678 26.2084 69.4902L43.1985 52.5001L26.2084 35.51C25.0206 34.3221 25.0206 32.3962 26.2084 31.2084C27.3962 30.0206 29.3221 30.0206 30.51 31.2084L47.5001 48.1985L64.4902 31.2084C65.678 30.0206 67.6039 30.0206 68.7917 31.2084C69.9796 32.3962 69.9796 34.3221 68.7917 35.51L51.8016 52.5001L68.7917 69.4902C69.9796 70.678 69.9796 72.6039 68.7917 73.7917C67.6039 74.9796 65.678 74.9796 64.4902 73.7917L47.5001 56.8016Z"
                fill="#F33F3F"/>
        </svg>
      </header>
      <div className="content">
        <p className="title">Êtes-vous sûr de vouloir supprimer cette formation ?</p>
        <p>La suppression sera irréversible</p>
        <div className="actions">
          <button className="white" onClick={() => props.onCancel()}>Annuler</button>
          <button className="blue" onClick={() => props.onConfirm()}>Valider</button>
        </div>
      </div>
    </>
  )
}

const FormationsForm = props => {

  const [modal, setModal] = React.useContext(ModalContext);

  const submit = (values) => {

    const reduced = props.currentDatas.reduce((acc, item) => {
      if (props.data && (
        props.data.id === item.id
      )) {
        acc.push({
          "description": item.description,
          "finishDate": item.finishDate,
          "name": item.name,
          "startDate": item.startDate,
          "university": item.university,
          "city": item.city,
          "id": item.id
        })
      } else {
        acc.push({
          "description": item.description,
          "finishDate": item.finishDate,
          "name": item.name,
          "startDate": item.startDate,
          "university": item.university,
          "city": item.city
        })
      }

      return acc
    }, []);

    let data;

    if (props.data && reduced.find(item => item.id === props.data.id)) {
      const updated = reduced.map(item => {
        if (item.id === props.data.id) {
          item = {
            "description": values.description,
            "finishDate": values.finishDate,
            "name": values.name,
            "startDate": values.startDate,
            "university": values.university,
            "city": values.city
          }
        }
        return item;
      })

      data = {[props.type]: [...updated]}
    } else {
      data = {
        [props.type]: [
          ...reduced,
          {
            "description": values.description,
            "finishDate": values.finishDate,
            "name": values.name,
            "startDate": values.startDate,
            "university": values.university,
            "city": values.city
          }
        ]
      }
    }

    profileService.updateProfile(props.user_id, data).then(() => {
      props.setEditMode(false)
      props.getUserProfile(props.user_id)
    })

  }

  const deleteFormation = id => {
    setModal({
      ...modal,
      display: true,
      component: <ConfirmDelete
        onCancel={() => setModal({...modal, display: false, component: null})}
        onConfirm={() => doConfirm(id)}
      />
    })

  }

  const doConfirm = id => {
    setModal({...modal, display: false, component: null})
    props.remove('trainings', id, localStorage.getItem('user_id'))
    props.setEditMode(false)
    props.getUserProfile(props.user_id)
  }

  const schema = Yup.object().shape({
    name: Yup.string().required('Obligatoire'),
    description: Yup.string().required('Obligatoire'),
    university: Yup.string().required('Obligatoire'),
    startDate: Yup.string().required('Obligatoire'),
    city: Yup.string().required('Obligatoire'),
    finishDate: Yup.string().required('Obligatoire')
  });

  return (
    <div className="edit-mode">
      <div className="wrapper">
        <span className="title" style={{textAlign: 'center', width: '100%'}}>
          {props.data === null ? 'Nouvelle Formation' : 'Modifier une formation'}
        </span>
        <Formik
          onSubmit={submit}
          initialValues={{
            "name": props.data ? props.data.name : '',
            "description": props.data ? props.data.description : '',
            "university": props.data ? props.data.university : '',
            "startDate": props.data ? props.data.startDate : '',
            "finishDate": props.data ? props.data.finishDate : '',
            "city": props.data ? props.data.city : ''
          }}
          validationSchema={schema}
        >
          {(
            {handleSubmit, setFieldValue}) => (
            <form onSubmit={handleSubmit}>
              <div className="flex" style={{padding: '0 85px'}}>
                <div className="col-md-5">
                  <Field obligatoire setFieldValue={setFieldValue} name="name" placeholder="" label="Nom du diplôme"
                         component={InputField}/>
                  <ErrorMessage name="position" component={ErrorMesage}/>
                </div>
                <div className="col-md-5">
                  <Field obligatoire setFieldValue={setFieldValue} name="university" placeholder=""
                         label="Ecole / Université" component={InputField}/>
                  <ErrorMessage name="company" component={ErrorMesage}/>
                </div>
                <div className="col-md-5">
                  <Field setFieldValue={setFieldValue} name="startDate" placeholder="" label="Date de début" required
                         component={DatePickerField}/>
                  <ErrorMessage name="startDate" component={ErrorMesage}/>
                </div>
                <div className="col-md-5">
                  <Field setFieldValue={setFieldValue} name="finishDate" placeholder="" label="Date de fin" required
                         component={DatePickerField}/>
                  <ErrorMessage name="finishDate" component={ErrorMesage}/>
                </div>
                <div className="col-md-12">
                  <Field obligatoire setFieldValue={setFieldValue} name="city" placeholder="" label="Ville"
                         component={InputField}/>
                  <ErrorMessage name="city" component={ErrorMesage}/>
                </div>
                <div className="col-md-12">
                  <Field richtext={true} obligatoire setFieldValue={setFieldValue} name="description" placeholder=""
                         label="Description" component={TextareaInput}/>
                  <ErrorMessage name="description" component={ErrorMesage}/>
                </div>
              </div>
              <div className="actions">
                <button className="button" type="submit">Valider</button>
                <button onClick={() => props.setEditMode(false)} className="button outline" type="button">Annuler
                </button>
                {props.data &&
                <button onClick={() => deleteFormation(props.data.id)} className="button outline" type="button"
                        style={{borderColor: 'red', color: 'red', outline: 'none'}}>Supprimer</button>}
              </div>
            </form>
          )}
        </Formik>
      </div>
    </div>
  )
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({
    getUserProfile: _getUserProfile,
    remove: profile_creators.deleteCompetence
  }, dispatch);
}

export default connect(null, mapDispatchToProps)(FormationsForm)
