const lessSkilledData = {
  lessSkilledList: [],
  lessSkilledNextUrl: null,
  lessSkilledTotal: 0,
  lessSkilledLoading: false,
  /**
   * custom prop to use inside affectation drawer (true ==> display 2 grids)
   * @see https://www.figma.com/file/qJStBKYMMdmnPF3VbienIP/ConvictionsRH-%2F-MyStaff-%2F-UI?node-id=11075%3A154306
   */
  toDisplayMatchBlocks: false,
}

const consultantMissionsInitialState = {
  consultantMissions: [],
  loadingConsultantMissions: false,
}

export default {
  list: [],
  prev_url: null,
  next_url: null,
  total: 0,
  loading: false,
  ...lessSkilledData,
  ...consultantMissionsInitialState,
};
