import React, { Component } from 'react'
import Select from 'react-select';
import * as _ from 'lodash';
const selectStyles = {
  container: (base, state) => ({
    ...base,
    opacity: state.isDisabled ? ".5" : "1",
    backgroundColor: "transparent"
  }),
  menuItem:(base, state) => ({
    ...base,
    zIndex: "99999"
  })
};


export default class FilterComponentSelect extends Component {

  constructor(props) {
    super(props)
    this.state = {
      selectedOption: null
    }

  }

  handleFilterSelect(value) {

    this.setState({
      filters: [ ...this.state.filters , value]
    });

    this.props.select(
      this.state.filters,
      this.props.filter
    )

  }

  handleFilterUnselect(value) {
    this.props.select(
      this.arrayRemove(this.props.selected, value),
      this.props.filter
    )
  }

  arrayRemove(arr, value) {
      if( typeof arr === 'object' ) {
          return arr.filter(function(element){
              return value.includes(element.id);
          });
      }
  }

  filter(arr,value) {
    if( typeof arr === 'object' ) {
      return arr.filter(function(element){
          return !value.includes(element.id);
      });
    }
  }

  handleChange = (selectedOption) => {
    this.setState({ selectedOption });
    this.props.select(
      selectedOption,
      this.props.filter
    )
  }

  componentDidMount() {
    if( this.props.default !== undefined ) {

      let prefilled_array = [];

      prefilled_array.push({
        'value': this.props.default.id ,
        'label' : this.props.default.name
      });
      this.setState({
        selectedOption: prefilled_array
      })

    } else {
      this.setState({
        selectedOption: this.props.value
      })
    }
  }

  componentDidUpdate(prevProps) {

    if( prevProps.prefilled !== this.props.prefilled ) {

      let prefilled_array = [];

      if( this.props.prefilled  ) {

          if( this.props.refer === 'exp' ) {
            if( this.props.prefilled < 2) {
              prefilled_array.push({
                'value': '[lt]=2' ,
                'label' : '- de 2 années'
              })
            }
            else if( this.props.prefilled >= 2 && this.props.prefilled <= 5 ) {
              prefilled_array.push({
                'value': '[between]=2..5',
                'label' : '2 - 5 annnées'
              })
            }
            else if( this.props.prefilled >= 5 && this.props.prefilled <= 8 ) {
              prefilled_array.push({
                'value': '[between]=5..8' ,
                'label' : '5 - 8 annnées'
              })
            }
            else {
              prefilled_array.push({
                'value': '[gt]=8' ,
                'label' : '+ de 8 annnées'
              })
            }
          }

          else {
              if( this.props.refer === 'requiredPositions' ) {
                Object.keys( _.uniq(this.props.prefilled) ).forEach((value, index) => {
                     prefilled_array.push({
                      'value': this.props.prefilled[index].id ,
                      'label' : this.props.prefilled[index].name
                    })
                });
              } else {
                Object.keys( _.uniq(this.props.prefilled) ).forEach((value,index) => {
                  if( this.props.prefilled[index][this.props.refer] !== undefined ) {
                     prefilled_array.push({
                      'value': this.props.prefilled[index][this.props.refer].id ,
                      'label' : this.props.prefilled[index][this.props.refer].name
                    })
                  }
                })
              }
          }
      }

      this.setState({
        selectedOption: prefilled_array
      }, () => this.props.select(
        prefilled_array,
        this.props.filter
      ))
    }
  }

  render() {
    const { selectedOption } = this.state;
    return (
       this.props.data
       ?
          <Select
            styles={selectStyles}
            isSearchable={true}
            placeholder={ this.props.placeholder ? this.props.placeholder : 'Choisissez dans la liste'}
            isMulti={ this.props.multiple === undefined }
            value={selectedOption}
            onChange={this.handleChange}
            options={this.props.data}
          />
          : null
    )
  }
}
