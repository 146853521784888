import React from 'react';
import ReactDOM from 'react-dom';
import * as Sentry from "@sentry/react";
import { Integrations } from "@sentry/tracing";

import * as serviceWorker from './serviceWorker';
import Initialize from './Initialize';
import './styles/index.scss';
import './ui/_responsive.scss';

/**
 * set up sentry
 * @see https://sentry.io/cosavostra/mystaff-react/getting-started/javascript-react/
 */
const SENTRY_FRONT_URL = process.env.REACT_SENTRY_URL;
Sentry.init({
    dsn: SENTRY_FRONT_URL,
    integrations: [new Integrations.BrowserTracing()],
    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: 1.0,
  });

ReactDOM.render(<Initialize />, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
