
import { newConnectConfig } from "./contant";
import Axios from "axios";

export const notif_service = {
  get_user_notifs,
  notificationCheck
};

function notificationCheck() {
    let url = `${newConnectConfig.API_PATH}/hasNotifications`;
    return Axios({
      method:'get',
      url,
      headers: {"Authorization" : `Bearer ${localStorage.getItem('token')}`}
    })
}

function get_user_notifs() {
    let url = `${newConnectConfig.API_PATH}${newConnectConfig.NOTIFICATIONS_ENDPOINT}`;
    return Axios({
      method:'get',
      url,
      headers: {"Authorization" : `Bearer ${localStorage.getItem('token')}`}
    })
}

