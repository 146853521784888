import React from 'react'
import cx from 'classnames/bind'
import moment from "moment";
import {Link} from "react-router-dom";
import Select from "react-select";
import {connect} from "react-redux";
import {bindActionCreators} from "redux";
import {tryBookDispo} from "../../../reducers/Planning/planning.actions";

const UserAvailibilityBubble = (
  {availability,setBubbleOpen,bubbleOpen, ...props}
  ) => {

  const [facturable, setFacturable] = React.useState( null);
  const [comment, setComment] = React.useState( '');

  const setBubbleActive = (plage,e) => {
    const key = `${plage.user_id}-${plage.dispo}-${plage.plage}`
    if( !e.target.closest('.actions-card') )
      setBubbleOpen( bubbleOpen === key ? null : key )
  }
  const bookConsultant = consultant => {
    props.tryBookDispo(
      consultant.short_availabilities_id,
      {
        projectState : facturable.label, // Donnée obligatoire
        comment : comment
      },
      consultant.fullName
    )
  }

  const unBook = consultant => {
    props.tryBookDispo(
      consultant.short_availabilities_id,
      {},
      consultant.fullName,
      true
    )
  }

  return (
    <div>
      {Object.values(availability).map(
        (plage, index) => plage === undefined ? <div key={`plage-${index}`} className='bubble bubble--empty'/> : <div
          key={`plage-${index}`}
          onMouseLeave={ () => setBubbleOpen(null) }
          className={cx({bubble:true, unavailable: plage.booked, facturable: plage.staffer && plage.staffer.data.project_state === 'Facturable' })} onClick={ e =>setBubbleActive(plage,e)}>
          <img src={plage.profilePic} alt=""/>
          <div className={cx({
            "actions-card": true,
            "actions-card--active": bubbleOpen === `${plage.user_id}-${plage.dispo}-${plage.plage}`
          })}>
            <header>
              <div>{ plage.booked ? 'Indisponible' : 'Disponible' }</div>
              <div>{ moment(plage.dispo).format('DD MMM')}</div>
            </header>
            <div className="content">
              {
                !plage.booked
                  ?
                  <div style={{width: '100%'}}>
                    <Select
                      isSearchable={false}
                      isMulti={false}
                      value={facturable}
                      onChange={ (value) => setFacturable(value) }
                      placeholder='Facturable?'
                      options={[
                        {value:true, label : 'Facturable'},
                        {value:false, label : 'Non Facturable'}
                      ]}
                    />
                    <textarea
                      style={{
                        borderRadius: 8,
                        border: '1px solid #dedede',
                        marginTop : 10,
                        maxWidth: '100%',
                        padding: 10,
                        fontSize: '13px',
                        lineHeight: '18px',
                        width: '100%'
                      }}
                      onChange={ e => setComment(e.target.value) }
                      placeholder="Nom du besoin ou de l'activité interne"
                    />
                    <button
                      disabled={(facturable === null || comment === '')}
                      className="button"
                      style={{width: '100%', color:"#fff", marginTop: 8}}
                      onClick={ () => bookConsultant(plage)}
                    >Planifier</button>
                  </div>
                  : <span  style={{ textAlign: 'left', fontSize: 14 }}>
                    <Link to={`consultant/view/${plage.staffer.id}`} target="_blank"  style={{
                      display: 'block',
                     textAlign: 'left', fontSize: 14, color: '#222222' }}>
                      Staffé par
                      <div className="card" style={{
                        display:'flex',
                        justifyContent: 'flex-start',
                        padding: '10px 8px',
                        marginTop: 5,
                        flexDirection: 'row',
                        alignItems: 'center',
                        background: '#06b5d338',
                        width: 'calc(100% + 16px)',
                        marginLeft: '-8px',
                        borderRadius: 0
                      }}>
                        <span>
                          <img src={ plage.staffer.profilePic } alt='' />
                        </span>
                        <span style={{marginLeft: 8}}>
                            <h4 style={{ fontSize: 16,color: '#222222' }}>{plage.staffer.fullName} </h4>
                            <span style={{ fontSize: 11,color: '#00b5d4' }}> {plage.staffer.role} </span>
                        </span>
                      </div>
                    </Link>
                    {plage.staffer.data.comment && <p style={{ marginTop: 10, fontStyle:'italic', width: '100%' }}>{plage.staffer.data.comment} </p> }
                    <button
                      className="button"
                      style={{width: '100%', color:"#fff", marginTop: 8}}
                      onClick={ ()=> unBook(plage) }
                    >Déplanifier</button>
                  </span>
              }
            </div>
          </div>
        </div>
      )}
    </div>
  )
}

export default connect(
  null,
  dispatch => {
    return bindActionCreators({ tryBookDispo }, dispatch);
  }
)(UserAvailibilityBubble);
