import React, {useState} from 'react'
import {withRouter} from "react-router";
import {AppService} from "../_services/App";
import maintenanceLogo from '../assets/images/20945780.jpg'

function MaintenanceModeScreen() {
    return (
        <div className="wrapper" style={{marginTop: 103, display: 'flex', flexFlow: 'column'}}>
            <div className="container mt-4">
                <div className="row d-flex align-items-center">
                    <div className="col-md-4 offset-1 mb-4">
                        <span className="t-header-large">Maintenance <br/> en cours.</span>
                        <p className="t-base-small mt-3">MyStaff est momentanément indisponible pour cause de
                            maintenance. Veuillez revenir plus tard, merci de votre compréhension !</p>
                    </div>
                    <div className="col">
                        <img style={{borderRadius: 8}} src={maintenanceLogo} className="img-fluid"/>
                    </div>
                </div>
            </div>
        </div>
    );
}

const MaintenanceChecker = props => {

    const [appReady, setAppReady] = useState(false)
    const [isMaintenance, setIsMaintenance] = useState(false)

    React.useEffect(() => {
        AppService.checkMaintenance(`?user.id=${localStorage.getItem('user_id')}`)
            .then(response => {
                setAppReady(true)
                setIsMaintenance(() => response.data)
            })
    }, [props.location])

    return (
        <>
            {
                appReady
                    ?
                    isMaintenance
                        ?
                        <MaintenanceModeScreen/>
                        :
                        props.children
                    : null
            }
        </>
    )
}

export default withRouter(MaintenanceChecker)
