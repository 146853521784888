import React, { useEffect } from 'react';
import {connect} from 'react-redux';
import { bindActionCreators } from "redux";
import { fetchUserDispo } from '../../../reducers/Planning/planning.actions';
import Listing from "../../../components/ui/Listing/Listing";
import HeaderThead from "../../Missions/listing/header";
import LineListing from "./listing/content/LineListing";

const DispoCourteManager = (props) => {

    useEffect(() => {
        props.fetchUserDispo()
    }, [props.data] )

    const onReload = () => props.fetchUserDispo()

    const listingHeader = [
      { label: '', id: '', filter: null ,filterXPos: 'left', styles: {width: 40} },
      { label: 'Date', id: '', filter: null ,filterXPos: 'left', styles: {width: 160} },
      { label: 'Plage horaire', id: '', filter: null ,filterXPos: 'left', styles: {width: 160} },
      { label: '', id: '', filter: null ,filterXPos: 'left'}
    ]

    return (
      <div className="col-md-12 col-lg-12">
        <Listing>
          <Listing.Header>
            { listingHeader.map( (header, index) => <HeaderThead styles={header.styles} sorting='' select_data={props.select_data} header={header} key={`header-${index}`} />)}
          </Listing.Header>
          <Listing.Content>
            { props.userDispos && props.userDispos.map( (dispo, index) => <LineListing onReload={onReload} dispo={dispo} key={`content-${index}`}/> )}
          </Listing.Content>
        </Listing>
      </div>
    )
}

export default connect(
    state => ({
        userDispos: state.planning.userDispos,
        profile: state.Role
    }),
    dispatch => {
        return bindActionCreators({ fetchUserDispo }, dispatch);
    }
)(DispoCourteManager);
