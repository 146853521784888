import React from 'react'
import CoreSkills from './components/edits/CoreSkills';
import ExpertiseParentForm from './components/edits/ExpertiseParentForm';
import ParentExpertiseContainer from './components/ParentExpertiseContainer';
import StarsRating from './components/StarsRating';
import LineRatingItem from "./LineRatingItem";
import {hasCap} from "../../helpers/app";
import {bindActionCreators} from "redux";
import {_getUserProfile} from "../../reducers/App/actions/user";
import {connect} from "react-redux";
import LanguageForm from "./components/edits/LanguageForm";
import LineRatingItemSocle from './LineRatingItemSocle';

const IconPlus = props => {
  return(
    ( props.props.match.path === "/my-profile" || hasCap('MANAGER', props.props.role ) ) &&
    <span onClick={props.onClick}>
         <svg width="25" height="25" viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect width="25" height="25" rx="12.5" fill="#00B5D4"/>
            <path d="M13.1071 6.25H11.3929V11.3929H6.25V13.1071H11.3929V18.25H13.1071V13.1071H18.25V11.3929H13.1071V6.25Z" fill="white"/>
          </svg>
      </span>
  )
}

const Competences = props => {

  const [EditMode, setEditMode] = React.useState(null);
  const [data, setData] = React.useState(null);
  const [sectionOpened, setSectionOpened] = React.useState(null);

  const disPlayEditForm = (type, d = null, list = [], parent = null) => {
    switch (type) {
      case 'skills':
        !EditMode ? setEditMode(
          <ExpertiseParentForm
            parent={parent}
            labelTooltipContent={props.labelTooltipContent}
            data={d}
            list={list}
            user_id={props.props.profile.id}
            userExperinces={`parent_skills?experiences.userExperiences.user.id=${props.props.profile.id}`}
            currentDatas={props.props.profile.userSkills}
            type='userSkills'
            parentEndpont='parent_skills'
            parentKey='skills'
            keySave='skill'
            setEditMode={setEditMode} />
            ) : setEditMode(false)
        break;
      case 'fields':
        !EditMode ? setEditMode(
          <ExpertiseParentForm
            parent={parent}
            labelTooltipContent={props.labelTooltipContent}
            data={d}
            list={list}
            user_id={props.props.profile.id}
            userExperinces={`parent_fields?experiences.userExperiences.user.id=${props.props.profile.id}`}
            currentDatas={props.props.profile.userFields}
            type='userFields'
            parentEndpont='parent_fields'
            parentKey='fields'
            keySave='field'
            setEditMode={setEditMode} />
        ) : setEditMode(false)
        break;
      case 'solutions':
        !EditMode ? setEditMode(
          <ExpertiseParentForm
            parent={parent}
            labelTooltipContent={props.labelTooltipContent}
            data={d}
            list={list}
            user_id={props.props.profile.id}
            userExperinces={`parent_solutions?experiences.userExperiences.user.id=${props.props.profile.id}`}
            currentDatas={props.props.profile.userSolutions}
            type='userSolutions'
            parentEndpont='parent_solutions'
            parentKey='solutions'
            keySave='solution'
            setEditMode={setEditMode} />
        ) : setEditMode(false)
        break;
      case 'languages':
        !EditMode ? setEditMode(
          <LanguageForm
            data={d}
            labelTooltipContent={props.labelTooltipContent}
            user_id={props.props.profile.id}
            currentDatas={props.props.profile.userLanguages}
            type='userLanguages'
            setEditMode={setEditMode} />
        ) : setEditMode(false)
        break;
      case 'CoreSkills':
        !EditMode ? setEditMode(
          <CoreSkills
            data={d}
            labelTooltipContent={props.labelTooltipContent}
            user_id={props.props.profile.id}
            currentDatas={props.props.profile.userCoreSkills}
            type='core_skills'
            setEditMode={setEditMode} />
        ) : setEditMode(false)
        break;
      default:
        break;
    }
  }

  React.useEffect(() => {
    if( EditMode !== null && !EditMode) {
      props.getUserProfile(props.props.profile.id)
    }
  }, [EditMode]);

  React.useEffect(() => {
    if(typeof props.datas === 'function') {
      props.datas().then(response => {
        setData(response.data['hydra:member'])
      })
    } else {
      setData(props.datas)
    }
  }, [props.datas]);


  const getLineratingItems = () => data.map(
    (value, index) =>
      props.type !== 'CoreSkills' ?
        <LineRatingItem
          profile={props.props.profile}
          role={props.props.role}
          key={index}
          onEdit={(type, data) => disPlayEditForm(type, data)}
          col={props.col}
          data={value}
          type={props.type}
          hasInterest={props.hasInterest}
          labelTooltipContent={props.labelTooltipContent}
        />
        : <LineRatingItemSocle
          profile={props.props.profile}
          role={props.props.role}
          key={index}
          onEdit={(type, data) => disPlayEditForm(type, data)}
          col={props.col}
          data={value}
          type={props.type}
          hasInterest={props.hasInterest}
          labelTooltipContent={props.labelTooltipContent}
        />
  )

  const getLineratingItemsByParent = () =>  props.hasParent !== undefined ? <ParentExpertiseContainer
    profile={props.props.profile}
    role={props.props.role}
    type={props.type}
    subType={props.subType}
    onEdit={(type, d, list, parent) => disPlayEditForm(type, d, list, parent)}
    setSectionOpened={setSectionOpened}
    sectionOpened={sectionOpened}
    data={data}
    labelTooltipContent={props.labelTooltipContent}
    col={props.col}
  /> : getLineratingItems()

  return (
    data &&
    <>
      <h3>
        <span className="expertise-box-name" style={{
          position: 'relative',
          marginRight: 12
        }}>
          {props.label}
          <svg style={{
            position: 'absolute',
            right: -20,
            top: -8
          }} width="17" height="17" viewBox="0 0 17 17" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clipPath="url(#clip0_10539_149037)">
              <path d="M8.68164 16.0254C6.55972 16.0254 4.52484 15.1823 3.02484 13.6822C1.52484 12.1821 0.681641 10.1472 0.681641 8.02539C0.681641 5.90363 1.52476 3.86859 3.02484 2.36859C4.52492 0.868591 6.55988 0.0253906 8.68164 0.0253906C10.8034 0.0253906 12.8384 0.868511 14.3384 2.36859C15.8384 3.86867 16.6816 5.90363 16.6816 8.02539C16.6791 10.1467 15.8354 12.1798 14.336 13.6798C12.836 15.1792 10.8029 16.023 8.68164 16.0254ZM8.68164 1.19979C6.87172 1.19979 5.1354 1.91917 3.8554 3.19915C2.5754 4.47913 1.85604 6.21547 1.85604 8.02539C1.85604 9.83531 2.57542 11.5716 3.8554 12.8516C5.13538 14.1316 6.87172 14.851 8.68164 14.851C10.4916 14.851 12.2279 14.1316 13.5079 12.8516C14.7879 11.5716 15.5072 9.83531 15.5072 8.02539C15.5054 6.21595 14.7854 4.48107 13.506 3.20107C12.226 1.92169 10.4909 1.20171 8.68164 1.19979Z" fill="#003D71"/>
              <path d="M8.68113 6.99609C9.04612 6.99609 9.34176 7.36109 9.34176 7.65672V11.9135C9.34176 12.2785 9.04612 12.5741 8.68113 12.5741C8.31614 12.5741 8.02051 12.2092 8.02051 11.9135V7.65672C8.02051 7.29173 8.31614 6.99609 8.68113 6.99609Z" fill="#003D71"/>
              <path d="M9.63628 4.79421C9.63628 5.32109 9.20877 5.74858 8.68191 5.74858C8.15504 5.74858 7.72754 5.32108 7.72754 4.79421C7.72754 4.26733 8.15504 3.83984 8.68191 3.83984C9.20877 3.83984 9.63628 4.26735 9.63628 4.79421Z" fill="#003D71"/>
            </g>
            <defs>
              <clipPath id="clip0_10539_149037">
                <rect width="16" height="16" fill="white" transform="translate(0.681641 0.0253906)"/>
              </clipPath>
            </defs>
          </svg>
          <div className="tooltip-ratings">
            <span className="t-base-small flex justify-content-start mb-3">Définitions</span>
            <div className="content flex flex-column">
              {props.labelTooltipContent.map(line => <div style={{width: '100%'}} className="content__line flex mb-2">
                <span className="rating"><StarsRating stars={4} rating={line.rating}/></span>
                <span className="label t-base-small u-color-primary">{line.label}</span>
              </div>)}
            </div>
          </div>
        </span>
        {(props.canAdd === undefined || !props.canAdd) &&
        <IconPlus
          {...props}
          onClick={() => disPlayEditForm(props.type)}
        />
        }
      </h3>
      {
        !EditMode
          ? getLineratingItemsByParent()
          : EditMode
      }
    </>
  )
}

const mapStateToProps = (state) => ({
})

function mapDispatchToProps(dispatch){
  return bindActionCreators({
    getUserProfile:_getUserProfile
  },dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps )(Competences)
