import * as _ from 'lodash';
import initialState from './initialState';

function UserCircle(state = initialState, action = {}) {
    if (_.isUndefined(action)) {
        return state;
    }

    const {type, payLoad} = action;

    if (type === 'GET_USER_CIRCLES') {
        return {
            userCircles: payLoad,
        }
    }

    return state;
}

export default UserCircle;
