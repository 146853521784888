const theme = {
  // Colors
  buttonTxt: 'rgb(80, 95, 121)',
  buttonBg: 'rgba(9, 30, 66, 0.04)',
  buttonBgHover: 'rgba(9, 30, 66, 0.08)',
  buttonBgActive: 'rgba(9, 30, 66, 0.12)',

  buttonTxtPrimary: '#FFF',
  buttonBgPrimary: '#50AAE0',
  buttonBgPrimaryHover: '#50AAE0',
  buttonBgPrimaryActive: '#50AAE0',

  buttonTxtDanger: '#FFF',
  buttonBgDanger : '#F44336',
  buttonBgDangerHover : '#F44336',
  buttonBgDangerActive : '#F44336',

  toolbarBdColor: '#E2E4E7',

  baseBlockColor: '#B9B9B9',
  baseBlockColorHover: '#A9A9A9',
  baseBlockColorFocus: '#999',
  blockHeight: '32px',

  // Toggle
  toggleBg: '#C4C4C4',
  toggleBgActive: '#50AAE0',
  toggleSize: '16px',
  togglePadding: '2px',
  toggleTransition: 'transform 300ms ease-in-out',
  handleBg: '#FFF',

  // input
  inputBorder: '#D4D4D4',
  inputBorderActive: '#50AAE0',
  inputSize: '24px',

  // segment controls
  segmentBg: '#F2F2F2',
  segmentBgActive: '#50AAE0',
  segmentColor: '#B9B9B9',
  segmentColorActive: '#FFF',

  // Sizing
  toolbarHeight: '42px',

  // Default
  pdsm: '6px',
  pdmd: '10px',
  pdlg: '20px'
}

export default theme
