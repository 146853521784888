import * as _ from 'lodash';
import initialState from './initialState';

function applicationStatusHistory(state = initialState, action = {}) {
    if (_.isUndefined(action)) {
        return state;
    }

    const { type, payLoad } = action;

    if (type === 'GET_APPLICATION_STATUS_HISTORIES') {
        return {
            histories: payLoad,
        }
    }

    return state;
}

export default applicationStatusHistory;
