import React, { createContext, useState } from 'react';

export const ListingCandidatesContext = createContext();

export function ListingCandidatesProvider({ children }) {

  const [data, setData] = useState({
    perLines: 25,
    statuses : {
      selection: [
        {label: 'A traiter', value: 'treatment'},
        {label: 'Refusé sur CV', value: 'refuse'},
        {label: 'StandBy', value: 'standby'},
        {label: 'A contacter', value: 'contact'}
      ],
      vivier: [
        {label: 'StandBy', value: 'standby'},
        {label: 'A contacter', value: 'contact'},
        {label: 'Refuser sur CV', value: 'refuse'},
        {label: 'Refuser sur entretien', value: 'refuse'},
        {label: 'NO GO Candidat', value: 'no-go-candidate'},
        {label: 'Entretien RH', value: 'rh-entretien'},
        {label: 'RDV 1', value: 'rdv-1'},
        {label: 'RDV 2', value: 'rdv-2'},
        {label: 'RDV 3', value: 'rdv-3'},
        {label: 'RDV 3', value: 'rdv-4'},
        {label: 'Décision CRC', value: 'decision-crc'}
      ],
      entretien: [
        {label: 'Entretien RH', value: 'rh-entretien'}
      ],
      offre: [],
      refus: []
    }
  });

  return (
    <ListingCandidatesContext.Provider value={[data, setData]}>
      {children}
    </ListingCandidatesContext.Provider>
  );
}
