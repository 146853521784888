import React from 'react'
import { debounce } from 'lodash';
import {bindActionCreators} from "redux";
import { connect } from 'react-redux';
import '../../../ui/style.scss'
import cx from 'classnames/bind'
import MonthAvailability from "../../../components/MonthAvailability";
import {AffectContext} from "../../../context/AffectContext";
import moment from 'moment';
import ConsultantMissionsTooltip from './view/ConsultantMissionsTooltip';
import { consultantMissionsCreators } from '../../../reducers/Consultants/actions/getConsultantMissions';

import './index.scss';

const ConsultantLinkWrapper = ({wrapper, toWrap, children}) => toWrap ? wrapper(children) : children;

const ConsultantItem = props => {
  const [checked, setChecked] = React.useState(false);
  const [modalAffect, setModalAffect] = React.useContext(AffectContext);
  const [visibility, setVisibility] = React.useState(false);

  React.useEffect(() => {
    setChecked(modalAffect.consultantsSelected.find(consultant => consultant.id === props.consultant.id))
  }, [props.consultant]);

  React.useEffect(() => {
    if (modalAffect.consultantsSelected.find(consultant => consultant.id === props.consultant.id)) {
      setChecked(true)
    } else {
      setChecked(false)
    }
  }, [modalAffect.consultantsSelected]);

  const selectConsultant = () => {
    if (props.readOnly === undefined) {
      if (modalAffect.consultantsSelected.find(consultant => consultant.id === props.consultant.id)) {
        setModalAffect({
          ...modalAffect,
          consultantsSelected: modalAffect.consultantsSelected.filter(consultant => consultant.id !== props.consultant.id)
        })
      } else {
        setModalAffect({
          ...modalAffect,
          consultantsSelected: [
            ...modalAffect.consultantsSelected,
            props.consultant
          ]
        })
      }
      setChecked(!checked)
    }
  }

  const selectConsultantOutside = () => {
    if (props.readOnly === undefined) {
      props.onChecked(props.consultant)
    }
  }

  const getPhoto = () => {
    let photo = ''

    if (props.consultant.photo && props.consultant.photo.contentUrl) {
      photo = props.consultant.photo.contentUrl
    } else if (typeof props.consultant.photo === 'string') {
      photo = props.consultant.photo
    } else {
      photo = props.consultant.profilePhoto ? props.consultant.profilePhoto.contentUrl : 'https://back-mystaff.convictionsrh.com/media/5cc3ac194031a_man-user.png'
    }

    return photo
  }

  const getRecentMissions = debounce(() => {
    setVisibility(true);
    props.consultant.id && props.getConsultantMissions(props.consultant.id);
  }, 400);

  const handleMouseOnLeave = () => {
    setVisibility(false);
    getRecentMissions.cancel();
    props.getConsultantMissions(null, true);
  }

  let positionName = '';
  if (props.outsideModal) {
    positionName = props.consultant.position && (typeof props.consultant.position === 'string' ?
      props.consultant.position : props.consultant.position.name);
  } else {
    positionName = props.consultant.positionName;
  }

  const arrivalDate = props.outsideModal ?
    props.consultant.arrivalDate : (
      props.consultant.application &&
      props.consultant.application.applicant &&
      props.consultant.application.applicant.arrivalDate
    );

  return (
    <div
      onClick={() => props.outsideModal && selectConsultantOutside()}
      style={props.itemStyle || {}}
      className={cx({
        "card-consultant": true,
        "card-consultant--checked": checked || props.checked
      })}>

      <div className="left">
        <div className="checker"
          onClick={() => !props.outsideModal && selectConsultant()}
          style={{
            display: props.readOnly === undefined ? 'flex' : 'none',
            ...(props.checkerStyle || {})
          }}
        >
          {(
            (checked || props.checked) && props.readOnly === undefined
          ) &&
          <span>
              <svg width="18" height="13" viewBox="0 0 18 13" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path fillRule="evenodd" clipRule="evenodd"
                    d="M17.7072 2.20718L7.00008 12.9143L0.292969 6.20718L1.70718 4.79297L7.00008 10.0859L16.293 0.792969L17.7072 2.20718Z"
                    fill="#00B5D4"/>
              </svg>
            </span>
          }
        </div>
        <div style={{flexDirection: 'column'}}>
          <span
            style={{margin: 0}}
            className={`left__name${!props.outsideModal ? ' consultant-mission-tooltip' : ''}`}
            onMouseEnter={() => !props.outsideModal && getRecentMissions()}
            onMouseLeave={() => !props.outsideModal && handleMouseOnLeave()}
          >
            <ConsultantLinkWrapper
              toWrap={!props.outsideModal && props.consultant.id}
              wrapper={children =>
                <a href={`/#/consultant/view/${props.consultant.id}`} rel="noopener noreferrer" target="_blank">
                  <u>{children}</u>
                </a>
              }
            >
              {props.consultant.fullname ? props.consultant.fullname : `${props.consultant.firstName} ${props.consultant.lastName}`}
            </ConsultantLinkWrapper>
            {!props.outsideModal && visibility && <ConsultantMissionsTooltip />}
          </span>
          <span className="left__grade">{positionName || ''}</span>
          {
            arrivalDate &&
            <span className="left__arrival">
              Arrivée le : {moment(arrivalDate).format('DD/MM/YY')}
            </span>
          }
        </div>
      </div>
      <div className="right">
        {props.outsideModal === undefined &&
        <MonthAvailability availabilities={props.consultant.availabilitiesData}/>}
        <img src={getPhoto()} alt="profile-pic"/>
      </div>
    </div>
  )
}

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      getConsultantMissions: consultantMissionsCreators.get_consultant_missions
    },
    dispatch
  );
};

export default connect(null, mapDispatchToProps)(ConsultantItem)
