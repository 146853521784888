import React from 'react'
import cx from 'classnames/bind.js'

const DynamicDropDown = ({
   setIsOpen,
   open,
   id,
   dropDownStatuses,
   processing,
   onStatusChange,
   defaultValue,
   readOnly = false,
   center = true
 }) => {
  const [current, setCurrent] = React.useState(defaultValue);

  React.useEffect(() => {
    setCurrent(defaultValue)
  }, [dropDownStatuses, defaultValue]);

  const statusChange = status => {
    onStatusChange(status)
    setIsOpen(false)
  }

  function openOpts(id) {
    if (open === id) {
      setIsOpen(() => false)
    } else {
      setIsOpen(() => id)
    }
  }

  return (
    <div
      className={cx({
        "ui-dynamic-dropdown": true,
        "mx-auto": center,
        "ui-dynamic-dropdown--open": open && (
          parseInt(open) === parseInt(id)
        ),
        "disabled": processing,
        "relative": true
      })}>
      <span
        style={{background: current && current['background_color'], color: current && current['color']}}
        onClick={() => (
          dropDownStatuses && dropDownStatuses.length > 0
        ) && openOpts(id)}
      >
        <label>{current && current.text}</label>
        {!readOnly &&
        <svg width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path
            d="M18.6603 9.29292L12.9532 3.58582L7.24609 9.29292L8.66031 10.7071L12.9532 6.41424L17.2461 10.7071L18.6603 9.29292Z"
            fill="white"/>
          <path
            d="M8.66031 13.2929L12.9532 17.5858L17.2461 13.2929L18.6603 14.7071L12.9532 20.4142L7.24609 14.7071L8.66031 13.2929Z"
            fill="white"/>
        </svg>}
      </span>
      {
        open && (
          parseInt(open) === parseInt(id)
        ) && !readOnly && <div className="options">
          {dropDownStatuses.map(opt => <span onClick={() => statusChange(opt)} key={opt.key} className="options__item">{opt.text}</span>)}
        </div>
      }
    </div>
  )
}

export default DynamicDropDown
