import React, { Component } from 'react'
import '../../styles/components/ExtraitAboutComponent.css'
import EditAboutForm from './editing/EditAboutForm';
import classNames from 'classnames/bind';

export default class ExtraitAboutComponent extends Component {

  constructor(props) {
    super(props)
    this.state = {
      'editing' : false,
      'more_profile' : false,
      'long_profile' : false
    }
  }

  render() {
    const classes = classNames({
      'box-about__content': (!this.state.editing),
      'box-about__content--fitcontent' : (!this.state.editing )
    });

    return (
      <div className="box-about" style={{position: 'relative'}}>
        { !this.state.editing && this.props.canEdit && <span
          onClick={() => this.setState({editing: true})}
          style={{
            position: 'absolute',
            top: -12,
            right: 0,
            zIndex: 9,
            cursor: 'pointer'
        }} className="edit">
          <span className="t-base-small c-blue">Description</span>
          <svg width="25" height="25" viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
<path fillRule="evenodd" clipRule="evenodd" d="M15.5606 5.56144C16.3092 4.81285 17.5283 4.81285 18.2983 5.56144L19.6886 6.95169C20.4371 7.70028 20.4371 8.91942 19.6886 9.6894L19.0956 10.2823L14.9682 6.15383L15.5606 5.56144ZM14.092 7.03001L6.55609 14.5659C6.21388 14.9296 6 15.4215 6 15.9348V18.6083C6 18.972 6.27805 19.25 6.64165 19.25H9.3152C9.82852 19.25 10.3204 19.0575 10.6841 18.6939L18.2194 11.1585L14.092 7.03001Z" fill="#00B5D4"/>
</svg>
</span>}
        <div className={classes}>
        { this.state.editing ? <EditAboutForm handleEdit={this.handleEdit} text={this.props.text}/> : <div className="mt-3" dangerouslySetInnerHTML={{__html: this.props.text}} />  }
        </div>
      </div>
    )
  }

  displayMoretext() {
    this.setState({
     'more_profile': !this.state.more_profile
    })
  }

  handleEdit = (val) => {
    this.setState({editing: val});
  }

}
