import React, { Component } from 'react'
import '../styles/components/FilterComponentRadio.css';

class FilterComponentRadio extends Component {
    constructor(props) {
        super(props);
        this.state =  {
            site: 'Non'
         };
    }

    onChange( val ) {
       if( this.state.site === val ) {
            this.setState({
                site:''
            });
       }  else {
        this.setState({
            site: val
        });
       }
    }

    componentDidUpdate(prevProps, prevState) {
        if( this.state.site !== prevState.site ) {
            this.props.select(
                this.state.site ,
                this.props.filter
           )
        }
    }

    render () {
        return (
            <React.Fragment>
                { this.props.choices.map( choice => <div className="custom-control custom-radio">
                    <input onClick={ (e) => this.onChange(e.target.value) } value={choice}  checked={this.state.site === choice}  onChange={this.onSiteChanged.bind(this)} type="radio" id="customRadio1" name="customRadio" className="custom-control-input"/>
                    <label className="custom-control-label" htmlFor="customRadio1">{choice}</label>
                </div>) }
            </React.Fragment>
        )
    }

    onSiteChanged(e) {
        this.setState({
          site: e.currentTarget.value
        });
    }
}

export default FilterComponentRadio
