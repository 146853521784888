import React, { Component } from 'react'
import classNames from 'classnames/bind';

export default class MonthAvailability extends Component {

  getColor( dispo, pot ) {

    const dispo_float = parseFloat(dispo);
    const pot_float = parseFloat(pot);

    const classes = classNames({
      'value': true,
      'green' : dispo_float < 0,
      'blue' : dispo_float === 0,
      'yellow' : dispo_float > 0 && (dispo_float/pot_float) * 100 <= 50,
      'orange' : dispo_float > 0 && ( 50 < (dispo_float/pot_float) * 100 < 100 ) <= 50,
      'red' : dispo_float > 0 && (dispo_float/pot_float) * 100 === 100
    });

    return classes;
  }
  render() {

    return (
      <div className="MonthAvailability">
        { this.props.availabilities && this.props.availabilities.map( (data, index) =>
            <div className="MonthAvailability__item" key={`month-${index}`}>
            <span>{data.month_nam}.</span>
            <div className={ this.getColor(data.dispo, data.pot) }>{Math.round(data.dispo)}j</div>
            </div>
        ) }
      </div>
    )
  }
}
