import React from 'react'
import StarsRating from "./components/StarsRating";
import InterestLevel from "./components/InterestLevel";
import {withRouter} from "react-router";
import {hasCap} from "../../helpers/app";
import ToolTipRatings from './ToolTipRatings';

const EditIcon = props => (
  <span onClick={props.onClick} className="edit" style={{
    position: 'absolute',
    top: 0,
    right: 12,
    cursor: 'pointer'
  }}>
    <svg width="20" height="19" viewBox="0 0 20 19" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path fillRule="evenodd" clipRule="evenodd"
            d="M12.5603 2.56144C13.3089 1.81285 14.528 1.81285 15.298 2.56144L16.6883 3.95169C17.4368 4.70028 17.4368 5.91942 16.6883 6.6894L16.0953 7.28233L11.9679 3.15383L12.5603 2.56144ZM11.0917 4.03001L3.55579 11.5659C3.21357 11.9296 2.99969 12.4215 2.99969 12.9348V15.6083C2.99969 15.972 3.27774 16.25 3.64135 16.25H6.31489C6.82821 16.25 7.32014 16.0575 7.68375 15.6939L15.2191 8.15851L11.0917 4.03001Z"
            fill="#00B5D4"/>
    </svg>
  </span>
)

const LineRatingItem = props => {

  const [canEdit, setCanEdit] = React.useState(false);

  const editRating = () => {
    props.onEdit(
      props.type,
      props.data
    )
  }

  React.useEffect(() => {
    if (props.match.path === '/my-profile' || hasCap('MANAGER', props.role)) {
      setCanEdit(true)
    }
  }, []);

  const isEvaluatedByOwner = () => props.data.ratings && props.data.ratings.length === 1 && props.data.ratings[0].addedByData.id === props.profile.id

  const getEvaluation = () => {
    let average = 0;
    if (props.data.ratings) {
      if (!isEvaluatedByOwner()) {
        const total = props.data.ratings.filter(item => item.addedByData.id !== props.profile.id).reduce((acc, item) => {
          acc += item.rating;
          return acc;
        }, 0)
        average = total / (
          props.data.ratings.filter(item => item.addedByData.id !== props.profile.id)
        ).length
      } else {
        average = props.data.ratings[0].rating
      }
    }
    return average
  }

  return (
    <div className={`col-md-${props.col} line-item-rating`}>
      <div>
        <span className="name">{props.data.experience ? props.data.experience.name : props.name}</span>
        <div className="rating">
          {canEdit && <EditIcon onClick={() => editRating()}/>}
          <StarsRating stars={4} rating={getEvaluation()} isEvaluatedByOwner={isEvaluatedByOwner()}/>
          <InterestLevel interestLevel={props.data.isInterested}/>
          <ToolTipRatings
            profileId={props.profile.id}
            ratings={props.data.ratings}
            profile={props.profile}
            average={getEvaluation()}
            isEvaluatedByOwner={isEvaluatedByOwner()}
            skillId={props.data.id}
            type={props.type}
            labelTooltipContent={props.labelTooltipContent}
          />
        </div>
      </div>
    </div>
  )
}

export default withRouter(LineRatingItem)
