import React from 'react'
import BoxComponent from "../../../components/BoxComponent";
import ParcoursListing from "./ParcoursListing";

const Parcours = props => {
  return (
    <>
      <BoxComponent custom_class='box-profile col-md-10 offset-md-1 row'>
        <ParcoursListing type="missions" userId={props.props.profile.id} match={props.props.match} role={props.props.role} label="Projets / Missions" datas={props.props.profile.professionalExperiences} activeTab={props.activeTab}/>
      </BoxComponent>
      <BoxComponent custom_class='box-profile col-md-10 offset-md-1 row'>
        <ParcoursListing type="formations" userId={props.props.profile.id} match={props.props.match} role={props.props.role}  label='Formations initiales' datas={props.props.profile.trainings}  activeTab={props.activeTab}/>
      </BoxComponent>
    </>
  )
}

export default Parcours
