export function buildUrl(filters) {
  return Object.entries(filters).reduce((acc, item) => {
    if(item[1] !== null && item[1] !== '' && item[1] !== 'null') {
      if (typeof item[1] === Object) {
        acc += `${item[0]}=${encodeURIComponent(item[1].join(','))}&`
      } else {
        acc += `${item[0]}=${encodeURIComponent(item[1])}&`
      }
    }
    return acc
  }, '');
}
