import React from 'react'
import StarsRating from "./components/StarsRating";
import {withRouter} from "react-router";
import {hasCap} from "../../helpers/app";
import ToolTipRatings from './ToolTipRatings';

const EditIcon = props => (
  <span onClick={props.onClick} className="edit">
    <svg width="20" height="19" viewBox="0 0 20 19" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path fillRule="evenodd" clipRule="evenodd" d="M12.5603 2.56144C13.3089 1.81285 14.528 1.81285 15.298 2.56144L16.6883 3.95169C17.4368 4.70028 17.4368 5.91942 16.6883 6.6894L16.0953 7.28233L11.9679 3.15383L12.5603 2.56144ZM11.0917 4.03001L3.55579 11.5659C3.21357 11.9296 2.99969 12.4215 2.99969 12.9348V15.6083C2.99969 15.972 3.27774 16.25 3.64135 16.25H6.31489C6.82821 16.25 7.32014 16.0575 7.68375 15.6939L15.2191 8.15851L11.0917 4.03001Z" fill="#00B5D4"/>
    </svg>
  </span>
)

const LineRatingItemSocle = props => {

  const [canEdit, setCanEdit] = React.useState(false);

  const editRating = () => {
    props.onEdit(
      props.type,
      props.data
    )
  }

  React.useEffect(() => {
    if( props.match.path === '/my-profile' || hasCap('MANAGER', props.role)) {
      setCanEdit(true)
    }
  }, []);

  const isEvaluatedByOwner = () => props.data.ratings && props.data.ratings.length === 1 && props.data.ratings[0].addedByData.id === props.profile.id

  const getEvaluation = () => {
    let average = 0;
    if (props.data.ratings) {
      if (!isEvaluatedByOwner()) {
        const otherRatings = props.data.ratings.filter(item => item.addedByData.id !== props.profile.id);
        const total = otherRatings.reduce((acc, item) => {
          acc += item.rating;
          return acc;
        }, 0)
        average = total / otherRatings.length;
      } else {
        average = props.data.ratings[0].rating
      }
    }
    return average
  }

  return (
    <div className={`col-md-${props.col} line-item-rating mb-4`}>
      <div className="flex align-items-start justify-content-between">
        <span className="name">
          <span className="socle-item-line relative" style={{maxWidth: '100%'}}>
            {props.data.coreSkill.name}
            {props.data.coreSkill.coreSkillDescriptions.length > 0 &&
            <>
            <svg style={{cursor: 'pointer'}} className="ml-2" width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
              <g clipPath="url(#clip0_10068_136991)">
                <path
                  d="M8 16C5.87808 16 3.8432 15.1569 2.3432 13.6568C0.8432 12.1567 0 10.1218 0 8C0 5.87824 0.84312 3.8432 2.3432 2.3432C3.84328 0.8432 5.87824 0 8 0C10.1218 0 12.1568 0.84312 13.6568 2.3432C15.1568 3.84328 16 5.87824 16 8C15.9975 10.1213 15.1537 12.1544 13.6544 13.6544C12.1544 15.1538 10.1213 15.9976 8 16ZM8 1.1744C6.19008 1.1744 4.45376 1.89378 3.17376 3.17376C1.89376 4.45374 1.1744 6.19008 1.1744 8C1.1744 9.80992 1.89378 11.5462 3.17376 12.8262C4.45374 14.1062 6.19008 14.8256 8 14.8256C9.80992 14.8256 11.5462 14.1062 12.8262 12.8262C14.1062 11.5463 14.8256 9.80992 14.8256 8C14.8237 6.19056 14.1037 4.45568 12.8243 3.17568C11.5443 1.8963 9.80928 1.17632 8 1.1744Z"
                  fill="#003D71"/>
                <path
                  d="M7.99974 6.9707C8.36473 6.9707 8.66036 7.3357 8.66036 7.63133V11.8881C8.66036 12.2531 8.36473 12.5488 7.99974 12.5488C7.63474 12.5488 7.33911 12.1838 7.33911 11.8881V7.63133C7.33911 7.26634 7.63474 6.9707 7.99974 6.9707Z"
                  fill="#003D71"/>
                <path
                  d="M8.95439 4.76882C8.95439 5.2957 8.52689 5.72319 8.00002 5.72319C7.47316 5.72319 7.04565 5.29569 7.04565 4.76882C7.04565 4.24194 7.47316 3.81445 8.00002 3.81445C8.52689 3.81445 8.95439 4.24196 8.95439 4.76882Z"
                  fill="#003D71"/>
              </g>
              <defs>
                <clipPath id="clip0_10068_136991">
                  <rect width="16" height="16" fill="white"/>
                </clipPath>
              </defs>
            </svg>
              <div className="tooltip-ratings">
                {props.data.coreSkill.coreSkillDescriptions.length > 0 &&
                  <>
                    <span className="mb-2">{props.data.coreSkill.name}</span>
                    <ul>
                      {props.data.coreSkill.coreSkillDescriptions.map(item =>
                        <li className="t-base-small u-color-primary mt-2">{item.description}</li>
                      )}
                    </ul>
                  </>
                }
              </div>
            </>
            }
          </span>
          {props.data.coreSkill.coreSkillDescriptions.length > 0 &&
          <ul>
            <li className="t-base-small mt-2" style={{
              color: '#BFBFC2',
              fontSize: 11
            }}>{props.data.coreSkill.coreSkillDescriptions[0].description}</li>
          </ul>
          }
        </span>
        <div className="rating flex align-content-center" style={{
          width: 'auto'
        }}>
          <StarsRating stars={4} rating={getEvaluation()} isEvaluatedByOwner={isEvaluatedByOwner()} />
          {canEdit && <EditIcon onClick={() => editRating()} />}
          <ToolTipRatings
            profileId={props.profile.id}
            ratings={props.data.ratings}
            profile={props.profile}
            average={getEvaluation()}
            isEvaluatedByOwner={isEvaluatedByOwner()}
            skillId={props.data.id}
            type={props.type}
            labelTooltipContent={props.labelTooltipContent}
          />
        </div>
      </div>
    </div>
  )
}

export default withRouter(LineRatingItemSocle)
