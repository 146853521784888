import { planningService } from "../../_services/planningService";
import { toast } from "react-toastify";
// import apiService from "../../conf/api.custom";

export const REQUEST_DISPOS = 'get dispos';
export const FETCH_DISPOS = 'fetch planning';
export const FETCH_PLANNING_SUCCESS = 'fetch planning success';
export const FETCH_DISPOS_ERROR= 'fetch dispos error';

export const REQUEST_USEr_DISPOS = 'get user dispos';
export const FETCH_USER_DISPOS = 'fetch user dispos';
export const FETCH_USER_DISPOS_SUCCESS = 'fetch user dispos success';

export const TRY_ADD_USER_DISPO_COURTE = 'try add user dispo courte'
export const TRY_EDIT_USER_DISPO_COURTE =  'try edit user dispo courte'



export const requestUser = () => ({
    type: REQUEST_DISPOS,
});

export const fetchDisposSucess = data => ({
    type: FETCH_USER_DISPOS_SUCCESS,
    data
});

export const fetchPlanningSucess = data => ({
    type: FETCH_PLANNING_SUCCESS,
    data
});

export const tryAddUserDispoCourte = (content) => dispatch => {
    dispatch( requestUser() );
    return planningService.try_add_user_dispo(content).then(
        data => dispatch(fetchUserDispo() ),
        error => dispatch( fetchDisposError(error.response) )
    )
}


export const fecthDispos  = () => dispatch => {
    dispatch( requestUser() );
    return planningService.fetch_planning().then(
        data => dispatch( fetchPlanningSucess(data.data) ),
        error => dispatch( fetchDisposError(error.response) )
    )
}

export const fetchUserDispo = () => dispatch => {
    dispatch( requestUser() );
    return planningService.fetch_user_dispo().then(
        data => dispatch( fetchDisposSucess(data.data) ),
        error => dispatch( fetchDisposError(error.response) )
    )
}


export const tryBookDispo = (short_availabilities_id, content, full_name = '', unbook = false) => dispatch => {
    dispatch( requestUser() );
    return planningService.book_dispo(short_availabilities_id, content, unbook).then(
        () => { 
            dispatch( fecthDispos() )
            if( unbook ) {
                toast.success( `Vous venez de déplanifier ${full_name} `, {
                    position: toast.POSITION.BOTTOM_LEFT,
                });
            }else {
                toast.success( `Vous venez de planifier ${full_name} `, {
                    position: toast.POSITION.BOTTOM_LEFT,
                });
            }
            
        },
        error => dispatch( fetchDisposError(error.response) )
    )
}


export const fetchDisposError = error => ({
    type: FETCH_DISPOS_ERROR,
    error
});

export const tryEditUserDispoCourte = (content,id) => dispatch => {
    dispatch( requestUser() );
    return planningService.try_edit_user_dispo(content,id).then(
        data => dispatch( fetchUserDispo() ),
        error => dispatch( fetchDisposError(error.response) )
    )
}