import cx from 'classnames/bind';
import React from 'react'
import {usePrevious} from '../../hooks/usePrevious';

const CustomSelect = ({value, onChange, style, options, fullW, filterMode}) => {
  const [open, setOpen] = React.useState(false);
  const [data, setData] = React.useState(false);
  const [selected, setSelected] = React.useState(value);
  const prevSelected = usePrevious(selected)

  React.useEffect(() => {
    if(typeof options === 'function') {
      options().then(response => {
        setData(response.data)
        setSelected(value ? value : null)
      })
    } else {
      setData(options)
      setSelected(value ? value : options[0])
    }
  }, [value]);

  React.useEffect(() => {
    if(prevSelected !== undefined) {
      onChange(selected)
    }
  }, [selected]);

  return (
    <span className="flex--horizontal-space-b flex--vertical-space-c" style={{
      ...style,
      background: '#fff',
      color: '#00B5D4'
    }}>
      <span className="flex--vertical-space-c">
        <span style={{
          width: fullW ? '100%' : 'initial',
          minWidth:  '175px'
        }} className="ui-select flex align-items-center" onClick={ () => setOpen(!open)}>
          {selected ?  <span className={cx({selected})}>{selected} - {parseInt(selected) + 1}</span> : <span>Tous</span>}
            <i>
              <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M17.7072 9.29304L12.0001 3.58594L6.29297 9.29304L7.70718 10.7073L12.0001 6.41436L16.293 10.7073L17.7072 9.29304Z" fill="#00B5D4"/>
                <path d="M7.70718 13.293L12.0001 17.5859L16.293 13.293L17.7072 14.7073L12.0001 20.4144L6.29297 14.7073L7.70718 13.293Z" fill="#00B5D4"/>
              </svg>
            </i>
          {
            open &&
            <div className="choices flex flex--column">
              { filterMode !== undefined && <span className={cx({selected: selected === null})} onClick={() => setSelected(null)}>Tous</span> }
              {
                data.map((choice,index) => <span key={`key-${index}`} onClick={() => setSelected(choice)}>
                  {choice} - {parseInt(choice) + 1}
                </span>)
              }
            </div>
          }
        </span>
      </span>
    </span>
  )
}

export default CustomSelect
