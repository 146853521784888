import React from 'react'
import './index.scss'
import {bindActionCreators} from "redux";
import {hasCap} from '../../helpers/app';
import {_getUserProfile} from "../../reducers/App/actions/user";
import {profile_creators} from "../../reducers/Profile/actions/_update_profile";
import {withRouter} from "react-router-dom";
import {connect} from "react-redux";
import ExterneFeedbacks from './components/ExterneFeedbacks';
import Resume from "./components/Resume";
import Expertise from "./components/Expertise";
import Parcours from "./components/Parcours";
import cx from 'classnames/bind'


const profileContainer = props => {

  const [activeTab, setActiveTab] = React.useState('resume');

  React.useEffect(() => {
    if( props.location.pathname === '/' ) {
      window.location.replace('#/my-profile')
    }
    if( props.match.params.id ) {
      props.getUserProfile(
        props.match.params.id
      );
    }else {
      props.getUserProfile(
        localStorage.getItem('user_id')
      );
    }
    return () => {
      props.getUserProfile(null)
    };
  }, [props.location]);

  function onProfileChange(value) {
    props.updateProfile(
      props.profile.id,
      value
    );
  }

  return (
    props.profile !== null &&
    <div className="profile">
      <header>
        <div className="container">
          <div className="col-md-11 mx-auto">
            <h2>
              {props.profile.firstName} {props.profile.lastName}
              { (props.profile.availability || props.profile.actif ) && <span className="available" /> }
            </h2>
            <div className="tabs-container">
              <span onClick={ () => setActiveTab('resume') } className={cx({active: activeTab === 'resume'})}>Résumé</span>
              <span onClick={ () => setActiveTab('expertise') }className={cx({active: activeTab === 'expertise'})}>Expertises</span>
              <span onClick={ () => setActiveTab('parcours') } className={cx({active: activeTab === 'parcours'})}>Parcours</span>
              {props.profile.externe  && hasCap('CONSULTANT_SENIOR', props.role) && <span onClick={ () => setActiveTab('feedback-history') } className={cx({active: activeTab === 'feedback-history'})}>Historique des échanges</span>}
            </div>
        </div>
        </div>
      </header>
      <div className="page container">
        { activeTab === 'resume' && <Resume onProfileChange={ value => onProfileChange(value) } props={props} /> }
        { activeTab === 'expertise' && <Expertise props={props} /> }
        { activeTab === 'parcours' && <Parcours props={props} activeTab={activeTab}/> }
        { activeTab === 'feedback-history' && <ExterneFeedbacks userId={props.match.params.id} props={props} activeTab={activeTab}/> }
      </div>
    </div>
  )
}

const mapStateToProps = (state) => ({
  profile : state.Profile.profile,
  loading : state.App.isLoading,
  role: state.Role.role
})

function mapDispatchToProps(dispatch){
  return bindActionCreators({
    getUserProfile:_getUserProfile,
    updateProfile: profile_creators.update_profile
  },dispatch);
}

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(profileContainer)
)
