import React from 'react'
import cx from 'classnames/bind'

const HalfDaysButton = props => {
  const [selected, setSelected] = React.useState(0.5);
  const durations = [0.5,1,1.5,2,2.5,3,3.5,4,4.5,5]

  React.useEffect(() => {
    props.onUpdateState(selected)
  }, [selected]);

  return (
    <div className='buttons-day'> {
      durations.map((duration, index) => {
        return(
          <button key={index} className={ cx({selected: duration === selected})} onClick={() => setSelected(duration)}>
            {duration}
          </button>
        )
      })
    } </div>
  )
}

export default HalfDaysButton
