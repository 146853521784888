import Axios from 'axios';
import moment from 'moment';
import React from 'react'
import {ErrorMessage, Field, Formik} from "formik";
import * as Yup from "yup";
import {Link} from 'react-router-dom';
import {AtsService} from '../../_services/AtsService';
import {newConnectConfig} from '../../_services/contant';
import ErrorMesage from '../../Formik/ErrorMesage';
import CustomSearch from '../../Formik/Fields/CustomSearch';
import DatePickerField from '../../Formik/Fields/DatePickerField';
import ExpAdderField from '../../Formik/Fields/ExpAdderField';
import FileUploadMissionForm from '../../Formik/Fields/FileUploadMissionForm';
import FiscalYear from '../../Formik/Fields/FisacalYear';
import InputField from '../../Formik/Fields/InputField';
import SelectField from '../../Formik/Fields/SelectField';
import TextareaInput from '../../Formik/Fields/TextareaInput';
import UserSearchField from '../../Formik/Fields/UserSearchField';
import {SubmitButtonWithControlledDisable} from '../../ui/ButtonWithControlledDisable';

let defaultState = {
  title: '',
  description: '',
  client: '',
  leader: '',
  invoicingMode: '',
  etp: '',
  priority: '',
  finishDate: '',
  startDate: '',
  city: '',
  missionFields: [],
  missionLanguages: [],
  missionSolutions: [],
  businessCode: '',
  missionSkills: [],
  requiredExperience: '',
  requiredPositions: '',
  approach: '',
  myTempsProjectId: 0,
  fiscalYear: moment().format('YYYY')
}

const FormCreateBesoin = ({selectData, mission, onSubmit, missionId, onDuplicate, isDuplicata, match, currentFiscalYear}) => {
  const requiredMatching = 3
  const [missionState, setMissionState] = React.useState(defaultState);
  const [minMatching, setMinMatching] = React.useState([]);
  const [businessCode, setBusinessCode] = React.useState([]);

  React.useEffect(() => {
    if ((
      mission && mission.id
    ) && missionId) {
        setMissionState({
        title: mission.title,
        description: mission.description,
        client: mission.client,
        leader: {id: mission.leaderId},
        invoicingMode: mission.invoicingMode ?
          selectData['invoicing_mode'].find(item =>
            parseInt(item.value) === parseInt(mission.invoicingMode.id)) : '',
        etp: selectData['etp'].find(item => parseInt(item.value) === parseInt(mission.etp.id)),
        priority: selectData['prioritie'].find(item => parseInt(item.value) === parseInt(mission.priority.id)),
        approach: {
          label: mission.approachData.text,
          value: mission.approachData.key
        },
        myTempsProjectId: mission.myTempsProjectId ? mission.myTempsProjectId : null,
        finishDate: mission.finishDate,
        fiscalYear: mission.fiscalYear,
        startDate: mission.startDate,
        businessCode: mission.businessCode ? {
          value: mission.businessCode,
          label: mission.businessCode
        } : '',
        city: mission.city,
        missionFields: buildDefaultExp(mission, 'missionFields', 'field'),
        missionLanguages: buildDefaultExp(mission, 'missionLanguages', 'language'),
        missionSolutions: buildDefaultExp(mission, 'missionSolutions', 'solution'),
        missionSkills: buildDefaultExp(mission, 'missionSkills', 'skill'),
        requiredExperience: mission.requiredExperience,
        requiredPositions: mission.requiredPositions.map(positionItem => ({
          value: positionItem.id,
          label: positionItem.name
        }))
      })
    } else {
      if (currentFiscalYear) {
        defaultState = {
          ...defaultState,
          fiscalYear: currentFiscalYear
        }
      }
      setMissionState(defaultState)
    }
  }, [mission, selectData, currentFiscalYear]);

  function buildDefaultExp(mission, type, key) {
    return mission[type].reduce((acc, item) => {
      acc.push({
        rating: item.rating,
        [key]: {
          id: item.experience.id,
          label: item.experience.name
        }
      })
      return acc;
    }, [])
  }

  const schema = Yup.object().shape({
    title: Yup.string().required('Obligatoire'),
    leader: Yup.string().required('Obligatoire'),
    client: Yup.string().required('Obligatoire'),
    finishDate: Yup.string().required('Obligatoire'),
    startDate: Yup.string().required('Obligatoire'),
    invoicingMode: Yup.string().required('Obligatoire'),
    etp: Yup.string().required('Obligatoire'),
    priority: Yup.string().required('Obligatoire'),
    city: Yup.string().required('Obligatoire'),
    requiredExperience: Yup.string().required('Obligatoire'),
    requiredPositions: Yup.string().required('Obligatoire'),
    fiscalYear: Yup.string().required('Obligatoire'),
    approach: Yup.string().required('Obligatoire')
  });

  const submit = values => onSubmit(values)

  const handle = (type, data) => {
    setMinMatching(value => {
      return {
        ...value,
        [type]: data
      }
    })
  }

  const numberMatchingMissing = () => {
    return requiredMatching - Object.entries(minMatching).reduce(
      (acc, item) => {
        acc += item[1].length;
        return acc
      }, 0)
  }

  const getMinMatching = () => {
    return Object
      .entries(minMatching)
      .reduce((acc, item) => {
        acc += item[1].length;
        return acc
      }, 0)
  }

  const isMatchingComplete = () => {
    return getMinMatching() >= requiredMatching
  }

  return (
    missionState &&
    <Formik
      onSubmit={submit}
      initialValues={missionState}
      validationSchema={schema}
      enableReinitialize={true}
    >
      {(
        {handleSubmit, setFieldValue, isValid, values, handleChange}) => (
          <>
        <div className="actions" style={{
          position: 'absolute',
          top: 0,
          right: 0,
          display: 'flex',
          alignItems: 'center',
          flexDirection: 'row-reverse'
        }}>
          <SubmitButtonWithControlledDisable
            text={`${(missionId && !isDuplicata) ? "Éditer" : "Déposer"} cette offre`}
            isTargetDisabled={!isValid || !isMatchingComplete()}
            onClick={() => handleSubmit()}
            otherProps={{ style: { position: 'relative'} }}
          />
          {(
              missionId && !isDuplicata
            ) &&
            <button type="button" onClick={() => onDuplicate(values)} style={{ position: 'relative', marginRight: 8 }}
                    className="button-ui button-ui--white">
              Dupliquer
            </button>
          }
        </div>
        <form onSubmit={handleSubmit} className="mt-0">
          <div className="d-flex justify-content-between mb-5">
            <div className="header-feedback__title">
              <h1 style={{ lineHeight: 'initial' }}>
                {mission && !match.url.includes('duplicate') ? 'Édition de l\'offre' : 'Créer un besoin'}
                {mission && <small><Link to={`/missions/view/${mission.id}`}>Retour à la mission</Link></small>}
                <small style={{
                  fontWeight: 700,
                  color: '#00B5D4'
                }}>
                  {mission ? mission.title : 'Détail de l\'offre'}
                </small>
              </h1>
            </div>
          </div>
          <div className="form-content">
            <div className="form-section">
              <div className="form-section__title">Mission</div>
              <div className="form-fields">
                <div>
                  <Field setFieldValue={setFieldValue} obligatoire name="title" placeholder=""
                         label="Intitulé du besoin" component={InputField}/>
                  <ErrorMessage name="title" component={ErrorMesage}/>
                </div>

                <div>
                  <Field edit={mission !== null} setFieldValue={setFieldValue} required name="fiscalYear" placeholder=""
                         label="Année fiscale" component={FiscalYear}/>
                  <ErrorMessage name="fiscalYear" component={ErrorMesage}/>
                </div>

                <div>
                  <Field
                    handleChange={(field, data) => {
                      setFieldValue('businessCode','')
                      const url = `${newConnectConfig.API_PATH}/get-my-temps-data?property=projects&value=${data.customerValue}&filter_attribute=customer.value`;
                      Axios({
                        method: 'GET',
                        url,
                        headers: {"Authorization": `Bearer ${localStorage.getItem('token')}`}
                      }).then(
                        response => setBusinessCode(() => {
                          return response.data.reduce((acc, item) => {
                            acc.push({
                              value:item.label,
                              label:item.label
                            })
                            return acc;
                          }, [])
                        })
                      );
                      setFieldValue('myTempsProjectId', data.value)
                    }}
                    dataDisplay="formattedLabel"
                    selectedDisplay="customer.value"
                    endpoint="get-my-temps-data?property=projects"
                    setFieldValue={setFieldValue}
                    name="client"
                    placeholder={mission && mission.client}
                    label="Entreprise"
                    obligatoire
                    required
                    component={CustomSearch}
                  />
                  <ErrorMessage name="client" component={ErrorMesage}/>
                </div>

                <div>
                  <Field setFieldValue={setFieldValue} required name="leader"
                         // eslint-disable-next-line jsx-a11y/aria-role
                         placeholder={mission && mission.leaderName} label="Chef de projet" role="CONSULTANT_SENIOR"
                         component={UserSearchField}/>
                  <ErrorMessage name="leader" component={ErrorMesage}/>
                </div>

              </div>

              <div className="form-fields form-fields--full">
                <div>
                  <Field setFieldValue={setFieldValue} richtext obligatoire name="description" placeholder=""
                         label="Description du besoin" component={TextareaInput}/>
                  <ErrorMessage name="description" component={ErrorMesage}/>
                </div>
              </div>

              <div className="form-fields">
                <div>
                  <Field missionForm datas={selectData['invoicing_mode']} setFieldValue={setFieldValue} required
                         name="invoicingMode" placeholder="" label="Type" component={SelectField}/>
                  <ErrorMessage name="invoicingMode" component={ErrorMesage}/>
                </div>

                <div>
                  <Field missionForm datas={selectData['etp']} setFieldValue={setFieldValue} required name="etp"
                         placeholder="" label="Etps" component={SelectField}/>
                  <ErrorMessage name="etp" component={ErrorMesage}/>
                </div>

                <div>
                  <Field missionForm datas={selectData['prioritie']} setFieldValue={setFieldValue} required
                         name="priority" placeholder="" label="Priorité" component={SelectField}/>
                  <ErrorMessage name="priority" component={ErrorMesage}/>
                </div>
              </div>

              <div className="form-fields form-fields--full">
                <div>
                  <Field setFieldValue={setFieldValue} required name="startDate" placeholder="" label="Date de début"
                         component={DatePickerField}/>
                  <ErrorMessage name="startDate" component={ErrorMesage}/>
                </div>

                <div>
                  <Field setFieldValue={setFieldValue} required name="finishDate" placeholder="" label="Date de fin"
                         component={DatePickerField}/>
                  <ErrorMessage name="finishDate" component={ErrorMesage}/>
                </div>

                <div>
                  <Field setFieldValue={setFieldValue} obligatoire name="city" placeholder="" label="Localisation"
                         component={InputField}/>
                  <ErrorMessage name="city" component={ErrorMesage}/>
                </div>
              </div>

              <div className="form-fields form-fields--full">
                <div>
                  <Field setFieldValue={setFieldValue} required name="file" placeholder="" label="Brief besoin"
                         component={FileUploadMissionForm}/>
                  <ErrorMessage name="file" component={ErrorMesage}/>
                </div>
              </div>

              <div className="form-fields form-fields--full">
                <div>
                  <Field type="number" setFieldValue={setFieldValue} obligatoire name="requiredExperience"
                         placeholder="" label="Années d'expérience requises" component={InputField}/>
                  <ErrorMessage name="requiredExperience" component={ErrorMesage}/>
                </div>

                <div>
                  <Field isMulti requiredPositions datas={selectData['position']} setFieldValue={setFieldValue} required
                         name="requiredPositions" placeholder="" label="Grades" component={SelectField}/>
                  <ErrorMessage name="requiredPositions" component={ErrorMesage}/>
                </div>

                <div>
                  <Field datas={() => AtsService.getDbalDataForSelect('mission_approach_type')}
                         setFieldValue={setFieldValue} required name="approach" placeholder=""
                         label="Domaine d’expertise" component={SelectField}/>
                  <ErrorMessage name="approach" component={ErrorMesage}/>
                </div>

                <div>
                  <Field datas={businessCode} setFieldValue={setFieldValue}  name="businessCode" placeholder="" label="Code affaire" component={SelectField}/>
                  <ErrorMessage name="businessCode" component={ErrorMesage}/>
                </div>
              </div>

              <div className="form-section__title">
                Match automatique - 3 expertises obligatoires. 2 complémentaires recommandées.
              </div>
              {!isMatchingComplete() &&
              <div className="warning-notice mb-4">
                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path
                    d="M10.29 3.85923L1.82002 17.9992C1.64539 18.3017 1.55299 18.6445 1.55201 18.9937C1.55103 19.343 1.64151 19.6864 1.81445 19.9898C1.98738 20.2931 2.23675 20.546 2.53773 20.7231C2.83871 20.9002 3.18082 20.9954 3.53002 20.9992H20.47C20.8192 20.9954 21.1613 20.9002 21.4623 20.7231C21.7633 20.546 22.0127 20.2931 22.1856 19.9898C22.3585 19.6864 22.449 19.343 22.448 18.9937C22.4471 18.6445 22.3547 18.3017 22.18 17.9992L13.71 3.85923C13.5318 3.56533 13.2807 3.32235 12.9812 3.15371C12.6817 2.98508 12.3438 2.89648 12 2.89648C11.6563 2.89648 11.3184 2.98508 11.0188 3.15371C10.7193 3.32235 10.4683 3.56533 10.29 3.85923V3.85923Z"
                    stroke="#FFA300" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                  <path d="M12 9V13" stroke="#FFA300" stroke-width="2" strokeLinecap="round"
                        strokeLinejoin="round"/>
                  <path d="M12 17H12.01" stroke="#FFA300" strokeWidth="2" strokeLinecap="round"
                        strokeLinejoin="round"/>
                </svg>
                <p className="t-label-medium ml-2">
                  Il vous reste {numberMatchingMissing()} expertises à choisir
                </p>
              </div>
              }

              <div className="form-fields form-fields--full">
                <div className="form-fields__exp">
                  <Field labelTooltipContent={[
                    {rating: 1, label: 'Notions de bases'},
                    {rating: 2, label: 'En cours d’acquisition'},
                    {rating: 3, label: 'Bien acquis'},
                    {rating: 4, label: 'Maîtrise / Expertise'}
                  ]} handleChange={handle} type="skill" selectDatas={selectData['skill']} setFieldValue={setFieldValue}
                         name="missionSkills" label="Connaissances métiers" component={ExpAdderField}/>
                  <ErrorMessage name="missionSkills" component={ErrorMesage}/>

                  <Field labelTooltipContent={[
                    {rating: 1, label: 'Notions de bases'},
                    {rating: 2, label: 'En cours d’acquisition'},
                    {rating: 3, label: 'Bien acquis'},
                    {rating: 4, label: 'Maîtrise / Expertise'}
                  ]} handleChange={handle} type="solution" selectDatas={selectData['solution']} isMulti
                         setFieldValue={setFieldValue} name="missionSolutions" label="Solutions SIRH"
                         component={ExpAdderField}/>
                  <ErrorMessage name="missionSolutions" component={ErrorMesage}/>

                  <Field labelTooltipContent={[
                    {rating: 1, label: 'Peu ou pas mobilisé'},
                    {rating: 2, label: 'Rarement mobilisé'},
                    {rating: 3, label: 'Souvent mobilisé'},
                    {rating: 4, label: 'Très souvent mobilisé'}
                  ]} handleChange={handle} type="field" selectDatas={selectData['field']} isMulti
                         setFieldValue={setFieldValue} name="missionFields" label="Contextes d'applications"
                         component={ExpAdderField}/>
                  <ErrorMessage name="missionFields" component={ErrorMesage}/>

                  <Field labelTooltipContent={[
                    {rating: 1, label: 'Mission non réalisable, niveau insuffisant à l’écrit et/ou à l’oral'},
                    {rating: 2, label: 'Mission réalisable, niveau intermédiaire'},
                    {rating: 3, label: 'Mission réalisable, niveau avancé'},
                    {rating: 4, label: 'Mission réalisable, niveau maîtrise'}
                  ]} handleChange={handle} type="language" selectDatas={selectData['language']} isMulti
                         setFieldValue={setFieldValue} name="missionLanguages" label="Langues (Facultatif)"
                         component={ExpAdderField}/>
                  <ErrorMessage name="missionLanguages" component={ErrorMesage}/>
                </div>
              </div>

            </div>
          </div>
        </form>
          </>
      )}
    </Formik>
  )
}

export default FormCreateBesoin
