import React from 'react'
import '../ui/style.scss'

const Modal = props => {
  return(
  <>
    <div className="modal-container">
      <div className="modal-container__backdrop"/>
      <div className="modal-container__content">
        {props.data.component}
      </div>
    </div>
    </>
  )
}

export default Modal
