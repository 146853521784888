import * as _ from 'lodash'
import initialState from './initialState'

function Notifications(state = initialState, action) {

    if (_.isUndefined(action)) {
        return state
    }

    const { type, payload } = action

    switch (type) {
      case 'GET_NOTIFICATIONS':
        return {'notifications' : payload.data, refresh: false}
      case 'UPDATE_HAS_NOTIF':
          return {
            ...state,
            hasNotif: payload,
          }
      default:
        return state
    }

}

export default Notifications
