import {newConnectConfig } from './contant';
import Axios from 'axios';

export const circleService = {
    get_circles,
    get_circle,
}

function get_circles() {
    const url = `${newConnectConfig.API_PATH}${newConnectConfig.CIRCLE_ENDPOINT}`;

    return Axios({
        method: 'get',
        url,
        headers: {"Authorization" : `Bearer ${localStorage.getItem('token')}`}
    })
}

function get_circle(circleId) {
    const url = `${newConnectConfig.API_PATH}${newConnectConfig.CIRCLE_ENDPOINT}/${circleId}`;

    return Axios({
        method: 'get',
        url,
        headers: {"Authorization" : `Bearer ${localStorage.getItem('token')}`}
    })
}
